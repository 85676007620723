export default {
    name: 'CommonCheckbox',
    props: {
        label: String,
        checked:  Boolean,
        type: {
            type: String,
            default: 'default'
        }
    },
    methods: {
        clickHandler() {
            this.$emit('update:checked', !this.checked);
        }
    }
}