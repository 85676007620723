<style scoped>
    .preview_container {
        position: relative;
        min-height: 11%;
    }
    .the_preview {
        width: 150px;
        border-radius: 10px;
        /* cursor: pointer; */
    }
    .close_the_preview {
        position: absolute;
        top: 6px;
        right: 6px;
        background: url('../../assets/img/materials-close.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 20px;
        background-color: rgba(23, 30, 30, 0.8);
        width: 28px;
        height: 28px;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 16px;
    }
    .my_play_bg {
        position: absolute;
        top: 17px;
        left: 64px;

        background: url('../../assets/img/play.svg');
        background-position: center;
        background-repeat: no-repeat;

        background-color: #ffffffa0;
        width: 50px;
        height: 50px;
        padding-top: 10px;
        padding-left: 11px;
        border-radius: 25px;
    }

    .materials {
        height: 100%;
        min-width: 0;
        min-height: 0;
        padding: 20px 16px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        /* width: 200px; */
        z-index: 20;
        /* background-color: rgba(0,0,0,0.5); */
        /* background-color: black; */
    }

    /* -- */
    ::-webkit-scrollbar-button {
        width:4px;
        height:4px;
    }

    ::-webkit-scrollbar-track {
        background-color:#000;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #444;
        border: 2px solid #ccc;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#aaa;
    }

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* --- --- */

</style>


<template>
    <div class='scrolling_content materials pt-3'>
        <div v-for="preview in snapshots"
             v-bind:key="preview.id"
             class="text-center my-3 preview_container"
             @click="showPreview(preview)">
            <img class="the_preview" :src="preview.img_src"/>
            <div class="close_the_preview" @click.stop="deletePreview(preview)"></div>
            <!--
            <div v-if="preview.type_id==2" class="my_play_bg"></div>
            -->
        </div>
    </div>
</template>

<script>

    export default {
        name: 'DevArchiveMaterials',
        props: ['snapshots'],
    data: () => ({
    }),
    mounted() {
    //   window.$('.scrolling_content').css('max-height', window.innerHeight-60)
    
        window.resizeScrollingContent();
    },
    methods: {
        showPreview() {
            // this.$emit('showPreview', preview)
        },
        deletePreview(preview) {
            for(let i=0; i<this.snapshots.length; i++) {
                if(this.snapshots[i].img_id==preview.img_id) {
                    this.$emit('deletePreview', i)
                    this.$refs['CamsRef'].deleteSnapshot(i)
                    break
                }
            }
        },

    }
  }
</script>
