import ModeTumblr from '../../../components/mods/tumblr';


const SECONDS_TO_SHOW_CANCEL_BUTTON = 60;

export default {
    name: 'PricesUser',
    components: {
        ModeTumblr
        //todo
    },
    props: {
        selectedObj: {
            type: Object,
            default: null
        },
        mode: {
            type: String,
            default: null
        }
    },
    data: () => ({
        loading: false,

        objects: [],
        filteredObjects: [],
        competitors: null,

        categories: [],
        filteredCategories: [],
        products: [],
        filteredProducts: [],

        units: null,

        selectedObject: null,
        selectedCategories: [],
        selectedProduct: null,
        /** 
        0 - выбор объекта
        1 - дерево категорий/продуктов
        2 - загрузка изображения
        */
        state: 0,
        fileLoading: false,
        fileLoadedSuccessful: false,

        loadingPhotos: [],
        filesLength: 0,
        showCancelButton: false,
        // unmarked: {}
    }),
    watch: {
        selectedObj: {
            handler() {
                this.filterObjects();
            }
        }
    },
    mounted() {
        this.getObjects();
        if (this.mode !== 'barcodes') {
            this.getCategories();
            // this.getProducts();
            this.getUnits();
        } else {
            // todo
        }
    },
    methods: {
        inputChanged(event) {
            if (this.fileLoading) {
                return;
            }
            this.postRecognitionTasks(event.target.files);
            setTimeout( () => {
                this.showCancelButton = true;
            }, SECONDS_TO_SHOW_CANCEL_BUTTON * 1000)
        },
        cancelLoading() {
            this.fileLoading = false;
            document.getElementById('dsv-price-img').value = null;
            this.fileLoadedSuccessful = false;
        },
        modeChangeHandler(data) {
            this.$emit('modeChange', data);
        },
        selectObject(object) {
            this.selectedObject = object;
            this.nextState();
        },
        rejectObject() {
            this.selectedObject = null;
            this.selectedCategories = [];
            this.selectedProduct = null;
            this.changeState(0);
        },
        selectCategory(category) {
            this.selectedCategories.unshift(category);
            this.sortCategories();
            this.getProducts();
        },
        rejectCategory() {
            this.selectedCategories.shift();
            this.selectedProduct = null;
            this.changeState(1);
        },
        selectProduct(product) {
            this.selectedProduct = product;
            this.nextState();
        },
        rejectProduct() {
            this.selectedProduct = null;
            this.prevState();
        },
        sortCategories() {
            this.filteredCategories = [];
            this.categories.forEach( cat => {
                if (
                    (!this.selectedCategories.length && !cat.parent_id) ||
                    (this.selectedCategories.length && cat.parent_id === this.selectedCategories[0].id)
                ) {
                    this.filteredCategories.push(cat);
                }
            });
        },
        sortProducts() {
            if (!this.selectedCategories.length) {
                return;
            }
            this.filteredProducts = [];
            this.products.forEach( product => {
                if (product.category_id === this.selectedCategories[0].id) {
                    this.filteredProducts.push(product);
                }
            })
        },
        nextState() {
            if (this.mode === 'barcodes') {
                this.changeState(2);
                return;
            }
            if (this.state < 2) {
                this.changeState(this.state + 1);
            }
        },
        prevState() {
            if (this.mode === 'barcodes') {
                this.state == 0;
                return;
            }
            if (this.state > 0) {
                this.selectedProduct = null;
                if (this.state === 1) {
                    this.selectedCategories = [];
                }
                this.changeState(this.state - 1);
            } else {
                this.$emit('modeChange', {mode: 'cams'});
            }
        },
        endMode() {
            if (this.state > 0) {
                this.state = 0;
                this.selectedObject = null;
                this.selectedProduct = null;
                this.selectedCategories = [];
                this.sortCategories();
                // this.sortProducts();
            } else {
                this.$emit('modeChange', {mode: 'cams'});
            }
        },
        changeState(newState) {
            this.state = newState;
            if (this.mode !== 'barcode') {
                this.sortCategories();
                // this.sortProducts();
            }
        },
        filterObjects() {
            if (!this.selectedObj) {
                return;
            }
            this.filteredObjects = [];
            this.objects.forEach( obj => {
                if (obj.object_id === this.selectedObj.id) {
                    this.filteredObjects.push(obj);
                }
            })
        },
        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        setNextProduct() {
            const index = this.products.findIndex( p => p.id === this.selectedProduct.id);
            if (index < this.products.length - 1) {
                this.selectedProduct = Object.assign({}, this.products[index + 1]);
                this.selectedCategories = [];
                const catIndex = this.categories.findIndex( c => c.id === this.selectedProduct.category_id);
                if (catIndex) {
                    this.selectedCategories.push(this.categories[catIndex]);
                }
                return;
            }
            this.changeState(1);
            setTimeout( () => {
                this.selectedCategories = [];
                this.selectedProduct = null;
                this.sortCategories();
                // this.sortProducts();
            }, 100);
            return;
        },
        postRecognitionTasks(files) {
            this.loadingPhotos = [];
            this.filesLength = files.length;
            for (var i = 0; i < files.length; i++) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    const resultData = {
                        task_id: this.uuidv4(),
                        photos: []
                    };
                    const now = new Date();
                    const fileData = {
                        photo_id: this.uuidv4(),
                        date: now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2),
                        monitoring_object_id: this.selectedObject.id,
                        photo_base64: e.target.result
                    }
                    if (this.mode !== 'barcodes') {
                        fileData.product_id = this.selectedProduct.id
                    }
                    resultData.photos.push(fileData);
                    resultData.photos = JSON.stringify(resultData.photos);
                    this.fileLoading = true;
                    if (this.mode === 'barcodes') {
                        window.myAjax('POST', '/price-tag/barcode/task', resultData, (error) => {
                            this.loadingPhotos.push(fileData);
                            if (error) {
                                window.myAlert('Произошла ошибка загрузки фотографии, попробуйте позже.');
                            } else {
                                if (this.loadingPhotos.length === files.length) {
                                    this.fileLoading = false;
                                    document.getElementById('dsv-price-img').value = null;
                                    this.fileLoadedSuccessful = true;
                                    window.myAlert(`Загружено ${this.filesLength} фотографий`);
                                    setTimeout( () => {
                                        this.fileLoadedSuccessful = false;
                                    }, 2000)
                                }
                            }
                        });
                    } else {
                        window.myAjax('POST', '/price-tag/recognition-tasks', resultData, (error) => {
                            this.loadingPhotos.push(fileData);
                            if (error) {
                                window.myAlert('Произошла ошибка загрузки фотографии, попробуйте позже.');
                            } else {
                                if (this.loadingPhotos.length === files.length) {
                                    this.fileLoading = false;
                                    document.getElementById('dsv-price-img').value = null;
                                    this.fileLoadedSuccessful = true;
                                    setTimeout( () => {
                                        this.fileLoadedSuccessful = false;
                                        this.setNextProduct();
                                    }, 2000)
                                }
                            }
                        });
                    }
                    resultData.photos = [];
                };
                reader.readAsDataURL(files[i]);
            }
        },
        getObjects() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/monitoring_objects', {}, this.getObjectsHandler);
        },
        getObjectsHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.objects = response;
                this.filterObjects();
                this.getCompetitors();
            }
        },
        getCompetitors() {
            window.myAjax('GET', '/price-tag/competitors', {}, this.getCompetitorsHandler);
        },
        getCompetitorsHandler(error, response) {
            if (!error) {
                this.competitors = {};
                response.forEach( (comp) => {
                    if (!this.competitors[comp.id]) {
                        this.competitors[comp.id] = comp;
                    }
                });
            }
        },
        getCategories() {
            window.myAjax('GET', '/price-tag/categories', {}, this.getCategoriesHandler);
        },
        getCategoriesHandler(error, response) {
            if (!error) {
                this.categories = this.getCategoriesFromChildren(response);
                this.sortCategories();
            }
        },
        getProducts() {
            window.myAjax('GET', '/price-tag/products', {
                category_ids: (this.selectedCategories[0] || {}).id ? JSON.stringify([(this.selectedCategories[0] || {}).id]) : JSON.stringify([]),
            }, (error, response) => {
                if (!error) {
                    this.products = response;
                    this.sortProducts();
                }
            });
        },
        getCategoriesFromChildren(children) {
            let result = children;
            children.forEach(category => {
                result = result.concat( this.getCategoriesFromChildren(category.children) );
            });
            return result;
        },
        // monitoring_object_formats
        getUnits() {
            window.myAjax('GET', '/price-tag/measure_units', {}, this.getUnitsHandler);
        },
        getUnitsHandler(error, response) {
            if (!error) {
                this.units = {};
                response.forEach( (unit) => {
                    if (!this.units[unit.id]) {
                        this.units[unit.id] = unit;
                    }
                });
            }
        },
    }
}
