export default {
    name: 'SmartCams',
    components: {
        //
    },
    props: {
        filter: {
            type: Object,
            default: {}
        },
        obj: {
            type: Object,
            default: {}
        }
    },
    data: () => ({
        camsLoading: false,
        rawCams: [],
        cams: [],
        smartRecords: {},
        positions: [],
    }),
    watch: {
        obj: {
            handler() {
                this.sortCams()
            }
        }
    },
    mounted() {
        this.getCams();
    },
    methods: {
        getCams() {
            this.camsLoading = true;
            window.myAjax('GET', '/api/client-cameras', {}, this.getCamsCallback);
        },
        getCamsCallback(error, response) {
            this.camsLoading = false;
            if (!error) {
                this.rawCams = response.client_cameras;
                this.sortCams();
            }
        },
        sortCams() {
            this.cams = [];
            if (!(this.obj || {}).object_type) {
                return;
            }
            if ((this.obj || {}).id) {
                for (let cam of this.rawCams) {
                    if (cam.object_id === this.obj.id) {
                        this.cams.push(cam);
                    }
                }
            }
            this.loadingCamsCount = this.cams.length;
            for (let cam of this.rawCams) {
                this.getSmartRecords(cam);
            }
        },
        getSmartRecords(cam) {
            var start = new Date();
            start.setDate(start.getDate() - 365);
            start.setUTCHours(0,0,0,0);
            var end = new Date();
            end.setUTCHours(23,59,59,999);
            const data = Object.assign({
                camera_id: cam.id,
                start_time_in_sec: Math.floor(start.valueOf() / 1000),
                end_time_in_sec: Math.floor(end.valueOf() / 1000)
            })
            window.myAjax('GET', '/smart_archive/records', data, (error, response) => {
                this.sortedCamsCount--;
                if (!error) {
                    this.smartRecords[cam.id] = response;
                }
                console.log('this.sortedCamsCount', this.sortedCamsCount);
                if (this.sortedCamsCount === 0) {
                    console.log('this.smartRecords', this.smartRecords);
                }
            });
        },
    }
}
