const SOURCES_TITLES = {
    'digressions': 'Отклонения',
    'smart_archive': 'Смарт-архив',
    'archive': 'Архив'
};

export default {
    name: 'MediaSpaceList',
    components: {
        //todo
    },
    props: ['clientId'],
    data: () => ({
        loading: false,
        data: [],
        titles: {
            'digression': 'Отклонения',
            'smart_archive': 'Смарт-архив',
            'archive': 'Архив'
        },
        maxSize: 0.01,
        commonSize: 0,
        commonLastUpdate: 0,
    }),
    mounted() {
        this.getMediaSpace();
    },
    methods: {
        getMediaSpace() {
            this.loading = true;
            window.myAjax('GET', '/monitoring/media-space' , 
                this.clientId ? { client_id: this.clientId } : {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    response.forEach(space => {
                        const size = parseFloat(space.value_in_mb);
                        if (size > this.maxSize) {
                            this.maxSize = size;
                        }
                        this.commonLastUpdate = new Date(`${space.last_update} UTC`).toLocaleString();
                        this.commonSize += size;
                        const source = {
                            type: space.media_source,
                            title: SOURCES_TITLES[space.media_source],
                            size: size
                        }
                        this.data.push(source);
                    });
                }
            })
        },
        formatSize(num) {
            return parseFloat(num.toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        }
    }
}
