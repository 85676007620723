import CheckboxNew from './../checkboxNew';

export default {
    name: 'CheckboxListDropdown',
    components: {
        CheckboxNew
    },
    emits: ["update:value"],
    props: {
        values: {
            type: Array,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        optionNameField: {
            type: String,
            default: 'name',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        selectCaption: {
            type: String,
            default: 'Выберите',
        },
        emptyCaption: {
            type: String,
            default: 'Не найдено',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data:() => ({
        filter: {
            name: ''
        },
        filteredOptions: [],
        selectedAll: false,
        showPopup: false,
    }),
    watch: {
        options: {
            deep: true,
            handler() {
                this.filterChanged();
            }
        },
        filter: {
            deep: true,
            handler() {
                this.filterChanged();
            }
        },
        values: {
            deep: true,
            handler() {
                this.filterChanged();
            }
        }
    },
    mounted() {
        this.filterChanged();
    },
    methods: {
        selectAllToggler() {
            let updatedValue = [];
            if (!this.isSelectedAll()) {
                this.filteredOptions.forEach( option => {
                    updatedValue.push(option.id);
                })
            }
            this.$emit('update:value', updatedValue);
            // const findedIndex = this.hiddenGroups.indexOf(groupId);
            // if (findedIndex < 0) {
            //     this.hiddenGroups.push(groupId);
            // } else {
            //     this.hiddenGroups.splice(this.hiddenGroups.indexOf(groupId), 1);
            // }
        },
        updateValuesHandler(optionId, checked) {
            let updatedValue = [...this.values];
            this.selectedAll = false;
            if (checked) {
                updatedValue.push(optionId);
            } else {
                updatedValue.splice(updatedValue.indexOf(optionId), 1);
            }
            this.$emit('update:value', updatedValue);
        },
        isSelectedAll() {
            return this.options.length === this.values.length;
        },
        filterChanged() {
            this.filteredOptions = [];
            const searchStr = this.filter.name;
            if (searchStr.length) {
                
                this.options.forEach( option => {
                    if (option[this.optionNameField].toLowerCase().indexOf(searchStr.toLowerCase()) >= 0) {
                        this.filteredOptions.push(Object.assign({}, option))
                    }
                });
            } else {
                this.options.forEach( option => {
                    this.filteredOptions.push(Object.assign({}, option))
                });
                this.filteredCategories = this.categories;
            }
            this.filteredOptions = [...new Set(this.filteredOptions)];

            
            this.selectedAll = this.isSelectedAll();
            // const searchString = this.filter.name.trim();
        },
    },
}
