const activeCamera = {
    state: () => ({
        activeCam: null,
    }),

    getters: {
        ACTIVE_CAM: state => {
            return state.activeCam
        }
    },

    mutations: {
        SET_ACTIVE_CAM: (state, payload) => {
            state.activeCam = payload;
        }
    },

    actions: {
        WRITE_ACTIVE_CAM: (context, payload) => {
            context.commit('SET_ACTIVE_CAM', payload);
        }
    },
};

export default activeCamera;