/**
 * @typedef {object} getParams
 * @property {number} id Id корня чек-листа.
 */
/**
 * Получение чек-листа по id.
 * @param {getParams} params Параметры.
 */
export function getChecklist(params, callback) {
    window.myAjax('GET', '/api/checklist', {
        checklist_id: params.id
    }, function(error, response) {
        if (error) {
            window.myAlert('Ошибка, при получении списка чек-листов.');
        } else {
            callback(response);
        }
    })
}
