import CheckboxNew from './../../base/checkboxNew';

const GROUPS = [
    { id: 1, group: 'HUMAN', name: 'HUMAN', title: 'Поиск людей' },
    { id: 2, group: 'SMOKER', name: 'SMOKER', title: 'Поиск курильщиков' },
    { id: 3, group: 'FACE_VERIFY', name: 'FACE_VERIFY', title: 'Распознование лиц' },
    { id: 4, group: 'FACE_ADD_PERSON', name: 'FACE_ADD_PERSON', title: 'Распознование лиц (добавление)' },
    { id: 5, group: 'FACE_REMOVE_PERSON', name: 'FACE_REMOVE_PERSON', title: 'Распознование лиц (удаление)' },
    { id: 6, group: 'FACE_UPDATE_PERSON', name: 'FACE_UPDATE_PERSON', title: 'Распознование лиц (обновление)' },
    { id: 7, group: 'UNIFORM', name: 'UNIFORM', title: 'Распознавание головных уборов' },
    { id: 8, group: 'GLOVES', name: 'GLOVES', title: 'Распознавание перчаток' },
    { id: 9, group: 'CLEANING', name: 'CLEANING', title: 'Распознование уборки' },
    { id: 10, group: 'PRICE', name: 'PRICE', title: 'Распознавание ценников' },
    { id: 11, group: 'BARCODE', name: 'BARCODE', title: 'Распознавание штрихкодов' },
    { id: 12, group: 'BACTERICIDAL', name: 'BACTERICIDAL', title: 'Бактерицидная обработка' },
    { id: 13, group: 'PLATE', name: 'PLATE', title: 'Распознавание автономеров' },
    { id: 14, group: 'EQUIPMENT', name: 'EQUIPMENT', title: 'Распознавание инвентаря' },
    { id: 15, group: 'GLOVES_AND_COOKING', name: 'GLOVES_AND_COOKING', title: 'Распознавание перчаток и готовки' },
]

export default {
    name: 'PathList',
    components: {
        CheckboxNew
    },
    props: {
        cards: Array,
        clientId: Number,
    },
    data: () => ({
        loading: false,
        groups: [],
        paths: {},
        showAdded: false,
        edited: [],
        added: {
            ml_group_id: 0,
            video_card_id: 0,
            host: '',
            path: '',
            priority: '',
            client_id: null,
            available: true,
        },
        showed: [],
        clients: [],
    }),
    watch: {
        //todo
    },
    mounted() {
        this.getClients();
        //todo
    },
    methods: {
        getClients() {
            window.myAjax('GET', '/api/clients', {}, (error, response) => {
                if (!error) {
                    this.clients = response.clients;
                    this.getPaths();
                }
            });
        },
        getPaths() {
            this.loading = true;
            window.myAjax('GET', '/ml/module-paths', {}, this.getPathsHandler);
        },
        pathAvailableHandler(path, value) {
            path.available = value;
            this.save(path);
        },
        getPathsHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.paths = [];
                this.groups = GROUPS;
                this.groups.forEach( group => {
                    this.paths[group.id] = [];
                });
                (response || []).forEach( path => {
                    if (
                        ((this.clientId && path.client_id === this.clientId) ||
                        !this.clientId) && this.paths[path.ml_group_id]
                    ) {
                        this.paths[path.ml_group_id].push(Object.assign(path, {
                            host: (this.cards.find( card => card.id === path.video_card_id) || {}).host,
                        }));
                    }
                    if (this.paths[path.ml_group_id]) {
                        this.paths[path.ml_group_id].sort((a, b) => a.video_card_id - b.video_card_id);
                    }
                });
            }
        },
        
        cancel(path) {
            const index = this.edited.indexOf(path.id);
            if (index >= 0) {
                this.edited.splice(index, 1);
            } else {
                this.showAdded = false;
            }
        },
        remove(path) {
            window.myConfirm('Внимание!', 'Удалить путь из списка?', () => {
                if ((path || {}).id) {
                    window.myAjax('DELETE', '/ml/module-path', {
                        ml_path_id: path.id
                    }, (error) => {
                        if (!error) {
                            this.getPaths();
                        }
                    });
                }
            })
        },
        save(path) {
            window.myAjax((path || {}).id ? 'PUT' : 'POST', '/ml/module-path', Object.assign(path || {}, {
                client_id: this.clientId || path.client_id,
                ml_path_id: (path || {}).id ? path.id : null,
            }), (error) => {
                if (!error) {
                    this.getPaths();
                    if (!(path || {}).id) {
                        this.added = {
                            ml_group_id: 0,
                            video_card_id: 0,
                            host: '',
                            path: '',
                            priority: '',
                            client_id: null,
                        }
                    }
                }
            });
        },

        cardChangedHandler() {
            this.added.host = (this.cards.find( card => card.id === this.added.video_card_id) || {}).host;
        },
    }
}
