const api = "/api/messages/";

const Chat = {

    state: () => ({
        ChatRoomList: {
            chats: [],
            code: 0,
            number_of_chats: 0,
        },
        ChatMessages: {},
        showChatButton: true,
        openDialog: false,
        openChat: false,
        remoteOpening: null, // id for remote opening chat
        isNewMessages: false,
        timeOutID: null,
        timeOutIDForMessages: null,
        IDForMessages: null,
    }),

    getters: {
        IS_NEW_MESSAGES: state => {
            return state.isNewMessages
        },
        CHAT_ROOMS_LIST: state => {
            return state.ChatRoomList;
        },
        CHAT_MESSAGES_LIST: state => {
            return state.ChatMessages;
        },
        SHOW_CHAT_BUTTON: state => {
            return state.showChatButton
        },
        OPEN_DIALOG: state => {
            return state.openDialog
        },
        OPEN_CHAT: state => {
            return state.openChat
        },
        REMOTE_OPENING: state => {
            return state.remoteOpening
        }
    },

    mutations: {
        mutate(state, payload) {
            state[payload.property] = payload.burden
        }
    },

    actions: {
        DESTROY_CHAT_MESSAGES_TIMEOUT ( { commit, state } ) {
            clearTimeout(state.timeOutIDForMessages);
            commit('mutate', { property: 'IDForMessages', burden: null })
            commit('mutate', { property: 'timeOutIDForMessages', burden: null})
        },
        CHECK_NEW_CHAT_MESSAGES ( { state, commit, dispatch }, payload ) {
            commit('mutate', { property: 'IDForMessages', burden: payload.id })
            function refreshChatMessages() {
                if (state.IDForMessages !== null) {
                    let url = `${api}getChatNewMsgs`;
                    window.myAjax('POST', url, {chat_id: state.IDForMessages}, function (error, response) {
                        if (error) {
                            window.myAlert(response.message)
                        } else {
                            if (response.msgs.length > 0) {
                                dispatch('GET_CHAT_MESSAGES_LIST', {id: state.IDForMessages} )
                            }
                            let flag = setTimeout(refreshChatMessages, 7000);
                            commit('mutate', {property: 'timeOutIDForMessages', burden: flag})
                        }
                    })
                }
            }
            refreshChatMessages();
        },
        DESTROY_TIMEOUT ( { commit, state } ) {
            clearTimeout(state.timeOutID);
            commit('mutate', { property: 'timeOutID', burden: null})
        },
        CHECK_NEW_MESSAGES ( { commit } ) {
            function refreshChatFlag() {
                let url = `${api}getChatFlag`;
                window.myAjax('GET', url, {}, function (error, response) {
                    if (error) {
                        if (response.message === 'Вы не авторизованы.') {
                            setTimeout(refreshChatFlag, 20000);
                        } else {
                            window.myAlert(response.message)
                        }
                    } else {
                        commit('mutate', {
                            property: 'isNewMessages',
                            burden: response.flag
                        });
                        let flag = setTimeout(refreshChatFlag, 10000);
                        commit('mutate', {property: 'timeOutID', burden: flag})
                    }
                })
            }
            refreshChatFlag();
        },
        CHANGE_REMOTE_OPENING ( { commit }, payload) {
            commit('mutate', {
                property: 'remoteOpening',
                burden: payload.id
            });
        },
        OPEN_CHAT_BY_ID ( { commit, dispatch }, payload ) {
            dispatch('CHANGE_REMOTE_OPENING', {id: payload.id})
            commit('mutate', {
                property: 'openChat',
                burden: payload.openChat
            });
            commit('mutate', {
                property: 'showChatButton',
                burden: payload.showChatButton
            });
            commit('mutate', {
                property: 'openDialog',
                burden: payload.openDialog
            });
        },
        CHANGE_OPEN_CHAT ( { commit }, payload ) {
            commit('mutate', {
                property: 'openChat',
                burden: payload
            });
        },
        CHANGE_CHAT_BUTTON ( { commit }, payload) {
            commit('mutate', {
                property: 'showChatButton',
                burden: payload
            });
        },
        CHANGE_OPEN_DIALOG ( { commit }, payload) {
            commit('mutate', {
                property: 'openDialog',
                burden: payload
            });
        },
        RESET_CHAT_ROOM_LIST ( { commit, dispatch }) {
            commit('mutate', {
                property: 'ChatRoomList',
                burden: {
                    chats: [],
                    code: 0,
                    number_of_chats: 0,
                }
            });
            dispatch('GET_CHAT_ROOMS_LIST', {page: 0, perpage: 20})
        },
        async GET_CHAT_ROOMS_LIST ({ commit }, payload) {
            return new Promise((resolve) => {
                let url = `${api}getChats/${payload.page}/${payload.perpage}`;
                window.myAjax(
                    'GET',
                    url,
                    {},
                    function (error, response) {
                        if (error) {
                            window.myAlert(response.message)
                            resolve(false);
                        } else {
                            if (response.chats.length > 0) {
                                resolve(true);
                                commit('mutate', {
                                    property: 'ChatRoomList',
                                    burden: response
                                });
                            }
                        }
                    }
                );
            });
        },
        async GET_CHAT_MESSAGES_LIST ({ commit }, payload) {
            return new Promise((resolve) => {
                let url = `${api}getRoom`;
                window.myAjax(
                    'POST',
                    url,
                    {chat_id: payload.id},
                    function (error, response) {
                        console.log(response, 'msgs2')
                        if (error) {
                            window.myAlert(response.message)
                            resolve(false);
                        } else {
                            resolve(true);
                            commit('mutate', {
                                property: 'ChatMessages',
                                burden: response
                            });
                        }
                    }
                );
            });
        },
        async PUSH_MESSAGE (context, payload) {
            return new Promise((resolve) => {
                let url = `${api}sendChatMsg`;
                window.myAjax(
                    'POST',
                    url,
                    {
                        chat_id: payload.id,
                        message: payload.message,
                        user_fio: payload.user_fio,
                        user_base_role: payload.user_base_role
                    },
                    function (error, response) {
                        if (error) {
                            window.myAlert(response.message)
                        } else {
                            resolve(response);
                        }
                    }
                );
            });
        }
    },
};

export default Chat;