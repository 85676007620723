import HeaderBurger from './../headerBurger';
import HeaderItems from './../headerItems';
import HeaderSettings from './../headerBurger';
  
export default {
    name: 'HeaderLayout',
    components: {
        HeaderBurger,
        HeaderItems,
        HeaderSettings
    },
    props: [
        'menuOpened'
    ],
    data: () => ({
        // todo
    }),
    watch: {
        // todo
    },
    mounted() {
        // todo
    },
    methods: {
        
        // todo
    }
}
