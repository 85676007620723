const TYPES = ['digressions', 'smart_archive', 'archive', 'price_tag', 'barcode'];
const SOURCES_TITLES = {
    'digressions': 'Отклонения',
    'smart_archive': 'Смарт-архив',
    'archive': 'Архив',
    'price_tag': 'Ценники',
    'barcode': 'Сплошной мониторинг'
};

export default {
    name: 'MediaSpaceSettings',
    components: {
        //todo
    },
    props: ['clientId'],
    data: () => ({
        loading: false,
        oldSettings: {},
        settings: [],
        maxSize: 0.01,
        commonSize: 0,
        commonLastUpdate: 0,
    }),
    mounted() {
        TYPES.forEach( type => {
            this.settings.push({
                type: type,
                title: SOURCES_TITLES[type]
            })
        });
        this.getMediaSpaceSettigns();
    },
    methods: {
        getMediaSpaceSettigns() {
            this.loading = true;
            window.myAjax('GET', '/monitoring/media-space-settings' , 
                this.clientId ? { client_id: this.clientId } : {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    console.log(response)
                    this.settings = [];
                    this.oldSettings = {};
                    TYPES.forEach( type => {
                        const setting = (response || []).find( set => set.media_source === type );
                        if (setting) {
                            this.oldSettings[type] = setting;
                            let path = setting.path || '';
                            if ( path.length > 2 ) {
                                if (path.startsWith('/')) {
                                    path = path.substring(1, path.length);
                                }
                                if (path.endsWith('/')) {
                                    path = path.substring(0, path.length - 1);
                                }
                            }
                            this.settings.push({
                                type: type,
                                title: SOURCES_TITLES[type],
                                value: Number.isInteger(setting.retention_period_days) ? setting.retention_period_days : 0,
                                path: path
                            })
                            this.oldSettings[type].path = (setting.path || '').length > 2 ? setting.path.substring(1, setting.path.length-1) : '';
                        } else {
                            this.settings.push({
                                type: type,
                                title: SOURCES_TITLES[type]
                            })
                        }
                    });
                }
            })
        },
        postMediaSpaceSettigns() {
            if (this.settings.length) {
                const currentSetting = this.settings.pop();
                if ( this.isChanged(currentSetting) ) {
                    window.myAjax('POST', '/monitoring/media-space-settings?client_id=' + this.clientId , {
                        media_source: currentSetting.type,
                        path: (currentSetting.path || '').length > 0 ? `/${currentSetting.path}/` : '',
                        retention_period_days: currentSetting.value
                    }, (error) => {
                        if (!error) {
                            this.postMediaSpaceSettigns();
                        }
                    });
                } else {
                    this.postMediaSpaceSettigns();
                }
            } else {
                console.log('done');
                this.getMediaSpaceSettigns();
            }
        },
        isChanged(setting) {
            const oldSetting = this.oldSettings[setting.type];
            if (!oldSetting) {
                return setting.value > 0 || (setting.path || '').length > 0;
            }
            if (
                ( oldSetting.retention_period_days !== setting.value ) ||
                ( oldSetting.path !== setting.path )
            ) {
                return true;
            }
            return false;
        },
        validatePath(event) {
            if (/[0-9a-zA-Z_/]/.test(event.key)) {
                return true;
            }
            event.preventDefault();
        }
    }
}
