import CheckboxNew from './../../../base/checkboxNew';

export default {
    name: 'UserList',
    components: {
        CheckboxNew
    },
    props:{
        options: {
            type: Array,
            default: []
        },
        selecting: {
            type: Boolean,
            default: false
        },
        selectingUsers: {
            type: Array,
            default: []
        }
    },
    data: () => ({
        loading: false,
        users: [],
        roles: [],
        // todo
    }),
    computed: {
        // fullname() {
        //     return this.user.last_name + ' ' + this.user.first_name + ' ' + (this.user.middle_name || '');
        // }
    },
    watch: {
        options: {
            handler(value) {
                console.log('options', value)
                this.users = this.options;
            }
        }
    },
    mounted() {
        this.users = this.options;
        this.roles = window.myRoles;
    },
    methods: {
        selectUser(userId, checked) {
            let updatedUsers = [...this.selectingUsers];
            if (checked) {
                updatedUsers.push(userId);
            } else {
                updatedUsers.splice(updatedUsers.indexOf(userId), 1);
            }
            this.$emit('updateSelected', updatedUsers);
        },
        userCard(user) {
            if (this.selecting) {
                return;
            }
            this.$emit('open', user);
        },
        userStatusStr(status_id) {
            let res
            switch(status_id) {
                case 1:
                    res = 'Заблокирован'
                    break
                case 10:
                    res = 'Активен'
                    break
            }
            return res
        },
        userFio(user_profile) {
            let res
            if(!user_profile)
                res = 'Профиль пользователя не создан'
            else if(user_profile.middle_name)
                res = user_profile.last_name + ' ' + user_profile.first_name + ' ' + user_profile.middle_name
            else
                res = user_profile.last_name + ' ' + user_profile.first_name
            return res
        },
    }
}
