export default {
    name: 'PersonsList',
    components: {
        // todo
    },
    props:{
        type: {
            type: String
        },
        filter: {
            type: String
        },
        // todo
    },
    data: () => ({
        resp: [],
        persons: [],
        loading: false,
    }),
    computed: {
        // todo
    },
    watch: {
        filter: {
            handler() {
                this.filterPersons();
                // todo
            }
        },
        type: {
            handler() {
                this.filterPersons();
                // todo
            }
        }
    },
    mounted() {
        this.getPersons();
    },
    methods: {
        getPersons() {
            this.loading = true;
            window.myAjax('GET', '/ml/face_recognition/persons', {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    this.resp = response;
                    this.filterPersons();
                }
            })
        },
        filterPersons() {
            this.persons = [];
            const filteredPersons = []
            if (this.filter.length > 0) {
                (this.resp || []).forEach(person => {
                    if (person.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) {
                        filteredPersons.push(person);
                    }
                });
            } else {
                (this.resp || []).forEach(person => {
                    filteredPersons.push(person);
                });
            }
            (filteredPersons || []).forEach( person => {
                if (this.type === 'none') {
                    if (person.person_type === null) {
                        this.persons.push(person);
                    }
                } else {
                    if (person.person_type === this.type) {
                        this.persons.push(person);
                    }
                }
            });
        }
    }
}
