import NeuralList from './../list';
import Checkbox from './../../base/checkbox';
import CheckboxNew from './../../base/checkboxNew';
import CheckboxListDropdown from './../../base/checkboxListDropdown';
import { STEPS } from './../utils'; 

import ScheduleDays from './../../base/scheduleDays'

const AVAILABLE_TO_CHANGE_STEP = [
    'queue_detection',
    'license_plate_detection',
    'smoker_detection',
    'uniform_detection',
    'gloves_detection',
    'bac_treatment',
    'human_count',
    'equipment_detection',
    'absence_and_queue',
]
// const MODULE_NAMES = {
//     'smoker_detection': 'Курильщики',
//     'queue_detection': 'Наличие очереди',
//     'absence_and_queue': 'Очередь и отсутствие продавца',
//     'uniform_detection': 'Контроль шапок',
//     'gloves_detection': 'Контроль перчаток',
//     'cleaning_detection': 'Уборка помещений',
//     'human_count': 'Подсчёт посетителей',
//     'absence_in_zone': 'Отсутствие продавца/кассира',
//     'license_plate_detection': 'Распознование автономеров',
//     'first_in_detection': 'Контроль времени открытия',
//     'last_out_detection': 'Контроль времени закрытия',
//     'bac_treatment': 'Бакобработка',
//     'equipment_detection': 'Распознавание инвентаря',
// }

export default {
    name: 'NeuralContent',
    components: {
        NeuralList,
        Checkbox,
        CheckboxNew,
        CheckboxListDropdown,

        ScheduleDays,
    },
    props: {
        selectedTasks: {
            type: Array,
            default: []
        },
        page: {
            type: Number,
            required: true
        },
        limit: {
            type: Number,
            required: true
        },
        selectedObjects: {
            type: Array,
            default: []
        },
        selectedCameras: {
            type: Array,
            default: []
        },
        selectedTypes: {
            type: Array,
            default: []
        },
    },
    data: () => ({
        steps: STEPS,
    
        isChangeTime: false,
        isChangeStep: false,
        isChangeDays: false,

        timeStart: '12:00',
        timeEnd: '14:00',
        changerStep: null,
        selectedDays: [],

        loading: false,
        initLoading: false,

        tasks: [],
        errorsCount: 0,
    }),
    watch: {
        selectedTasks: {
            handler() {
                // todo
            },
        },
    },
    mounted() {
        console.log(this.selectedTasks);
        this.changerStep = this.steps[5];
        this.getTasks();
        // todo
    },
    destroyed() {
        // todo
    },
    methods: {
        getTasks() {
            this.initLoading = true;
            window.myAjax('GET', '/ml/tasks', {
                page: this.page,
                limit: this.limit,
                object_ids: JSON.stringify(this.selectedObjects),
                camera_ids: JSON.stringify(this.selectedCameras),
                task_types: JSON.stringify(this.selectedTypes),
            }, (error, response) => {
                if (!error) {
                    this.tasks = [];
                    for (const [key, value] of Object.entries(response)) {
                        if (this.selectedTasks.includes(key)) {
                            this.tasks.push(value);
                        }
                    }
                    this.initLoading = false;
                    console.log(this.tasks);
                } else {
                    window.myAlert('Произошла ошибка. Обратитесь к администратору.');
                }
            });
        },
        cancel() {
            this.$emit('cancel');
        },
        change() {
            if (!this.isChangeTime && !this.isChangeStep && !this.isChangeDays) {
                return;
            }
            this.errorsCount = 0;
            this.changeNext();
        },

        changeNext() {
            if (!this.tasks.length) {
                this.loading = false;
                if (this.errorsCount) {
                    window.myAlert(`Произошла ошибка. Обратитесь к администратору.\nКоличество ошибок: ${this.errorsCount}`);
                } else {
                    window.myAlert('Модули были изменены');
                }
                this.$emit('cancel');
                return;
            }
            this.loading = true;
            let currentTask = this.tasks.shift();
            if (this.isChangeTime) {
                currentTask = this.changeTime(currentTask);
            } else {
                currentTask.start_datetime = currentTask.start;
                currentTask.stop_datetime = currentTask.stop;
            }
            if (this.isChangeStep && AVAILABLE_TO_CHANGE_STEP.includes(currentTask.task_type)) {
                currentTask = this.changeStep(currentTask);
            }
            if (this.isChangeDays) {
                currentTask = this.changeDays(currentTask);
            }
            // console.log(currentTask);
            // this.changeNext();
            window.myAjax('POST', `/ml/${currentTask.task_type}/update_schedule`, Object.assign({
                task_id: currentTask.id,
                responsible_ids: currentTask.responsible_ids ? JSON.stringify(currentTask.responsible_ids) : null,
                digression_ids: currentTask.digression_ids ? JSON.stringify(currentTask.digression_ids) : null,
            }, currentTask), (error) => {
                if (error) {
                    this.errorsCount++;
                }
                this.changeNext();
            });
        },

        changeTime(task) {
            // обновление для расписания
            let schedule = task.schedule.split(' ');
            const hours = [];
            const startTime = parseInt(this.timeStart.slice(0, 2));
            const startEnd = parseInt(this.timeEnd.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd + 1; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            schedule[1] = hours.join(',');
            task.schedule = schedule.join(' ');

            // обновление для полей start и stop
            let start = task.start.split(' ');
            let startDateTime = new Date(`${start[0]} ${this.timeStart}:00`);
            startDateTime.setHours(startDateTime.getHours() + diff);
            task.start_datetime = this.getFormat(startDateTime)

            let stop = task.stop.split(' ');
            let stopDateTime = new Date(`${stop[0]} ${this.timeEnd}:00`);
            stopDateTime.setHours(stopDateTime.getHours() + diff);
            task.stop_datetime = this.getFormat(stopDateTime)

            return task;
        },

        changeStep(task) {
            let schedule = task.schedule.split(' ');
            if (this.changerStep.minutes) {
                schedule[0] = `*${this.changerStep.value}`;
                schedule[5] = '';
            } else if (this.changerStep.seconds) {
                schedule[0] = '*';
                schedule[5] = `*${this.changerStep.value}`;
            }
            task.schedule = schedule.join(' ').trim();
            return task;
        },
        changeDays(task) {
            let schedule = task.schedule.split(' ');
            if (this.selectedDays.length) {
                schedule[4] = this.selectedDays.join(',');
            } else {
                schedule[4] = '*';
            }
            task.schedule = schedule.join(' ');
            return task;
        },

        getFormat(date) {
            return this.getFormatValue(date.getFullYear()) + "-"
                + this.getFormatValue((date.getMonth()+1))  + "-"
                + this.getFormatValue(date.getDate()) + " "
                + this.getFormatValue(date.getHours()) + ":"
                + this.getFormatValue(date.getMinutes()) + ":"
                + this.getFormatValue(date.getSeconds());
        },
        getFormatValue(timeValue) {
            const str = timeValue.toString();
            return str.length === 1 ? '0' + str : str;
        },
    }
}
