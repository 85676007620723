/**
 * @typedef {object} getParams
 * @property {number} id Id корня чек-листа.
 */
/**
 * Получение чек-листа по id.
 * @param {getParams} params Параметры.
 */
export function getChecklistLog(params, callback) {
    window.myAjax('GET', '/api/checklist-log', {
        root_id: params.id
    }, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    })
}
