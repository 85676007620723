export const PERIODS = {
    's3': '3 сек.',
    's5': '5 сек.',
    's10': '10 сек.',
    's15': '15 сек.',
    's30': '30 сек.',
    's45': '45 сек.',
    'm1': '1 мин.',
    'm2': '2 мин.',
    'm5': '5 мин.',
    'm10': '10 мин.',
    'm15': '15 мин.',
    'm30': '30 мин.',
    'h1': '1 час',
    'h2': '2 часа'
}

export const CRON_DAYS = {
    'mon': 1,
    'tue': 2,
    'wed': 3,
    'thu': 4,
    'fri': 5,
    'sat': 6,
    'sun': 0
}

export const WEEKDAYS = [
    { id: 'mon', index: 1, caption: 'Пн', title: 'Понедельник' },
    { id: 'tue', index: 2, caption: 'Вт', title: 'Вторник' },
    { id: 'wed', index: 3, caption: 'Ср', title: 'Среда' },
    { id: 'thu', index: 4, caption: 'Чт', title: 'Четверг' },
    { id: 'fri', index: 5, caption: 'Пт', title: 'Пятница' },
    { id: 'sat', index: 6, caption: 'Сб', title: 'Суббота' },
    { id: 'sun', index: 0, caption: 'Вс', title: 'Воскресенье' },
]

export const STEPS = [
    { id: '1', caption: '3 сек.', value: '/3', seconds: true, step: 3 },
    { id: '2', caption: '5 сек.', value: '/5', seconds: true, step: 5 },
    { id: '3', caption: '15 сек.', value: '/15', seconds: true, step: 15 },
    { id: '4', caption: '30 сек.', value: '/30', seconds: true, step: 30 },
    { id: '5', caption: '1 мин.', value: '/1', minutes: true, step: 60 },
    { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
    { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
    { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
    { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
    { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
    // { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
    // { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
]

export const RANGE_TIMES = [
    { value: '00:00', caption: '0:00 - 1:00' },
    { value: '00:30', caption: '0:30 - 1:30' },
    { value: '01:00', caption: '1:00 - 2:00' },
    { value: '01:30', caption: '1:30 - 2:30' },
    { value: '02:00', caption: '2:00 - 3:00' },
    { value: '02:30', caption: '2:30 - 3:30' },
    { value: '03:00', caption: '3:00 - 4:00' },
    { value: '03:30', caption: '3:30 - 4:30' },
    { value: '04:00', caption: '4:00 - 5:00' },
    { value: '04:30', caption: '4:30 - 5:30' },
    { value: '05:00', caption: '5:00 - 6:00' },
    { value: '05:30', caption: '5:30 - 6:30' },
    { value: '06:00', caption: '6:00 - 7:00' },
    { value: '06:30', caption: '6:30 - 7:30' },
    { value: '07:00', caption: '7:00 - 8:00' },
    { value: '07:30', caption: '7:30 - 8:30' },
    { value: '08:00', caption: '8:00 - 9:00' },
    { value: '08:30', caption: '8:30 - 9:30' },
    { value: '09:00', caption: '9:00 - 10:00' },
    { value: '09:30', caption: '9:30 - 10:30' },
    { value: '10:00', caption: '10:00 - 11:00' },
    { value: '10:30', caption: '10:30 - 11:30' },
    { value: '11:00', caption: '11:00 - 12:00' },
    { value: '11:30', caption: '11:30 - 12:30' },
    { value: '12:00', caption: '12:00 - 13:00' },
    { value: '12:30', caption: '12:30 - 13:30' },
    { value: '13:00', caption: '13:00 - 14:00' },
    { value: '13:30', caption: '13:30 - 14:30' },
    { value: '14:00', caption: '14:00 - 15:00' },
    { value: '14:30', caption: '14:30 - 15:30' },
    { value: '15:00', caption: '15:00 - 16:00' },
    { value: '15:30', caption: '15:30 - 16:30' },
    { value: '16:00', caption: '16:00 - 17:00' },
    { value: '16:30', caption: '16:30 - 17:30' },
    { value: '17:00', caption: '17:00 - 18:00' },
    { value: '17:30', caption: '17:30 - 18:30' },
    { value: '18:00', caption: '18:00 - 19:00' },
    { value: '18:30', caption: '18:30 - 19:30' },
    { value: '19:00', caption: '19:00 - 20:00' },
    { value: '19:30', caption: '19:30 - 20:30' },
    { value: '20:00', caption: '20:00 - 21:00' },
    { value: '20:30', caption: '20:30 - 21:30' },
    { value: '21:00', caption: '21:00 - 22:00' },
    { value: '21:30', caption: '21:30 - 22:30' },
    { value: '22:00', caption: '22:00 - 23:00' },
    { value: '22:30', caption: '22:30 - 23:30' },
    { value: '23:00', caption: '23:00 - 00:00' },
]

export const TIMES = [
    { value: '00:00', caption: '0:00' },
    { value: '01:00', caption: '1:00' },
    { value: '02:00', caption: '2:00' },
    { value: '03:00', caption: '3:00' },
    { value: '04:00', caption: '4:00' },
    { value: '05:00', caption: '5:00' },
    { value: '06:00', caption: '6:00' },
    { value: '07:00', caption: '7:00' },
    { value: '08:00', caption: '8:00' },
    { value: '09:00', caption: '9:00' },
    { value: '10:00' },
    { value: '11:00' },
    { value: '12:00' },
    { value: '13:00' },
    { value: '14:00' },
    { value: '15:00' },
    { value: '16:00' },
    { value: '17:00' },
    { value: '18:00' },
    { value: '19:00' },
    { value: '20:00' },
    { value: '21:00' },
    { value: '22:00' },
    { value: '23:00' },
    { value: '24:00' },
]

export const EQUIPMENT_CLASSES = {
    '0': 'разделочная доска',
    '1': 'разделочный нож',
}

export const COLORS = [
    { id: 0, name: 'синий' },
    { id: 1, name: 'коричневый' },
    { id: 2, name: 'зелёный' },
    { id: 3, name: 'иной цвет' },
    { id: 4, name: 'красный' },
    { id: 5, name: 'белый' },
    { id: 6, name: 'желтый' },
]

export const EQUIPMENT_COLORS = {
    '0': 'синий',
    '1': 'коричневый',
    '2': 'зелёный',
    '3': 'иной цвет',
    '4': 'красный',
    '5': 'белый',
    '6': 'желтый',
}

export const CAPTIONS = {
    'smoker_detection': 'Поиск курильщиков',
    'queue_detection': 'Обнаружение людей в заданной области',
    'absence_and_queue': 'Очередь и отсутствие продавца',
    'absence_in_zone': 'Отсутствие сотрудника в заданной области',
    'license_plate_detection': 'Распознование автомобильных номеров',
    'first_in_detection': 'Контроль времени открытия',
    'last_out_detection': 'Контроль времени закрытия',
    'face_recognition': 'Распознование лица',
    'uniform_detection': 'Контроль шапок',
    'gloves_detection': 'Контроль перчаток',
    'cleaning_detection': 'Уборка помещений',
    'human_count': 'Подсчёт посетителей',
    'bac_treatment': 'Бакобработка',
    'equipment_detection': 'Распознавание инвентаря',
}