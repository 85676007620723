const TransitionState = {
    Enter: 'enter',
    Entering: 'entering',
    Leave: 'leave',
    Leaving: 'leavng'
}

export default {
    name: 'BaseModal',
    props: {
        name: {
            required: true,
            type: String
        },
        resizable: {
            type: Boolean,
            default: false
        },
        resizeEdges: {
            default: () => ['r', 'br', 'b', 'bl', 'l', 'tl', 't', 'tr'],
            validator: val =>
            ['r', 'br', 'b', 'bl', 'l', 'tl', 't', 'tr'].filter(
                value => val.indexOf(value) !== -1
            ).length === val.length,
            type: Array
        },
        centerResize: {
            type: Boolean,
            default: true
        },
        resizeIndicator: {
            type: Boolean,
            default: true
        },
        adaptive: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: [Boolean, String],
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        focusTrap: {
            type: Boolean,
            default: false
        },
        reset: {
            type: Boolean,
            default: false
        },
        overlayTransition: {
            type: String,
            default: 'vm-transition--overlay'
        },
        transition: {
            type: String,
            default: 'vm-transition--modal'
        },
        clickToClose: {
            type: Boolean,
            default: true
        },
        classes: {
            type: [String, Array],
            default: () => []
        },
        styles: {
            type: [String, Array, Object]
        },
        minWidth: {
            type: Number,
            default: 0,
            validator(value) {
                return value >= 0
            }
        },
        minHeight: {
            type: Number,
            default: 0,
            validator(value) {
                return value >= 0
            }
        },
        maxWidth: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER
        },
        maxHeight: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER
        },
        width: {
            type: [Number, String],
            default: 600,
            validator(value) {
                return value === 'auto' || false
            }
        },
        height: {
            type: [Number, String],
            default: 300,
            validator(value) {
                return value === 'auto' || false
            }
        },
        shiftX: {
            type: Number,
            default: 0.5,
            validator(value) {
                return value >= 0 && value <= 1
            }
        },
        shiftY: {
            type: Number,
            default: 0.5,
            validator(value) {
                return value >= 0 && value <= 1
            }
        }
        },
        components: {
            //
        },
        data() {
            return {
                visible: false,
                visibility: {
                modal: false,
                overlay: false
                },
                overlayTransitionState: null,
                modalTransitionState: null,
                shiftLeft: 0,
                shiftTop: 0,
                modal: {
                width: 0,
                widthType: 'px',
                height: 0,
                heightType: 'px',
                renderedHeight: 0
                },
                viewportHeight: 0,
                viewportWidth: 0
            }
        },
        created() {
            this.setInitialSize();
        },
        beforeMount() {
            window.addEventListener('orientationchange', this.onWindowResize);
            this.onWindowResize();
            if (this.clickToClose) {
                window.addEventListener('keyup', this.onEscapeKeyUp);
            }
        },
        mounted() {
            this.$focusTrap = {};
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onWindowResize);
            window.removeEventListener('orientationchange', this.onWindowResize);
            if (this.clickToClose) {
                window.removeEventListener('keyup', this.onEscapeKeyUp);
            }
            document.body.classList.remove('vm--block-scroll');
        },
        computed: {
            guaranteedOverlayTransition() {
                return this.overlayTransition;
            },
            guaranteedModalTransition() {
                return this.transition;
            },
            isAutoHeight() {
                return this.modal.heightType === 'auto';
            },
            position() {
                const {
                    viewportHeight,
                    viewportWidth,
                    trueModalWidth,
                    trueModalHeight
                } = this
                const maxLeft = viewportWidth - trueModalWidth
                const maxTop = Math.max(viewportHeight - trueModalHeight, 0)
                return {
                left: maxLeft,
                top:
                    !trueModalHeight && this.isAutoHeight
                    ? undefined
                    : maxTop
                }
            },
            trueModalWidth() {
                const { viewportWidth, modal, adaptive, minWidth, maxWidth } = this
                const value =
                modal.widthType === '%'
                    ? (viewportWidth / 100) * modal.width
                    : modal.width
                if (adaptive) {
                    const max = Math.max(minWidth, Math.min(viewportWidth, maxWidth))
                    return max
                }
                return value
            },
            trueModalHeight() {
                const {
                viewportHeight,
                modal,
                isAutoHeight,
                adaptive,
                minHeight,
                maxHeight
                } = this
                const value =
                modal.heightType === '%'
                    ? (viewportHeight / 100) * modal.height
                    : modal.height
                if (isAutoHeight) {
                return this.modal.renderedHeight
                }
                if (adaptive) {
                    const max = Math.max(minHeight, Math.min(viewportHeight, maxHeight))
                    return max;
                }
                return value;
            },
            autoHeight() {
                return this.adaptive && this.modal.renderedHeight >= this.viewportHeight
                ? Math.max(this.minHeight, this.viewportHeight) + 'px'
                : 'auto'
            },
            containerClass() {
                return [
                'vm--container',
                this.scrollable && this.isAutoHeight && 'scrollable'
                ]
            },
            modalClass() {
                return ['vm--modal', this.classes]
            },
            stylesProp() {
                return this.styles
            },
            modalStyle() {
                return [
                this.stylesProp,
                {
                    top: this.position.top + 'px',
                    left: this.position.left + 'px',
                    width: this.trueModalWidth + 'px',
                    height: this.isAutoHeight
                    ? this.autoHeight
                    : this.trueModalHeight + 'px'
                }
                ]
            },
            isComponentReadyToBeDestroyed() {
                return (
                this.overlayTransitionState === TransitionState.Leave &&
                this.modalTransitionState === TransitionState.Leave
                )
            }
        },
        watch: {
            isComponentReadyToBeDestroyed(isReady) {
                if (isReady) {
                this.visible = false
                }
            }
        },
        methods: {
        startTransitionEnter() {
            this.visibility.overlay = true
            this.visibility.modal = true
        },
        startTransitionLeave() {
            this.visibility.overlay = false
            this.visibility.modal = false
        },
        beforeOverlayTransitionEnter() {
            this.overlayTransitionState = TransitionState.Entering
        },
        afterOverlayTransitionEnter() {
            this.overlayTransitionState = TransitionState.Enter
        },
        beforeOverlayTransitionLeave() {
            this.overlayTransitionState = TransitionState.Leaving
        },
        afterOverlayTransitionLeave() {
            this.overlayTransitionState = TransitionState.Leave
        },
        beforeModalTransitionEnter() {
            this.modalTransitionState = TransitionState.Entering
        },
        afterModalTransitionEnter() {
            this.modalTransitionState = TransitionState.Enter
            if (this.draggable) {
            this.addDraggableListeners()
            }
            if (this.focusTrap) {
            this.$focusTrap.enable(this.$refs.modal)
            }
            const event = this.createModalEvent({
            state: 'opened'
            })
            this.$emit('opened', event)
        },
        beforeModalTransitionLeave() {
            this.modalTransitionState = TransitionState.Leaving
            if (this.$focusTrap.enabled()) {
            this.$focusTrap.disable()
            }
        },
        afterModalTransitionLeave() {
            this.modalTransitionState = TransitionState.Leave
            const event = this.createModalEvent({
                state: 'closed'
            })
            this.$emit('closed', event)
        },
        onToggle(name, state, params) {
            if (this.name === name) {
                const nextState = typeof state === 'undefined' ? !this.visible : state
                this.toggle(nextState, params)
            }
        },
        setInitialSize() {
            const width = this.width;
            const height = this.height;
            this.modal.width = width.value
            this.modal.widthType = width.type
            this.modal.height = height.value
            this.modal.heightType = height.type
        },
        onEscapeKeyUp(event) {
            if (event.which === 27 && this.visible) {
                this.$modal.hide(this.name)
            }
        },
        onWindowResize() {
            this.viewportHeight = window.innerHeight
            this.ensureShiftInWindowBounds()
        },
        createModalEvent(properties = {}) {
            return {
                name: this.name,
                ref: this.$refs.modal || null,
                ...properties
            }
        },
        onModalResize(event) {
            this.modal.widthType = 'px'
            this.modal.width = event.size.width
            this.modal.heightType = 'px'
            this.modal.height = event.size.height
            if (!this.centerResize) {
                this.shiftLeft = this.getResizedShiftLeft(event)
                this.shiftTop = this.getResizedShiftTop(event)
            }
            const { size } = this.modal
            this.$emit(
                'resize',
                this.createModalEvent({
                    size
                })
            )
        },
        getResizedShiftLeft(event) {
            let result = this.shiftLeft
            switch (event.direction) {
                case 'vue-modal-topRight':
                case 'vue-modal-bottomRight':
                case 'vue-modal-right':
                    result = result + 0.5 * event.dimGrowth.width
                    break
                case 'vue-modal-bottomLeft':
                case 'vue-modal-topLeft':
                case 'vue-modal-left':
                    result = result - 0.5 * event.dimGrowth.width
                    break
                case 'vue-modal-top':
                case 'vue-modal-bottom':
                    break
                default:
                    break
            }
            return result
        },
        getResizedShiftTop(event) {
            let result = this.shiftTop
            switch (event.direction) {
            case 'vue-modal-bottom':
            case 'vue-modal-bottomRight':
            case 'vue-modal-bottomLeft':
                result = result + 0.5 * event.dimGrowth.height
                break
            case 'vue-modal-top':
            case 'vue-modal-topRight':
            case 'vue-modal-topLeft':
                result = result - 0.5 * event.dimGrowth.height
                break
            case 'vue-modal-left':
            case 'vue-modal-right':
                break
            default:
                break
            }
            return result
        },
        open(params) {
            if (this.reset) {
            this.setInitialSize()
            this.shiftLeft = 0
            this.shiftTop = 0
            }
            if (this.scrollable) {
            document.body.classList.add('vm--block-scroll')
            }
            let cancelEvent = false
            const cancel = () => {
            cancelEvent = true
            }
            const event = this.createModalEvent({
            cancel,
            state: 'before-open',
            params
            })
            this.$emit('before-open', event)
            if (cancelEvent) {
                if (this.scrollable) {
                    document.body.classList.remove('vm--block-scroll')
                }
                return
            }
            this.visible = true
            this.$nextTick(() => {
            this.startTransitionEnter()
            })
        },
        close(params) {
            if (this.scrollable) {
                document.body.classList.remove('vm--block-scroll')
            }
            let cancelEvent = false
            const cancel = () => {
                cancelEvent = true
            }
            const event = this.createModalEvent({
                cancel,
                state: 'before-close',
                params
            })
            this.$emit('before-close', event)
            if (cancelEvent) {
                return
            }
            this.startTransitionLeave()
        },
        toggle(isOpening, params) {
            const { visible } = this
            if (visible === isOpening) {
                return
            }
            if (isOpening) {
                this.open(params)
            } else {
                this.close(params)
            }
        },
        getDraggableElement() {
            if (this.draggable === true) {
                return this.$refs.modal
            }
            if (typeof this.draggable === 'string') {
                return this.$refs.modal.querySelector(this.draggable)
            }
            return null
        },
        onOverlayClick() {
            if (this.clickToClose) {
            this.toggle(false)
            }
        },
        addDraggableListeners() {
            const dragger = this.getDraggableElement()
            if (dragger) {
            let startX = 0
            let startY = 0
            let initialShiftLeft = 0
            let initialShiftTop = 0
            const handleDraggableMousedown = () => {
                document.addEventListener('mousemove', handleDraggableMousemove)
                document.addEventListener('touchmove', handleDraggableMousemove)
                document.addEventListener('mouseup', handleDraggableMouseup)
                document.addEventListener('touchend', handleDraggableMouseup)
            }
            const handleDraggableMousemove = (event) => {
                this.shiftLeft = initialShiftLeft + startX
                this.shiftTop = initialShiftTop + startY
                event.preventDefault()
            }
            const handleDraggableMouseup = event => {
                this.ensureShiftInWindowBounds()
                document.removeEventListener('mousemove', handleDraggableMousemove)
                document.removeEventListener('touchmove', handleDraggableMousemove)
                document.removeEventListener('mouseup', handleDraggableMouseup)
                document.removeEventListener('touchend', handleDraggableMouseup)
                event.preventDefault()
            }
            dragger.addEventListener('mousedown', handleDraggableMousedown)
            dragger.addEventListener('touchstart', handleDraggableMousedown)
            }
        },
        ensureShiftInWindowBounds() {
            // todo
        }
    }
};
