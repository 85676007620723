import { render, staticRenderFns } from "./headerBurger.vue?vue&type=template&id=201e2090&"
import script from "./_header/burger.js?vue&type=script&lang=js&"
export * from "./_header/burger.js?vue&type=script&lang=js&"
import style0 from "./_header/burger.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports