import ObjectCard from './../objectCard';
import ExcelButtons from './../excelButtons';

export default {
    name: 'PricesObjectList',
    props: ['selectedObj', 'banner'],
    components: {
        ObjectCard,
        ExcelButtons
    },
    data: () => ({
        objects: [],
        filteredObjects: [],
        loading: false,
        filter: {
            name: '',
            // todo object_id: 0
        },
        selected: null
    }),
    watch: {
        selectedObj: {
            deep: true,
            handler(newValue) {
                this.obj = Object.assign({}, newValue);
                this.filterChanged();
            }
        },
        clonedFilter: {
            deep: true,
            handler() {
                this.filterChanged();
            }
        }
    },
    computed:{
        clonedFilter: function(){
           return JSON.parse(JSON.stringify(this.filter))
        }
    },
    mounted() {
        this.getObjects();
    },
    destroyed() {
        // todo
    },
    methods: {
        excelPostedHandler() {
            this.getObjects();
        },
        filterChanged() {
            this.filteredObjects = [];
            const searchString = this.filter.name.trim();
            if (searchString.length) {
                this.objects.forEach(object => {
                    if (
                        (searchString.length && object.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) ||
                        (searchString.length && object.address_in_city.toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
                    ) {
                        if (this.selectedObj) {
                            if (object.object_id === this.selectedObj.id) {
                                this.filteredObjects.push(object);
                            }
                        } else {
                            this.filteredObjects.push(object);
                        }
                    }
                });
            } else {
                this.objects.forEach(obj => {
                    if (this.selectedObj) {
                        if (obj.object_id === this.selectedObj.id) {
                            this.filteredObjects.push(obj);
                        }
                    } else {
                        this.filteredObjects.push(obj);
                    }
                });
            }
        },
        getObjects() {
            this.loading = true;
            this.objects = [];
            this.filteredObjects = [];
            window.myAjax('GET', '/price-tag/monitoring_objects', {}, this.getObjectsHandler);
        },
        getObjectsHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.objects = response;
                (response || []).forEach(obj => {
                    if (this.selectedObj) {
                        if (obj.object_id === this.selectedObj.id) {
                            this.filteredObjects.push(obj);
                        }
                    } else {
                        this.filteredObjects.push(obj);
                    }
                })
            }
        },
        create() {
            if (!this.obj) {
                window.myAlert('Выберите объект из списка слева.');
                return;
            }
            this.open({
                title: '',
                address_in_city: '',
                banner_id: null,
                object_id: null
            })
        },
        open(object) {
            this.selected = Object.assign({}, object);
            this.$emit('openObject', {object: this.selected});
            // this.emit$('select', {object: this.selected})
        },
        savedHandler() {
            this.selected = null;
            this.getObjects();
        }
    }
}
