<template>
  <div id="cont-map" ref="container" class="map-container">
    <div ref="contmap" style="position: relative; overflow: hidden">
      <v-img ref="map" :src="mapSource" @load="getSizes" @mousedown="takeMap"
             @mouseup="dropMap" height="auto"/>
      <camera-icon v-for="(camera, index) in addedCameras" :key="`camKey${index}`"
                   :coverage="coverage"
                   :data="camera"
                   :mapSizes="mapSizes"
                   :reference="`cam${index}`"
                   :canDrag="cameraDrag"
                   @showMe="selectingCamera"
                   :status="!cameraDrag && (selectedCamera !== null) && (camera.id === selectedCamera.id) ? 'selected' : camera.status"
      />
    </div>
    <div class="scale-button-group">
      <div class="scale-button" @click="scale(100)">
        <svg fill="none" height="24" style="display: block; margin: auto" viewBox="0 0 40 40" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M20 1L20 39M39 20L1 20" stroke="#096866" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
        </svg>
      </div>
      <div class="scale-button" @click="scale(-100)">
        <svg fill="none" height="2"
          style="display: block; margin: auto"
          viewBox="0 0 40 2" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M39 1L1 1" stroke="#096866"
            stroke-linecap="round" stroke-linejoin="round"
            stroke-width="2"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * Map component for setting cams positions
 * properties is:
 * @objectData must contain a link to the aria map (Object)
 *    @map_path is a String link to the aria map inside the @objectData prop
 * @camsData is an Array of the cams Object variables contains information
 * about each camera of this aria. Required parameters (Array):
 *    @status: is the camera status (String),
 *    @name: is the camera name (String),
 *    @coordX: is the camera coordinate by X-axis (Number),
 *    @coordY: is the camera coordinate by Y-axis (Number),
 *    @rotate: is the angle of camera rotation (Number),
 *    @ip_address: is the IP address of the camera (String),
 *
 *    All incoming properties are brought in line with the data coming from the database
 */


import CameraIcon from "./cameraIcon"

export default {
  name: 'LocationMap',
  components: {
    CameraIcon
  },
  props: {
    objectData: {
      type: Object,
      default() {
        return {
          map_path: "https://img-lib.wm-help.net/3061515169/i_167.jpg"
        }
      }
    },
    camsData: {
      type: Array,
      default() {
        return [
          {
            status: "Онлайн",
            name: "Camera 1",
            coordX: 312,
            coordY: 207,
            rotate: 172,
            ip_address: "76.23.54.210",
            id: 3
          },
        ]
      }
    },
    mapPreview: {
      type: Object,
      default() {
        return null
      }
    },
    cameraDrag: {
      type: Boolean,
      default: true
    },
    selectedCamera: {
      type: Object,
      default() {
        return {
          status: "Онлайн",
          name: "Camera 1",
          coordX: 450,
          coordY: 129,
          rotate: 172,
          ip_address: "76.23.54.210",
        }
      }
    }
  },
  model: {
    prop: 'selectedCamera',
    event: 'selected'
  },
  watch: {
    mapPreview: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.createImage(val)
        }
      }
    },
    objectData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.addedCameras.splice(0, this.addedCameras.length);
          this.mapSource = val.map_path;
          this.getSizes();
        }
      }
    },
    camsData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.addedCameras.splice(0, this.addedCameras.length);
          val.forEach(camera => {
            if (camera.coordX !== null && camera.coordY !== null) {
              this.addedCameras.push(camera);
            }
          })
        }
      }
    }
  },

  /**
   * @returns {{mapScale: number, mapSizes: {startWidth: number, width: number, startHeight: number, height: number, ratio: number}, mapTaked: boolean, mapY: number, mapX: number}}
   *
   * @mapScale is the scale when the map is zoomed in (Number);
   *
   * @mapSizes is the map-image description Object, contains Numbers:
   *      @width: is the current width,
   *      @height: is the current height,
   *      @startWidth: is the width of the map when component was mounted, is equal to 800 in the mounted hook,
   *      @startHeight: is the height of the map when component was mounted,
   *      @ratio: is the aspect ration of the map-image
   *
   * @mapTaked: is the trigger when map is taking to drag (boolean),
   *
   * @mapX and @mapY is the coordinates of the map zero point after moving (Number)
   */
  data() {
    return {
      mapSizes: {
        width: 0,
        startWidth: 0,
        startHeight: 0,
      },
      mapScale: 1,
      mapTaked: false,
      mapX: 0,
      mapY: 0,
      mapSource: "",
      addedCameras: [],
      coverage: {
        right: 0,
        bottom: 0
      },
    }
  },

  /**
   * @getSizes() - function calculate ratio and start params for map image on component was mounted
   *
   * @scaleUp() - increase the map size
   * @scaleDown() - decrease the map size
   *
   * @mapResize() - calculate current scale of the map image
   *
   * @takeMap(event) - takes an event as an argument, prepares the map for movement
   * @dropMap() - stops the map's movement
   * @moveMap(event) - takes an event as an argument, moves map on mouse moving
   *
   */

  methods: {
    selectingCamera(value) {
      if (!this.cameraDrag)
              this.$emit('selected', value)
    },
    getSizes() {
      if (this.$refs.map) {
        const {naturalHeight, naturalWidth} = this.$refs.map.image;
        // needed to bind coordinates to the real size of the picture
        // needed to keep the image dimensions constant and resize the scaled image
        // the formulas for transforming coordinates are as follows:
        // naturalWidth / clientWidth = coordinateX / clientCoordinateX
        // naturalHeight / clientHeight = coordinateY / clientCoordinateX

        // saving naturalWidth & naturalHeight - it's changing only if current map has been changed
        this.mapSizes.startWidth = naturalWidth;
        this.mapSizes.startHeight = naturalHeight;

        this.setCoverage()
      }
    },
    setCoverage() {
      // save current scaled width of the image
      if (this.$refs.contmap) {
        this.coverage.right = this.$refs.contmap.clientWidth;
        this.coverage.bottom = this.$refs.contmap.clientHeight;
      }
    },
    scale(step, eventData) {
      if (eventData) {
        let currentWidth = this.$refs.contmap.clientWidth;
        let currentHeight = this.$refs.contmap.clientHeight;
        this.mapSizes.width = this.mapSizes.width + step;
        this.$refs.contmap.style.width = currentWidth + step + 'px';

        const xkf = (this.$refs.contmap.clientWidth - currentWidth) * 0.5;
        const ykf = (this.$refs.contmap.clientHeight - currentHeight) * 0.5;

        this.$refs.contmap.style.left = (this.$refs.contmap.offsetLeft - xkf) + 'px';
        this.$refs.contmap.style.top = (this.$refs.contmap.offsetTop - ykf) + 'px';
        
        this.coverage.right = this.$refs.contmap.clientWidth;
        this.coverage.bottom = this.$refs.contmap.clientHeight;
      } else {
        let currentWidth = this.$refs.contmap.clientWidth;
        let currentHeight = this.$refs.contmap.clientHeight;
        this.mapSizes.width = this.mapSizes.width + step;
        this.$refs.contmap.style.width = currentWidth + step + 'px';

        const xkf = (this.$refs.contmap.clientWidth - currentWidth) * 0.5;
        const ykf = (this.$refs.contmap.clientHeight - currentHeight) * 0.5;

        this.$refs.contmap.style.left = (this.$refs.contmap.offsetLeft - xkf) + 'px';
        this.$refs.contmap.style.top = (this.$refs.contmap.offsetTop - ykf) + 'px';
        
        this.coverage.right = this.$refs.contmap.clientWidth;
        this.coverage.bottom = this.$refs.contmap.clientHeight;
      }
    },
    takeMap(event) {
      this.mapTaked = true;
      this.mapX = event.touches && event.touches.length ? event.touches[0].pageX : event.pageX;
      this.mapY = event.touches && event.touches.length ? event.touches[0].pageY : event.pageY;
      document.onmousemove = this.moveMap;
    },
    dropMap() {
      this.mapTaked = false;
      document.onmousemove = null;
    },
    moveMap(event) {
      if (this.mapTaked) {
        let pos = {
          moveX: 0,
          moveY: 0
        };
        event.preventDefault();
        pos.moveX = this.mapX - event.clientX;
        pos.moveY = this.mapY - event.clientY;
        this.mapX = event.clientX;
        this.mapY = event.clientY;
        this.$refs.contmap.style.top = (this.$refs.contmap.offsetTop - pos.moveY) + 'px';
        this.$refs.contmap.style.left = (this.$refs.contmap.offsetLeft - pos.moveX) + 'px';
      }
    },

    createImage(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.mapSource = e.target.result
        };
        reader.readAsDataURL(file);
      }
    },

    addingCamera(camera) {
      camera.coordX = parseInt(this.mapSizes.startWidth / 2);
      camera.coordY = parseInt(this.mapSizes.startHeight / 2);
      camera.rotate = 0;
      this.addedCameras.push(camera)
    },
    removeCamera(camera) {
      let index = 0;
      while (index < this.addedCameras.length) {
        if (this.addedCameras[index].id === camera.id) {
          this.addedCameras.splice(index, 1);
        } else {
          ++index;
        }
      }
    },
    scaleOnResize() {
      this.$refs.contmap.style.width = 'auto';
      this.mapSizes.width = 0;
      this.setCoverage();
    },
    scrollHandler(event) {
      event.preventDefault();
      this.scale(-event.deltaY, event);
    }
  },
  mounted() {
    if (this.mapPreview !== null && this.mapPreview !== undefined) {
      this.createImage(this.mapPreview);
    } else {
      this.mapSource = this.objectData.map_path;
      this.$refs.map.loadImage(this.objectData.map_path)
    }
    (document.getElementById('cont-map') || {}).addEventListener('wheel', this.scrollHandler);
  },
  created() {
    window.addEventListener('resize', this.scaleOnResize);
  },
  beforeDestroy() {
    const compMap = document.getElementById('cont-map');
    if (compMap) { compMap.removeEventListener('wheel', this.scrollHandler); }
  },
  destroyed() {
    window.removeEventListener('resize', this.scaleOnResize);
  },
}
</script>

<style scoped>
.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #e0e0e0;
}

.scale-button-group {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.scale-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 27px 198px rgba(0, 0, 0, 0.03), 0 11px 83px rgba(0, 0, 0, 0.0215656), 0 6px 44px rgba(0, 0, 0, 0.0178832), 0 3px 25px rgba(0, 0, 0, 0.015), 0 2px 13px rgba(0, 0, 0, 0.0121168), 0 1px 5px rgba(0, 0, 0, 0.00843437);
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
}
</style>