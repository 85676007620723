const Calendar = {
    state: () => ({
        allowedDates: [],
    }),

    getters: {
        ALLOWED_DATES: state => {
            return state.allowedDates;
        },
    },

    mutations: {
        SET_ALLOWED_DATES: (state, payload) => {
            if (payload === undefined) {
                state.allowedDates = [];
            } else {
                state.allowedDates = payload;
            }
        },
    },

    actions: {
        GET_ALLOWED_DATES: async (context, payload) => {
            let url = `/archive/calendar`;
            await window.myAjax('GET', url, {
                camera_id: payload.id,
                start_time: payload.start,
                end_time: payload.stop
            }, function(error, response) {
                context.commit('SET_ALLOWED_DATES', response);
            });
        },
    },
};

export default Calendar;