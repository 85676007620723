/**
 * Создание контакта.
 * @param {*} data - Объект с новым контактом.
 */
export function createContact(params, callback) {
    const url = '/api/contact';
    const method = 'POST';
    window.myAjax(method, url, params.data, function(error, response) {
        if (!error) {
            callback(response);
        }
    });
}

/**
 * Обновление контакта.
 * @param {*} data - Объект с обновленным контактом.
 */
export function updateContact(params, callback) {
    const url = '/api/contact';
    const method = 'PUT';
    window.myAjax(method, url, params.data, function(error, response) {
        if (!error) {
            callback(response);
        }
    });
}

/**
 * Удаление контакта.
 * @param {*} id - Идентификатор контакта.
 */
export function deleteContact(params, callback) {
    const url = '/api/contact';
    const method = 'DELETE';
    window.myAjax(method, url, {id: params.id}, function(error, response) {
        if (!error) {
            callback(response);
        }
    });
}
