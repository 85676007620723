<template>
    <div>
        <div v-for="node in nodes" 
            v-bind:key="node.id"
            :class="[node.hasChildren, 'dsv-checklistEdit__tree-object']"
            v-bind:node_id="node.id">
            <div class="dsv-flexbox dsv-checklistEdit__tree-object-row">
                <div :class="['dsv-flexbox dsv-checklistEdit__tree-object-caption', node.hasChildren]">{{ node.name }}</div>
                <div v-if="!node.hasChildren"
                    :class="[
                        'dsv-checklistEdit__tree-object__checkbox',
                        selected[node.id] ? 'dsv-checklistEdit__tree-object__checkbox_selected' : ''
                    ]"
                    @click="selectNode(node)">
                    <label :for="node.id" class="dsv-checklistEdit__tree-object__checkbox-label"></label>
                </div>
            </div>
            <div v-if="node.childrenNodes.length" class="ml-4">
                <DevNodes
                    :nodes="node.childrenNodes"
                    :digressions="digressions"
                    :selected="selected"
                    @selectNode="selectNode"/>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     * Рекурсивный шаблон для выбора списка отклонений редактирования пункта чек-листа.
     * 
     * События:
     * - `selectNode` - при клике на чекбокс. В параметрах приходит пункт.
     */
    export default {
        name: 'DevNodes',
        props: ['nodes', 'digressions', 'selected'],
        data: () => ({
            // selected: {}
        }),
        mounted() {
            this.getSelected = this.getSelected.bind(this);
            // if (this.digressions) {
                // for (let i = 0; i < this.digressions.length; i++) {
                    // const digression = this.digressions[i];
                    // this.selected[digression.digression_id] = true;
                // }
            // }
        },
        methods: {
            selectNode(node) {
                this.$emit('selectNode', node);
            },

            getSelected() {
                return this.selected;
            }
        }
    }
</script>
