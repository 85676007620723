// import { getAllChecklists } from './../../../checklist/Utils/getAllChecklists';
// import { createRelations, deleteRelations, getRelationsByUser } from '../../../checklist/Utils/userChecklistRelations';
// import globalPermissions from './permissions';
// import rolesPermissions from './rolesPermissions';

// const GROUP_TITLES = {
//     'analytic': 'Аналитика и отчёты',
//     'cameras': 'Доступ Пользователей к Камерам',
//     'checklists': 'Чек-листы',
//     'clients': 'Клиенты',
//     'contacts': 'Контакты',
//     'digressions': 'Отклонения',
//     'journal': 'Журнал',
//     'logs': 'Отчёты об активности пользователей',
//     'ml': 'Нейромодули',
//     'objects': 'Доступ Пользователей к Объектам',
//     'permissions': 'Настройка прав',
//     'roles': 'Доступ Пользователей к Ролям',
//     'users': 'Доступ Пользователей к Пользователям',
// }

export default {
    name: 'UserCard',
    props:{
        data: {
            type: Object,
            default: {}
        }
    },
    data: () => ({
        user: {}
        // todo
    }),
    computed: {
        fullname() {
            return this.user.last_name + ' ' + this.user.first_name + ' ' + (this.user.middle_name || '');
        }
        // todo
    },
    mounted() {
        this.user = this.data;
    },
    methods: {
        // todo
    }
}
