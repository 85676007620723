import { create } from './../../Utils/guid';

export default {
    name: 'PersonsCard',
    components: {
        // todo
    },
    props:{
        data: {
            type: Object
        },
        type: {
            type: String
        },
    },
    data: () => ({
        loading: false,
        person: {
            first_name: '',
            last_name: '',
            person_id: '',
            client_person_id: '',
            images: []
        },
        media: [],
        personImages: [],
        loadingPersonImages: false,
    }),
    computed: {
        // todo
    },
    watch: {
        activeObject: {
            handler() {
                // todo
            }
        }
    },
    mounted() {
        this.person = Object.assign({}, this.data, {
            images: []
        });
        if (this.person.person_id) {
            this.loadingPersonImages = true;
            this.personImages = [];
            window.myAjax('GET', '/ml/face_recognition/person_images', {
                person_id: this.person.person_id
            }, (error, response) => {
                this.loadingPersonImages = false;
                if (!error) {
                    (response || []).forEach( (item, index) => {
                        this.personImages.push({
                            id: index,
                            src: item
                        });
                    })
                }
            });
        }
    },
    methods: {
        getPerson() {
            // window.myAjax('GET', '/ml/face_recognition/person_face', {person_id: this.person.person_id}, (error, response) => {
            //     if (!error) {
            //         console.log(response);
            //     }
            // })
        },
        deleteHandler() {
            if (this.loading) {
                return;
            }
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить ?', () => {
                this.loading = true;
                window.myAjax( 'DELETE', '/ml/face_recognition/person_face?person_id=' + this.person.person_id, {}, (error, response) => {
                    this.loading = false;
                    if (!error) {
                        this.$emit('closed');
                    } else {
                        window.myAlert(response.message);
                    }
                })
            })
        },
        saveHandler() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            const method = this.person.person_id ? 'PUT' : 'POST';
            var formdata = new FormData();
            if (this.person.first_name) {
                formdata.append("first_name", this.person.first_name);
            }
            if (this.person.last_name) {
                formdata.append("last_name", this.person.last_name);
            }
            if (this.person.client_person_id) {
                formdata.append("client_person_id", this.person.client_person_id);
            }
            formdata.append("person_id", this.person.person_id ? this.person.person_id : create());
            formdata.append("person_type", this.type);
            this.media.forEach( med => {
                formdata.append("images", med.src);
            })
            window.myAjaxFile(method, '/ml/face_recognition/person_face', formdata, (error) => {
                this.loading = false;
                if (!error) {
                    this.$emit('closed');
                } else {
                    window.myAlert('Не удалось сохранить.');
                }
            });
            // window.myAjax( method, '/ml/face_recognition/person_face', data, (error) => {
            //     this.loading = false;
            //     if (!error) {
            //         this.$emit('closed');
            //     } else {
            //         window.myAlert('Не удалось сохранить.');
            //     }
            // })
        },
        inputChanged(files) {
            if (files.length === 0) {
                return;
            }
            files.forEach(file => {
                if (file.type.indexOf('image') !== 0) {
                    return;
                }
                this.getBase64(file);
            });
        },
        getBase64(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.media.push({
                    src: reader.result,
                    id: create(),
                    type: 'added'
                })
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
            };
        },
        deleteMedia(itemId) {
            const index = this.media.findIndex( item => item.id === itemId );
            this.media.splice(index, 1);
        }
    }
}
