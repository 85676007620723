<template>
    <div class="scrolling_content">
        <Objects
            v-if="content=='Objects'"
            ref="ObjectsRef"
            :selectedObj="selectedObj"
            @setActiveObject="setActiveObject"
            @addRootObjectFromObjects="addRoObject"
            @updateObjectsListFromObjects="updadeObjectListFromSettings"
            :addRootObject="addRootObject"/>
        <Cams
            v-else-if="content=='Cams'"
            ref="CamsRef"
            :selectedObj="selectedObj"
            @updateObjectsListFromObjects="updadeObjectListFromSettings"/>
        <Devs
            v-else-if="content=='Devs'"
            ref="DevsRef"
            @updateDevsListFromDevs="updadeDevsListFromSettings"/>
        <Users
            v-else-if="content=='Users'"
            ref="UsersRef"
            @updateRolesListFromUsers="updadeRolesListFromSettings"
            @updateUsersListFromUsers="updadeUsersListFromSettings"/>
        <ChecklistContent
            v-else-if="content=='Checklist'"
            ref="ChecklistRef"/>
        <SensorSettings 
            v-else-if="content=='SensorSettings'"
            ref="SensorSettings"/>
        <PricesContent
            v-else-if="content=='PriceTag'"
            :selectedObj="selectedObj"
            @setActiveBanner="setActiveBanner"
            @setActiveCategory="setActiveCategory"
            @changeMenu="changeMenuHandler"
            @selectedObjChanged="setActiveObject"
            ref="PriceTagRef"/>
        <NeuralSettings 
            v-else-if="content=='NeuralSettings'"
            ref="NeuralSettings"/>
    </div>
</template>

<script>
    // import ObjectList from './objects/objectList';
    import Objects from './objects/Objects';
    import Cams from './cams/Cams';
    import Devs from './Devs';
    import Users from './users/Users';
    import SensorSettings from './../sensor/settings';
    import ChecklistContent from './../checklist/content';
    import PricesContent from './../prices/content';
    import NeuralSettings from './neuralSettings';

  export default {
    name: 'Settings',
    components: {
        // ObjectList,
        Objects,
        Cams,
        Users,
        Devs,
        ChecklistContent,
        PricesContent,
        SensorSettings,
        NeuralSettings
    },
    data: () => ({
          content: 'Objects'
    }),
    props: ['addRootObject', 'obj', 'selectedObj'],
    mounted() {
        window.resizeScrollingContent();
        // window.$('.scrolling_content').css('max-height', window.innerHeight-60)
    },
    methods: {
        changeMenuHandler(data) {
            console.log('settings changeMenuHandler', data);
            this.$emit('changeMenu', data);
        },
        setActiveBanner(data) {
            this.$emit('setActiveBanner', data);
        },
        selectBanner(data) {
            if (this.content === 'PriceTag') {
                this.$refs['PriceTagRef'].selectBanner(data);
            }
        },
        setActiveCategory(data) {
            this.$emit('setActiveCategory', data);
        },
        selectCategory(data) {
            if (this.content === 'PriceTag') {
                this.$refs['PriceTagRef'].selectCategory(data);
            }
        },
        addRoObject(val) {
            this.$emit('addRootObjectFromSettings', val)
        },
        updadeObjectListFromSettings() {
            this.$emit('updadeObjectListFromSettings', false)
        },
        updadeDevsListFromSettings() {
            this.$emit('updadeDevsListFromSettings', false)
        },
        updadeRolesListFromSettings() {
            this.$emit('updadeRolesListFromSettings', false)
        },
        updadeUsersListFromSettings() {
            this.$emit('updadeUsersListFromSettings', false)
        },
        showObjectCard(obj) {
            if(this.content=='Objects')
                this.$refs['ObjectsRef'].showObjectCard(obj)
            else if(this.content=='Cams')
                this.$refs['CamsRef'].showObjectCard(obj)
            else if(this.content=='Devs')
                this.$refs['DevsRef'].showObjectCard(obj)
            else if(this.content=='Users' && obj==null)
                this.$refs['UsersRef'].userCard(null)
            else if(this.content=='Users')
                this.$refs['UsersRef'].showObjectCard(obj)
            else if(this.content=='PriceTag')
                this.$refs['PriceTagRef'].showObjectCard(obj)
        },
        showContent(event) {
            this.content = event
        },
        setActiveObject(obj) {
            this.$emit('setActiveObject', obj)
        },

    }
  }
</script>
