
import BaseHelperList from './../../base/helperList';
import ModalNew from './../../base/modalNew';
import CheckboxNew from './../../base/checkboxNew';

export default {
    name: 'ChecklistTaskList',
    components: {
        BaseHelperList,
        ModalNew,
        CheckboxNew
    },
    props: {
        // todo
    },
    data: () => ({
        loading: false,
        tasks: [],
        filteredTasks: [],

        selectedObject: null,
        selectedContact: null,

        objects: [],
        contacts: [],
        checklists: [],

        emailsToLoad: [],
        emails: {},
        mapEmails: [],

        modalSchedule: false,
        selectedTaskId: null,

        editedTask: null,

        editedObjects: [],
        showEditedObjects: false,

        editedChecklists: [],
        showEditedChecklists: false,

        editedEmails: [],
        showEditedEmails: false,

        selectEmailsTemplates: false,
        emailTemplates: [],
        selectedEmailTemplates: [],
    }),
    watch: {
        selectedObject: {
            handler() {
                this.filterTasks();
            }
        },
        selectedContact: {
            handler() {
                this.filterTasks();
            }
        }
    },
    mounted() {
        this.getObjects();
        this.getTemplates();
    },
    methods: {
        getObjects() {
            this.loading = true;
            window.myAjax('GET', '/api/objects', {}, (error, response) => {
                if (!error) {
                    this.objects = (response.objects || []).filter( object => object.object_type === 'object' );
                    window.myAjax('GET', '/api/checklists', {}, (error, response) => {
                        if (!error) {
                            this.checklists = response.check_lists || [];
                            this.getContacts();
                        }
                    })
                }
            })
        },
        
        getContacts() {
            window.myAjax('GET', '/api/contacts?page=0&limit=1000', {}, (error, response) => {
                if (!error) {
                    this.contacts = (response.contacts || []);
                    this.contacts.forEach( contact => {
                        this.emails[contact.id] = [];
                    } );
                    this.getEmails();
                }
            });
        },
        getEmails() {
            window.myAjax('GET', '/api/emails', {}, (error, response) => {
                if (!error) {
                    const emails = (response.emails || []).filter( email => email.deleted_at === null);
                    this.mapEmails = emails.filter( email => (email || {}).id > 0 );
                    emails.forEach( email => {
                        if (this.emails[email.contact_id]) {
                            this.emails[email.contact_id].push(email);
                        }
                    })
                    this.getTasks();
                }
            });
        },
        getTasks() {
            window.myAjax('GET', '/analytic/checklist/tasks', {}, (error, response) => {
                if (!error) {
                    this.tasks = [];
                    for (const key in response) {
                        this.tasks.push(Object.assign(response[key], {
                            id: key,
                            days: this.getDays(response[key].schedule),
                            time: this.getTime(response[key].schedule),
                            emailsNames: (response[key].emails_id || []).length ? this.mapEmails.filter( email => (response[key].emails_id || []).includes(email.id) )
                                .map( email => email.email ).sort().join(', ') : this.getEmailsNames(response[key].email_template_ids),
                            objectsNames: (
                                (
                                    ( response[key].object_ids.length === 0 ? 
                                        this.objects :
                                        this.objects.filter( object => response[key].object_ids.includes(object.id) )
                                    ) || []).map( object => object.name )
                                || [] ).sort().join(', '),
                            checklistsNames: (
                                (
                                    ( response[key].checklist_ids.length === 0 ?
                                        this.checklists :
                                        this.checklists.filter( checklist => response[key].checklist_ids.includes(checklist.id) )
                                    ) || []).map( checklist => checklist.caption )
                                || [] ).sort().join(', '),
                        }));
                    }
                    this.loading = false;
                    this.filterTasks();
                } else {
                    this.loading = false;
                }
            });
        },
        getTemplates() {
            window.myAjax('GET', '/api/email-templates', {}, (error, response) => {
                if (!error) {
                    this.emailTemplates = response;
                }
            });
        },
        stopTask(taskId) {
            window.myConfirm('Остановить расписание по отправке отчётов?', null, () => {
                window.myAjax('POST', '/analytic/checklist/tasks/stop', {
                    task_id: taskId
                }, (error) => {
                    if (error) {
                        window.myAlert('Ошибка при остановке расписания');
                    } else {
                        this.getTasks();
                    }
                });
            })
            
        },
        getEmailsNames(ids) {
            let result = [];
            const templates = this.emailTemplates.filter( template => ids.includes(template.id) );
            templates.forEach( template => {
                (template.email_ids || []).forEach( id => {
                    result.push(this.mapEmails.find( email => email.id === id));
                })
            })
            result = [...result];
            return result.map( email => email.email ).sort().join(', ');
        },
        getDays(schedule) {
            const weekdays = schedule.split(' ')[4];
            if (weekdays === '*') {
                return [0,1,2,3,4,5,6];
            }
            return weekdays.split(',').map( day => parseInt(day) );
        },
        getTime(schedule) {
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            const minutes = schedule.split(' ')[0];
            const hours = schedule.split(' ')[1];
            return `${parseInt(hours) - diff}:${('0' + minutes).slice(-2)}`
        },
        filterTasks() {
            let result = [...this.tasks];
            this.filteredTasks = [];
            if (!this.selectedObject && !this.selectedContact) {
                this.filteredTasks = [...this.tasks];
            } else {
                if (this.selectedObject) {
                    result = result.filter( task =>
                        task.objects.includes(this.selectedObject) ||
                        task.objects.length === 0
                    );
                }
                if (this.selectedContact) {
                    result = result.filter( task =>
                        (task.emailsNames || '').indexOf(this.selectedContact) >= 0
                    );
                }
                this.filteredTasks = [...result];
            }
        },
        editTask(taskId) {
            const task = this.tasks.find(task => task.id == taskId);
            this.editedTask = task;
            if (task) {
                this.selectedTaskId = taskId;
                if ((task.emails_id || []).length) {
                    this.editedEmails = task.emails_id || [];
                    this.selectedEmailTemplates = [];
                    this.selectEmailsTemplates = false;
                } else {
                    this.editedEmails = [];
                    this.selectedEmailTemplates = task.email_template_ids || [];
                    this.selectEmailsTemplates = true;
                }
                this.editedObjects = (task.object_ids || []).length ? task.object_ids : this.objects.map( object => object.id );
                this.editedChecklists = (task.checklist_ids || []).length ? task.checklist_ids : this.checklists.map( checklist => checklist.id );

                this.modalSchedule = true;
            }
        },
        startSaving() {
            window.myAjax('PUT', '/analytic/checklist/tasks/update', {
                task_id: this.selectedTaskId,
                emails_id: this.selectEmailsTemplates ? JSON.stringify([]) : JSON.stringify(this.editedEmails),
                email_template_ids: this.selectEmailsTemplates ? JSON.stringify(this.selectedEmailTemplates) : JSON.stringify([]),
                object_ids: JSON.stringify(this.editedObjects),
                checklist_ids: JSON.stringify(this.editedChecklists),
                headline: (this.editedTask.headline || '').length ? this.editedTask.headline : ''
            }, (error) => {
                if (error) {
                    window.myAlert('Ошибка при редактировании расписания');
                } else {
                    this.selectedTaskId = null;

                    this.editedTask = null;

                    this.editedObjects = [];
                    this.editedEmails = [];

                    this.modalSchedule = false;
                    this.getTasks();
                }
            });
        },
        checkEmail(emailId, checked) {
            if (checked) {
                this.editedEmails.push(emailId);
            } else {
                this.editedEmails.splice(this.editedEmails.indexOf(emailId), 1);
            }
        },
        checkEmailTemplate(emailTemplateId, checked) {
            if (checked) {
                this.selectedEmailTemplates.push(emailTemplateId);
            } else {
                this.selectedEmailTemplates.splice(this.selectedEmailTemplates.indexOf(emailTemplateId), 1);
            }
        },
        checkChecklist(checklistId, checked) {
            if (checked) {
                this.editedChecklists.push(checklistId);
            } else {
                this.editedChecklists.splice(this.editedChecklists.indexOf(checklistId), 1);
            }
        },
        checkObject(objectId, checked) {
            if (checked) {
                this.editedObjects.push(objectId);
            } else {
                this.editedObjects.splice(this.editedObjects.indexOf(objectId), 1);
            }
        },
    }
}
