export default {
    name: 'PricesBannerList',
    props: {
        items: {
            type: Array,
            default: []
        }
    },
    components: {
        // todo
    },
    data: () => ({
        totalRows: 0,
        rows: 2,
        banners: [],
        filteredBanners: [],
        loading: false,
        filter: {
            name: ''
        }
    }),
    mounted() {
        if (this.items.length) {
            this.banners = this.items
        } else {
            this.getBanners()
        }
    },
    destroyed() {
        // todo
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                this.filterChanged();
            }
        }
    },
    methods: {
        filterChanged() {
            const searchString = this.filter.name.trim();
            this.filteredBanners = [];
            if (searchString.length) {
                this.banners.forEach(banner => {
                    if (
                        banner.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
                        // ||
                        // banner.format.indexOf(searchString) >= 0
                    ) {
                        this.filteredBanners.push(banner);
                    }
                });
            } else {
                this.filteredBanners = this.banners;
            }
        },
        getBanners() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/competitors', {}, this.getBannersHandler);
        },
        getBannersHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.banners = response;
                this.filteredBanners = response;
            }
        },
        create() {
            this.open(null)
        },
        open(banner) {
            this.$emit('open', {banner: banner});
            this.$emit('setActiveBanner', {banner: banner});
        }
    }
}
