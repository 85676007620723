
import CheckboxNew from './../../base/checkboxNew';

export default {
    name: 'DigressionsSchedule',
    components: {
        CheckboxNew
        //todo
    },
    props: {
        objects: {
            type: Array,
            default: []
        },
        digressions: {
            type: Array,
            default: []
        },
        cameras: {
            type: Array,
            default: []
        },
        groupByFormat: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'digressions'
        }
    },
    data: () => ({
        contactsLoadings: false,
        contacts: [],
        selectedContacts: [],
        selectedEmails: [],

        subject: null,

        emails: {},

        selected: {
            startDate: null,
            endDate: null,
            time: '10:00',
            type: 'yesterday'
        },
        days: [],

        selectEmailsTemplates: false,
        emailTemplates: [],
        selectedEmailTemplates: [],
    }),
    mounted() {
        this.getContacts();
        this.getTemplates();
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        this.selected.startDate = now.toISOString().slice(0,10);
        now.setDate(now.getDate() + 30);
        this.selected.endDate = now.toISOString().slice(0,10);
    },
    methods: {
        getTemplates() {
            window.myAjax('GET', '/api/email-templates', {}, (error, response) => {
                if (!error) {
                    this.emailTemplates = response;
                }
            });
        },
        getContacts() {
            this.contactsLoadings = true;
            window.myAjax('GET', '/api/contacts?page=0&limit=1000', {}, (error, response) => {
                if (!error) {
                    this.contacts = (response.contacts || []);
                    this.contacts.forEach( contact => {
                        this.emails[contact.id] = [];
                    } );
                    this.getEmails();
                }
            });
        },
        getEmails() {
            window.myAjax('GET', '/api/emails', {}, (error, response) => {
                this.contactsLoadings = false;
                if (!error) {
                    const emails = (response.emails || []).filter( email => email.deleted_at === null);
                    emails.forEach( email => {
                        if (this.emails[email.contact_id]) {
                            this.emails[email.contact_id].push(email);
                        }
                    });
                }
            });
        },
        check(contactId, checked) {
            if (checked) {
                this.selectedEmails.push(contactId);
            } else {
                this.selectedEmails.splice(this.selectedEmails.indexOf(contactId), 1);
            }
        },
        startSaving() {
            const time = this.selected.time.split(':');
            let hour = time[0].startsWith('0') ? time[0].slice(-1) : time[0];
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            hour = (parseInt(hour) + diff + 24) % 24;
            const minute = time[1].startsWith('0') ? time[1].slice(-1) : time[1];

            if (this.selected.type === 'last_week') {
                this.days = [1];
            }
            let monthDays = '*';
            if (this.selected.type === 'last_month') {
                monthDays = '1';
                this.days = [0, 1, 2, 3, 4, 5, 6];
            }

            const data = {
                start_datetime: this.selected.startDate + ' 00:00:00',
                stop_datetime: this.selected.endDate + ' 23:59:59',
                schedule: `${minute} ${hour} ${monthDays} * ${ this.days.length ? this.days.join(',') : '*'}`,
                schedule_type: this.selected.type
            };

            if (this.selectEmailsTemplates) {
                data.email_template_ids = JSON.stringify(this.selectedEmailTemplates);
                data.emails_id = JSON.stringify([]);
            } else {
                data.email_template_ids = JSON.stringify([]);
                data.emails_id = JSON.stringify(this.selectedEmails);
            }
            data.objects = this.objects.length > 0 ? JSON.stringify(this.objects) : null;
            if (this.type === 'checklist' || this.type === 'digressions') {
                data.digressions = this.digressions.length > 0 ? JSON.stringify(this.digressions) : null;
            }

            if ((this.subject || '').length) {
                data.headline = this.subject;
            }
            if (this.groupByFormat) {
                data.group_by_format = true;
            }
            if (this.type === 'checklist') {
                data.checklist_ids = this.digressions.length > 0 ? JSON.stringify(this.digressions) : null;
                data.object_ids = this.objects.length > 0 ? JSON.stringify(this.objects) : null;
            }

            // start_datetime +
            // stop_datetime
            // schedule +
            // schedule_type +

            // email_template_ids +
            // emails_id +

            // objects +
            // digressions +
            
            // headline +
            // group_by_format +
            if (this.type === 'checklist') {
                window.myAjax('POST', '/analytic/checklist/create_schedule', {
                    'emails_id': JSON.stringify([1254, 1257, 1258, 1255, 1259, 1256]),
                    'schedule_type': 'yesterday',
                    'group_by_format': true,
                    'email_template_ids': JSON.stringify([]),
                    'digressions': JSON.stringify([1501, 1502, 1503, 1504]),
                    'objects': JSON.stringify([994, 193, 1083, 1084, 1072, 165, 1106, 1085, 1086, 998, 1071, 1087, 1004, 995, 1116, 1074, 1073, 1088, 2150, 1089, 1080, 1090, 999, 992, 1091, 1092, 1078, 1015, 991, 1093, 1114, 1012, 1098, 2012, 1115, 1094, 1001, 997, 1099, 1101, 169, 1103, 1104, 1833, 170, 1059, 1061, 1062, 1003, 1105, 1107, 1109, 1111, 993, 1095, 1005, 1096, 1075, 1016, 1076, 1097, 1014, 1000, 1110, 194, 1011, 1079, 1013, 1009, 1008, 1006, 1007, 1113, 196, 1550, 1020, 171, 1022, 1023, 1024, 197, 1026, 1027, 198, 1029, 172, 1031, 1032, 1033, 1034, 1035, 1036, 173, 1038, 1037, 1041, 1042, 1043, 1044, 1046, 1045, 1047, 1048, 174, 1049, 1050, 1051, 1052, 1053, 1056, 1054, 1057, 1602, 1601, 1604, 1750, 1603, 2163, 2217, 2195, 1064, 1066, 1063, 167, 1067, 1069, 1070, 2268, 2267, 2117]),
                    'headline': 'Столовая ',
                    'start_datetime': '2024-08-09 00:00:00',
                    'stop_datetime': '2025-07-16 23:59:59',
                    'schedule': '55 4 * * 1,2,3,4,5,6,0'
                }, (error, response) => {
                    if (error) {
                        window.myAlert(response.message);
                    } else {
                        window.myAlert('Расписание задано');
                        this.$emit('saved');
                    }
                });
            } else if (this.type === 'digressions') {
                window.myAjax('POST', '/analytic/create_schedule', data, (error, response) => {
                    if (error) {
                        window.myAlert(response.message);
                    } else {
                        window.myAlert('Расписание задано');
                        this.$emit('saved');
                    }
                });
            } else if (this.type === 'cameras') {
                window.myAjax('POST', '/analytic/camera_log/create_schedule', data, (error, response) => {
                    if (error) {
                        window.myAlert(response.message);
                    } else {
                        window.myAlert('Расписание задано');
                        this.$emit('saved');
                    }
                });
            }
        },
        setDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.days = [];
                this.days.push(1, 2, 3, 4, 5);
            } else if (dayKey === 'all') {
                this.days = [];
                this.days.push(0, 1, 2, 3, 4, 5, 6);
            } else {
                const index = this.days.indexOf(dayKey);
                if (index < 0) {
                    this.days.push(dayKey);
                } else {
                    this.days.splice(index, 1);
                }
            }
        },
        checkEmailTemplate(emailTemplateId, checked) {
            if (checked) {
                this.selectedEmailTemplates.push(emailTemplateId);
            } else {
                this.selectedEmailTemplates.splice(this.selectedEmailTemplates.indexOf(emailTemplateId), 1);
            }
        },
    }
}