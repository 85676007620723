var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dsv-layoutTumbler"},[(_vm.extendedLayout)?_c('div',{staticClass:"dsv-layoutTumbler__items"},_vm._l((_vm.items),function(item){return _c('div',{key:item.value,class:[
                'dsv-layoutTumbler__item', ("dsv-layoutTumbler__item_col_" + (item.value)),
                _vm.layout === item.value ? 'dsv-layoutTumbler__item_active' : ''
            ],attrs:{"title":_vm.$t(item.title)},on:{"click":function($event){return _vm.selectItemHandler(item.value)}}})}),0):_c('div',{on:{"mouseleave":function($event){_vm.menuOpened = false}}},[_c('div',{class:[
                'dsv-layoutTumbler__item dsv-layoutTumbler__item_active',
                ("dsv-layoutTumbler__item_col_" + _vm.layout)
            ],on:{"mouseover":function($event){_vm.menuOpened = true}}}),(_vm.menuOpened)?_c('div',{staticClass:"dsv-layoutTumbler__menu"},_vm._l((_vm.items),function(item){return _c('div',{key:item.value,class:[
                    'dsv-layoutTumbler__menu-item', ("dsv-layoutTumbler__menu-item_col_" + (item.value)),
                    _vm.layout === item.value ? 'dsv-layoutTumbler__menu-item_active' : ''
                ],attrs:{"title":_vm.$t(item.title)},on:{"click":function($event){return _vm.selectItemHandler(item.value)}}})}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }