/**
 * Получение списка всех чек-листов.
 * @param {function} callback Функция обратного вызова при успешном вызове. 
 */
export function getAllChecklists(callback, showError) {
    const url = '/api/checklists';
    const method = 'GET';
    window.myAjax(method, url, {}, function(error, response) {
        if (error) {
            window.izkChecklistRoots = false;
            if (showError === false) {
                return;
            }
            window.myAlert(response.message);
        } else {
            window.checklists = response.check_lists || [];
            callback(response);
        }
    });
}
