const LAYOUT_ITEMS = [
    { value: 1, title: '1 колонка' },
    { value: 2, title: '2 колонки' },
    { value: 3, title: '3 колонки' },
    { value: 6, title: '6 колонки' }
]

export default {
    name: 'LayoutTumbler',
    components: {
        // todo
    },
    props: {
        extendedLayout: { type: Boolean, default: false },
        layout: { type: Number, default: 3 }
    },
    data: () => ({
        items: [],
        menuOpened: false
    }),
    watch: {
        // todo
    },
    mounted() {
        this.items = LAYOUT_ITEMS;
    },
    methods: {
        selectItemHandler(value) {
            this.$emit('changed', value)
        }
    }
}
