import Calendar from "./../Calendar/Calendar.vue";
import SmartCalendar from "./../Calendar/SmartCalendar.vue";
import CheckboxNew from "./../../base/checkboxNew";

const PARTS_TYPES_NAMES = {
    'SMART': 'Смарт-архив',
    'smoker_detection': 'Обнаржуение урильщики',
    'queue_detection': 'Наличие очереди',
    'human_count': 'Подсчёт посетителей',
    'absence_and_queue': 'Очередь и отсутствие продавца',
    'uniform_detection': 'Контроль шапок',
    'gloves_detection': 'Контроль перчаток',
    'cleaning_detection': 'Уборка помещений',
    'absence_in_zone': 'Отсутствие продавца/кассира',
    'license_plate_detection': 'Распознование автономеров',
    'first_in_detection': 'Контроль времени открытия',
    'last_out_detection': 'Контроль времени закрытия',
    'bac_treatment': 'Бакобработка',
    'equipment_detection': 'Распознавание инвентаря',
}

const PARTS_COLORS = {
    'SMART': '#ffc807',
    'smoker_detection': '#d57d7d',
    'queue_detection': '#d57dad',
    'human_count': '#d57dad',
    'absence_and_queue': '#d57dad',
    'uniform_detection': '#d57dcb',
    'gloves_detection': '#b27dd5',
    'cleaning_detection': '#867dd5',
    'absence_in_zone': '#7da2d5',
    'license_plate_detection': '#647080',
    'first_in_detection': '#7dd5c3',
    'last_out_detection': '#7dd5c3',
    'bac_treatment': '#81d57d',
    'equipment_detection': '#d5a97d',
}

const stateVars = {
    'min': {
        n1: 9,
        n2: 2,
        n3: 1,
        long: 10000,
        short: 10,
        long2: 5,
        long3: 5,
        sec: true
    },
    'decade': {
        n1: 8,
        n2: 12,
        n3: 1,
        long: 120000,
        short: 120,
        long2: 10,
        long3: 60
    },
    'hour': {
        n1: 9,
        n2: 11,
        n3: 1,
        long: 600000,
        short: 600,
        long2: 60,
        long3: 60
    },
    'eight': {
        n1: 11,
        n2: 6,
        n3: 1,
        long: 3600000,
        short: 3600,
        long2: 600,
        long3: 60
    },
    'day': {
        n1: 25,
        n2: 1,
        n3: 1,
        long: 3600000,
        short: 3600,
        long2: 600,
        long3: 60
    }
}

const STATSNUM = {
    'min': 10000,
    'decade': 120000,
    'hour': 600000,
    'eight': 3600000, 
    'day': 3600000
}
const SCALENUM = {
    'min': 2,
    'decade': 10,
    'hour': 50,
    'eight': 150, 
    'day': 500
}
export default {
    name: 'Archive',
    components: {
        Calendar,
        SmartCalendar,
        CheckboxNew,
    },
    props: ['startArchiveDev', 'show_left_menu', 'smartAchive', 'activeCam', 'isSmart', 'controls'],
    data: () => ({
        all: {
            start: null,
            duration: null,
            end: null
        },
        startTime: null,
        startDate: null,
        startRecordDate: null,
        stopRecordDate: null,
        playbackRate: 1,
        duration: 0,
        currentTime: 0,
        currentTimeCaption: '',
        pause: true,
        records: [],
        parts: [],
        showUpTime: false,
        upTime: '',
        upTimeStyle: '',
        day: '2020-07-10',
        ballCurrentTime: '',
        time1: '11:55',
        time2: '12:03',
        startPeriodDay: null,
        endPeriodDay: null,
        timeLineTitle: '',
        date1: {
            date: null,
            str: ''
        },
        date2: {
            date: null,
            str: ''
        },
        noRecords: false,
        times: [],
        oneTimeWidth: 100,
        secondsInPixel: 0,
        timeLineWidth: 0,
        currentPlayTimestamp: 0,
        snapshotTS: null,
        snapshots: [],
        allArchives: [],
        fileLoading: false,
        typesLoading: false,
        tagsMode: true,
        archive: {
            i: 0,
            link: null,
            timestart: null,
            timeend: null,
            videoSpeed: 1,
            pause: false,
            record: false,
            currentTS: null,
            recordStartTS: null,
            recordFinishTS: null,
            currentTime: 0,
            onStart: false,
        },
        deltatimes: [],
        newTimeline: {
            start: null,
            end: null,
            duration: 0,
            scale: 1,
            tStart: 0,
            tEnd: 0,
            tDuration: 0,
            times: [],
            currentPosition: 0,
            vcenter: 0,
            vend: 0,
            vstart: 0,
            vduration: 0,
            mouse: false,
            parts: [],
            move: false,
            currentDuration: 0,
            arrowBorderShow: false,
            arrowBorderClass: '',
            ghost: {
                show: false,
                position: {}
            },
            currentTimesState: 'min',
            startHour: 0,
            startOffset: 0,
            maxScale: 100
        },
        smartRecords: [],
        currentSmartRecordsIndex: null,
        currentSmartRecordsTime: null,
        smartPlayId: null,
        smartPayback: 1,
        allSmartRecords: [],
        showMultiCams: false,
        currentSmartPos: null,
        registrar: null,
        playlistGuid: null,
        revisorLoading: false,
        currentGuidDuration: null,
        smartTimeoutId: null,
        firstSmartResponse: true,
        selectNewDate: true,
        recordTypes: [],
        selectedRecordTypes: [],
        showRecordsTypes: false,
        allNewTimelineParts: [],
        unfiltredSmartRecords: [],
    }),
    mounted() {
        window.addEventListener('beforeunload', () => {
            this.stopArchive();
            this.smartPause();
        });
        if (window.tmpArchive) {
            window.tmpArchive = null;
            this.closeArchive();
            return;
        }
        this.tagsMode = true;
        window.archiveDate = null;
        window.fromArchive = false;
        this.registrar = null;
        this.timeUpdateHandler = this.timeUpdateHandler.bind(this);
        this.setDates();
        
        let now = new Date()
        this.day = now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2);//now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
        let tm = new Date(now.getTime() - 30 * 60000)
        this.time1 = tm.getHours() + ':' + tm.getMinutes()
        tm = new Date(now.getTime() - 15 * 60000)
        this.time2 = tm.getHours() + ':' + tm.getMinutes()
        
        this.date1 = this.dateStr2(this.day, this.time1)
        this.date2 = this.dateStr2(this.day, this.time2)
        if (this.isSmart) {
            if (window.globalDate) {
                let start = new Date(window.globalDate);
                start.setUTCHours(0,0,0,0);
                let end = new Date();
                end.setUTCHours(23,59,59,999);
                if (this.isSmart) {
                    window.myAjax('GET', '/smart_archive/records' , {
                        camera_id: this.activeCam.id,
                        start_time_in_sec: Math.floor(start.valueOf() / 1000),
                        end_time_in_sec: Math.floor(end.valueOf() / 1000)
                    }, (error, resp) => {
                        if (!error) {
                            let newdate = null;
                            if (resp) {
                                newdate = Object.keys(resp).sort()[0];
                            }
                            if (newdate) {
                                window.myAjax('GET', '/api/users_settings', {}, (error, response) => {
                                    console.log('/api/users_settings', response);
                                    if (!error) {
                                        const typesSetting = (response || []).find( setting => setting.name === 'default_tags_mode');
                                        this.tagsMode = typesSetting ? typesSetting.value === 'true' : false;
                                    }
                                    this.setupDateTimes({
                                        day: newdate,
                                        time1: '00:00',
                                        time2: '23:59'
                                    })
                                })
                            }
                        }
                    });
                }
            } else {
                this.archive.pause = true;
                this.pause = true;
                window.myAjax('GET', '/api/users_settings', {}, (error, response) => {
                    if (!error) {
                        console.log('/api/users_settings', response);
                        const typesSetting = (response || []).find( setting => setting.name === 'default_tags_mode');
                        this.tagsMode = typesSetting ? typesSetting.value === 'true' : false;
                    }
                    this.setupDateTimes({
                        day: this.day,
                        time1: '00:00',
                        time2: '23:59'
                    })
                })
            }
        } else if (!this.smartAchive) {
            this.$store.dispatch('GET_ALLOWED_DATES', {
                id: this.activeCam.id,
                start: this.startPeriodDay,
                stop: this.endPeriodDay
            });
            this.showModalDateTimesArchive();
        }
        this.setWidthVideoControl()
        window.$('.archive_controls:first').on('resize', this.setWidthVideoControl)
        this.currentTime = (document.getElementById('hls-video') || {}).currentTime;
        this.snapshots = []
        document.getElementById('timeline-wrapper').addEventListener('wheel', this.timeScroll);
        document.getElementById('timeline-wrapper').addEventListener('mousedown', this.timeDown);
        document.getElementById('timeline-wrapper').addEventListener('mousemove', this.timeMoveHandler);
        document.getElementById('timeline-wrapper').addEventListener('mouseup', this.timeUp);
        document.getElementById('timeline-wrapper').addEventListener('mouseout', this.timeUp);
        document.getElementById('timeline-wrapper').addEventListener('mouseleave', this.timeUp);
        document.getElementById('timeline-wrapper').addEventListener('click', this.timeClick);
        if (this.smartAchive) {
            document.addEventListener('keydown', this.keyPressHandler);
        }
    },
    beforeDestroy() {
        if (this.smartAchive) {
            document.removeEventListener('keydown', this.keyPressHandler);
        }
        if (this.smartTimeoutId) {
            window.clearTimeout(this.smartTimeoutId);
        }
        this.stopArchive();
        this.smartPause();
        const timelineWrapper = document.getElementById('timeline-wrapper');
        if (!timelineWrapper) {
            return
        }
        timelineWrapper.removeEventListener('wheel', this.timeScroll);
        timelineWrapper.removeEventListener('mousedown', this.timeDown);
        timelineWrapper.removeEventListener('mousemove', this.timeMoveHandler);
        timelineWrapper.removeEventListener('mouseup', this.timeUp);
        timelineWrapper.removeEventListener('mouseout', this.timeUp);
        timelineWrapper.removeEventListener('mouseleave', this.timeUp);
        timelineWrapper.removeEventListener('click', this.timeClick);
    },
    destroyed() {
        window.$(window).off('resize');
        const video = document.getElementById('hls-video');
        if ((video || {}).removeEventListener) {
            (video || {}).removeEventListener('timeupdate', this.timeUpdateHandler);
        }
        if (window.hlsTimeout) {
            clearTimeout(window.hlsTimeout);
            window.hlsTimeout = 0;
        }
        if (window.hls) {
            if (window.hls.stopLoad) {
                window.hls.stopLoad();
                window.hls.destroy();
            }
        }
    },
    computed: {
        activeCam: {
            get() {
                return this.$store.getters.ACTIVE_CAM;
            },
            set(val) {
                this.$store.dispatch('WRITE_ACTIVE_CAM', val);
            }
        }
    },
    methods: {
        changeRecordTypes(recordTypeId, checked) {
            console.log('changeRecordTypes', recordTypeId, checked);
            const index = this.selectedRecordTypes.indexOf(recordTypeId);
            if (index > -1) {
                if (this.selectedRecordTypes.length > 1) {
                    this.selectedRecordTypes.splice(index, 1);
                }
            } else {
                this.selectedRecordTypes.push(recordTypeId);
            }
            this.newTimeline.parts = [];
            
            this.newTimeline.parts = this.allNewTimelineParts.filter((record) => {
                return this.selectedRecordTypes.includes(record.type);
            })
            this.smartRecords = [];
            this.smartRecords = this.unfiltredSmartRecords.filter((record) => {
                const type = (record.split('.')[1] || '') || 'SMART';
                return this.selectedRecordTypes.includes(type);
            })
            // console.log(this.smartRecords[0].record);
            this.smartPause();
            this.updateNewTimeline();
            this.updateSmartTimeline();
        },
        keyPressHandler(e) {
            if ( e.keyCode == 32 ) {
                this.togglePauseHandler();
            } else if (e.keyCode == 37) {
                this.nextFrameHandler(false);
            } else if (e.keyCode == 39){
                this.nextFrameHandler(true);
            }
        },
        timeDown() {
            this.newTimeline.mouse = true;
        },
        timeMoveHandler(event) {
            if (this.newTimeline.mouse) {
                var rect = event.target.getBoundingClientRect();
                this.newTimeline.move = true;
                // this.newTimeline.ghost.show = false;
                this.timeMove(event.movementX, rect.width);
            }
        },
        timeUp(event) {
            event.preventDefault();
            // this.newTimeline.ghost.show = true;
            this.newTimeline.mouse = false;
        },
        timeClick(event) {
            event.preventDefault();
            var rect = event.target.getBoundingClientRect();
            var x = event.clientX - rect.left;
            if (!this.newTimeline.move) {
                const duration = this.newTimeline.currentDuration = this.getDurationByClick(x, rect.width);
                if (this.smartAchive) {
                    let clickTime = 0;
                    if (this.tagsMode) {
                        clickTime = Number(this.unfiltredSmartRecords[0].split('.')[0].split('_')[0]) + duration;
                    } else {
                        clickTime = Number(this.smartRecords[0].split('_')[0]) + duration;
                    }
                    let lastIndex = 0;
                    for (let i = 1; i < this.smartRecords.length - 1; i++) {
                        if (this.tagsMode) {
                            if (Number(this.smartRecords[i].split('.')[0].split('_')[0]) >= clickTime) {
                                break;
                            }
                        } else {
                            if (Number(this.smartRecords[i].split('_')[0]) >= clickTime) {
                                break;
                            }
                        }
                        lastIndex = i;
                    }
                    this.currentSmartRecordsIndex = lastIndex;
                    
                    if (this.tagsMode) {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0]);
                    } else {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0]);
                    }
                    this.updateSmartRecord();
                    if (!this.pause) {
                        this.smartPlay();
                    }
                    this.updateSmartTimeline();
                    // this.currentTime = document.getElementById('hls-video').currentTime = duration - offset;
                } else {
                    if (this.registrar === 'revisor') {
                        if (duration < (document.querySelector("#hls-video") || {}).duration) {
                            const {part, index} = this.getPartByDuration(duration) || {};
                            if (part) {
                                this.setVideoDuration(index, duration);
                                this.updateArrow();
                            }
                        } else {
                            this.getRevisorPart();
                        }
                    } else {
                        const {part, index} = this.getPartByDuration(duration) || {};
                        if (part) {
                            this.setVideoDuration(index, duration);
                            this.updateArrow();
                        }
                    }
                }
            }
            this.newTimeline.move = false;
        },
        getRevisorPart() {
            this.currentGuidDuration = this.newTimeline.currentDuration
            if (window.hls) {
                if (window.hls.stopLoad) {
                    window.hls.stopLoad();
                }
            }

            this.currentTime = document.getElementById('hls-video').currentTime;
            const _part = this.getPartByTime(this.currentTime);
            if (!_part) {
                return;
            }
            const part = _part.part;
            this.getDurationFromTime(this.currentTime);
            const currentTime = this.currentGuidDuration ? this.currentGuidDuration + this.currentTime : this.currentTime;
            let time = new Date((part.start + currentTime - part.videoStart) * 1000);
            if (this.registrar === 'revisor') {
                const d = new Date();
                let diff = d.getTimezoneOffset()/ -60;
                time.setHours(time.getHours() - diff);
            }
            var end = new Date(this.day);
            end.setUTCHours(23,59,59,999);
            window.myAjax('GET', '/archive/playlist/start?' +
                'alias=' + this.playlistGuid + 
                '&camera_id=' + this.activeCam.id +
                '&start_time=' + parseInt(time.getTime() / 1000) +
                '&end_time=' + parseInt(Math.floor(end.valueOf() / 1000))
            , {}, this.getRevisorPartCallback)
        },
        getRevisorPartCallback(error, response) {
            if (!error) {
                var video = document.getElementById('hls-video');
                let playlist = response.split('hls/');
                if (!playlist[1]) {
                    playlist = response.split('playlist/');
                }
                this.playlistGuid = playlist[1].split('.')[0];
                this.revisorLoading = true;
                if (window.hlsTimeout) {
                    clearTimeout(window.hlsTimeout);
                    window.hlsTimeout = 0;
                }
                if (window.hls) {
                    if (window.hls.stopLoad) {
                        window.hls.stopLoad();
                        window.hls.destroy();
                    }
                }
                window.hls = new window.Hls({
                    xhrSetup: xhr => {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + window.getCookie('userToken'))
                    }
                });
                var videoSrc = decodeURIComponent(response);
                window.hls.loadSource(videoSrc);
                window.hls.attachMedia(video);
                window.hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
                    this.revisorLoading = false;
                    if (video) {
                        video.play();
                        this.pause = false;
                        this.updateArrow();
                    }
                    if (window.hlsTimeout) {
                        clearTimeout(window.hlsTimeout);
                        window.hlsTimeout = 0;
                    }
                });
                window.hls.on(window.Hls.Events.ERROR, (event, data) => {
                    if (data.fatal) {
                    switch (data.type) {
                        case window.Hls.ErrorTypes.NETWORK_ERROR:
                            this.revisorLoading = true;
                            window.hlsTimeout = setTimeout(() => {
                                this.revisorLoading = false;
                                // video.play();
                                // this.pause = false;
                                window.hls.loadSource(videoSrc);
                                window.hls.startLoad();
                            }, 1000)
                            break;
                        }
                    }
                });
            }
        },
        getPartByTime2(time) {
            let lastTime = time;
            for (let i = 0; i < this.newTimeline.parts.length; i++) {
                const part = this.newTimeline.parts[i];
                if (
                    lastTime >= part.start &&
                    lastTime <= part.end
                ) {
                    return {
                        part: part,
                        lastTime: lastTime
                    };
                } else if (this.newTimeline.parts[i+1]) {
                    lastTime += this.newTimeline.parts[i+1].start - part.end;
                }
            }
            return null;
        },
        getDurationFromTime(time) {
            const { part, lastTime } = this.getPartByTime2(time) || {};
            if (part) {
                this.newTimeline.currentDuration = lastTime;
            }
        },
        setVideoDuration(index, duration) {
            let offset = 0;
            for (let i = 1; i <= index; i++) {
                const part = this.newTimeline.parts[i];
                const lastpart = this.newTimeline.parts[i-1];
                offset += part.start - lastpart.end;
            }
            this.currentTime = document.getElementById('hls-video').currentTime = duration - offset;
        },
        getDurationByClick(x, width) {
            return this.newTimeline.vstart + this.newTimeline.vduration * x / width ;
        },
        getPartByDuration(duration) {
            for (let i = 0; i < this.newTimeline.parts.length; i++) {
                const part = this.newTimeline.parts[i];
                if (
                    part.start <= duration &&
                    part.end >= duration
                ) {
                    return { part: part, index: i };
                }
            }
            return null;
        },
        timeScroll(event) {
            event.preventDefault();
            this.newTimeline.scale -= event.deltaY / SCALENUM[this.newTimeline.currentTimesState];
            if (this.newTimeline.scale <= 1) {
                this.newTimeline.scale = 1;
            }
            if (this.newTimeline.scale >= this.newTimeline.maxScale) {
                this.newTimeline.scale = this.newTimeline.maxScale;
            }
            
            // var rect = event.target.getBoundingClientRect();
            // var x = event.clientX - rect.left;

            var rect = document.getElementById('timeline-wrapper').getBoundingClientRect();
            var x = event.clientX - rect.left;
            const kf = x / rect.width;

            const vcent = this.newTimeline.vstart +
                (this.newTimeline.vend - this.newTimeline.vstart) * kf
                - ( (this.newTimeline.duration / this.newTimeline.scale) * (kf - 0.5) );

            this.newTimeline.vcenter = vcent; //(this.newTimeline.vend + this.newTimeline.vstart) / 2;
            this.newTimeline.vduration = this.newTimeline.duration / this.newTimeline.scale;
            this.newTimeline.vstart = this.newTimeline.vcenter - this.newTimeline.vduration / 2;
            this.newTimeline.vend = this.newTimeline.vcenter + this.newTimeline.vduration / 2;

            this.setNewTimes();
            this.updateTimesState();
            this.updateNewTimeline();

            
            this.showUpTime = true;
            this.newTimeline.ghost.show = true;
            // var rect = document.getElementById('timeline-wrapper').getBoundingClientRect();
            // var x = event.clientX - rect.left;
            this.newTimeline.ghost.position = {
                left: x + 'px'
            };
            const currentTime = new Date(this.newTimeline.start.getTime() + this.getDurationByClick(x, rect.width) * 1000);
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            if (this.registrar === 'revisor') {
                const d = new Date();
                let diff = d.getTimezoneOffset()/ -60;
                currentTime.setHours(currentTime.getHours() - diff);
            }
            this.upTime = currentTime.toLocaleString(short, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            });
        },
        updateTimesState() {
            const dur = this.newTimeline.vduration;
            let newState = '';
            if (dur < 80) {
                newState = 'min';
            } else if (dur < 620) {
                newState = 'decade';
            } else if (dur < 4000) {
                newState = 'hour';
            } else if (dur < 30000) {
                newState = 'eight';
            } else {
                newState = 'day';
            }
            if (newState !== this.newTimeline.currentTimesState) {
                this.newTimeline.currentTimesState = newState;
                this.setNewTimes();
            }
            this.updateTimes();
        },
        timeMove(delta, width) {
            const kf = this.newTimeline.vduration / width;
            this.newTimeline.vcenter -= 1.5 * kf * delta;
            this.newTimeline.vduration = this.newTimeline.duration / this.newTimeline.scale;
            this.newTimeline.vstart = this.newTimeline.vcenter - this.newTimeline.vduration / 2;
            this.newTimeline.vend = this.newTimeline.vcenter + this.newTimeline.vduration / 2;
            this.setNewTimes();
            this.updateNewTimeline();
        },
        checkMove() {
            if (this.newTimeline.vstart < 0) {
                this.newTimeline.vcenter = this.newTimeline.vduration / 2;
                this.newTimeline.vstart = this.newTimeline.vcenter - this.newTimeline.vduration / 2;
                this.newTimeline.vend = this.newTimeline.vcenter + this.newTimeline.vduration / 2;
            }
            if (this.newTimeline.vend > this.newTimeline.duration) {
                this.newTimeline.vcenter = this.newTimeline.duration - this.newTimeline.vduration / 2;
                this.newTimeline.vstart = this.newTimeline.vcenter - this.newTimeline.vduration / 2;
                this.newTimeline.vend = this.newTimeline.vcenter + this.newTimeline.vduration / 2;
            }
        },
        updateArrow() {
            let duration = this.currentGuidDuration ? (this.currentGuidDuration + this.newTimeline.currentDuration) : this.newTimeline.currentDuration;
            this.newTimeline.arrowStyle = {
                left: 100 * ( (duration - this.newTimeline.vstart) / this.newTimeline.vduration ) + '%',
            }
            this.newTimeline.arrowBorderShow = false;
            if (duration < this.newTimeline.vstart) {
                this.newTimeline.arrowBorderShow = true;
                this.newTimeline.arrowBorderClass = 'timeline-arrow-border_left';
            } else if (duration > this.newTimeline.vstart + this.newTimeline.vduration) {
                this.newTimeline.arrowBorderShow = true;
                this.newTimeline.arrowBorderClass = 'timeline-arrow-border_right';
            }
        },
        updateNewTimeline() {
            this.checkMove();
            this.updateParts();
            this.updateTimes();
            this.updateArrow();
        },
        updateParts() {
            for (let i = 0; i < this.newTimeline.parts.length; i++) {
                const part = this.newTimeline.parts[i];
                if (this.smartAchive) {
                    if (this.tagsMode) {
                        part.style = {
                            left: 100 * ( (part.start - this.newTimeline.vstart) / this.newTimeline.vduration ) + '%',
                            width: '1px',
                            background: part.color,
                        };
                    } else {
                        part.style = {
                            left: 100 * ( (part.start - this.newTimeline.vstart) / this.newTimeline.vduration ) + '%',
                            width: '1px'
                        };
                    }
                } else {
                    part.style = {
                        left: 100 * ( (part.start - this.newTimeline.vstart) / this.newTimeline.vduration ) + '%',
                        width: 100 * part.duration / this.newTimeline.vduration + '%'
                    };
                }
                part.position = (part.start - this.newTimeline.vstart) / this.newTimeline.vduration;
            }         
        },
        setNewTimes() {
            const startV = this.newTimeline.start.getTime() + this.newTimeline.vstart * 1000;
            const offset = (startV) % STATSNUM[this.newTimeline.currentTimesState];
            this.newTimeline.startOffset = offset;
            const startHour = startV - offset;
            this.newTimeline.startHour = startHour;
            const numbers = stateVars[this.newTimeline.currentTimesState];
            this.newTimeline.times = [];
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            for (let i = 0; i < numbers.n1; i++) {
                const date = new Date(this.newTimeline.startHour + i * numbers.long);
                const position = this.newTimeline.vstart + i * numbers.short - this.newTimeline.startOffset / 1000;
                if (this.registrar === 'revisor') {
                    date.setHours(date.getHours() - diff);
                }
                this.newTimeline.times.push({
                    position: position,
                    visible: true,
                    caption: date.toLocaleString('ru', numbers.sec ? {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                    } : {
                        hour: 'numeric',
                        minute: 'numeric'
                    }),
                    style: {
                        left: 100 * (position / this.newTimeline.duration) + '%'
                    }
                })
                for (let j = 1; j < numbers.n2; j++) {
                    this.newTimeline.times.push({
                        position: position + j * numbers.long2,
                        visible: true,
                        caption: '',
                        style: {
                            left: 100 * ((position - + j * numbers.long2)/ this.newTimeline.duration) + '%',
                            height: '9px'
                        }
                    })
                }
            }
        },
        cancelCreateArchiveDev() {
            this.$emit('cancelCreateArchiveDev', true)
        },
        createArchiveDev() {
            this.$emit('createArchiveDev', true)
        },
        updateTimes() {
            for (let i = 0; i < this.newTimeline.times.length; i++) {
                const time = this.newTimeline.times[i];
                if (
                    time.position >= this.newTimeline.vstart - 50 &&
                    time.position <= this.newTimeline.vend + 2
                ) {
                    time.visible = true;
                } else {
                    time.visible = false;
                }
                if (time.visible) {
                    time.style.left = 100 * ( (time.position - this.newTimeline.vstart) / this.newTimeline.vduration) + '%';
                } else {
                    time.style.left = '-50px';
                }
            }
        },
        getPart(time) {
            for (let i = 0; i < this.newTimeline.parts.length; i++) {
                const part = this.newTimeline.parts[i];
                if (
                    time > part.start &&
                    time < part.end
                ) {
                    return {
                        part: part,
                        index: i
                    };
                }
            }
            return null;
        },
        getTimeAndDuration() {
            // todo
        },
        timeUpdateHandler() {
            this.currentTime = document.getElementById('hls-video').currentTime;
            const _part = this.getPartByTime(this.currentTime);
            if (!_part) {
                return;
            }
            const part = _part.part;
            this.getDurationFromTime(this.currentTime);
            // const partWidth = window.$('#dsv-timeline-part-' + _part.index).width();
            // const x = partWidth * ( (this.currentTime - part.videoStart) / part.duration);
            // const timelineWidth = window.$('.archive_bottom_interface').width();
            // const left = - 14 + timelineWidth * part.timelineStart / this.all.duration + x;
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            const currentTime = this.currentGuidDuration ? this.currentGuidDuration + this.currentTime : this.currentTime;
            let time = new Date((part.start + currentTime - part.videoStart) * 1000);
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            if (this.registrar === 'revisor') {
                time.setHours(time.getHours() - diff);
            }
            if (!window.fromArchive) {
                window.archiveDate = new Date(time);
                window.archiveDate.setHours(window.archiveDate.getHours() - diff);
            }
            this.currentTimeCaption = time.toLocaleString(short, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            });
            this.updateArrow();
            // window.$('.slider_ball:first').css('left', left + 'px');
            return;
        },
        closeArchive() {
            this.$emit('closeArchive')
        },
        setDates() {
            const date = new Date()
            this.endPeriodDay = Date.parse(date.toString())/1000;
            date.setDate(date.getDate() - 365);
            let [day, month, year] = date.toLocaleDateString().split('.');
            this.startPeriodDay = Date.parse(`${year}-${month}-${day}`)/1000
        },
        timeLineShow(event) {
            if (this.newTimeline.move) {
                this.newTimeline.ghost.show = false;
                return;
            }
            this.showUpTime = true;
            this.newTimeline.ghost.show = true;
            var rect = event.target.getBoundingClientRect();
            var x = event.clientX - rect.left;
            this.newTimeline.ghost.position = {
                left: x + 'px'
            };
            // this.upTime = this.getDurationByClick(x, rect.width);
            // const partWidth = window.$('#dsv-timeline-part-' + index).width();
            // const partLeft = parseFloat(window.$('#dsv-timeline-part-' + index).css("left"));
            // const partKf = x / partWidth;
            // const currentTimeInSec = part.start + partKf * part.duration;
            const currentTime = new Date(this.newTimeline.start.getTime() + this.getDurationByClick(x, rect.width) * 1000);
            // this.upTimeStyle = 'left:' + (partLeft + x - 40) + 'px;';
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            if (this.registrar === 'revisor') {
                const d = new Date();
                let diff = d.getTimezoneOffset()/ -60;
                currentTime.setHours(currentTime.getHours() - diff);
            }
            this.upTime = currentTime.toLocaleString(short, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            });
        },
        startTagsLoad() {
            this.tagsMode2 = true;
            // this.tagsMode = true;
            // this.typesLoading = true;
            this.getRecords();
        },
        getPartByTime(time) {
            for (let i = 0; i < this.parts.length; i++) {
                const part = this.parts[i];
                if (
                    time > part.videoStart &&
                    time < part.videoStart + part.duration
                ) {
                    return {
                        part: part,
                        index: i
                    };
                }
            }
        },
        timeLineClick(e, index) {
            if (e.target.classList.contains('slider_ball')) {
                return;
            }
            const part = this.parts[index];
            var rect = e.target.getBoundingClientRect();
            var x = e.clientX - rect.left;
            const partWidth = window.$('#dsv-timeline-part-' + index).width();
            const partKf = x / partWidth;
            const currentTime = part.videoStart + partKf * part.duration;
            this.currentTime = document.getElementById('hls-video').currentTime = currentTime;
            const timelineWidth = window.$('.archive_bottom_interface').width();
            const left = - 14 + timelineWidth * part.timelineStart / this.all.duration + x;
            window.$('.slider_ball:first').css('left', left + 'px');
        },
        setWidthVideoControl() {
            this.timeLineWidth = window.$('.archive_bottom_interface:first').innerWidth()
            this.times = []
            let maxTime = parseInt(this.timeLineWidth / this.oneTimeWidth),
                restMiliSeconds = (this.timeLineWidth % this.oneTimeWidth) * (this.date2.date.getTime() - this.date1.date.getTime()) / this.timeLineWidth,
                intermidiateMiliseconds = parseInt((this.date2.date.getTime() - this.date1.date.getTime() - restMiliSeconds) / maxTime)
            this.secondsInPixel = (this.date2.date.getTime() - this.date1.date.getTime()) / (1000 * this.timeLineWidth)
            this.currentPlayTimestamp = this.date1.date.getTime()
            for (let i = 0; i <= maxTime; i++) {
                let ts = this.date1.date.getTime() + (0.5 + i) * intermidiateMiliseconds
                this.times.push(this.timeStr(ts))
            }
            let active_cam = window.$('video.active_cam:first')
            active_cam.css('height', active_cam.parent().parent().height())
        },
        strDate(ts) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(ts),
                options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                }
            return date.toLocaleString(short, options) //  среда, 31 декабря 2014 г. н.э. 12:30:00
        },
        strDateArchiveFinish(ts) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(ts),
                options = {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                }
            return date.toLocaleString(short, options) //  12:30:00
        },
        dateStr() {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(),
                options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric'
                }
            return date.toLocaleString(short, options);
        },
        timestampToDate(timeStampSec) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(timeStampSec * 1000),
                options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric'
                }
            return {
                date: date,
                str: date.toLocaleString(short, options)
            }
        },
        dateToString(date) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
            return new Date(date).toLocaleString(short, options);
        },
        dateStr2(day, hour_minute) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(`${day} ${hour_minute}:00`),
                options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }
            return {
                date: date,
                str: date.toLocaleString(short, options) //  среда, 31 декабря 2014 г. н.э. 12:30:00
            }
        },
        timeStr(timeStamp) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(timeStamp),
                options
            if (this.secondsInPixel > 6)
                options = {
                    hour: 'numeric',
                    minute: 'numeric'
                }
            else
                options = {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                }
            return date.toLocaleString(short, options) //  12:30:00 or 12:30
        },
        stopArchive() {
            if (this.playlistGuid) {
                window.myAjax('DELETE', '/stream/archive/playlist/stop', {
                    alias: this.playlistGuid
                }, () => {})
            }
        },
        getAllArchivesLinks(cam) {
            this.activeCam = cam
            let ts = parseInt((this.date1.date.getTime() + (this.date1.date.getTimezoneOffset() + 180) * 60000) / 1000),
                tf = parseInt((this.date2.date.getTime() + (this.date2.date.getTimezoneOffset() + 180) * 60000) / 1000);
            // this.sendLog(this.date1.str, this.date2.str)
            
            window.myAjax('GET', '/archive/playlist/records', {
                camera_id: cam.id,
                start_time: ts,
                end_time: tf,
            }, this.myCallbackGetAllArchivesLinks)
        },
        loadedMetaDatahandler() {
            // todo
        },
        myCallbackGetAllArchivesLinks(error, response) {
            this.noRecords = false;
            if (error) {
                this.noRecords = true;
                window.myAlert('За указанный период времени архивных записей для выбранной камеры не найдено.')
                this.$emit('restartPreview')
            } else {
                var video = document.getElementById('hls-video');
                if (response.registrar === 'revisor') {
                    this.registrar = 'revisor';
                }
                var videoSrc = decodeURIComponent(response.playlist);
                let playlist = response.playlist.split('hls/');
                if (!playlist[1]) {
                    playlist = response.playlist.split('playlist?file_id=');
                }
                this.playlistGuid = playlist[1].split('.')[0];
                this.records = [];
                this.records = response.records;

                const recs = [];
                for (let i = 0; i < response.records.length; i++) {
                    const rec = response.records[i];
                    const recStart = new Date(rec.start_time * 1000);
                    const recDuration = rec.duration;
                    const recEnd = new Date(rec.start_time * 1000 + rec.duration * 1000);
                    recs.push({
                        start: recStart,
                        end: recEnd,
                        duration: recDuration
                    });
                }
                this.newTimeline.start = recs[0].start;
                this.newTimeline.end = recs[recs.length - 1].end;
                this.newTimeline.duration = (this.newTimeline.end.getTime() - this.newTimeline.start.getTime()) / 1000;
                this.newTimeline.vstart = 0;
                this.newTimeline.vend = this.newTimeline.duration;
                this.newTimeline.vduration = this.newTimeline.duration;
                this.newTimeline.maxScale = this.newTimeline.duration / 60;
                this.newTimeline.vcenter = (this.newTimeline.vend - this.newTimeline.vstart) / 2;
                this.updateTimesState();
                const offset = this.newTimeline.start.getTime() % 3600000;
                this.newTimeline.startOffset = offset;
                const startHour = this.newTimeline.start.getTime() - offset;
                this.newTimeline.startHour = startHour;
                this.updateTimes();
                for (let i = 0; i < recs.length; i++) {
                    const rec = recs[i];
                    const recStart = (rec.start.getTime() - this.newTimeline.start.getTime()) / 1000;
                    const recDuration = rec.duration;
                    const recEnd = recStart + recDuration;
                    this.newTimeline.parts.push({
                        start: recStart,
                        end: recEnd,
                        duration: recDuration,
                        style: {
                            left: 100 * recStart / this.newTimeline.duration + '%',
                            width: 100 * recDuration / this.newTimeline.duration + '%',
                        }
                    });
                }
                this.startTime = this.records[0].start_time;
                this.startDate = this.dateToString(this.startTime * 1000);
                this.duration = response.duration;
                this.all.start = new Date(this.startTime * 1000);
                const lastRecord = this.records[this.records.length - 1];
                this.all.end = new Date( (lastRecord.start_time + lastRecord.duration) * 1000 );
                this.all.duration = (this.all.end.getTime() - this.all.start.getTime()) / 1000;
                let currentStart = 0; // для таймлайна
                let currentStart2 = 0; // для видео
                let prev = null;
                this.parts = [];
                const timelineWidth = window.$('.archive_bottom_interface').width();
                const dsvLocale = window.getCookie('dsvLocale');
                const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
                for (let i = 0; i < this.records.length; i++) {
                    const record = this.records[i];
                    if (prev) {
                        currentStart += record.start_time - prev.start_time
                    }
                    const part = {
                        start: record.start_time,
                        duration: record.duration,
                        timelineStart: currentStart,
                        videoStart: currentStart2
                    };
                    part.style = {
                        left: (100 * part.timelineStart / this.all.duration) + '%',
                        width: (100 * part.duration / this.all.duration) + '%'
                    }
                    part.captionStart = new Date(part.start * 1000).toLocaleString(short, {
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                    part.captionEnd = new Date((part.start + part.duration) * 1000).toLocaleString(short, {
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                    const partW = timelineWidth * part.duration / this.all.duration;
                    part.showCaptionEnd = partW > 100;
                    part.showCaptionStart = partW > 53;
                    this.parts.push(part);
                    prev = record;
                    currentStart2 += part.duration;
                }
                video.addEventListener('timeupdate', this.timeUpdateHandler);
                if (window.Hls.isSupported()) {
                    if (window.hlsTimeout) {
                        clearTimeout(window.hlsTimeout);
                        window.hlsTimeout = 0;
                    }
                    if (window.hls) {
                        if (window.hls.stopLoad) {
                            window.hls.stopLoad();
                            window.hls.destroy();
                        }
                    }
                    window.hls = new window.Hls({
                        // maxBufferLength: 100000,
                        // maxMaxBufferLength: 100000
                        xhrSetup: xhr => {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + window.getCookie('userToken'))
                        }
                    });
                    window.hls.loadSource(videoSrc);
                    window.hls.attachMedia(video);
                    window.hls.on(window.Hls.Events.MANIFEST_PARSED, (event, a) => {
                        this.duration = a.levels[0].details.totalduration;
                        if (video) {
                            video.play();
                            this.pause = false;
                        }
                        if (window.hlsTimeout) {
                            clearTimeout(window.hlsTimeout);
                            window.hlsTimeout = 0;
                        }
                    });
                    if (response.registrar === 'revisor') {
                        this.registrar = 'revisor';
                        this.revisorLoading = true;
                        window.hls.on(window.Hls.Events.ERROR, (event, data) => {
                            if (data.fatal) {
                            switch (data.type) {
                                case window.Hls.ErrorTypes.NETWORK_ERROR:
                                    this.revisorLoading = true;
                                    window.hlsTimeout = setTimeout(() => {
                                        this.revisorLoading = false;
                                        video.play();
                                        this.pause = false;
                                        window.hls.loadSource(videoSrc);
                                        window.hls.startLoad();
                                    }, 2000)
                                    break;
                                }
                            }
                        });
                    }
                }
            }
        },
        sendLog(startTS, finishTS) {
            let url = '/api/log-camera-watching',
                method = 'POST',
                data = {
                    camera_id: this.activeCam.id,
                    datetime_start: startTS,
                    datetime_end: finishTS
                };
            window.myAjax(method, url, data, this.myCallbackEmpty)
        },
        myCallbackEmpty(error, response) {
            if (error) {
                window.myAlert(response.message)
            }
        },
        setTimeLine(arch_i, currentTime) {
            this.archive.currentTS = this.allArchives[arch_i].timestart + currentTime;
            let allCurrentSeconds = this.allArchives[arch_i].timestart + currentTime - this.date1.date.getTime() / 1000;
            let currentPixel = allCurrentSeconds / this.secondsInPixel;
            window.$('.archive_bottom_up_from_left_of_slider_ball:first').css('width', currentPixel + 'px');
            window.$('.slider_ball:first').css('left', (12 + currentPixel) + 'px');
            const date = new Date(this.archive.currentTS*1000);
            this.ballCurrentTime = date.toLocaleTimeString();
        },
        showModalDateTimesArchive() {
            window.$('#modalDateTimesArchive').modal('show')
        },
        setDateTimesArchive() {
            if (this.day && this.time1 && this.time2 && this.time1 < this.time2) {
                this.date1 = this.dateStr2(`${this.day}`, `${this.time1}`)
                this.date2 = this.dateStr2(`${this.day}`, `${this.time2}`)
                this.setWidthVideoControl()
                if (this.activeCam) {
                    this.getAllArchivesLinks(this.activeCam)
                }
                window.$('#modalDateTimesArchive').modal('hide')
            } else {
                window.myAlert('Выбраны некорректные дата или время')
            }
        },
        controlArchiveVideo(button) {
            if (this.smartAchive) {
                if (button === 'speedDown' && this.smartPayback > 1) {
                    this.smartPayback /= 2;
                    this.smartPlay();
                } else if (button === 'speedUp' && this.smartPayback < 8) {
                    this.smartPayback *= 2;
                    this.smartPlay();
                } else if (button === 'speedDefault') {
                    this.smartPayback = 1;
                    this.smartPlay();
                } else if (button === 'togglePause') {
                    this.pause = !this.pause;
                    if (this.pause) {
                        this.smartPause();
                    } else {
                        this.smartPlay();
                    }
                } else if (button === 'onStart') {
                    this.currentSmartRecordsIndex = 0;
                    if (this.tagsMode) {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0]);
                    } else {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0]);
                    }
                    this.smartPayback = 1;
                    this.smartPause();
                    this.updateSmartTimeline();
                    // this.playbackRate = 1;
                    // const part = this.getPartByTime(video.currentTime).part;
                    // video.currentTime = part.videoStart;
                    // this.archive.onStart = true
                }
                return;
            }
            const video = document.getElementById('hls-video');
            if (button === 'speedDown' && this.playbackRate > 1) {
                this.playbackRate /= 2;
            } else if (button === 'speedUp' && this.playbackRate < 16) {
                this.playbackRate *= 2;
            } else if (button === 'speedDefault') {
                this.playbackRate = 1;
            } else if (button === 'togglePause') {
                this.pause = !this.pause;
                if (this.pause) {
                    video.pause();
                } else {
                    video.play();
                }
            } else if (button === 'onStart') {
                this.playbackRate = 1;
                const part = this.getPartByTime(video.currentTime).part;
                video.currentTime = part.videoStart;
                this.archive.onStart = true
            }
            video.playbackRate = this.playbackRate;
            this.$emit('controlArchiveVideo', this.archive)
        },
        togglePauseHandler() {
            this.controlArchiveVideo('togglePause');
        },

        nextFrameHandler(value) {
            if (value) {
                if (this.currentSmartRecordsIndex + 1 < this.smartRecords.length) {
                    this.currentSmartRecordsIndex = this.currentSmartRecordsIndex + 1;
                    if (this.tagsMode) {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0]);
                    } else {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0]);
                    }
                    this.updateSmartRecord();
                    // this.updateNewTimeline();
                    this.updateSmartTimeline();
                }
            } else {
                if (this.currentSmartRecordsIndex > 0) {
                    this.currentSmartRecordsIndex = this.currentSmartRecordsIndex - 1;
                    if (this.tagsMode) {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0]);
                    } else {
                        this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0]);
                    }
                    this.updateSmartRecord();
                    // this.updateNewTimeline();
                    this.updateSmartTimeline();
                }
            }
        },
        setArchivePause(flag) {
            this.archive.pause = !!flag;
            this.$emit('controlArchiveVideo', this.archive)
        },
        setupDateTimes(obj) {
            this.day = obj.day;
            this.time1 = obj.time1;
            this.time2 = obj.time2;
            if (this.smartAchive) {
                // this.firstSmartResponse = true;
                // if (!window.globalDate) {
                this.selectNewDate = true;
                this.getRecords();
                // }
            } else {
                this.setDateTimesArchive();
            }
        },
        
        getRecords() {
            console.log(this.tagsMode);
            this.tagsMode = true;
            console.log(this.tagsMode);
            var start = new Date(this.day);
            // start.setUTCHours(0,0,0,0);
            var end = new Date(this.day);
            // end.setUTCHours(23,59,59,999);
            if (this.time1 && this.time2) {
                const times1 = this.time1.split(':');
                const times2 = this.time2.split(':');
                if (times1[1]) {
                    start.setHours(times1[0], times1[1]);
                } else if (times1[0]) {
                    start.setHours(times1[0]);
                }
                if (times2[1]) {
                    end.setHours(times2[0], times2[1]);
                } else if (times2[0]) {
                    end.setHours(times2[0]);
                }
            }
            // const d = new Date();
            // let diff = d.getTimezoneOffset()/ 60;
            // start.setHours(start.getHours() + diff);
            const data = Object.assign({
                camera_id: this.activeCam.id,
                start_time_in_sec: Math.floor(start.valueOf() / 1000),
                end_time_in_sec: Math.floor(end.valueOf() / 1000)
            })
            window.myAjax('GET', `/smart_archive/records${this.tagsMode ? '' : ''}`, data, this.getRecordsCallback);
        },
        getRecordsCallback(error, response) {
            this.noRecords = false;
            if (error) {
                this.noRecords = true;
            } else {
                let needUpdate = true;
                this.typesLoading = false;
                this.newTimeline.parts = [];
                this.allSmartRecords = [];
                this.smartRecords = [];
                if (this.tagsMode) {
                    this.recordTypes = [];
                }
                for (let key in response) {
                    // const day1 = new Date(this.day);
                    // const day1Str = day1.getFullYear() + '-' + ('0' + (day1.getMonth() + 1)).slice(-2) + '-' + ('0' + day1.getDate()).slice(-2);
                    // if (day1Str !== key) {
                    //     continue;
                    // }
                    let posKey = 0;
                    if (this.activeCam.posId) {
                        posKey = this.activeCam.posId;
                    }
                    // this.isSmart = true;
                    if (response[key][posKey]) {
                        console.log(response[key][posKey]);
                        if (
                            this.pause ||
                            this.currentSmartRecordsIndex + 1 !== this.smartRecords.length
                        ) {
                            needUpdate = false;
                        }
                        if (this.tagsMode) {
                            this.smartRecords = this.smartRecords.concat(response[key][posKey]);
                            this.unfiltredSmartRecords = this.smartRecords.sort((a, b) => a.record - b.record);
                        } else {
                            this.smartRecords = this.smartRecords.concat(response[key][posKey]);
                            this.smartRecords = this.smartRecords.sort((a, b) => a - b);
                        }
                        let currentTimestampIndex = 0;
                        let currentTimestamp = 0;
                        if (this.tagsMode) {
                            currentTimestamp = this.smartRecords[0].split('.')[0];
                        } else {
                            currentTimestamp = this.smartRecords[0];
                        }

                        if (window.globalTime1) {
                            const dayStamp = new Date(this.day);
                            const hours = window.globalTime1.split(':')[0];
                            const mins = window.globalTime1.split(':')[1];
                            dayStamp.setHours(hours, mins);
                            const selectedTimestamp = Math.floor(dayStamp / 1000);
                            if (this.tagsMode) {
                                this.smartRecords.forEach( record => {
                                    if (Number(record.split('.')[0].split('_')[0]) < selectedTimestamp) {
                                        currentTimestamp = record.record;
                                    }
                                })
                                currentTimestampIndex = this.smartRecords.findIndex(record => record.record === currentTimestamp);
                            } else {
                                this.smartRecords.forEach( timestamp => {
                                    if (Number(timestamp.split('_')[0]) < selectedTimestamp) {
                                        currentTimestamp = timestamp;
                                    }
                                })
                                currentTimestampIndex = this.smartRecords.indexOf(currentTimestamp);
                            }
                        }
                        
                        this.showMultiCams = false;
                        if (this.firstSmartResponse || needUpdate || this.selectNewDate) {
                            this.currentSmartRecordsIndex = currentTimestampIndex;
                            if (this.tagsMode) {
                                this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0])
                            } else {
                                this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0])
                            }
                        }
                        if (this.firstSmartResponse && !window.globalTime1) {
                            this.currentSmartRecordsIndex = this.smartRecords.length - 1;
                            if (this.tagsMode) {
                                this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0])
                            } else {
                                this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0])
                            }
                        }
                        if (this.firstSmartResponse || this.selectNewDate) {
                            this.startSmartRecords();
                            this.smartPlay();
                        }
                        for (let i = 0; i < this.smartRecords.length; i++) {
                            // const rec = this.smartRecords[0];
                            const recStart = this.tagsMode ?
                                parseInt(this.smartRecords[i].split('.')[0].split('_')[0]) - parseInt(this.smartRecords[0].split('.')[0].split('_')[0]) :
                                parseInt(this.smartRecords[i].split('_')[0]) - parseInt(this.smartRecords[0].split('_')[0]);
                            const recDuration = 1;
                            const recEnd = recStart + recDuration;
                            const type = (this.smartRecords[i].split('.')[1] || '') || 'SMART';
                            console.log(type);
                            if (this.tagsMode) {
                                const recordData = {
                                    start: recStart,
                                    end: recEnd,
                                    duration: recDuration,
                                    type: type,
                                    color: PARTS_COLORS[type] || 'red',
                                    style: {
                                        left: 100 * recStart / this.newTimeline.duration + '%',
                                        width: '1px',
                                    }
                                };
                                this.allNewTimelineParts.push(recordData);
                                this.newTimeline.parts.push(recordData);
                                if (this.recordTypes.findIndex(record => record.id === type) === -1) {
                                    this.recordTypes.push({
                                        id: type,
                                        name: PARTS_TYPES_NAMES[type] || 'Иной модуль',
                                    });
                                    this.selectedRecordTypes.push(type);
                                }
                                this.selectedRecordTypes = [...new Set(this.selectedRecordTypes)];
                            } else {
                                this.newTimeline.parts.push({
                                    start: recStart,
                                    end: recEnd,
                                    duration: recDuration,
                                    style: {
                                        left: 100 * recStart / this.newTimeline.duration + '%',
                                        width: '1px',
                                    }
                                });
                            }
                        }
                    } else {
                        for (let pos in response[key]) {
                            this.allSmartRecords.push(response[key][pos])
                        }
                        this.showMultiCams = true;
                        this.getAllSmartsPreview();
                    }
                    console.log('this.smartRecords.length', this.smartRecords.length)
                }
                if (this.smartTimeoutId) {
                    window.clearTimeout(this.smartTimeoutId);
                }
                if (!this.tagsMode) {
                    this.smartTimeoutId = window.setTimeout(this.getRecords, 60000);
                }
                this.pause = true;
                this.smartPause();
                this.updateNewTimeline();
                this.updateSmartTimeline();
                this.firstSmartResponse = false;
                this.selectNewDate = false;
            }
        },
        startSmartRecords() {
            if (this.tagsMode) {
                this.newTimeline.start = new Date(Number(this.smartRecords[0].split('.')[0].split('_')[0]) * 1000);
                this.newTimeline.end = new Date(Number(this.smartRecords[this.smartRecords.length - 1].split('.')[0].split('_')[0]) * 1000);
            } else {
                this.newTimeline.start = new Date(Number(this.smartRecords[0].split('_')[0]) * 1000);
                this.newTimeline.end = new Date(Number(this.smartRecords[this.smartRecords.length - 1].split('_')[0]) * 1000);
            }
            this.newTimeline.duration = (this.newTimeline.end.getTime() - this.newTimeline.start.getTime()) / 1000;
            this.newTimeline.vstart = 0;
            this.newTimeline.vend = this.newTimeline.duration;
            this.newTimeline.vduration = this.newTimeline.duration;
            
            if (this.tagsMode) { // вопрос
                this.newTimeline.maxScale = this.smartRecords.length > 3 ? this.newTimeline.duration / (this.smartRecords[3].record - this.smartRecords[0].record) : 1;
            } else {
                this.newTimeline.maxScale = this.smartRecords.length > 3 ? this.newTimeline.duration / (this.smartRecords[3] - this.smartRecords[0]) : 1;
            }
            this.newTimeline.vcenter = (this.newTimeline.vend - this.newTimeline.vstart) / 2;
            this.startDate = this.dateToString(this.newTimeline.start);
            this.updateSmartRecord();
            
            this.updateTimesState();
            const offset = this.newTimeline.start.getTime() % 3600000;
            this.newTimeline.startOffset = offset;
            const startHour = this.newTimeline.start.getTime() - offset;
            this.newTimeline.startHour = startHour;
            this.updateTimes();
        },
        nextRecord() {
            if (this.currentSmartRecordsIndex + 1 >= this.smartRecords.length) {
                return;
            }
            this.currentSmartRecordsIndex = this.currentSmartRecordsIndex + 1;
            if (this.tagsMode) {
                this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('.')[0].split('_')[0]);
            } else {
                this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0]);
            }
            this.updateSmartRecord(); 
        },
        smartPlay() {
            this.smartPause();
            this.pause = false;
            this.smartPlayId = setInterval(() => {
                this.nextRecord();
                this.updateSmartTimeline();
            }, 500 / this.smartPayback);
        },
        smartPause() {
            if (this.smartPlayId) {
                clearInterval(this.smartPlayId)
            }
        },
        updateSmartTimeline() {
            if (this.tagsMode) {
                this.currentTime = this.newTimeline.currentDuration = this.currentSmartRecordsTime - Number(this.unfiltredSmartRecords[0].split('.')[0].split('_')[0]);
            } else {
                this.currentTime = this.newTimeline.currentDuration = this.currentSmartRecordsTime - Number(this.smartRecords[0].split('_')[0]);
            }
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            
            let time = 0;
            if (this.tagsMode) {
                time = new Date((Number(this.unfiltredSmartRecords[0].split('.')[0].split('_')[0]) + this.currentTime) * 1000);
            } else {
                time = new Date((Number(this.smartRecords[0].split('_')[0]) + this.currentTime) * 1000);
            }
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            if (this.registrar === 'revisor') {
                time.setHours(time.getHours() - diff);
            }
            if (!window.fromArchive) {
                window.archiveDate = new Date(time);
                window.archiveDate.setHours(window.archiveDate.getHours() - diff);
            }
            this.currentTimeCaption = time.toLocaleString(short, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            });
            this.updateArrow();
        },
        getAllSmartsPreview() {
            // for ()

            this.allSmartRecords.forEach((element, index) => {
                console
                if (!element.length) {
                    return;
                }
                const time_in_sec = element[element.length - 1];
                const src = `/smart_archive/snapshot?camera_id=${this.activeCam.id}&time_in_sec=${time_in_sec}&date=${this.day}`;
                fetch(src, {
                    headers: {
                        'Authorization': 'Bearer ' + window.getCookie('userToken')
                    }
                }).then(res => res.blob())
                    .then(blob => {
                        if (blob) {
                            document.getElementById('preview-smart-camera-' + index).src = URL.createObjectURL(blob);
                        }
                    });
            });
            // preview-smart-camera-
        },
        updateSmartRecord() {
            // const position = this.currentSmartPos || this.activeCam.posId;
            const time_in_sec = this.smartRecords[this.currentSmartRecordsIndex];
                // position ? (this.currentSmartRecordsTime + ('_' + position)) : this.currentSmartRecordsTime;
            // const type = this.smartRecords[this.currentSmartRecordsIndex].split('.')[1] || 'SMART';
            const src = `/smart_archive/snapshot?camera_id=${this.activeCam.id}&time_in_sec=${time_in_sec}&date=${this.day}`;
            fetch(src, {
                headers: {
                    'Authorization': 'Bearer ' + window.getCookie('userToken')
                }
            }).then(res => res.blob())
                .then(blob => {
                    document.getElementById('smartAchive-id').src = URL.createObjectURL(blob);
                });        
        },
        recordArchiveVideo() {
            this.archive.record = !this.archive.record;
            const currentTime = document.getElementById('hls-video').currentTime;
            const part = this.getPartByTime(currentTime).part;
            if (this.archive.record) {
                this.startRecordDate = Math.floor(part.start + currentTime - part.videoStart);
            } else {
                this.stopRecordDate = Math.ceil(part.start + currentTime - part.videoStart);
                this.createVideoArchive()
            }
        },
        createSnapshot() {
            if (this.smartAchive) {
                const img = document.getElementById('smartAchive-id');
                let canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                let dataURI = canvas.toDataURL('image/jpeg');
                this.snapshotSelected = {
                    type_id: 1,
                    img_src: dataURI,
                    name: `Скриншот от ${this.date1.str}, ${this.currentTimeCaption}`
                };
                this.snapshotTS = parseInt(this.archive.currentTS);
                this.sendImage(this.snapshotSelected.img_src);
                return;
            }
            let video = window.$('video:first')[0];
            let width = video.videoWidth || video.clientWidth;
            let height = video.videoHeight || video.clientHeight;
            let canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            let dataURI = canvas.toDataURL('image/jpeg');
            this.snapshotSelected = {
                type_id: 1,
                img_src: dataURI,
                name: `Скриншот от ${this.date1.str}, ${this.currentTimeCaption}`
            };
            this.snapshotTS = parseInt(this.archive.currentTS);
            this.sendImage(this.snapshotSelected.img_src);
        },
        sendImage(dataURI) {
            let url = '/preview/snapshot',
                method = 'POST';
            window.myAjax(method, url, {image: dataURI}, this.sendImageCallback)
        },
        sendImageCallback(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                let newSnap = {
                    img_id: response.id,
                    img_src: response.link,
                    name: `Скриншот от ${this.date1.str}, ${this.currentTimeCaption}`,
                    type_id: 1
                }
                this.snapshots.push(newSnap)
                this.$emit('transferSnaps', {snapshots: this.snapshots, flag: false})
            }
        },
        createVideoArchive() {
            this.fileLoading = true;
            // window.$("#waitModal").modal('show')
            window.myAjax('GET', '/archive/cutvideo', {
                camera_id: this.activeCam.id,
                start_time: this.startRecordDate,
                end_time: this.stopRecordDate,
            }, this.myCallbackCreateArchiveVideo)
        },
        myCallbackCreateArchiveVideo(error, response) {
            // setTimeout(() => {
            //     window.$("#waitModal").modal('hide')
            // }, 100)
            this.fileLoading = false;
            if (error) {
                window.myAlert(response.message)
            } else {
                let newSnap = {
                    img_id: response.id,
                    video_src: response.link,
                    img_src: response.link_img,
                    name: 'Видео от ' + this.strDate(this.startRecordDate * 1000) + '-' + this.strDateArchiveFinish(this.stopRecordDate * 1000),
                    type_id: 2
                }
                this.snapshots.push(newSnap)
                this.$emit('transferSnaps', {snapshots: this.snapshots, flag: false})
            }
        },
        deleteSnapshot(i) {
            this.snapshots.splice(i, 1)
        },
        transferSnaps() {
            if (this.snapshots.length) {
                this.$emit('transferSnaps', {snapshots: this.snapshots, flag: true});
                window.tmpArchive = Object.assign({}, this._data);
            } else {
                this.$emit('cancelTransferSnaps')
                window.myAlert('Сделайте хотя бы один скриншот или нарезку видео')
            }
        },

        selectingRecord(record, index) {
            if (!this.allSmartRecords[index][0]) {
                return;
            }
            this.currentSmartPos = this.allSmartRecords[index][0].split('_')[1];
            if (!this.currentSmartPos) {
                return;
            }
            const tmp = [];
            this.allSmartRecords[index].forEach( item => {
                tmp.push(item.split('_')[0]);
            })
            this.smartRecords = tmp.sort((a, b) => a - b);
            this.showMultiCams = false;
            this.currentSmartRecordsIndex = 0;
            this.currentSmartRecordsTime = Number(this.smartRecords[this.currentSmartRecordsIndex].split('_')[0])
            this.startSmartRecords();
            this.newTimeline.parts = [];
            for (let i = 0; i < this.smartRecords.length; i++) {
                // const rec = this.smartRecords[0];
                const recStart = parseInt(this.smartRecords[i].split('_')[0]) - parseInt(this.smartRecords[0].split('_')[0]);
                const recDuration = 1;
                const recEnd = recStart + recDuration;
                this.newTimeline.parts.push({
                    start: recStart,
                    end: recEnd,
                    duration: recDuration,
                    style: {
                        left: 100 * recStart / this.newTimeline.duration + '%',
                        width: '1px',
                    }
                });
            }
        },
        cancelSelectingRecord() {
            this.smartRecords = [];
            this.showMultiCams = true;
            this.currentSmartPos = null;
            this.getAllSmartsPreview();
        },
        getSmartStatus() {
            return this.showMultiCams;
        },

        getAllSmartRecordsLength() {
            return this.allSmartRecords.length;
        }
    }
}