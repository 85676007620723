import { CRON_DAYS } from './../utils'; 

const POINT_BINDING_LENGHT = 12;

export default {
    name: 'NeuralSetting',
    components: {
        // todo
    },
    props: {
        activeCam: {
            type: Object,
            default: () => ({}),
        },
        object: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: 'queue_detection'
        },
        task: {
            type: Object,
            default: () => ({}),
        }
    },
    data: () => ({
        steps: [
            { id: '1', caption: '3 сек.', value: '/3', seconds: true, step: 3 },
            { id: '2', caption: '5 сек.', value: '/5', seconds: true, step: 5 },
            { id: '3', caption: '15 сек.', value: '/15', seconds: true, step: 15 },
            { id: '4', caption: '30 сек.', value: '/30', seconds: true, step: 30 },
            { id: '5', caption: '1 мин.', value: '/1', minutes: true, step: 60 },
            { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
            { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
            { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
            { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
            { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
            { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
            { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
        ],
        countSteps: [
            { id: '1', caption: '15 секунд', value: 15 },
            { id: '2', caption: '30 секунд', value: 30 },
            { id: '3', caption: '1 минуту', value: 60 },
            { id: '4', caption: '2 минуты', value: 120 }
        ],
        intervalId: null,
        positionId: 0,
        area: {
            x0: 0,
            x1: 0,
            y0: 0,
            y1: 0
        },
        showMessage: true,
        showBorder: false,
        showResult: false,
        checkResult: {},
        editMode: false,
        mousePressed: false,
        loading: false,
        border: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            startX: 0,
            startY: 0,
            width: 0,
            height: 0,
            realyStartX: 0,
            realyStartY: 0
        },
        devs: [],
        devTypes: [],
        users: [],
        selected: {
            dev: null,
            devType: "0",
            user: null,
            count: 1,
            step: null,
            position: "0",
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            extraCount: false,
            countStep: null,
            renewal: false,
            double_check: false
        },
        days: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false
        },
        times: [
            { value: '00:00', caption: '0:00' },
            { value: '01:00', caption: '1:00' },
            { value: '02:00', caption: '2:00' },
            { value: '03:00', caption: '3:00' },
            { value: '04:00', caption: '4:00' },
            { value: '05:00', caption: '5:00' },
            { value: '06:00', caption: '6:00' },
            { value: '07:00', caption: '7:00' },
            { value: '08:00', caption: '8:00' },
            { value: '09:00', caption: '9:00' },
            { value: '10:00' },
            { value: '11:00' },
            { value: '12:00' },
            { value: '13:00' },
            { value: '14:00' },
            { value: '15:00' },
            { value: '16:00' },
            { value: '17:00' },
            { value: '18:00' },
            { value: '19:00' },
            { value: '20:00' },
            { value: '21:00' },
            { value: '22:00' },
            { value: '23:00' },
            { value: '24:00' },
        ],
        loadingUsers: false,
        loadingPositions: false,
        positions: [],
        needGetSize: true,
        canvas: null,
        ctx: null,
        points: [],
        thisLast: false,
        src: '/',
        fullscreen: false,
        lastWidth: 1,
        needSetSize: true
    }),
    watch: {
        fullscreen() {
            this.needSetSize = true;
        }
    },
    mounted() {
        console.log('mounted activeCam', this.activeCam)
        this.imageLoadHandler = this.imageLoadHandler.bind(this);
        this.imageErrorHandler = this.imageErrorHandler.bind(this);
        const hasChild = {};
        this.devs = [];
        this.devTypes = [];
        for (let i = 0; i < window.myDevs.length; i++) {
            const dev = window.myDevs[i];
            if (
                dev.parent_id != null &&
                dev.priority_id != null &&
                this.devsToObject(dev.id, this.activeCam.object_id)
            ) {
                this.devs.push(dev);
                hasChild[dev.parent_id] = dev;
            }
        }
        const par = [];
        for (let i = 0; i < window.myDevs.length; i++) {
            const dev = window.myDevs[i];
            if (hasChild[dev.id]) {
                par.push(this.getRootDev(hasChild[dev.id].id));
            }
        }
        this.devTypes = [...new Set(par)];
        this.src = this.task.src;
        this.selected.dev = (this.devs[0] || {}).id;
        this.selected.step = this.steps[0];
        this.selected.countStep = this.countSteps[0];
        this.newobject = this.getObject(this.activeCam.object_id);
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
        this.selected.startDate = now.toISOString().slice(0,10);
        now.setMinutes(now.getMinutes() + 60);
        this.selected.endDate = now.toISOString().slice(0,10);

        const key = Object.keys(CRON_DAYS).find(day => 
            CRON_DAYS[day] === now.getDay()
        );
        this.resetDays();
        this.setDay(key);

        this.selected.startTime = '08:00';
        this.selected.endTime = '21:00';
        
        let image = this.$refs['image'];
        if (image) {
            image.addEventListener('load', this.imageLoadHandler);
            image.addEventListener('error', this.imageErrorHandler);
        }
        this.canvas = this.$refs['canvas'];
        if (this.canvas) {
            this.ctx = this.canvas.getContext('2d');
        }
        let container = this.$refs['container'];
        if (container) {
            container.addEventListener('click', this.containerMouseClickHandler);
            container.addEventListener('mousemove', this.containerMouseMoveHandler);
        }
        setTimeout( () => {
            let image = this.$refs['image'];
            if (image) {
                image.addEventListener('load', this.imageLoadHandler);
                image.addEventListener('error', this.imageErrorHandler);
            }
            this.canvas = this.$refs['canvas'];
            if (this.canvas) {
                this.ctx = this.canvas.getContext('2d');
                this.ctx.lineWidth = 1;
                this.ctx.strokeStyle="#bb0000";
                this.ctx.fillStyle="#dd6666";
            }
            let container = this.$refs['container'];
            if (container) {
                container.addEventListener('click', this.containerMouseClickHandler);
                container.addEventListener('mousemove', this.containerMouseMoveHandler);
                let boundingRect = container.getBoundingClientRect();
                this.canvas.width = boundingRect.width;
                this.canvas.height = boundingRect.height;
                this.lastWidth = boundingRect.width;
            }
        }, 300)

        this.getWatchers();
        window.$("#modalNeural").on('hidden.bs.modal', this.close);
        if (!this.activeCam.ptz_enabled) {
            return;
        }
        this.getPositions({
            id: this.activeCam.id
        })
    },
    updated() {
        if (this.needSetSize) {
            this.imageLoadHandler();
            this.needSetSize = false;
        }
    },
    beforeDestroy() {
        this.close();
    },
    methods: {
        getObject(obj_id) {
            for (let i=0; i<window.myObjects.length; i++) {
                if (window.myObjects[i].id===obj_id) {
                    return window.myObjects[i];
                }
            }
            return null
        },
        getRootDev(parent_id) {
            for (let i = 0; i < window.myDevs.length; i++) {
                const dev = window.myDevs[i];
                if (dev.id == parent_id) {
                    if (dev.parent_id == null) {
                        return dev;
                    } else {
                        return this.getRootDev(dev.parent_id);
                    }
                }
            }
            return null;
        },
        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
            this.needGetSize = true;
        },
        imageErrorHandler() {
            this.needGetSize = true;
        },
        imageLoadHandler() {
            if (!this.ctx) {
                return;
            }
            if (!this.needGetSize) {
                let image = document.getElementById('dsv-neuralSetting-preview-image');
                image.removeEventListener('load', this.imageLoadHandler);
                image.removeEventListener('error', this.imageErrorHandler);
                return;
            }
            this.needGetSize = false;
            let container = document.getElementById('dsv-neuralSetting-preview-image-container');
            let boundingRect = container.getBoundingClientRect();
            let canvas = document.getElementById('dsv-neuralSetting-canvas');
            canvas.width = boundingRect.width;
            canvas.height = boundingRect.height;
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle="#bb0000";
            this.ctx.fillStyle="#dd6666";
            if (this.lastWidth !== boundingRect.width) {
                const kf = boundingRect.width / this.lastWidth;
                const _points = [];
                for (let i = 0; i < this.points.length; i++) {
                    const point = this.points[i];
                    _points.push({x: point.x * kf, y: point.y * kf});
                }
                this.points = [];
                for (let i = 0; i < _points.length; i++) {
                    const point = _points[i];
                    this.points.push(point);
                }
            }
            this.lastWidth = boundingRect.width;
            this.drawLines();
        },
        devsToObject(dev_id, object_id) {
            for (let i = 0; i < window.myDevsToObjects.length; i++) {
                if (
                    window.myDevsToObjects[i].digression_id == dev_id &&
                    window.myDevsToObjects[i].object_id == object_id
                ) {
                    return true;
                }
            }
            return false;
        },
        setTypes(event) {
            const id = event.target.value;
            this.devs = [];
            if (id == 0) {
                for (let i = 0; i < window.myDevs.length; i++) {
                    const dev = window.myDevs[i];
                    if (
                        dev.parent_id != null &&
                        dev.priority_id != null &&
                        this.devsToObject(dev.id, this.activeCam.object_id)
                    ) {
                        this.devs.push(dev);
                    }
                }
            } else {
                for (let i = 0; i < window.myDevs.length; i++) {
                    const dev = window.myDevs[i];
                    const par = this.getRootDev(dev.parent_id);
                    if (par && dev.priority_id != null) {
                        if (par.id == id) {
                            this.devs.push(dev);
                        }
                    }
                }
            }
            if (this.devs.length) {
                this.selected.dev = this.devs[0].id;
                this.getWatchers();
            } else {
                this.selected.dev = null;
                this.users = [];
                this.selected.user = null;
            }
        },
        setEndDate(event) {
            const value = event.target.value;
            if (value === this.selected.startDate) {
                const date = new Date(value);
                const key = Object.keys(CRON_DAYS).find(day => 
                    CRON_DAYS[day] === date.getDay()
                );
                this.resetDays();
                this.setDay(key);
            }
        },
        resetDays() {
            this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = false;
            this.days['sat'] = this.days['sun'] = false;
        },
        setDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = true;
                this.days['sat'] = this.days['sun'] = false;
            } else if (dayKey === 'all') {
                for (const [key] of Object.entries(this.days)) {
                    this.days[key] = true;
                }
            } else {
                this.days[dayKey] = !this.days[dayKey];
            }
        },
        getPositions(data) {
            this.loadingPositions = true;
            this.positions = [];
            window.myAjax('GET', '/ptz/positions', {
                camera_id: data.id
            }, this.getPositionsCallback)
        },
        getPositionsCallback(error, response) {
            this.loadingPositions = false;
            if (error) {
                // todo
            } else {
                this.positions = (response.constructor || {}).name === 'Array' ? response : [];
                this.selected.position = this.positions[0] ? this.positions[0].id : 0;
            }
        },
        sendNewPosition() {
            if (!this.activeCam.ptz_enabled) {
                return;
            }
            this.sendPositions({
                camera_id: this.activeCam.id,
                position_id: this.selected.position
            });
        },
        sendPositions(data) {
            window.myAjax('POST', '/ptz/positions', {
                camera_id: data.camera_id,
                position_id: data.position_id
            }, this.sendPositionsCallback)
        },
        sendPositionsCallback() {
            // todo
        },
        getWatchers() {
            this.loadingUsers = true;
            window.myAjax('GET', '/api/digression-executors?object_id=' + this.activeCam.object_id + '&digression_id=' + this.selected.dev, {}, this.myCallbackGetWatchers);
        },
        myCallbackGetWatchers(error, response) {
            this.loadingUsers = false;
            if (error) {
                window.myAlert(response.message)
            } else {
                const users = [];
                for (let i=0; i < response.length; i++) {
                    users.push( this.getUser(response[i]) )
                }
                this.sortWatchers(users)
            }
        },
        getUser(id) {
            for (let i = 0; i < window.myUsers.length; i++){
                if (id == window.myUsers[i].id) {
                    return window.myUsers[i];
                }
            }
            return null;
        },
        sortWatchers(users) {
            let needUpdate = true;
            const userObject = window.myUsers.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item }), {}
            );
            this.users = [];
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                if (userObject[user.id]) {
                    if (this.selected.user == user.id) {
                        needUpdate = false;
                    }
                    this.users.push(userObject[user.id]);
                }
            }
            if (needUpdate) {
                this.selected.user = (this.users[0] || {}).id;
            }
        },
        clearInterval() {
            clearInterval(this.intervalId);
            this.intervalId = null;
        },
        updateImage() {
            this.src = '/preview/img?camera_id=' + this.activeCam.id + "&time=" + new Date();
        },
        containerMouseClickHandler(event) {
            if (this.showResult || (!this.showMessage && !this.editMode)) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            this.changeShowMessage(false);
            this.changeEditMode(true);
            if (this.thisLast && this.points.length >= 2) {
                const startPoint = this.points[0];
                this.points.push(startPoint);
                this.changeEditMode(false);
                this.drawLines();
                return;
            }
            let clientRect = document.getElementById('dsv-neuralSetting-canvas').getBoundingClientRect();
            let x = event.clientX - clientRect.x;
            let y = event.clientY - clientRect.y;
            if (this.points.length) {
                const lastPoint = this.points[this.points.length - 1];
                if (lastPoint.x === x && lastPoint.y === y) {
                    return;
                }
            }
            if (x < POINT_BINDING_LENGHT) {
                x = 0;
            } else if (x > (this.canvas.width - POINT_BINDING_LENGHT)) {
                x = this.canvas.width;
            }
            if (y < POINT_BINDING_LENGHT) {
                y = 0;
            } else if (y > (this.canvas.height - POINT_BINDING_LENGHT)) {
                y = this.canvas.height;
            }
            this.points.push({x: x, y: y});
            if (this.points.length >= 6) {
                const startPoint = this.points[0];
                this.points.push(startPoint);
                this.changeEditMode(false);
                this.drawLines();
                return;
            }
            this.drawLines();
        },
        drawLines(current) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            if (this.points.length) {
                for (let i = 0; i < this.points.length - 1; i++) {
                    const point1 = this.points[i];
                    const point2 = this.points[i + 1];
                    this.ctx.beginPath();
                    this.ctx.moveTo(point1.x, point1.y);
                    this.ctx.lineTo(point2.x, point2.y);
                    this.ctx.stroke();
                }
                if (current) {
                    let lastPoint = this.points[this.points.length - 1];
                    this.ctx.beginPath();
                    this.ctx.moveTo(lastPoint.x, lastPoint.y);
                    this.ctx.lineTo(current.x, current.y);
                    this.ctx.stroke();
                }
            }
            for (let i = 0; i < this.points.length; i++) {
                const point = this.points[i];
                this.ctx.beginPath();
                this.ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
                this.ctx.stroke();
                this.ctx.fill();
            }
            if (current) {                
                this.ctx.beginPath();
                this.ctx.arc(current.x, current.y, 2, 0, 2 * Math.PI);
                this.ctx.stroke();
                this.ctx.fill();
            }
            if (!this.editMode && this.points.length > 2) {
                const lastFillStyle = this.ctx.fillStyle;
                this.ctx.fillStyle = "#bb000022";
                this.ctx.beginPath();
                this.ctx.moveTo(this.points[0].x, this.points[0].y);
                for (let i = 1; i < this.points.length; i++) {
                    const point = this.points[i];
                    this.ctx.lineTo(point.x, point.y);
                }
                this.ctx.closePath();
                this.ctx.fill();
                this.ctx.fillStyle = lastFillStyle;
            }
        },
        containerMouseMoveHandler(event) {
            if (!this.editMode || this.showResult) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            let clientRect = document.getElementById('dsv-neuralSetting-canvas').getBoundingClientRect();
            let x = event.clientX - clientRect.x;
            let y = event.clientY - clientRect.y;
            let lastPoint = {x: x, y: y};
            const startPoint = this.points[0];
            this.thisLast = false;
            if (this.points.length >= 2) {
                if (
                    Math.abs(x - startPoint.x) < POINT_BINDING_LENGHT &&
                    Math.abs(y - startPoint.y) < POINT_BINDING_LENGHT
                ) {
                    this.thisLast = true;
                    lastPoint = startPoint;
                }
            }
            if (x < POINT_BINDING_LENGHT) {
                lastPoint.x = 0;
            } else if (x > (this.canvas.width - POINT_BINDING_LENGHT)) {
                lastPoint.x = this.canvas.width;
            }
            if (y < POINT_BINDING_LENGHT) {
                lastPoint.y = 0;
            } else if (y > (this.canvas.height - POINT_BINDING_LENGHT)) {
                lastPoint.y = this.canvas.height;
            }
            this.drawLines(lastPoint);
        },
        getFio(profile) {
            return (profile.last_name ? profile.last_name + ' ' : '') + 
                (profile.first_name ? profile.first_name + ' ' : '') +
                (profile.middle_name ? profile.middle_name + '' : '');
        },
        close() {
            this.clearInterval();
            let container = document.getElementById('dsv-neuralSetting-preview-image-container');
            if (container) {
                container.removeEventListener('click', this.containerMouseClickHandler);
                container.removeEventListener('mousemove', this.containerMouseMoveHandler);
            }
            window.$("#modalNeural").off('hidden.bs.modal', this.close);
            this.$emit('close');
        },
        changeShowResult(value) {
            this.border = {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                startX: 0,
                startY: 0,
                width: 0,
                height: 0,
                realyStartX: 0,
                realyStartY: 0
            };
            this.resetDraw(true);
            this.showBorder = false;
            this.showResult = value;
            if (!this.showResult) {
                this.checkResult = {
                    status: null,
                    caption: ''
                }
            }
            this.changeShowMessage(!value);
        },
        resetDraw() {
            this.points = [];
            this.thisLast = false;
            this.showMessage = true;
            this.drawLines();
        },
        changeShowMessage(value) {
            this.showMessage = value;
            this.changeEditMode(!value);
        },
        changeEditMode(value) {
            this.editMode = value;
        },
        convertDateToUTC(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },
        getFormat(date) {
            return this.getFormatValue(date.getFullYear()) + "-"
                + this.getFormatValue((date.getMonth()+1))  + "-"
                + this.getFormatValue(date.getDate()) + " "
                + this.getFormatValue(date.getHours()) + ":"
                + this.getFormatValue(date.getMinutes()) + ":"
                + this.getFormatValue(date.getSeconds());
        },
        getFormatValue(timeValue) {
            const str = timeValue.toString();
            return str.length === 1 ? '0' + str : str;
        },
        getSchedule() {
            const step = this.selected.step;
            let result = '';
            //минуты
            if (step.minutes) {
                result += '*' + step.value + ' ';
            } else {
                result += '0 ';
            }
            //часы
            const hours = [];
            const startTime = parseInt(this.selected.startTime.slice(0, 2));
            const startEnd = parseInt(this.selected.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(this.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.join(',');
            } else {
                result += '* ';
            }
            //секунды
            if (step.seconds) {
                result += ' *' + step.value;
            }
            return result;
        },
        saveZone() {
            if (this.points.length === 0) {
                this.points.push({x: 0, y: 0});
                this.points.push({x: 0, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: 0});
                this.points.push({x: this.canvas.width, y: 0});
            } else if (this.points.length < 4) {
                window.myAlert('Измените область.');
                return;
            }
            let image = document.getElementById('dsv-neuralSetting-preview-image');
            let ratio = image.naturalWidth / image.clientWidth;
            let zone = []
            for (let i = 0; i < this.points.length - 1; i++) {
                const point = this.points[i];
                zone.push({x: point.x * ratio, y: point.y * ratio});
            }
            const data = {
                task_id: this.task.id,
                zone: zone
            }
            window.myAjaxJson('PUT', '/ml/' + this.task.task_type + '/resize_zone', data, this.saveZoneCallback)
        },
        saveZoneCallback(error, response) {
            if (error) {
                window.myAlert(response.message || 'Произошла ошибка, попробуйте позже.');
            } else {
                this.close();
            }
        },
        startChecking() {
            if (this.loading) {
                return;
            }
            if (this.points.length === 0) {
                this.points.push({x: 0, y: 0});
                this.points.push({x: 0, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: 0});
                this.points.push({x: this.canvas.width, y: 0});
            } else if (this.points.length < 4) {
                window.myAlert('Измените область.');
                return;
            }
            this.clearInterval();
            let image = document.getElementById('dsv-neuralSetting-preview-image');
            let ratio = image.naturalWidth / image.clientWidth;
            let zone = []
            for (let i = 0; i < this.points.length - 1; i++) {
                const point = this.points[i];
                zone.push({x: point.x * ratio, y: point.y * ratio});
            }
            let data = {
                camera_id: this.activeCam.id,
                threshold: this.selected.count,
                zone: zone
            };
            if (this.selected.position >= 1) {
                Object.assign(data, {
                    position_id: this.selected.position
                });
            }
            this.loading = true;
            window.myAjaxJson('POST', '/ml/' + this.type + '/test_from_cam', data, this.checkingCallback)
        },
        checkingCallback(error, response) {
            this.loading = false;
            if (error) {
                window.myAlert('Произошла ошибка, попробуйте позже.');
            } else {
                this.showBorder = true;
                this.showResult = true;
                window.myAjaxJson('GET', response.image_link, {}, this.imageCallback)
                const caption = this.type === 'license_plate_detection' ?
                    (response.is_detect ?
                        ('Обнаружены номера: ' + response.plates.map(plate => plate.value || '[нераспознанный]').join(', ')) :
                        'Не обнаружено номеров'
                    ) :
                    (response.is_detect ? 'Обнаружено отклонение' : 'Не обнаружено отклонение')
                this.checkResult = {
                    show: true,
                    status: response.is_detect,
                    caption: caption
                }
            }
        },
        imageCallback(error, response) {
            if (error) {
                window.myAlert('Произошла ошибка, попробуйте позже.');
            } else {
                this.src = response;
            }
        },
        toggleRenewal() {
            this.selected.renewal = !this.selected.renewal;
            if (this.selected.renewal) {
                if (this.selected.endDate === this.selected.startDate) {
                    this.setDay('all');
                }
            }
        },
        toggleExtraCount() {
            this.selected.double_check = !this.selected.double_check;
        }
    }
}
