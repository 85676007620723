import Pagination from '../../../components/Pagination';
import ListColumn from './../../base/listColumn';
import BarcodeCard from './../barcodeCard';

export default {
    name: 'PricesBarcodeList',
    components: {
        Pagination,
        ListColumn,
        BarcodeCard
    },
    props: {
        selectedObj: {
            type: Object,
            default: null
        },
        mode: {
            type: String,
            default: null
        }
    },
    data: () => ({
        showFilters: false,
        results: [],
        selected: null,
        showCard: false,
        filter: {
            page: 0,
            limit: 10,
            objectId: '0',
            dateStart: null,
            dateEnd: null,
            verification_required_price: '0'
        },
        isMobile: false,
        screenshotResult: {},
        objectsFilter: [],
        pages: [],
        showInputs: false
    }),
    watch: {
        selectedObj: {
            handler() {
                // todo
            }
        }
    },
    mounted() {
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        var today = new Date();
        this.filter.dateEnd = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        today.setDate(today.getDate() - (window.journalFromNotify ? 7 : 1));
        this.filter.dateStart = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        if (window.journalFromNotify) {
            this.filter.verification_required_price = '2';
        }
        this.getObjects();
        this.getResultsList();
        window.journalFromNotify = false;
    },
    methods: {
        resetFilter() {
            const obj = {
                page: 0,
                limit: 10,
                objectId: 0,
                verification_required_price: '0'
            }
            var today = new Date();
            obj.dateEnd = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            today.setDate(today.getDate() - 1);
            obj.dateStart = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            this.filter = Object.assign({}, obj)
        },
        applyFilter() {
            this.getResultsList();
        },
        getPageHandler(data) {
            this.filter = Object.assign({}, this.filter, {
                page: data
            })
            this.getResultsList();
        },
        
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        rublesChanged() {
            if (this.selected.manual_price_integer) {
                if (!this.selected.manual_price_fraction) {
                    this.selected.manual_price_fraction = '00';
                }
            }
        },
        openScreenshot(result) {
            if (!(result.photo_path || {}).length) {
                return;
            }
            this.screenshotResult = result;
            window.$("#modalViewScreenShot2").modal('show')
        },
        closeScreenshot() {
            this.screenshotResult = {};
            window.$("#modalViewScreenShot2").modal('hide')
        },
        openCard(result) {
            this.selected = Object.assign({}, result);
            this.showCard = true;
        },
        closeCard() {
            this.selected = null;
            this.showCard = false;
            this.getResultsList();
        },
        getObjects() {
            window.myAjax('GET', '/price-tag/monitoring_objects', {}, (error, response) => {
                if (!error) {
                    this.objectsFilter = response;
                }
            });
        },
        getResultsList() {
            let filter = 'page=' + this.filter.page + '&limit=' + this.filter.limit;
            filter += '&start_date=' + this.filter.dateStart;
            filter += '&end_date=' + this.filter.dateEnd;
            if (this.filter.objectId !== '0' && this.filter.objectId !== 0) {
                filter += '&monitoring_object_id=' + this.filter.objectId;
            }
            if (this.filter.verification_required_price != '0') {
                filter += '&verification_required=' + (this.filter.verification_required_price == '2' ? 'true' : 'false');
            }
            window.myAjax('GET', '/price-tag/barcode/results?' + filter, {}, this.getResultsListHandler);
        },
        getResultsListHandler(error, response) {
            if (!error) {
                this.results = [];
                const now = new Date();
                const diff = now.getTimezoneOffset() / -60;
                response.forEach( result => {
                    const created = new Date(result.created_at);
                    created.setHours(created.getHours() + diff);
                    let _result = Object.assign({}, result, {
                        created_at: created.toLocaleString()
                    })
                    this.results.push(_result);
                })
                this.pages = [];
                if (this.results[0]) {
                    const total = this.results[0].total;
                    let all_pages = 2 + parseInt(total / this.filter.limit);
                    if (total % this.filter.limit == 0) {
                        all_pages--;
                    }
                    for (let i = 1; i < all_pages; i++) {
                        this.pages.push(i);
                    }
                }
            }
        },
        getMetaItems(result) {
            const items = [];
            items.push({
                    caption: result.created_at
                }, {
                    label: "№",
                    caption: result.id
                }, {
                    label: "Объект",
                    caption: result.monitoring_object_name
            });
            return items;
        },
        getResultsItems(result) {
            const items = [];
            items.push({
                label: "Продукт",
                caption: result.product_name
            });
            items.push({
                label: "QR-код/штрих-код",
                caption: result.barcode ? result.barcode : ''
            });
            if (Number.isInteger(result.manual_price_integer)) {
                items.push({
                    label: "Внесенная цена",
                    caption: this.getFormatedPrice(result.manual_price_integer, result.manual_price_fraction),
                    content: result.is_promotion ? '<span class="dsv-prices__journalList_promotion">Акция</span>' : ''
                });
            } else if (Number.isInteger(result.recognized_price_integer)) {
                items.push({
                    label: "Распознанная цена",
                    caption: this.getFormatedPrice(result.recognized_price_integer, result.recognized_price_fraction),
                    content: result.is_promotion ? '<span class="dsv-prices__journalList_promotion">Акция</span>' : ''
                });
            }
            if (Number.isInteger(result.manual_before_promotion_integer)) {
                items.push({
                    label: "Внесенная цена до акции",
                    caption: this.getFormatedPrice(result.manual_before_promotion_integer, result.manual_before_promotion_fraction),
                    captionStyle: { fontWeight: 400 }
                });
            } else if (result.before_promotion_integer) {
                items.push({
                    label: "Цена до акции",
                    caption: this.getFormatedPrice(result.before_promotion_integer, result.before_promotion_fraction),
                    captionStyle: { fontWeight: 400 }
                });
            }
            if (Number.isInteger(result.manual_additional_integer)) {
                items.push({
                    label: "Внесенная цена без карты",
                    caption: this.getFormatedPrice(result.manual_additional_integer, result.manual_additional_fraction),
                    captionStyle: { fontWeight: 400 }
                });
            } else if (result.additional_price_integer) {
                items.push({
                    label: "Цена до акции",
                    caption: this.getFormatedPrice(result.additional_price_integer, result.additional_price_fraction),
                    captionStyle: { fontWeight: 400 }
                });
            }
            return items;
        },
        getFormatedPrice(priceInteger, priceFraction) {
            if (
                (priceInteger === undefined && priceFraction === undefined) ||
                (priceInteger === null && priceFraction === null)
            ) {
                return;
            }
            let integer = priceInteger || 0;
            let fraction = priceFraction || 0;
            const priceIntegerStr = integer.toString();
            const priceFractionStr = fraction.toString();
            return `${ priceIntegerStr }.${ priceFractionStr.length === 1 ? '0' + priceFractionStr : priceFractionStr }`;
        }
    }
}
