import CheckboxNew from './../../base/checkboxNew';
import ModalNew from './../../base/modalNew';
import CategoryTree from './../categoryTree';

export default {
    name: 'PricesBarcodeCard',
    components: {
        CheckboxNew,
        ModalNew,
        CategoryTree,
    },
    props: {
        selected: {
            type: Object,
            default: {}
        },
        // objects: {
        //     type: Object,
        //     default: {}
        // },
        // products: {
        //     type: Object,
        //     default: {}
        // }
    },
    data: () => ({
        result: {},
        filter: '',
        showFilters: false,
        results: [],
        _products: {},
        selectedProduct: {
            title: null,
            article: null,
            barcode_ids: [],
            category_id: null,
            measure_unit_id: null,
            measure_value: null,
        },
        filteredProducts: [],
        // objects: {},
        selected: null,
        showCard: false,
        isMobile: false,
        screenshotResult: {},
        objectsFilter: [],
        pages: [],
        showInputs: false,

        categories: [],
        brands: [],

        showProductModal: false,

        units: {},

        selectedCategory: null,

        barcodes: {},
        page: 0,
    }),
    watch: {
        products: {
            handler() {
                console.log('products', this.products);
            }
        },
        filter: {
            handler() {
                this.page = 0;
                this.getProducts();
                // this.filterProduct();
            }
        },
        selectedProduct: {
            deep: true,
            handler() {
                this.filterProduct();
            }
        },
        selectedCategory: {
            handler() {
                this.filterProduct();
            }
        },
        selected: {
            handler() {
                this.result = this.selected;
            }
        },
        showProductModal: {
            handler() {
                if (this.showProductModal) {
                    this.getUnits();
                    // this.getBarcodes();
                }
            }
        }
    },
    mounted() {
        this.result = this.selected;
        window.journalFromNotify = false;
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        // this.filterProduct();
    },
    methods: {
        openProductModal() {
            this.showProductModal = true;
            this.getProducts();
        },
        filterProduct() {
            this.page = 0;
            // const _newProd = [];
            // this.filteredProducts = [];
            // for (let key in this.products) {
            //     const product = this.products[key];
            //     if (
            //         ((this.filter || '').length === 0 || (product.title || '').toLowerCase().indexOf(this.filter.trim().toLowerCase()) >= 0) &&
            //         (!(this.selectedCategory || {}).id || product.category_id == (this.selectedCategory || {}).id)
            //     ) {
            //         _newProd.push(Object.assign({}, product));
            //     }
            // }
            this.getBrands();
            this.filteredProducts = [];
            this.filteredProducts = this.products;
            // this.filteredProducts = _newProd.sort( (a, b) => a.title > b.title ? 1 : -1);
        },
        getBrands() {
            if (!(this.selectedCategory || {}).id) {
                return;
            }
            this.categories = this.$refs['CategoryTree'].getCategoriesTree() || [];
            const rootCategoryId = (this.findRootCategory((this.selectedCategory || {}).id) || {}).id;
            if (rootCategoryId) {
                window.myAjax('GET', '/price-tag/brands', {
                    limit: 0,
                    category_ids: JSON.stringify([rootCategoryId])
                }, (error, response) => {
                    if (!error) {
                        this.brands = (response || []);
                    }
                });
            }
        },
        findRootCategory(categoryId) {
            const categories = this.categories;
            for ( let i = 0; i < categories.length; i++ ) {
                const category = categories[i];
                if (category.id === categoryId) {
                    return category;
                }
                if (this.findRootFromChildren(categoryId, category.children)) {
                    return category;
                }
            }
            return null;
        },
        findRootFromChildren(categoryId, categories) {
            for ( let i = 0; i < categories.length; i++ ) {
                const category = categories[i];
                if (category.id === categoryId) {
                    return true;
                }
                if (this.findRootFromChildren(categoryId, category.children)) {
                    return true;
                }
            }
            return false;
        },
        getProducts() {
            window.myAjax('GET', '/price-tag/products', {
                page: this.page,
                limit: 25,
                category_ids: this.selectedCategory ? JSON.stringify([(this.selectedCategory || {}).id]) : JSON.stringify([]),
                search_title: this.filter,
            }, (error, response) => {
                if (!error) {
                    if (this.page === 0) {
                        this.products = response;
                    } else {
                        this.products = this.products.concat(response);
                    }
                    this.page += 1;
                    this.filteredProducts = this.products;
                    // this.filterProduct();
                }
            });
        },
        saveResultReq() {
            this.result.verification_required_price = false;
        },
        imageChecked() {
            this.result.image_low_quality = true;
            this.result.verification_required_price = false;
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        rublesChanged() {
            if (this.result.manual_price_integer) {
                if (!this.result.manual_price_fraction) {
                    this.result.manual_price_fraction = '00';
                }
            }
        },
        promRublesChanged() {
            if (this.result.manual_before_promotion_integer) {
                if (!this.result.manual_before_promotion_fraction) {
                    this.result.manual_before_promotion_fraction = '00';
                }
            }
        },
        addRublesChanged() {
            if (this.result.manual_additional_integer) {
                if (!this.result.manual_additional_fraction) {
                    this.result.manual_additional_fraction = '00';
                }
            }
        },
        closeCard() {
            this.$emit('close');
        },
        saveResult() {
            if (this.result.manual_price_integer || this.result.manual_price_fraction) {
                if (this.result.manual_price_integer === null) {
                    this.result.manual_price_integer = 0;
                }
                if (this.result.manual_price_fraction === null) {
                    this.result.manual_price_fraction = 0;
                }
                this.result.verification_required_price = false;
            }
            if (this.result.manual_before_promotion_fraction || this.result.manual_before_promotion_integer) {
                if (this.result.manual_before_promotion_fraction === null) {
                    this.result.manual_before_promotion_fraction = 0;
                }
                if (this.result.manual_before_promotion_integer === null) {
                    this.result.manual_before_promotion_integer = 0;
                }
                this.result.verification_required_price = false;
            }
            if (this.result.manual_additional_fraction || this.result.manual_additional_integer) {
                if (this.result.manual_additional_fraction === null) {
                    this.result.manual_additional_fraction = 0;
                }
                if (this.result.manual_additional_integer === null) {
                    this.result.manual_additional_integer = 0;
                }
                this.result.verification_required_price = false;
            }
            window.myAjax('PUT', '/price-tag/barcode/result', Object.assign({}, this.result, {result_id: this.result.id}), this.saveResultHandler);
        },
        saveResultHandler(error, response) {
            if (error) {
                window.myAlert((response || {}).message || 'Ошибка при сохранении.');
            } else {
                this.closeCard();
                window.notifyFromJournal = true;
                this.$emit('updateNotifier');
            }
        },
        getFormatedPrice(priceInteger, priceFraction) {
            if (
                (priceInteger === undefined && priceFraction === undefined) ||
                (priceInteger === null && priceFraction === null)
            ) {
                return;
            }
            let integer = priceInteger || 0;
            let fraction = priceFraction || 0;
            const priceIntegerStr = integer.toString();
            const priceFractionStr = fraction.toString();
            return `${ priceIntegerStr }.${ priceFractionStr.length === 1 ? '0' + priceFractionStr : priceFractionStr }`;
        },
        getUnits() {
            window.myAjax('GET', '/price-tag/measure_units', {}, (error, response) => {
                if (!error) {
                    this.units = {};
                    response.forEach( (unit) => {
                        if (!this.units[unit.id]) {
                            this.units[unit.id] = unit;
                        }
                    });
                }
            });
        },
        getBarcodes() {
            window.myAjax('GET', '/price-tag/barcodes', {}, (error, response) => {
                if (!error) {
                    this.barcodes = {};
                    response.forEach( (barcode) => {
                        if (!this.barcodes[barcode.id]) {
                            this.barcodes[barcode.id] = barcode;
                        }
                    });
                }
            });
        },
        selectedCategoryHandler(data) {
            this.brands = [];
            if (((data || {}).category || {}).id === (this.selectedCategory || {}).id) {
                return;
            }
            this.page = 0;
            this.selectedCategory = data.category;
            this.getProducts();
        },
        saveProduct() {
            if (this.selectedProduct.id) {
                const barcodeToSave = this.result.manual_barcode || this.result.barcode;
                if (barcodeToSave) {
                    window.myAjax('POST', '/price-tag/barcode', { value: barcodeToSave }, (error, response) => {
                        if (!error) {
                            this.result.barcode_id = response.id;
                            const productData = Object.assign({}, this.selectedProduct);
                            productData.barcode_ids = (this.selectedProduct.barcodes || []).map( barcode => barcode[0]);
                            productData.barcode_ids.push(response.id);
                            productData.barcode_ids = JSON.stringify(productData.barcode_ids);
                            window.myAjax('PUT', '/price-tag/product', Object.assign({}, productData, {
                                product_id: this.selectedProduct.id
                            }), (err2) => {
                                if (!err2) {
                                    this.result.product_id = this.selectedProduct.id;
                                    this.result.product_name = this.selectedProduct.title;
                                    this.result.product_article = this.selectedProduct.article;
                                    this.result.manual_barcode = barcodeToSave;
                                    this.showProductModal = false;
                                }
                            })
                        }
                    });
                } else if ((((this.selectedProduct || {}).barcodes || [])[0] || [])[1]) {
                    this.result.product_id = this.selectedProduct.id;
                    this.result.product_name = this.selectedProduct.title;
                    this.result.product_article = this.selectedProduct.article;
                    this.result.manual_barcode = this.selectedProduct.barcodes[0][1];
                    this.showProductModal = false;
                } else {
                    window.myAlert("Не введен штрих-код");
                    return;
                }
                
            } else {
                const barcodeToSave = this.result.manual_barcode || this.result.barcode;
                if (!(this.selectedCategory || {}).id) {
                    window.myAlert("Не выбрана категория");
                    return;
                }
                if (barcodeToSave) {
                    window.myAjax('POST', '/price-tag/barcode', { value: barcodeToSave }, (error, response) => {
                        if (!error) {
                            this.selectedProduct.barcode_ids = [response.id];
                            this.selectedProduct.category_id = this.selectedCategory.id;
                            this.result.barcode_id = response.id;
                            const productData = Object.assign({}, this.selectedProduct);
                            productData.barcode_ids = JSON.stringify(productData.barcode_ids);
                            window.myAjax('POST', '/price-tag/product', productData, (err2, resp2) => {
                                if (!err2) {
                                    this.result.product_id = resp2.id;
                                    this.result.product_name = resp2.title;
                                    this.result.product_article = resp2.article;
                                    this.$emit('updateProducts', resp2);
                                    this.result.verification_required_price = false;
                                    this.showProductModal = false;
                                }
                            })
                        }
                    });
                } else {
                    window.myAlert("Не введен штрих-код");
                    return;
                }
                this.showProductModal = false;
            }
        },
        selectProduct(product) {
            if (product) {
                this.selectedProduct = Object.assign({}, product);
            } else {
                this.selectedProduct = {
                    title: null,
                    article: null,
                    barcodes: [],
                    category_id: null,
                    measure_unit_id: null,
                    measure_value: null,
                };
            }
        },
        remove() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить результат?', () => {
                window.myAjax('DELETE', '/price-tag/barcode/result', { result_id: this.result.id }, () => {
                    this.closeCard();
                })
            });
        },
    }
}
