export function DrawPointTool(canvas, context, scale) {
    this.canvas = canvas;
    this.context = context;
    this.context.lineWidth = 30;
    this.context.strokeStyle="rgb(255,1,0)";
    this.context.fillStyle="rgb(255,1,0)";
    this.scale = scale;
    this.isMouseDown = false;

    this.onmousedown = function() {
        return true;
    }

    this.ontouchstart = function() {
        onmousedown();
    }
    
    this.getX = function(event) {
        return (
            event.offsetX ||
            event.touches[0].offsetX
        ) / this.scale;
    }
  
    this.getY = function(event) {
        return ((
            event.offsetY ||
            event.touches[0].offsetY
        ) / this.scale);
    }

    this.onmousemove = function() {
        return true;
    }

    this.ontouchmove = function() {
        onmousemove();
    }

    this.onmouseup = function(event) {
        this.context.beginPath();
        this.context.arc(this.getX(event), this.getY(event), 8, 0, 2 * Math.PI);
        this.context.fill();
    }

    this.ontouchend = function() {
        onmouseup();
    }
}