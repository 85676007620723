import MonitoringReports from './../monitoring';
import BarcodesMonitoring from './../barcodesMonitoring';
import DigressionsReports from './../digressions';
import TaskList from './../digressionTaskList';
import ChecklistReport from './../checklistReport';
import ChecklistTaskList from './../checklistTaskList';
import SendingTemplatesSettings from './../templatesSettings';
import CamerasReports from './../camerasReports';
import CamerasTaskList from './../camerasTaskList';
import ModulesReports from './../modulesReports';

export default {
    name: 'AnalyticReports',
    components: {
        MonitoringReports,
        BarcodesMonitoring,
        DigressionsReports,
        ChecklistReport,
        TaskList,
        ChecklistTaskList,
        SendingTemplatesSettings,
        CamerasReports,
        CamerasTaskList,
        ModulesReports,
    },
    data: () => ({
        tab: 'reports',
        mainTab: 'digressions',
        excelEnabled: false
    }),
    mounted() {
        this.excelEnabled = (window.direct_permissions || []).findIndex( (permission) => permission.name === 'download analytic report') >= 0;
        window.resizeScrollingContent();
    },
    methods: {
        tabClickHandler(tab) {
            this.tab = tab;
        },
    }
}