import FilesButton from './../button';
import FilesList from './../list';

/**
 * @name FilesDropArea
 * @description
 * Компонент содержащий в себе кнопку прикрепления файлов и список уже прикрепленных файлов.
 */
 export default {
    name: 'FilesDropArea',
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        files: {
            type: Array,
            default: []
        },
        canDeleted: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        },
        loaded: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FilesButton,
        FilesList
    },
    data: () => ({
        isLoading: false,
        loadedCounter: 0,
        dropCounter: 0,
        dropBoxCounter: 0,
        dropbox: null,
        isDragged: false,
        isDragOver: false,
        resultData: []
    }),
    mounted() {
        this.dropbox = document.getElementById("dsv-filesDropArea-dropbox");
        this.boxDragEnterHandler = this.boxDragEnterHandler.bind(this);
        this.boxDragLeaveHandler = this.boxDragLeaveHandler.bind(this);
        this.dropHandler = this.dropHandler.bind(this);
        this.dragEnterHandler = this.dragEnterHandler.bind(this);
        this.dragOverHandler = this.dragOverHandler.bind(this);
        this.dragLeaveHandler = this.dragLeaveHandler.bind(this);

        this.handleFiles = this.handleFiles.bind(this);

        this.dropbox.addEventListener("dragenter", this.boxDragEnterHandler, false);
        this.dropbox.addEventListener("dragleave", this.boxDragLeaveHandler, false);
        this.dropbox.addEventListener("drop", this.dropHandler, false);

        document.addEventListener('dragenter', this.dragEnterHandler, false);
        document.addEventListener("dragover", this.dragOverHandler, false);
        document.addEventListener("dragleave", this.dragLeaveHandler, false);
        document.addEventListener("drop", this.dragOverHandler, false);
    },
    beforeDestroy() {
        this.dropbox.removeEventListener("dragenter", this.boxDragEnterHandler);
        this.dropbox.removeEventListener("dragleave", this.boxDragLeaveHandler);
        this.dropbox.removeEventListener("drop", this.dropHandler);

        document.removeEventListener('dragenter', this.dragEnterHandler);
        document.removeEventListener('dragover', this.dragOverHandler);
        document.removeEventListener('dragleave', this.dragLeaveHandler);
        document.removeEventListener("drop", this.dragOverHandler, false);
    },
    methods: {
        boxDragEnterHandler(event) {
            this.dropBoxCounter++;
            event.stopPropagation();
            event.preventDefault();
            const dataTransfer = event.dataTransfer;
            const item = dataTransfer.files[0] || dataTransfer.items[0] || {};
            if (item.kind === 'file') {
                this.boxTarget = event.target;
                this.isDragOver = true;
            }
        },

        boxDragLeaveHandler(event) {
            this.dropBoxCounter--;
            if (this.dropBoxCounter === 0) {
                event.preventDefault();
                event.stopPropagation();
                this.isDragOver = false;
            }
        },

        dropHandler(event) {
            event.stopPropagation();
            event.preventDefault();
            var dataTransfer = event.dataTransfer;
            var files = dataTransfer.files.length ? dataTransfer.files : dataTransfer.items;
            this.handleFiles(files);
            this.dropBoxCounter = 0;
            this.dropCounter = 0;
        },

        dragEnterHandler(event) {
            this.dropCounter++;
            const dataTransfer = event.dataTransfer;
            const item = dataTransfer.files[0] || dataTransfer.items[0] || {};
            if (item.kind === 'file') {
                event.preventDefault();
                event.stopPropagation();
                this.isDragged = true;
            } else {
                this.isDragged = false;
            }
        },

        dragOverHandler(event) {
            event.preventDefault();
        },
        
        dragLeaveHandler(event) {
            this.dropCounter--;
            if (this.dropCounter === 0) {
                event.preventDefault();
                event.stopPropagation();
                this.isDragged = false;
            }
        },

        handleFiles(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.indexOf('image') !== 0){ continue }
                this.loadedCounter++;
            }
            if (this.handleFiles === 0) {
                return;
            }
            this.isLoading = true;
            const self = this;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.indexOf('image') !== 0){ continue }
                const img = document.createElement("img");
                img.classList.add("obj");
                img.file = file;
                // this.dropbox.appendChild(img);
                const reader = new FileReader();
                reader.onload = (function(aImg) {
                    return function(e) {
                        self.loadedCounter--;
                        aImg.src = e.target.result;
                        aImg.onload = function() {
                            self.resultData.push( {result: self.getBase64Image(this), file: file});
                            if (self.loadedCounter === 0) {
                                self.isLoading = false;
                                self.isDragged = false;
                                self.isDragOver = false;
                                self.sendFilesData();
                            }
                        };
                    };
                })(img);
                reader.readAsDataURL(file);
            }
        },

        getBase64Image(img) {
            // Create an empty canvas element
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
        
            // Copy the image contents to the canvas
            var ctx = canvas.getContext("2d");
            ctx.fillRect(0, 0, img.width, img.height);
            ctx.drawImage(img, 0, 0);
        
            // Get the data-URL formatted image
            // Firefox supports PNG and JPEG. You could check img.src to
            // guess the original format, but be aware the using "image/jpg"
            // will re-encode the image.
            return canvas.toDataURL("image/jpeg");
        
            // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        },

        sendFilesData() {
            this.$emit('changed', this.resultData);
            this.resultData = [];
        },

        fileSelectedHandler(file) {
            this.$emit('fileSelected', file);
        },

        fileDeletedHandler(file) {
            this.$emit('fileDeleted', file);
        }
    }
}
