export default {
    // админ
    9: {
        ids: ['perm1_1', 'perm1_2', 'perm3_1', 'perm3_2', 'perm5_2', 'perm5_1', 'perm2_1', 'perm2_2',  'perm4_3', 'perm6_1', 'perm7_1']
    },
    // Руководитель супервайзеров
    26: {
        ids: ['perm5_1', 'perm2_1', 'perm1_2', 'perm4_3', 'perm6_1']
    },
    // Супервайзер
    10: {
        ids: ['perm5_1', 'perm5_2', 'perm3_1', 'perm1_2', 'perm2_2']
    },
    // Исполнитель
    11: {
        ids: ['perm1_2']
    }
}
