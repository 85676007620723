import UserActivityReports from './../userActivityReports';
import MediaSpaceList from './../mediaSpaceList';
import LogsList from './../logsList';

export default {
    name: 'Reports',
    components: {
        UserActivityReports,
        MediaSpaceList,
        LogsList
    },
    data: () => ({
        canShowMediaSpace: false,
        tab: 'logs'
    }),
    mounted() {
        this.canShowMediaSpace = true;
    },
    methods: {
        tabClickHandler(tab) {
            this.tab = tab;
        }
    }
}
