export default {
    name: 'CheckboxNew',
    props: {
        label: String,
        checked: {
            type: Boolean,
        },
        interim: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'left'
        }
    },
    methods: {
        clickHandler() {
            this.$emit('update:checked', !this.checked);
        }
    }
}
