<style scoped>
    .control1 {
        background: url('../../assets/img/Group 198.svg');
    }
    .control2 {
        background: url('../../assets/img/Group 196.svg');
    }
    .control3 {
        background: url('../../assets/img/Group 197.svg');
    }
    .control4 {
        background: url('../../assets/img/Group 195.svg');
    }
    .control5 {
        background: url('../../assets/img/cams_control5.svg');
    }
    .control6 {
        background: url('../../assets/img/Subtract 2.svg');
    }
    .control62 {
        background: url('../../assets/img/Subtract.svg');
    }
    .control7 {
        background: url('../../assets/img/Group 2011.svg');
    }
    .control8 {
        background: url('../../assets/img/substream.svg');
        background-size: 28px;
    }
    .controlback {
        background-image: url('../../assets/img/_archiveBack.svg');
        background-size: 22px;
    }
    .control10 {
        background: url('../../assets/img/cams_control10.svg');
    }
    .control11 {
        background: url('../../assets/img/cams_control11.svg');
    }
    .control12 {
        background: url('../../assets/img/Group 201.svg');
    }
    .control13 {
        background: url('../../assets/img/smart2.svg');
    }
    .control-cal {
        background: url('../../assets/img/cal.svg');
        background-size: 22px;
    }
    .buttons-smart {
        display: flex;
        flex-flow: nowrap;
        margin-bottom: 24px!important;
        justify-content: center;
    
        & input {
            max-width: 140px;
        }
    }
    .control12.active {
        filter: none;
    }
    .archive {
        background: url('../../assets/img/archive.svg');
        filter: none;
    }
    .dsv-icon_checklist-cams {
        display: none;
    }
    .dsv-icon_checklist.dsv-icon_checklist-cams {
        background-image: url('../../assets/img/list_cams.svg');
        background-size: cover;
        filter: grayscale(1) opacity(.65);
    }
    @media (max-width: 992px) {
        .dsv-icon_checklist-cams {
            display: block;
        }

        .archive-smart {
            width: 32px!important;
            height: 32px!important;
            background-size: 26px 26px!important;
        }
    }
    .archive_menu {
        position: absolute;
        background: #ddd;
        left: -6px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
        z-index: 1;
    }
    .controls8-icon {
        font-size: 30px;
    }

    .dsv-colonnadaTubler .dsv-mods__tumblr-items {
        top: 7px!important;
        left: -1px!important;
        width: 180px!important;
    }

    .dsv-colonnadaTubler .dsv-mods__tumblr-icon {
        background-repeat: no-repeat!important;
        background-size: 20px!important;
        background-position: center!important;
    }
</style>

<template>
    <div id="left-mini-controls-cams">
        <ModalNew v-if="showGlobalCalendar" @close="showGlobalCalendar = false" :width="460">
            <div slot="body" class="">
            <v-card flat>
                <div class="row mt-2 mx-4">
                <div class="col">
                    <v-date-picker
                        v-model="picked"
                        :first-day-of-week="1"
                        :next-icon="nextIcon"
                        :prev-icon="prevIcon"
                        :weekday-format="getDay"
                        color="#096866"
                        flat
                        locale="ru"
                        no-title
                        width="350"
                        @input="setSelectedDay(picked)"
                    />
                </div>
                </div>
                <div class="row mb-4 mx-4 buttons buttons-smart">
                    <div>Время:</div>
                    <input class="mx-2 pl-3 py-1" type="time" v-model="time1">
                </div>
                <div class="row my-3 mx-7 buttons" style="align-items: end; justify-content: center;">
                    <v-btn color="rgba(51, 51, 51, 0.5)" text @click="calendarCancel" class="cbtn">{{ $t('Сбросить') }}</v-btn>
                    <span style="width: 50px"/>
                    <v-btn color="#096866" text @click="setupCalendar" class="zbtn">{{ $t('Задать') }}</v-btn>
                </div>
            </v-card>
            </div>
        </ModalNew>
        <div v-if="showCreateDevPanel" class="dsv-cams__control-container">
            <div :class="['dsv-cams__control', isHidden ? 'dsv-cams__control_hidden' : '']">
                <div class='dsv-cams__control-panel dsv-cams__control-panel_second cams_mini_controls'>
                    <div :class="['controls dsv-icon_checklist control6 dsv-cams__control-button margin-zero', showCamsMode ? '' : 'unactive']" :title="$t('создать отклонение')"
                        @click='createDev'></div>
                    <div :class="['controls dsv-icon_checklist dsv-icon_checklist-cams dsv-cams__control-button dsv-hidden', showCamsMode ? 'active' : '']" :title="$t('Скрыть камеры')" @click="toogleShowCamsMode"></div>
                    <!--
                    <div v-if="archive" :class="['controls control8', archiveClass ? 'active' : '']" :title="$t('использовать суб-потоки')"
                        @click='archiveVideoOut'></div>
                    -->
                </div>
            </div>
        </div>
                
        <!-- <div v-if="showCreateDevPanel">
            <div class='my-12 cams_mini_controls dsv-cams__control'>
            <div class='controls control10' :title="$t('Завершить')"
                @click='createArchiveDev'></div>
            <div class='controls control11' :title="$t('Отмена')"
                @click='cancelCreateArchiveDev'></div>
            </div>
        </div> -->
        <div class="dsv-cams__control-container" v-else>
            <div :class="['dsv-cams__control', isHidden ? 'dsv-cams__control_hidden' : '']">
                <ModeTumblr v-if="!archive" class=""
                    :mode="mode"
                    :checklistsEnabled="checklistsEnabled"
                    :priceTagsEnabled="priceTagsEnabled"
                    @changeMode="changeModeHandler"/>

                <div class='dsv-cams__control-panel dsv-cams__control-panel-view cams_mini_controls cams_mini_controls_smart'>
                    <!--
                    <div v-if="archive"
                        :class="['controls controlback', archiveClass ? 'active' : '']"
                        :title="$t('выйти из архива')"
                        @click='archiveVideoOut'></div>
                    -->
                    <div v-if="!archive && smartArchiveEnabled" :class="['controls archive-smart', archive ? 'archive' : 'control13', isSmart ? 'active' : '']" :title="$t('смарт')"
                        @click='archiveVideoSmart'></div>
                    <div v-if="!isSmart" :class="['controls', archive ? 'archive' : 'control8', isSubPreviewmode ? 'active' : '']" :title="$t('использовать суб-потоки')" @click="$emit('toggleSub')"></div>
                    <!--
                    <div v-if="!archive && archiveEnabled && !isSmart" :class="['controls', archive ? 'archive' : 'control8', archiveClass ? 'active' : '']" :title="$t('архив видео')"
                        @click='archiveVideo'></div>
                    -->
                    <div v-if="!archive && isSmart && (smartArchiveEnabled || archiveEnabled)" :class="['controls control-cal']" :title="$t('Календарь')"
                        @click='openGlobalCalendar'></div>
                </div>

                <div v-if="!archive" class='dsv-cams__control-panel dsv-cams__control-panel_second cams_mini_controls dsv-mobile'>
                    <div :class="[
                            'controls dsv-cams__control-button active',
                            colonnada==1 ? 'control1' : '',
                            colonnada==2 ? 'control2' : ''
                        ]" @click="colonnadin(colonnada === 1 ? 2 : 1)" >
                    </div>
                </div>

                <div v-if="!archive" class='dsv-cams__control-panel dsv-cams__control-panel_second cams_mini_controls mobile-hide'>
                    <div class="dsv-colonnadaTubler" @mouseleave='show_sub_menu = false'>
                        <div :class="[
                                'controls dsv-cams__control-button active',
                                colonnada==1 ? 'control1' : '',
                                colonnada==2 ? 'control2' : '',
                                colonnada==3 ? 'control3' : '',
                                colonnada==6 ? 'control4' : ''
                            ]" @mouseover='show_sub_menu = true' >
                        </div>
                        <div v-if="show_sub_menu" class="dsv-mods__tumblr-items">
                            <div :class="['dsv-mods__tumblr-icon control1 ', colonnada==1 ? 'active' : '']" :title="$t('1 колонка')" @click="colonnadin(1)"></div>
                            <div :class="['dsv-mods__tumblr-icon control2 ', colonnada==2 ? 'active' : '']" :title="$t('2 колонки')" @click="colonnadin(2)"></div>
                            <div :class="['dsv-mods__tumblr-icon control3 ', colonnada==3 ? 'active' : '']" :title="$t('3 колонки')" @click="colonnadin(3)"></div>
                            <div :class="['dsv-mods__tumblr-icon control4 ', colonnada==6 ? 'active' : '']" :title="$t('6 колонок')" @click="colonnadin(6)"></div>
                        </div>
                    </div>
                    
                    <div v-if="!isSmart" :class="['controls control12', showMap ? 'active': '']" :title="$t('Карта объекта')" @click="emitShowMap"></div>
                </div>

                <div class='dsv-cams__control-panel dsv-cams__control-panel_second cams_mini_controls'>
                    <div v-if="showNeural && !archive && neuralEnabled" class='controls control7' title='Нейромодуль' @click="neuralClick"></div>
                    <div v-if="!archive" :class="['controls control62 dsv-cams__control-button margin-zero', showCamsMode ? '' : 'dsv-hidden']" :title="$t('создать инцидент')"
                        @click='createDigression'></div>
                    <div :class="['controls dsv-icon_checklist control6 dsv-cams__control-button margin-zero', showCamsMode ? '' : 'unactive']" :title="$t('создать отклонение')"
                        @click='createDev'></div>
                    <div :class="['controls dsv-icon_checklist dsv-icon_checklist-cams dsv-cams__control-button dsv-hidden', showCamsMode ? 'active' : '']" :title="$t('Скрыть камеры')" @click="toogleShowCamsMode"></div>
                </div>
            </div>
            <div class="dsv-cams__control-hide-button" :style="isHidden ? {top: '-12px'} : {}" @click="toggleHidden">
                <v-icon>{{ isHidden ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </div>
        </div>
    </div>
</template>
<style src="./_leftMiniControlsCams/LeftMiniControlsCams.css"></style>

<script>
import rolesUtils from './../roles/utils';
import ModeTumblr from './../mods/tumblr';
import ModalNew from './../base/modalNew';


// import usersService from './../users/utils';

export default {
    name: 'LeftMiniControlsCams',
    components: {
        ModeTumblr,
        ModalNew
    },
    props: ['showMap', 'colonnada', 'archive', 'selectedObj', 'mode', 'smartArchive', 'isSmart',
        'archiveEnabled',
        'smartArchiveEnabled',
        'neuralEnabled',
        'checklistsEnabled',
        'priceTagsEnabled',
        'isSubPreviewmode'
    ],
    data: () => ({
        archiveClass: false,
        showCreateDevPanel: false,
        checklistMode: false,
        monitoringMode: false,
        isHidden: false,
        showCamsMode: true,
        showNeural: false,
        showChecklists: false,
        show_archive_menu: false,
        show_sub_menu: false,
        showGlobalCalendar: false,

        weakDaysHead: [ "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб" ],
        picked: null,
        day: '2020-07-10',
        prevIcon: '$vuetify.icons.CalendarListLeftIcon',
        nextIcon: '$vuetify.icons.CalendarListRightIcon',
        time1: '00:00',
        time2: '23:59',
    }),
    watch: {
        archive: {
            handler() {
                this.show_archive_menu = false;
            }
        }
    },
    computed: {
        activeCam() {
            return this.$store.getters.ACTIVE_CAM;
        },
    },
    mounted() {
        this.showNeural = true; //usersService.hasPermission('run ml tasks');
        this.checklistMode = window.checklistsMode;
        if (window.checklistsMode) {
            window.$('.dsv-icon_checklist-cams').removeClass('dsv-hidden');
        }
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isHidden = false;
        }
        this.showChecklists = rolesUtils.isRoleContained(['Администратор', 'Супервайзер']);
    },
    updated() {
        if (window.checklistsMode) {
            window.$('.dsv-icon_checklist-cams').removeClass('dsv-hidden');
        }
    },
    beforeUpdate() {
        window.resizeScrollingContent();
    },
    methods: {
        setSelectedDay() {
            // todo
        },
        calendarCancel() {
            this.showGlobalCalendar = false;
            this.picked = window.globalDate = null;
            this.time1 = window.globalTime1 = '00:00';
            this.time2 = window.globalTime2 = '23:59';
        },
        setupCalendar() {
            window.globalDate = this.picked;
            window.globalTime1 = this.time1;
            window.globalTime2 = this.time2;
            this.showGlobalCalendar = false;
        },
        openGlobalCalendar() {
            if (window.globalDate && window.globalTime1) {
                this.picked = window.globalDate;
                this.time1 = window.globalTime1;
            }
            this.showGlobalCalendar = true;
        },
        changeModeHandler(data) {
            this.$emit('modeChange', data);
        },
        setShowCamsMode(value) {
            this.showCamsMode = value;
        },
        working() {
    //        window.myAlert('Модуль подключения искусственного интеллекта находится в разработке')
        },
        neuralClick() {
            this.$emit('neural')
        },
        emitShowMap() {
            this.$emit('showMap')
        },
        fullScreenVideo() {
            if(this.activeCam)
            this.$emit('fullScreenVideo')
        },
        createDigression() {
            if (this.selectedObj && this.selectedObj.object_type !== 'node') {
                window.archiveDate = null;
                this.$emit('createHandDev');
            } else {
                window.myAlert('Выберите объект или зону из списка слева.');
            }
            return;
        },
        createDev() {
            if(!this.archive) { // поток
                this.$emit('createDev', false);
            } else {
                this.$emit('createDev', true);
            }
        },
        toogleShowCamsMode() {
            this.showCamsMode = !this.showCamsMode;
            this.showCams();
        },
        showCams() {
            this.$emit('showCams', this.showCamsMode);
        },
        showCreateDev() {
            this.showCreateDevPanel = true
        },
        cancelCreateArchiveDev() {
            this.showCreateDevPanel = false
            this.$emit('cancelCreateArchiveDev', true)
        },
        createArchiveDev() {
            this.showCreateDevPanel = false
            this.$emit('createArchiveDev', true)
        },
        cancelTransferSnaps() {
            this.showCreateDevPanel = true
        },

        toggleHidden() {
            this.isHidden = !this.isHidden;
            const self = this;
            setTimeout(function() {
                window.resizeScrollingContent();
                self.$emit('toggled', self.isHidden)
            }, 100)
        },

        colonnadin(cols) {
            this.show_sub_menu = false;
            this.$emit('setColonnada', cols)
        },
        archiveVideo() {
            this.show_archive_menu = false;
            if(this.activeCam) {
                if(!this.archive) {
                    this.$emit('archiveVideo', true)
                } else {
                    this.$emit('archiveVideo', false)
                }
            } else {
                window.myAlert("Выберите камеру из списка камер")
            }
        },
        archiveVideoOut() {
            if (this.archive) {
                this.$emit('archiveVideoOut', true)
            }
        },
        archiveVideoSmart() {
            // this.show_archive_menu = false;
            if (this.isSmart) {
                this.$emit('archiveVideoSmart', false);
            } else {
                this.$emit('archiveVideoSmart', true);
            }
            // if(this.activeCam) {
            //     if(!this.archive) {
            //         this.$emit('archiveVideoSmart', true)
            //     } else {
            //         this.$emit('archiveVideoSmart', false)
            //     }
            // } else {
            //     window.myAlert("Выберите камеру из списка камер")
            // }
        },
        toogleChecklistMode() {
            // if (this.selectedObj) {
            this.checklistMode = !window.checklistsMode;
            // if (this.checklistMode) {
                // this.monitoringMode = false;
                // this.$emit('monitoringOff');
            // }
            // }
            if (this.checklistMode) {
                window.$("#objects-list-title").show();
                window.$("#template_list_tmp").hide();
                // setTimeout(function() { window.$("#objects-list-title").hide(); }, 5000);
                if (window.$('#dsv-app').hasClass('dsv-mobile')) {
                    window.toggleMenu();
                }
            } else {
                window.$("#objects-list-title").hide();
                window.$("#template_list_tmp").show();
            }
            this.$emit( this.checklistMode ? 'checklistOn' : 'checklistOff');
            
            return;
            // window.myAlert('Выберите объект из списка слева, с которым будете работать по мониторингу');
        },

        toogleMonitoringMode() {
            // if (this.selectedObj) {
            this.monitoringMode = !this.monitoringMode;
            if (this.monitoringMode) {
                this.checklistMode = false;
                this.$emit('checklistOff');
            }
            this.$emit( this.monitoringMode ? 'monitoringOn' : 'monitoringOff');
            // }
            window.$("#objects-list-title").show();
            window.$("#template_list_tmp").hide();
            // setTimeout(function() { window.$("#objects-list-title").hide(); }, 5000);
            if (window.$('#dsv-app').hasClass('dsv-mobile')) {
                window.toggleMenu();
            }
            // window.myAlert('Выберите объект из списка слева, с которым будете работать по чек-листу');
            return;
        }
    }
}
</script>
