const NEW_PRODUCT_DATA = {
    title: '',
    article: '',
    measure_value: 1,
    measure_unit: 0,
    barcode_ids: []
}

export default {
    name: 'PricesProductList',
    props: ['category', 'products', 'filter'],
    components: {
        // todo
    },
    data: () => ({
        filteredProducts: [],
        selectedProduct: null
    }),
    watch: {
        filter: {
            deep: true,
            handler() {
                this.setFilteredProducts();
            }
        },
        products: {
            deep: true,
            handler() {
                this.setFilteredProducts();
            }
        }
    },
    mounted() {
        this.setFilteredProducts();
    },
    destroyed() {
        // todo
    },
    methods: {
        openBarcodes() {
            return;
        },
        setFilteredProducts() {
            const filtered = [];
            this.filteredProducts = [];
            const searchString = this.filter.name.trim();
            (this.products || []).forEach(product => {
                if (this.category.id === product.id) {
                    if (searchString.length) {
                        if (product.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                            filtered.push(product);
                        }
                    } else {
                        filtered.push(product);
                    }
                }
            })
            this.filteredProducts = filtered;
        },
        editProduct(product) {
            if (product) {
                this.selectedProduct = product;
            } else {
                this.selectedProduct = Object.assign({}, NEW_PRODUCT_DATA, {category_id: this.category.id});
            }
        },
        isValidProduct() {
            if (((this.selectedProduct || {}).title || '').length === 0) {
                return false;
            }
            return true;
        },
        saveProduct() {
            if (!this.isValidProduct()) {
                return;
            }
            window.myAjax(this.selectedProduct.id ? 'PUT' : 'POST', '/price-tag/product', Object.assign({}, this.selectedProduct, {
                product_id: this.selectedProduct.id
            }), this.saveProductHandler);
            this.selectedProduct = null;
        },
        saveProductHandler(error) {
            if (!error) {
                this.$emit('saved');
            }
        },
        showConfirm(product) {
            this.selectedProduct = product;
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить товар?', this.deleteProduct)
        },
        deleteProduct() {
            window.myAjax('DELETE', '/price-tag/product', {
                product_id: this.selectedProduct.id
            }, this.deleteProductHandler);
        },
        deleteProductHandler(error) {
            if (!error) {
                this.$emit('deleted');
            }
        },
    }
}
