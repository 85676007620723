/**
 * @param {*} id - Id типа отклонения.
 */
 export function getDigressionTypeWatchers(params, callback) {
    const url = '/api/digression-watchers';
    const method = 'GET';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            // window.myAlert(response.message || 'Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_id - Id типа отклонения.
 * @param {*} contact_id - Id контакта.
 */
 export function addDigressionTypeWatchers(params, callback) {
    const url = '/api/digression-watchers';
    const method = 'POST';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            // window.myAlert(response.message || 'Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_id - Id типа отклонения.
 * @param {*} contact_id - Id контакта.
 */
 export function removeDigressionTypeWatchers(params, callback) {
    const url = '/api/digression-watchers';
    const method = 'DELETE';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            // window.myAlert(response.message || 'Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} page
 * @param {*} limit
 */
 export function getContacts(params, callback) {
    const url = '/api/contacts?page=' + params.page + '&limit=' + params.limit;
    window.myAjax('GET', url, {}, function(error, response) {
        if (error) {
            window.myAlert(response.message || 'Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}
