import LeftNaviObjects from './../adminSettings/objects/LeftNaviObjects';
import MyHeader from './../MyHeader';
import CamsMain from './../CamsMain';
import HeaderLayout from './../header/headerLayout';
import Settings from './../adminSettings/Settings';
import { message } from '../Utils/modal';

export default {
    name: 'LkMain',
    components: {
        LeftNaviObjects,
    //  LeftNaviDevs,
        HeaderLayout,
        MyHeader,
        CamsMain,
        Settings,
    },
    data: () => ({
        myCams: [],
        show_left_menu: true,
        settings: false,
        content: 'MainCams',
        addRootObject: true,
        leftPanelHeader: 'Список объектов',
        leftPanelRootTitle: 'Добавить новый корневой узел или объект',
        theNodes: [],
        selectedObj: null,
        show_templates: true,

        mode: 'cams',

        nodes: [],
        rootNodes: [],
        treeBuilded: false,
        rootDevs: [],
        nodesUrl: '',
        objectsUrl: '/api/objects',
        camsUrl: '/api/client-cameras',
        devsUrl: '/api/digressions',
        devStatusesUrl: '/api/digression-statuses',
        rolesUrl: '/api/roles',
        usersUrl: '/api/users',
        supervisersUrl: '/api/get-related-supervisors',
        calendarTemplates: '/api/calendar-templates',
        priorsUrl: '/api/digression-priorities',
        devsToObjectsUrl: '/api/objects-digressions'
    }),
    mounted() {
        this.getStartData();
        window.resizeScrollingContent();
        // window.$('.content_admin').css('min-height', window.innerHeight - 60);
        this.closeMenu(true);
    },
    methods: {
        modeChangeHandler(data) {
            if (!(data || {}).mode) {
                return;
            }
            this.mode = data.mode;
            setTimeout( () => {
                this.show_templates = this.mode === 'cams';
                this.content = 'Objects';
                this.leftPanelHeader = 'Список объектов';
                this.leftPanelRootTitle = 'Добавить новый корневой узел или объект';
                this.addRootObject = true;
                this.nodes = window.myObjects
                this.treeBuilded = false
                this.getObjects()
            }, 50);
        },
        changeMenuHandler(data) {
            console.log('lkmain changeMenuHandler', data);
            this.$refs['LeftNaviObjectsRef'].changeMenu(data);
        },
        setActiveBanner(data) {
            this.$refs['LeftNaviObjectsRef'].setActiveBanner(data);
        },
        selectedBannerHandler(data) {
            this.$refs['SettingsRef'].selectBanner(data);
        },
        setActiveCategory(data) {
            this.$refs['LeftNaviObjectsRef'].setActiveCategory(data);
        },
        selectedCategoryHandler(data) {
            this.$refs['SettingsRef'].selectCategory(data);
        },
        addRootObjectFromSettings(val) {
            this.addRootObject = val;
        },
        logoutUser() {
            this.$emit('logoutUser');
            window.setTimeout(window.logoutUser, 200);
        },
        checklistModeOff() {
            // todo
        },
        checkMenuHandler(page) {
            this.content = page;
        },
        toggleSettings() {
            this.settings=!this.settings
            if(this.settings)
                this.show_templates = false
            else {
                if (!window.checklistsMode) {
                    this.show_templates = true
                }
            }
            this.content = 'Objects'
            this.leftPanelHeader = 'Список объектов'
            this.leftPanelRootTitle = 'Добавить новый корневой узел или объект'
            this.addRootObject = true
            this.getObjects()
            console.log('toggleSettings');
        },
        settingsPunkt(menuPunkt, data) {
            if (this.content === menuPunkt) {
                return;
            }
            this.show_templates = false;
            this.content = menuPunkt;
            console.log('this.content', this.content);
            switch(menuPunkt) {
                case 'MainCams':
                    console.log('settingsPunkt mainCams')
                    this.settings = false;
                    if (!window.checklistsMode) {
                        this.show_templates = true;
                    }
                    this.content = 'Objects';
                    this.leftPanelHeader = 'Список объектов';
                    this.leftPanelRootTitle = 'Добавить новый корневой узел или объект';
                    this.addRootObject = true;
                    this.getObjects();
                    this.$refs['MainRef'].showContent(menuPunkt, data);
                    break;
                case 'IZK':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'Reports':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'AnalyticReports':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'Sensor':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'Neural':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'Contacts':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'Calendar':
                    this.leftPanelRootTitle = '';
                    this.$refs['MainRef'].showContent(menuPunkt);
                    break;
                case 'Devs':
                    // if ((data || {}).fromStart) {
                    //     this.toggleSettings();
                    //     setTimeout(() => {
                    //         this.settingsPunkt(menuPunkt)
                    //     }, 100)
                    //     break;
                    // }
                    this.leftPanelHeader = 'Иерархия отклонений';
                    this.leftPanelRootTitle = 'Добавить корневой тип';
                    this.getDevs();
                    this.$refs['SettingsRef'].showContent(menuPunkt);
                    break;
                case 'Users':
                    // if ((data || {}).fromStart) {
                    //     this.toggleSettings();
                    //     setTimeout(() => {
                    //         this.settingsPunkt(menuPunkt)
                    //     }, 100)
                    //     break;
                    // }
                    this.leftPanelHeader = 'Роли пользователей';
                    this.leftPanelRootTitle = 'Корневые роли фиксированы';
                    this.getRoles();
                    this.$refs['SettingsRef'].showContent(menuPunkt);
                    break;
        //          case 'Objects':
        //        case 'Cams':
                default:
                    // if ((data || {}).fromStart) {
                    //     this.toggleSettings();
                    //     setTimeout(() => {
                    //         this.settingsPunkt(menuPunkt)
                    //     }, 100)
                    //     break;
                    // }
                    this.leftPanelHeader = 'Список объектов'
                    if(menuPunkt=='Cams')
                        this.leftPanelRootTitle = 'Добавить новую камеру'
                    else
                        this.leftPanelRootTitle = 'Добавить новый корневой узел или объект'
                    if(menuPunkt=='Objects')
                        this.addRootObject = true
                    this.getObjects()
                    this.$refs['SettingsRef'].showContent(menuPunkt)
                    break
            }
            // if (menuPunkt!='Devs' && menuPunkt!='Users' && this.selectedObj && this.selectedObj.object_type!=undefined)
            //     window.setTimeout(this.showObjectCar, 100)
        },

        /**
        * Сворачивает меню влево.
        * @param {boolean} onlyMobile - Свернуть меню, только если сайт в мобильном режиме. 
        */
        closeMenu(onlyMobile) {
            let needClose = true;
            const page = window.$('.dsv-page');
            const isOpenedClassName = 'dsv-page_opened_menu';
            //todo проверку на isMobile необходимо вынести в отдельную глобальную функцию
            if (onlyMobile) {
            if (window.$('#dsv-app').hasClass('dsv-mobile')) {
                needClose = true;
            } else {
                needClose = false;
            }
            }
            if (needClose) {
            if (page.hasClass(isOpenedClassName)) {
                page.removeClass(isOpenedClassName);
            }
            }          
        },

        showTemplate(template) {
        this.$refs['MainRef'].showTemplate(template);
        this.closeMenu(true);
        },
        showGroup(group) {
        this.$refs['MainRef'].showGroup(group)
        },

        refreshChatsFlag() {
            this.$refs['MyHeaderRef'].refreshChatFlag()
        },

        addNaviRootObject() {
        switch(this.content) {
            case 'Objects':
            this.addRootObject = true
            break
            case 'MainCams':
            this.addRootObject = true
            break
            case 'Cams':
            case 'Devs':
            case 'Users':
            this.$refs['SettingsRef'].showObjectCard(null)
            break
        }
        },

        getStartData() {
            this.getStartObjects()
            this.getStartCams()
            this.getStartDevs()
            this.getStartDevStatuses()

            if(window.getCookie('userBaseRole')=='Руководитель супервайзеров') {
                this.getStartSupervisors()
            } else {
                this.getStartRoles()
                this.getStartUsers()
            }

            // this.getStartCalendarTemplates()
            this.getStartPriors()
            this.getStartDevsToObjects()
            this.getUserObjDevs()
            this.getUserObjs()
        },

        getStartObjects() {
            let data = {},
                    url = this.objectsUrl,
                    method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartObj)
            },
            myCallbackGetStartObj (error, response) {
            if(error) {
                message(response.message)
            } else {
        //          window.myObjects = []
                //        for(let i=0; i<response.objects.length; i++)
                //        window.myObjects.push(Object.assign({}, response.objects[i]))
                window.myObjects = response.objects;
                if(this.content=='Objects' || this.content=='MainCams')
                this.getObjects()
            }
        },
        /*
        getAllObjects() {
        let data = {},
                url = this.objectsUrl,
                method = 'GET';
        window.myAjax(method, url, data, this.myCallbackGetStartObj2)
        },
        myCallbackGetStartObj2 (error, response) {
        if(error) {
            message(response.message)
        } else {
    //          window.myObjects = []
            //        for(let i=0; i<response.objects.length; i++)
            //        window.myObjects.push(Object.assign({}, response.objects[i]))
            window.myObjects = response.objects
        }
        },
        */
        getStartCams() {
            let data = {},
                url = this.camsUrl,
                method = 'GET';
                window.myAjax(method, url, data, this.myCallbackGetStartC)
        },
        myCallbackGetStartC (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myCams = response.client_cameras
                this.myCams = response.client_cameras;
            }
        },
        getStartDevs() {
            let data = {},
                    url = this.devsUrl,
                    method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartD)
        },
        myCallbackGetStartD (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myDevs = response.digressions.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                if(this.content=='Devs')
                this.getDevs()
            }
        },
        getStartDevStatuses() {
            let data = {},
                    url = this.devStatusesUrl,
                    method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartDevStatuses)
        },
        myCallbackGetStartDevStatuses (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myDevStatuses = response.digressionStatuses
            }
        },


        getStartSupervisors() {
            let url = this.supervisersUrl,
                    method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartSup)
        },
        myCallbackGetStartSup (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myUsers = response.users
                for(let j=0; j<window.myUsers.length; j++) {
                window.myUsers[j].user_objects_in_rucsup = []
                for(let k=0; k<window.myUsers[j].objects.length; k++) {
                    for(let i=0; i<window.myObjects.length; i++) {
                    if(window.myObjects[i].id==window.myUsers[j].objects[k].object_id) {
                        if(window.myObjects[i].object_type=='zone') {
                        let parentObjName = this.getParentObjectName(window.myObjects[i])
                        window.myUsers[j].user_objects_in_rucsup.push({
                            name: parentObjName + '/' + window.myObjects[i].name
                        })
                        } else
                        window.myUsers[j].user_objects_in_rucsup.push({
                            name: window.myObjects[i].name
                        })
                        break
                    }
                    }
                }
                }
            }
        },
        getParentObjectName(obj) {
            for(let i=0; i<window.myObjects.length; i++)
                if(obj.parent_id==window.myObjects[i].id)
                return window.myObjects[i].name
            return ''
        },
        getStartUsers() {
            let url = this.usersUrl,
                    method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartU)
        },
        myCallbackGetStartU (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myUsers = response.users
                const user = this.getUserById( window.getCookie('userId') );
                window.izkChecklistRoots = user.permissions.findIndex( p => p.id == 1009) >= 0;
            }
        },
        getUserById(id) {
            for (let i = 0; i < window.myUsers.length; i++) {
                if (window.myUsers[i].id == id) {
                    return window.myUsers[i];
                }
            }
            return null;
        },
        getStartCalendarTemplates() {
            let url = this.calendarTemplates,
                    method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartCalend)
        },
        myCallbackGetStartCalend (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myCalendarTemplates = response.templates
            }
        },

        getStartRoles() {
            let data = {},
                url = this.rolesUrl,
                method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartR)
        },
        myCallbackGetStartR (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myRoles = response.roles

                for(let i=0; i<window.myRoles.length; i++) {
                // режем суффиксы, если есть
        //            if(window.myRoles[i].client_id) {
        //            let str = '_' + window.myRoles[i].client_id
        //          window.myRoles[i].name = window.myRoles[i].name.substring(0,window.myRoles[i].name.length-str.length)
            //      }
                // отбираем права администраторов
                if(window.myRoles[i].name=='Администратор') {
                    window.myAllAdminPermissions = window.myRoles[i].permissions
                }

                }
                if(this.content=='Users')
                this.getRoles()
            }
        },
        getStartPriors() {
            let data = {},
                    url = this.priorsUrl,
                    method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartP)
        },
        myCallbackGetStartP (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myPriors = response.priorities
            }
        },
        getStartDevsToObjects() {
            let url = this.devsToObjectsUrl,
                method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartDevsToObjects)
        },
        myCallbackGetStartDevsToObjects (error, response) {
            if(error) {
                message(response.message)
            } else {
                window.myDevsToObjects = response.object_digressions
            }
        },

        getUserObjDevs() {
            window.myAjax('GET', '/api/user-digression-relations', {
                user_id: window.getCookie('userId')
            }, this.myCallbackGetUserObjDevs)
        },
        myCallbackGetUserObjDevs(error, response) {
            if(!error) {
                window.myDevsToUserObjects = response.relations
            }
        },

        getUserObjs() {
        //TODO
            window.myAjax('GET', '/api/user-object-relations', {
                user_id: window.getCookie('userId')
            }, this.myCallbackGetUserObjs)
        },
        myCallbackGetUserObjs(error, response) {
            if(!error) {
                window.myUserObjects = response.user_objects
            }
        },
        getObjects() {
            this.nodes = window.myObjects
            this.treeBuilded = false
            this.buildTree()
        },
        getDevs() {
            this.nodes = [];
            for (let i = 0; i < window.myDevs.length; i++) {
                this.nodes.push(window.myDevs[i]);
            }
            this.treeBuilded = false
            this.buildTree()
        },
        getRoles() {
            this.nodes = window.myRoles
            this.treeBuilded = false
            this.buildTree()
        },
        buildTree() {
            this.rootNodes = this.startBuildTree(0)
            this.treeBuilded = true
            window.setTimeout(window.addObjectListeners, 100)
        },
        startBuildTree(parent_id) {
            let oneLevelNodes = [];
            for (let i = 0; i < this.nodes.length; i++) {
                if (this.nodes[i].parent_id == parent_id ||
                        (parent_id == 0 && this.nodes[i].parent_id == null)) {
                    let nextLevelNode = Object.assign({}, this.nodes[i]);
                    nextLevelNode.childrenNodes = this.startBuildTree(nextLevelNode.id);
                    if (nextLevelNode.childrenNodes.length) {
                        nextLevelNode.hasChildren = 'hasChildren';
                    } else {
                        nextLevelNode.hasChildren = '';
                    }
                    oneLevelNodes.push(nextLevelNode);
                }
            }
            const result = oneLevelNodes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            return result;
        },
        showObjectCard(obj) {
            if (obj) {
                this.selectedObj = obj;
            }
            if (this.settings) {
                this.$refs['SettingsRef'].showObjectCard(this.selectedObj);
            } else if (this.content=='Objects' || this.content=='MainCams' || this.content=='Calendar') {
                if (obj.object_type !== 'node') {
                    this.$refs['MainRef'].showObjectCard(this.selectedObj);
                    this.closeMenu(true);
                }
            }
        },
        showTemplateCams(template) {
            this.selectedObj = null;
            this.$refs['MainRef'].showTemplateCams(template);
            this.closeMenu(true);
        },
        getTemplates() {
            this.$refs['LeftNaviObjectsRef'].getTemplates()
        },
        getGroups() {
            this.$refs['LeftNaviObjectsRef'].getGroups()
        },

        toggleLeftMenu() {
            this.show_left_menu = !this.show_left_menu
        },

        setActiveObject(obj) {
            this.$refs['LeftNaviObjectsRef'].setActiveObject(obj)
        },
        updateNotifierHandler() {
            this.$refs['MyHeaderRef'].refreshNotifier();
        }
    }
}
