import CheckboxNew from './../../../base/checkboxNew';

export default {
    components: {
        CheckboxNew
    },
    name: 'UserHeader',
    props: {
        selecting: {
            type: Boolean,
            default: false
        },
        selectedAll: {
            type: Boolean,
            default: false
        }
    }
}
