// import ModeTumblr from '../../../components/mods/tumblr';
import Checkbox from './../../base/checkbox';

const UPDATE_TIME_IN_SEC = 1.2;

export default {
    name: 'PreviewCamera',
    components: {
        Checkbox,
    },
    props: {
        activeCamera: {
            type: Object,
            default: () => ({})
        },
        subMode: {
            type: Boolean,
            default: false
        },
        camera: {
            type: Object,
            default: {}
        },
        templates: {
            type: Array,
            default: []
        },
        isArchive: {
            type: Boolean,
            default: false
        },
        isSmartArchive: {
            type: Boolean,
            default: false
        },
        isSensor: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        src: null,
        srcInterval: null,
        title: '',
        showTemplates: false,

        subLoading: false,
        subReady: false,

        hlsPlayer: null,
        hlsStartInterval: null,

        hls: null,
//192.168.76.12/user=SV&password=DSV12S045&channel=1&stream=0.sdp?
//rtsp://192.168.76.12/user=SV&password=DSV12S045&channel=2&stream=0.sdp?
        useSub: false,
    }),
    watch: {
        camera: {
            deep: true,
            handler() {
                // this.initTitle();
            }
        },
        subMode: {
            handler() {
                if (this.subMode) {
                    this.startSubPreview();
                } else {
                    this.endSubPreview();
                    this.startUpdatingSrc();
                }
            }
        }
    },
    mounted() {
        console.log('camera mount', this.camera.id);
        this.initTitle();
        this.startUpdatingSrc();
        if (this.subMode) {
            this.startSubPreview();
        }
    },
    beforeDestroy() {
        clearInterval(this.srcInterval);
        if (this.useSub) {
            this.endSubPreview();
        }
    },
    methods: {
        showTemplate() {
            this.showTemplates = true;
        },
        startSubPreview() {
            if (!this.camera.preview_link_enabled || !this.camera.preview_link) {
                return;
            }
            this.useSub = true;
            window.myAjax('POST', '/stream-preview/start', {
                camera_id: this.camera.id
            }, (error, response) => {
                if (error) {
                    return;
                }
                if (response.indexOf('https') !== 0) {
                    this.subLoading = false;
                }
                const videoPlayer = this.$refs.videoPlayer;
                var videoSrc = decodeURIComponent(response);
                if (window.Hls.isSupported()) {
                    this.hlsPlayer = new window.Hls({
                        backBufferLength: 60,
                        maxMaxBufferLength: 60,
                        xhrSetup: xhr => {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + window.getCookie('userToken'))
                        }
                    });
                    this.hlsPlayer.loadSource(videoSrc);
                    this.hlsPlayer.attachMedia(videoPlayer);
                    this.hlsPlayer.on(window.Hls.Events.MANIFEST_PARSED, () => {
                        clearInterval(this.srcInterval);
                        this.subLoading = false;
                        this.subReady = true;
                        if (videoPlayer) {
                            videoPlayer.play();
                            videoPlayer.controls = false;
                            videoPlayer.disablePictureInPicture = true;
                        }
                    });
                    this.hlsPlayer.on(window.Hls.Events.ERROR, (_event, data) =>  {
                        if (data.fatal) {
                            switch (data.type) {
                                case window.Hls.ErrorTypes.NETWORK_ERROR:
                                    this.hlsStartInterval = setTimeout(() => {
                                        this.hlsPlayer.loadSource(videoSrc);
                                        this.hlsPlayer.startLoad();
                                    }, 1000)
                                    break;
                            }
                        }
                    });
                } 
            });  
        },
        endSubPreview() {
            if (this.hlsStartInterval) {
                clearInterval(this.hlsStartInterval);
            }
            if (this.hlsPlayer) {
                if (this.hlsPlayer.stopLoad) {
                    this.hlsPlayer.stopLoad();
                    this.hlsPlayer.destroy();
                }
            }
            window.myAjax('POST', '/stream-preview/stop', {
                camera_id: this.camera.id
            }, () => {});
        },
        startUpdatingSrc() {
            if (!this.camera.id) {
                return;
            }
            this.updateImage();
            this.srcInterval = setInterval(this.updateImage, UPDATE_TIME_IN_SEC * 1000);
        },
        updateImage() {
            // const img = this.$refs.img;
            const url = `/preview/img?camera_id=${this.camera.id}&time=${Date.now()}`;
            this.src = `${url}`;
        },
        initTitle() {
            console.log('initTitle', this.camera);
            let separator = ' / ';
            this.title = '';
            if (this.camera.flag_company_name)
                this.title += window.getCookie('company_name') + separator;
            if (this.camera.flag_object_name)
                this.title += this.getObjectName(this.camera.object_id) + separator;
            if (this.camera.flag_cam_name)
                this.title += this.camera.name + separator;
            if (this.camera.flag_user_id)
                this.title += window.getCookie('userFio') + separator;
            if (this.camera.flag_comment && this.camera.comment)
                this.title += this.camera.comment + separator;
            if (this.camera.posId)
                this.title += this.camera.posName + separator;
            if (this.title.length)
                this.title = this.title.substring(0, this.title.length - 3);
        },
        toggleTemplate(templateId, checked) {
            const template = (this.templates || []).find(t => t.id === templateId);
            if (!template) {
                return;
            }
            if (checked) {
                this.$emit('camToTemplate', template, this.camera);
            } else {
                this.$emit('deleteCamFromTemplate', template, this.camera);
            }
        }
    }
}
