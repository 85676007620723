import NeuralList from './../list';
import Checkbox from './../../base/checkbox';
import CheckboxNew from './../../base/checkboxNew';
import CheckboxListDropdown from './../../base/checkboxListDropdown'; 
import ModalNew from './../../base/modalNew';
import TabsGroup from './../../base/tabsGroup';
import ParamsChanger from './../paramsChanger';
import ModulesReports from './../../reports/modulesReports';

const MODULE_NAMES = {
    'smoker_detection': 'Курильщики',
    'queue_detection': 'Наличие очереди',
    'absence_and_queue': 'Очередь и отсутствие продавца',
    'uniform_detection': 'Контроль шапок',
    'gloves_detection': 'Контроль перчаток',
    'cleaning_detection': 'Уборка помещений',
    'human_count': 'Подсчёт посетителей',
    'absence_in_zone': 'Отсутствие продавца/кассира',
    'license_plate_detection': 'Распознование автономеров',
    'first_in_detection': 'Контроль времени открытия',
    'last_out_detection': 'Контроль времени закрытия',
    'bac_treatment': 'Бакобработка',
    'equipment_detection': 'Распознавание инвентаря',
}

export default {
    name: 'NeuralContent',
    components: {
        NeuralList,
        Checkbox,
        CheckboxNew,
        CheckboxListDropdown,
        ModalNew,
        TabsGroup,
        ParamsChanger,
        ModulesReports,
    },
    props: {
        // todo
    },
    data: () => ({
        // selected: {
        //     object: 0,
        //     cam: 0,
        //     type: 0
        // },
        tab: 'list', // 'banners' || 'objects' || 'categories',
        tabs: [
            {id: 'list', caption: 'Активные модули'},
            {id: 'reports', caption: 'Отчеты работы модулей'},
        ],
        isMobile: false,
        pages: [],

        page: 0,
        limit: 10,
        total: 100,

        objects: [],
        cameras: [],
        filteredCameras: [],
        types: [],

        selectedObjects: [],
        selectedCameras: [],
        selectedTypes: [],

        selecting: false,
        selectedTasks: [],
        selectedAll: false,
        action_id: '0',

        showChangerModal: false,
    }),
    watch: {
        page: {
            handler() {
                this.updatePages();
            },
        },
        limit: {
            handler() {
                this.updatePages();
            },
        },
        selected: {
            handler() {
                this.updatePages();
            },
            deep: true
        }
    },
    mounted() {
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        document.getElementById('dsv-app').classList.add('izk');
        window.$('.scrolling_content').css('max-height', window.innerHeight - 102);

        this.types = Object.keys(MODULE_NAMES).map(key => ({id: key, name: MODULE_NAMES[key]}));

        this.getObjects();
    },
    destroyed() {
        document.getElementById('dsv-app').classList.remove('izk');
    },
    methods: {
        updatePages() {
            console.log('updatePages', this.page, this.limit, this.total);
            this.pages = [];
            // const current = this.$refs['nlist'].getFilteredTasks() || [];
            const coun = Math.ceil(this.total / this.limit);
            for (let i = 0; i < coun; i++) {
                this.pages.push({
                    id: i,
                    name: i + 1 
                });
            }
            if (this.pages.length < this.page) {
                this.page = 0;
            }
        },
        getObjects() {
            window.myAjax('GET', '/api/objects', {}, (error, response) => {
                if (!error) {
                    this.objects = (response.objects || []).filter(object => object.object_type === 'object');
                    this.getCameras();
                }
            });
        },
        totalUpdatedHandler(data) {
            console.log('totalUpdatedHandler', data);
            this.total = data;
            this.updatePages();
        },
        getCameras() {
            window.myAjax('GET', '/api/client-cameras', {}, (error, response) => {
                if (!error) {
                    this.cameras = (response.client_cameras || []);
                }
            });
        },
        updateSelectedHandler(data) {
            this.selectedAll = false;
            this.selectedTasks = data;
            const current = this.$refs['nlist'].getFilteredTasks() || [];
            if (this.selectedTasks.length === current.length) {
                this.selectedAll = true;
            }
        },
        selectAll() {
            if (this.selectedAll) {
                this.selectedTasks = [];
                this.selectedAll = false;
            } else {
                this.selectedTasks = this.$refs['nlist'].getFilteredTasks() || [];
                this.selectedAll = true;
            }
        },
        iconHandler() {
            this.selecting = !this.selecting;
            this.selectedTasks = [];
            this.selectedAll = false;
        },
        actionChange(action) {
            setTimeout( () => {
                this.action_id = '0';
            }, 10);
            switch (action) {
                case 'delete':
                    window.myConfirm('Внимание!', `Вы уверены, что хотите завершить выбранные (${this.selectedTasks.length}) модули?`, () => {
                        this.deleteSelected();
                    })
                    break;
                case 'changer':
                    this.showChangerModal = true;
                    break;
                default:
                    break;
            }
        },
        closeChangerModal() {
            this.showChangerModal = false;
            this.$refs['nlist'].getTasks();
        },
        deleteSelected() {
            if (!this.selectedTasks.length) {
                window.myAlert('Модули успешно остановлены');
                this.$refs['nlist'].getTasks();
                return;
            }
            const nextId = this.selectedTasks.pop();
            window.myAjax('POST', '/ml/tasks/stop', {
                task_id: nextId
            }, () => {
                this.deleteSelected();
            });

        },
        selectedObjectsHandler(data) {
            this.selectedObjects = data;
            if (this.selectedObjects.length) {
                this.filteredCameras = this.cameras.filter(cam => this.selectedObjects.includes(cam.object_id));
            } else {
                this.filteredCameras = [];
            }
        },
        setPage(id) {
            this.page = id;
        },
        filterObjects() {
            if (this.selected.object == 0) {
                //
            } else {
                this.selected.cam = 0;
                this.cams = [];
                for (let i = 0; i < window.myCams.length; i++) {
                    const cam = window.myCams[i];
                    if (cam !== undefined && cam.object_id == this.selected.object) {
                        this.cams.push(cam)
                    }
                }
            }
            
            setTimeout( () => {
                this.updatePages();
            }, 10);
            // this.filter();
        },
        filterCams() {
            setTimeout( () => {
                this.updatePages();
            }, 10);
        },
        filter() {
            // todo
        },
        tabsHandler(tab) {
            this.tab = tab;
        }
    }
}
