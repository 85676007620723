import BaseButton from './../../base/button';
import RootTemplate from './_templates/root';
import { getChecklistLog } from '../Utils/getChecklistLog';
import { getChecklist } from '../Utils/getChecklist';

// const CHECKLIST_TYPES = {
//     ROOT: 'root',
//     SUBGROUP: 'subgroup',
//     POINT: 'point',
// }

const SUBGROUP_NAME = 'subgroup';
const POINT_NAME = 'point';

export default {
    name: 'ChecklistTree',
    components: {
        BaseButton,
        RootTemplate
    },
    props: {
        /**
         * Режим для кабинета администратора: отработанные пункты не выделяются.
         * @default false
         */
        adminMode: {
            type: Boolean,
            default: true
        },
        /**
         * Выбранный объект.
         * @default null
         */
        obj: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        checklistTmp: null,
        checklist: null,
        id: null,
        active: null,
        lastActive: null,
        needNext: false,
        readOnly: true,
        loading: false,
        hasDig: false
    }),
    mounted() {
        this.getChecklistLogCallback = this.getChecklistLogCallback.bind(this);
        this.getChecklistCallback2 = this.getChecklistCallback2.bind(this);
        this.getChecklistCallback = this.getChecklistCallback.bind(this);
        this.getcallback = this.getcallback.bind(this);
        this.update = this.update.bind(this);
        this.findNextIntoGroup = this.findNextIntoGroup.bind(this);
        this.isRequiredPoint = this.isRequiredPoint.bind(this);
        this.getNext = this.getNext.bind(this);
        this.next = this.next.bind(this);
        this.reset = this.reset.bind(this);
        // this.update(1);
        document.getElementById('dsv-checklist-tree').addEventListener('update', (event) => {
            this.update(event.detail);
        });
        document.getElementById('dsv-checklist-tree').addEventListener('next', () => {
            this.next();
        });
        document.getElementById('dsv-checklist-tree').addEventListener('reset', (event) => {
            this.reset(event.detail);
        });
    },
    updated() {
        if (this.checklist) {
            if (this.active && !this.readOnly) {
                const prevActive = document.getElementById(this.active.id);
                if (prevActive) {
                    prevActive.classList.add('active');
                }
            }
        }
    },
    methods: {
        clickHandler(checklist) {
            if (!this.adminMode && checklist.nodeg) {
                return;
            }
            if (!this.adminMode && (checklist.status === 'ok' || checklist.status === 'resolved')) {
                return;
            }
            window.$('.dsv-checklistTree__item').removeClass('active');
            this.active = checklist;
            window.lastActive = this.lastActive = checklist;
            const prevActive = document.getElementById(this.active.id);
            if (prevActive) {
                prevActive.classList.add('active');
            }
            if (checklist.type === 'point') {
                this.$emit('hide');
                const card = document.getElementById('dsv-checklist-card');
                if (card) {
                    let status = true;
                    if (checklist.status === 'resolved' || checklist.status === 'ok' || checklist.status === 'has_digressions') {
                        status = false;
                    }
                    if ( (((checklist.log || {})[0] || {}).digressions || []).length > 0 ) {
                        if (checklist.log[0].session_id !== window.sessionId) {
                            status = true;
                        } else {
                            status = false;
                        }
                    }
                    const updateEvent = new CustomEvent('pointSelected', { detail: {root: this.checklist, point: checklist, status: status } });
                    card.dispatchEvent(updateEvent);
                }
                const editPoint = document.getElementById('dsv-checklist-edit-point');
                if (editPoint) {
                    const updateEvent = new CustomEvent('update', { detail: {root: this.checklist, point: checklist } });
                    editPoint.dispatchEvent(updateEvent);
                }
            }
            const editGroup = document.getElementById('dsv-checklist-edit-group');
            if (editGroup) {
                const updateEvent = new CustomEvent('update', { detail: {root: this.checklist, group: checklist } });
                editGroup.dispatchEvent(updateEvent);
            }
            const content = document.getElementById('dsv-checklist-content');
            if (content) {
                const updateEvent = new CustomEvent('pointSelected', { detail: checklist });
                content.dispatchEvent(updateEvent);
            }
        },

        update(detail) {
            window.$('.dsv-checklistTree__item').removeClass('active');
            this.checklist = null;
            this.lastActive = this.active;
            this.active = null;
            this.readOnly = false;
            this.hasDig = false;
            if (!detail) {
                return;
            }
            if (detail.break) {
                return;
            }
            this.id = detail.id ? detail.id : window.checklistId
            if (!this.id) {
                return;
            }
            // this.active = false;
            if (detail.log) {
                this.adminMode = false;
                window.$('#app').addClass('dsv-checkmode');
                const templatesTree = document.getElementById('template_list_tmp');
                if (templatesTree) { templatesTree.style.display = 'none'; }
                const searchBlock = document.getElementById('search_block');
                if (searchBlock) { searchBlock.style.display = 'none'; }
                const objectsHeader = document.getElementById('dsv-leftNavi__header');
                if (objectsHeader) { objectsHeader.style.display = 'none'; }
                if (detail.next) {
                    this.needNext = true;
                } else {
                    this.needNext = false;
                }
                this.loading = true;
                getChecklist({id: this.id}, this.getcallback);
            } else {
                this.adminMode = true;
                this.needNext = false;
                this.loading = true;
                getChecklist({id: this.id}, this.getChecklistCallback);
            }
        },

        reset(params) {
            window.$('.dsv-checklistTree__item').removeClass('active');
            if (params.readOnly === true || params.readOnly === false) {
                this.readOnly = params.readOnly;
            }
            setTimeout( function() {
                window.$('.dsv-checklistTree__item').removeClass('active');
            }, 100 );
        },

        next() {
            this.readOnly = false;
            const self = this;
            const current = this.active || this.lastActive || window.lastActive;
            const newActive = this.getNext(self, current);
            if (newActive) {
                window.$('#' + current.id + ' > .dsv-checklistTree__item-caption').addClass('dsv-checklistTree__item-caption_ok');
                this.clickHandler(newActive);
            } else {
                const cams = document.getElementById('dsv-cams-main');
                if (cams) {
                    const updateEvent = new CustomEvent('checklistRepeat', { detail: {} });
                    cams.dispatchEvent(updateEvent);
                }
            }
        },

        getNext(self, newCur) {
            window.isFinded = false;
            const current = self.active || newCur || self.lastActive || window.lastActive;
            let finded = this.findNextIntoGroup(this.checklist.children, self, current);
            if (!finded) {
                window.isFinded = true;
                finded = this.findNextIntoGroup(this.checklist.children, self, current);
            }
            return finded;
        },

        getcallback(response) {
            window.currentChecklist2 = response.check_list;
            getChecklistLog({id: this.id}, this.getChecklistLogCallback);
        },

        findNextIntoGroup(group, self, current) {
            for (let i = 0; i < group.length; i++) {
                const child = group[i];
                if (child.type === SUBGROUP_NAME) {
                    const finded = self.findNextIntoGroup(child.children, self, current);
                    if (finded) {
                        return finded;
                    }
                } else if (child.type === POINT_NAME) {
                    if (window.isFinded) {
                        if (self.isRequiredPoint(child)) {
                            return child;
                        }
                    }
                    if (current) {
                        if (current.id === child.id) {
                            window.isFinded = true;
                        }
                    }
                }
            }
        },

        isRequiredPoint(child) {
            const self = this;
            const currentPoint = this.findPointIntoGroup(window.currentChecklist2.children, self, child.id);
            if (currentPoint) {
                if (currentPoint.available_digressions) {
                    if (currentPoint.available_digressions.length === 0) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            if (child.log.length === 0) {
                return true;
            }
            if (child.log.length > 0) {
                if (child.log[0].session_id !== window.sessionId) {
                    return true;
                } else if (
                    child.log[0].nodeg === true ||
                    !(child.log[0].status === 'resolved' || child.log[0].status === 'ok' || child.log[0].status === 'has_digressions')
                ) {
                    return true;
                }
            }
            return false;
        },

        getTime(group, self, lastSessionId) {
            if (!group) {
                return false;
            }
            for (let i = 0; i < group.length; i++) {
                const child = group[i];
                if (child.type === SUBGROUP_NAME) {
                    const finded = self.getTime(child.children, self, lastSessionId);
                    if (finded) {
                        return finded;
                    }
                } else if (child.type === POINT_NAME && child.log) {
                    if (child.log[0]) {
                        if (child.log[0].session_id === lastSessionId) {
                            return child.log[0].created_at;
                        }
                    }
                }
            }
            return false;
        },

        findFirstPoint(group, self, lastSessionId) {
            if (!group) {
                return false;
            }
            for (let i = 0; i < group.length; i++) {
                const child = group[i];
                if (child.type === SUBGROUP_NAME) {
                    const finded = self.findFirstPoint(child.children, self, lastSessionId);
                    if (finded) {
                        return finded;
                    }
                } else if (child.type === POINT_NAME) {
                    if (self.isRequiredFirstPoint(child, lastSessionId, self)) {
                        return child;
                    }
                }
            }
            return false;
        },

        isRequiredFirstPoint(child, lastSessionId, self) {
            if (window.currentChecklist2) {
                const currentPoint = self.findPointIntoGroup(window.currentChecklist2.children, self, child.id);
                if (currentPoint) {
                    if (currentPoint.available_digressions) {
                        if (currentPoint.available_digressions.length === 0) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
            if (child.log.length === 0) {
                return true;
            } else if (child.log[0].session_id !== lastSessionId) {
                return true;
            }
            return false;
        },

        // findPointIntoGroup(groups, self) {
        //     for (let i = 0; i < groups.length; i++) {
        //         const child = groups[i];
        //         if (child.type === SUBGROUP_NAME) {
        //             const finded = self.findPointIntoGroup(child.children, self);
        //             if (finded) {
        //                 return finded;
        //             }
        //         } else if (child.type === POINT_NAME) {
        //             if (self.isRequiredPoint(child, self)) {
        //                 return child;
        //             }
        //         }
        //     }
        // },
        
        findPointIntoGroup(groups, self, findedId) {
            for (let i = 0; i < groups.length; i++) {
                const child = groups[i];
                if (child.type === SUBGROUP_NAME) {
                    const finded = self.findPointIntoGroup(child.children, self, findedId);
                    if (finded) {
                        return finded;
                    }
                } else if (child.type === POINT_NAME) {
                    if (child.id === findedId) {
                        return child;
                    }
                }
            }
        },

        /** Функция обратного вызова при получении лога чек-листа. */
        getChecklistLogCallback(response) {
            this.loading = false;
            if ((response.check_list_logs || {}).session_id) {
                if (response.check_list_logs.session_id !== window.sessionId) {
                    this.checklistTmp = response.check_list_logs;
                    const lastSessionId = response.check_list_logs.session_id;
                    const self = this;
                    window.checklistLogId = this.checklistTmp.id;
                    const first = this.findFirstPoint(this.checklistTmp.children, self, lastSessionId);
                    const time = this.getTime(this.checklistTmp.children, self, lastSessionId);
                    if (first) {
                        if (new Date(time).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                            this.startNew();
                        } else {
                            this.manageModal('show');
                        }
                    } else {
                        this.startNew();
                    }
                } else {
                    this.setChecklist(response.check_list_logs);
                    this.loading = true;
                    getChecklist({id: this.id}, this.getChecklistCallback2);
                }
                getChecklist({id: this.id}, this.getChecklistCallback3);
            } else {
                this.loading = true;
                getChecklist({id: this.id}, this.getChecklistCallback);
            }
        },
        
        getChecklistCallback2(response) {
            this.loading = false;
            window.currentChecklist = window.currentChecklist2 = response.check_list;
        },

        getChecklistCallback3(response) {
            this.hasDig = true;
            window.currentChecklist2 = response.check_list;
        },

        /** Функция обратного вызова при получении чек-листа. */
        getChecklistCallback(response) {
            this.loading = false;
            console.log('getChecklistCallback');
            this.setChecklist(response.check_list);
        },

        setChecklist(checklist) {
            this.checklist = Object.assign({}, checklist);
            this.id = checklist.id;
            window.currentChecklist2 = window.currentChecklist2 || checklist;
            window.checklistId = this.id;
            const leftNavi = document.getElementById('left-navi');
            if (leftNavi) {
                const updateEvent = new CustomEvent('update', {
                    detail: {
                        checklistObj: this.obj
                    }
                });
                leftNavi.dispatchEvent(updateEvent);
            }
            if (this.needNext) {
                this.next();
            }
        },

        startNew() {
            this.setChecklist(this.checklistTmp);
            this.manageModal('hide');
        },

        continueHandler() {
            this.setChecklist(this.checklistTmp);
            window.sessionId = this.checklist.session_id;
            this.manageModal('hide');
        },

        manageModal(param) {
            window.$("#dsv-checklist-tree-modal").modal(param);
        },
    }
}
