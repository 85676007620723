<template>
    <div :ref="reference" style="position: absolute;">
        <v-tooltip
                bottom
                class="tooltip"
                offset-overflow
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on"
                     :style="`
                        transform: rotate(${position.rotate}deg);
                        width: ${scale}px;
                        left: -${scale * 3 / 4}px;
                        top: -${scale * 3 / 8}px;
                        transform-origin: ${scale * 3 / 4}px ${scale * 3 / 8}px 0;
                        `"
                     class="camera"
                >
                    <svg fill="none" viewBox="0 0 135 100" width="100%" xmlns="http://www.w3.org/2000/svg"
                         @click="selectingCamera(data)">
                        <path
                                :fill="directionColor"
                                :class="canDrag ? 'direction' : 'selecting-only'"
                                d="M27.57 1.57043C25.6174 -0.382193 22.4387 -0.391776 20.6086 1.67609C9.19779 14.5691 2.10176 30.7634 0.398431 47.9811C-1.30488 65.1987 2.48048 82.4695 11.1438 97.3485C12.5333 99.7348 15.6522 100.348 17.9496 98.8161L76.9861 59.4431C79.6108 57.6926 79.9782 53.9786 77.7474 51.7478L27.57 1.57043Z"
                                fill-opacity="0.6"
                                @mousedown="startRotating"
                        />
                        <g :class="canDrag && isTacked ? 'tacked' : canDrag && !isTacked ? 'droped' : 'selecting-only'"
                           filter="url(#filter0_d)"
                           @mousedown="drag"
                           @mousemove="moving"
                           @mouseup="drop"
                        >
                            <circle :fill="circleColor" cx="105" cy="57"
                                    r="30"
                            />
                            <path
                                    :stroke="cameraColor"
                                    d="M95.0415 60.3824L95.0415 65.1882C95.0415 65.7412 95.5113 66.1894 96.0909 66.1894L120.069 66.1895C120.649 66.1895 121.119 65.7412 121.119 65.1882L121.119 47.1158C121.119 46.5628 120.649 46.1145 120.069 46.1145L96.0909 46.1145C95.5113 46.1145 95.0415 46.5628 95.0415 47.1158L95.0415 52.1722M95.0415 60.3824L88.7098 65.0232C88.0493 65.5074 87.1187 65.0356 87.1187 64.2166L87.1187 48.5781C87.1187 47.7706 88.0263 47.2961 88.6894 47.757L95.0415 52.1722M95.0415 60.3824L95.0415 52.1722"
                                    stroke-width="2"
                            />
                        </g>
                        <defs>
                            <filter id="filter0_d" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"
                                    height="110%" width="110%" x="0"
                                    y="0">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
                                <feOffset dx="0" dy="0"/>
                                <feGaussianBlur in="offOut" result="blurOut" stdDeviation="10"/>
                                <feColorMatrix type="matrix"
                                               values="0 0 0 0 0.898039 0 0 0 0 0.898039 0 0 0 0 0.898039 0 0 0 0.5 0"/>
                                <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow"/>
                                <feBlend in="SourceGraphic" in2="effect1_dropShadow" mode="normal" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                </div>
            </template>
            <span>{{ data.name }}<br/>{{ data.ip_address }}</span>
        </v-tooltip>
    </div>
</template>

<script>
    /**
     * Camera component shows the camera on the map
     * Takes properties:
     * @data - is the Object contains information about camera location and rotation angle.
     * Required parameters:
     *    @status: is the camera status (String),
     *    @name: is the camera name (String),
     *    @coordX: is the camera coordinate by X-axis (Number),
     *    @coordY: is the camera coordinate by Y-axis (Number),
     *    @rotate: is the angle of camera rotation (Number),
     *    @ip_address: is the IP address of the camera (String),
     *
     * @mapScale - is the current scale of the map (Number);
     * @reference - is the refs of the current camera (String);
     *
     * @position - is the Object contains current map coordinates
     * at the original map scale, new rotation angle and new camera coordinates at the original map scale
     *
     * Return @position Object
     */
    export default {
        name: 'CameraIcon',
        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            },
            mapSizes: undefined,
            reference: {
                type: String,
                default: "cam1"
            },
            coverage: {
                type: Object,
                default: undefined,
            },
            status: {
                type: String,
                default: "Оффлайн"
            },
            canDrag: {
                type: Boolean,
                default: true
            },
        },
        mounted() {
            this.onComponentMount(this.data);
        },
        watch: {
            coverage: {
                immediate: true,
                deep: true,
                handler() {
                    this.onComponentMount(this.data);
                }
            },
            status: {
                immediate: true,
                deep: true,
                handler() {
                    this.cameraStatus();
                }
            },
            data: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.onComponentMount(val);
                }
            },
            ratio: {
                immediate: true,
                deep: true,
                handler(val) {
                    if (val) {
                        this.scale = 60 / this.ratio;
                    }
                }
            }
        },
        data() {
            return {
                ratio: null,
                isTacked: false,
                isRotating: false,
                cameraColor: '',
                circleColor: '',
                directionColor: '',
                position: {
                    coordX: 0,
                    coordY: 0,
                    rotate: 0,
                    newStartCoordX: 0,
                    newStartCoordY: 0
                },
                move: {
                    movementX: 0,
                    movementY: 0,
                    coordX: 0,
                    coordY: 0
                },
                minDegree: 0,
                maxDegree: 360,
                scale: 60
            }
        },
        methods: {
            onComponentMount(data) {
                /**
                 *  @ratio отношение сторон исходной картинки и масштабированной
                 **/
                this.ratio = this.mapSizes.startWidth / this.coverage.right;
                this.position.newStartCoordX = data.coordX;
                this.position.newStartCoordY = data.coordY;
                if (this.coverage && this.coverage.right >= 0 && this.coverage.bottom >= 0) {
                    this.position.coordX = Math.ceil(this.position.newStartCoordX / Math.abs(this.ratio));
                    this.position.coordY = Math.ceil(this.position.newStartCoordY / Math.abs(this.ratio));
                } else {
                    this.position.coordX = this.position.newStartCoordX;
                    this.position.coordY = this.position.newStartCoordY;
                }
                this.position.rotate = this.data.rotate;
                this.cameraLocation();
            },
            selectingCamera(value) {
                if (!this.canDrag && !value.status.toString().match('Оффлайн')) {
                    this.$emit('showMe', value)
                }
            },
            cameraStatus() {
                switch (this.status) {
                    case 'Оффлайн':
                        this.cameraColor = '#BCBCBC';
                        this.circleColor = 'white';
                        this.directionColor = '#BCBCBC';
                        break;
                    case 'Онлайн':
                        this.cameraColor = '#096866';
                        this.circleColor = 'white';
                        this.directionColor = '#096866';
                        break;
                    case 'selected':
                        this.cameraColor = 'white';
                        this.circleColor = '#096866';
                        this.directionColor = '#096866';
                        break
                }
            },
            //Drag and drop methods
            drag(event) {
                if (this.canDrag && !this.isRotating) {
                    this.isTacked = true;
                    this.move.coordX = event.touches && event.touches.length ? event.touches[0].pageX : event.pageX;
                    this.move.coordY = event.touches && event.touches.length ? event.touches[0].pageY : event.pageY;
                    document.onmousemove = this.moving;
                }
            },
            drop() {
                if (this.canDrag && !this.isRotating) {
                    this.isTacked = false;
                    document.onmousemove = null;
                    /**
                     * set new camera coordinates
                     **/
                    this.position.coordX = this.$refs[this.reference].offsetLeft;
                    this.position.coordY = this.$refs[this.reference].offsetTop;

                    this.outputData(false, true);
                }
            },
            moving(event) {
                if (this.isTacked && !this.isRotating) {
                    event.preventDefault();
                    this.move.movementX = this.move.coordX - event.clientX;
                    this.move.movementY = this.move.coordY - event.clientY;
                    this.move.coordX = event.clientX;
                    this.move.coordY = event.clientY;
                    this.$refs[this.reference].style.top = (this.$refs[this.reference].offsetTop - this.move.movementY) + 'px';
                    this.$refs[this.reference].style.left = (this.$refs[this.reference].offsetLeft - this.move.movementX) + 'px';
                }

            },
            // Rotating methods
            keepValueInRange(value, min, max) {
                return (value >= max ? value - max : (value < min ? value + max : value))
            },
            processDegree(value) {
                this.position.rotate = this.keepValueInRange(value, this.minDegree, this.maxDegree)
            },
            getPointerDeg(e) {
                let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX;
                let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY;
                return Math.floor(Math.atan2(x - (this.metrics.left + this.metrics.width / 2), y - (this.metrics.top + this.metrics.height / 2)) * (-180 / Math.PI)) + 180
            },
            startRotating(e) {
                if (this.canDrag) {
                    this.isRotating = true;
                    this.metrics = e.target.getBoundingClientRect();
                    this.pointer = this.getPointerDeg(e) - this.position.rotate;
                    document.onmousemove = this.rotation;
                }
            },
            rotation(e) {
                if (this.isRotating) {
                    let step = 1;
                    if (e.altKey) {
                        step = 45
                    } else if (e.shiftKey) {
                        step = 15
                    }
                    this.position.rotate = Math.ceil((this.getPointerDeg(e) - this.pointer) / step) * step;
                    this.processDegree(this.position.rotate);
                    document.onmouseup = this.stopRotating;
                }
            },
            stopRotating() {
                this.isRotating = false;
                document.onmouseup = null;
                document.onmousemove = null;
                this.outputData(true, false);
            },
            /** output data method
             *
             */
            outputData(rotate, move) {
                if (move) {
                    /**
                     * according to the homothety formula, we convert the coordinates to the original image size
                     */
                    this.data.coordX = Math.ceil(Math.abs(this.ratio) * this.position.coordX);
                    this.data.coordY = Math.ceil(Math.abs(this.ratio) * this.position.coordY);
                }
                if (rotate) this.data.rotate = this.position.rotate
            },
            /**
             * Camera location on map scaling
             **/
            cameraLocation() {
                if (this.$refs[this.reference] && !this.isRotating) {
                    this.$refs[this.reference].style.top = this.position.coordY + 'px';
                    this.$refs[this.reference].style.left = this.position.coordX + 'px';
                }
            },
        },
    }
</script>

<style scoped>
    .camera {
        width: 120px;
        cursor: default;
        position: absolute;
        left: -95px;
        top: -50px;
        transform-origin: 95px 50px 0;
    }

    .tacked {
        cursor: move;
    }

    .droped {
        cursor: grab;
    }

    .direction {
        cursor: url("../../assets/img/rotate_icon.svg") 8 8, pointer;
    }

    .selecting-only {
        cursor: pointer;
    }
</style>
<style>
    .v-tooltip__content {
        background: #096866c4 !important;
        font-size: 10px !important;
    }
</style>