import BaseCheckbox from './../../../base/checkbox2';
import Checkbox from './../../../base/checkbox';
import { CRON_DAYS, WEEKDAYS } from './../../../neural/utils';


import CameraList from './../cameraList';
import CameraFilter from './../cameraFilter';
import CameraHeader from './../cameraHeader';

import ArchiveSettings from './../archiveSettings';
import CamerasMonitoring from './../../../cams/camerasMonitoring';


import ModalNew from './../../../base/modalNew';
import TabsGroup from './../../../base/tabsGroup';

export default {
    name: 'Cams',
    components: {
        BaseCheckbox,
        ArchiveSettings,
        CameraList,
        CameraFilter,
        CameraHeader,
        ModalNew,
        Checkbox,
        CamerasMonitoring,
        TabsGroup,
    },
    props: ['selectedObj'],
    data: () => ({
        tabsMode: 'list',
        tabs: [
            { id: 'list', caption: 'Список' },
            { id: 'monitoring', caption: 'Мониторинг' },
        ],
        checking: false,
        useLink: false,
        useSubLink: false,
        addCam: false,
        allZones: [],
        realObjects: [],
        zones: [],
        channel2: true,
        frameSrc: false,
        object: {
            id: 0,
            parentId: 0,
            parentType: 'node',
            name: '',
            object_type: 'node',
            city: '',
            address: ''
        },
        camCard: {
            id: 0,
            object_id: null,
            zone_id: null,
            name: '',

            imgSrc: null,
            imageId: null,
            newImage: null,

            cloud_record: false,
            archive_broadcasting: false,

            ip_address: '',
            url: '',
            port: '',
            login: '',
            pass: '',

            sub_ip_address: null,
            sub_url: null,
            sub_port: null,
            sub_login: null,
            sub_pass: null,

            registrar_ip: null,
            registrar_port: null,
            registrar_protocol: null,
            registrar_login: null,
            registrar_pass: null,
            registrar_model: null,
            registrar_metadata: null,

            status: 'Онлайн',
            manufacturer: '',
            model: '',
            stream_quality: 'FullHD 1080p',
            max_resolution: '1920x1080 px',

            flag_company_name: 0,
            flag_object_name: 0,
            flag_cam_name: 0,
            flag_user_id: 0,
            flag_comment: 0,
            comment: '',

            errorAddress: '',
            errorUrl: '',
            errorPort: '',
            errorLogin: '',
            errorPass: '',
            errorObject: '',

            errorSubAddress: '',
            errorSubUrl: '',
            errorSubPort: '',
            errorSubLogin: '',
            errorSubPass: '',

            errorName: '',
            errorManufacturer: '',
            errorModel: ''
        },
        search_str: '',
        selecting: false,
        selectedAll: false,
        selectingCameras: [],
        camNewCard: {
            id: 0,

            cloud_record: false,
            archive_broadcasting: false,

            object_id: null,
            zone_id: null,
            name: '',

            ip_address: '',
            url: '',
            port: '',
            login: '',
            pass: '',

            sub_ip_address: null,
            sub_url: null,
            sub_port: null,
            sub_login: null,
            sub_pass: null,

            registrar_ip: null,
            registrar_port: null,
            registrar_protocol: null,
            registrar_login: null,
            registrar_pass: null,
            registrar_model: null,
            registrar_metadata: null,

            status: 'Онлайн',
            manufacturer: '',
            model: '',
            stream_quality: 'FullHD 1080p',
            max_resolution: '1920x1080 px',

            flag_company_name: 0,
            flag_object_name: 0,
            flag_cam_name: 1,
            flag_user_id: 0,
            flag_comment: 0,
            comment: '',

            errorAddress: '',
            errorUrl: '',
            errorPort: '',
            errorLogin: '',
            errorPass: '',
            errorObject: '',

            errorSubAddress: '',
            errorSubUrl: '',
            errorSubPort: '',
            errorSubLogin: '',
            errorSubPass: '',

            errorName: '',
            errorManufacturer: '',
            errorModel: ''
        },
        cams: [
        ],
        showRegisterSettings: false,
        timerId: null,
        useSmart: false,
        useArchive: false,
        archiveSettings: {
            step: null,
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            auto_renewal: false,
            position_ids: []
        },
        camRunningArchives: {},
        camRunningArchivesSmart: {},
        commonSelected: {
            step: null,
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            auto_renewal: false,
            days: {
                'mon': false,
                'tue': false,
                'wed': false,
                'thu': false,
                'fri': false,
                'sat': false,
                'sun': false,
            }
        },
        selected: {
            step: null,
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            auto_renewal: false,
            position_ids: []
        },
        steps: [
            { id: '1', caption: '3 сек.', value: '/3', seconds: true, step: 3 },
            { id: '2', caption: '5 сек.', value: '/5', seconds: true, step: 5 },
            { id: '3', caption: '15 сек.', value: '/15', seconds: true, step: 15 },
            { id: '4', caption: '30 сек.', value: '/30', seconds: true, step: 30 },
            { id: '5', caption: '1 мин.', value: '/1', minutes: true, step: 60 },
            { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
            { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
            { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
            { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
            { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
            { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
            { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
        ],
        weekdays: [
            { id: 'mon', caption: 'Пн', title: 'Понедельник' },
            { id: 'tue', caption: 'Вт', title: 'Вторник' },
            { id: 'wed', caption: 'Ср', title: 'Среда' },
            { id: 'thu', caption: 'Чт', title: 'Четверг' },
            { id: 'fri', caption: 'Пт', title: 'Пятница' },
            { id: 'sat', caption: 'Сб', title: 'Суббота' },
            { id: 'sun', caption: 'Вс', title: 'Воскресенье' },
        ],
        days: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false,
        },
        days2: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false,
        },
        times: [
            { value: '00:00', caption: '0:00' },
            { value: '01:00', caption: '1:00' },
            { value: '02:00', caption: '2:00' },
            { value: '03:00', caption: '3:00' },
            { value: '04:00', caption: '4:00' },
            { value: '05:00', caption: '5:00' },
            { value: '06:00', caption: '6:00' },
            { value: '07:00', caption: '7:00' },
            { value: '08:00', caption: '8:00' },
            { value: '09:00', caption: '9:00' },
            { value: '10:00' },
            { value: '11:00' },
            { value: '12:00' },
            { value: '13:00' },
            { value: '14:00' },
            { value: '15:00' },
            { value: '16:00' },
            { value: '17:00' },
            { value: '18:00' },
            { value: '19:00' },
            { value: '20:00' },
            { value: '21:00' },
            { value: '22:00' },
            { value: '23:00' },
            { value: '24:00' }
        ],

        runningSmarts: {},
        runningArchives: {},
        camRunningSmarts: [],
        camRunningNewSmarts: [],
        positions: [],
        showMeta: false,
        meta: {
            key: null,
            value: null
        },
        metadata: [],

        showFrame: false,
        
        showSmartModal: false,
        showStopSmartModal: false,
        selectingCamerasCards: [],
        archiveEnabled: false,
        smartArchiveEnabled: false,

        smartsToSave: [],
        smartsToStop: [],
    }),
    mounted() {
        
        this.archiveEnabled = (window.features.find( feature => feature.feature_name === 'archive') || {}).enabled;
        this.smartArchiveEnabled = (window.features.find( feature => feature.feature_name === 'smart archive') || {}).enabled;
        this.getCams()
        this.getObjects()
        if (this.selectedObj) {
            window.$('div[node_id='+this.selectedObj.id+']:first').addClass('opened');
            window.$('div[node_id='+this.selectedObj.id+']:first>p:first').addClass('active');
            this.showObjectCard(this.selectedObj);
            if(this.selectedObj.parent_id) {
                this.openTreeObjects(this.getParentObject(this.selectedObj))
            }
        }
    },
      computed: {
          itogUrl() {
            if(this.isUrlBased()) { 
                if(this.camCard.url.indexOf('://') != -1) {
                    return this.camCard.url;
                } 
                return 'rtsp://' + this.camCard.url; 
            }
            if (this.useLink) {
                return 'rtsp://' + this.camCard.url;
            } else {
                return 'rtsp://' + this.camCard.login + ':' + this.camCard.pass +
                  '@' + this.camCard.ip_address + ':' + this.camCard.port;
            }
            // return 'rtsp://' + this.camCard.login + ':' + this.camCard.pass +
                //   '@' + this.camCard.ip_address + ':' + this.camCard.port + this.camCard.url
          },
          itogSubUrl() {
              return 'rtsp://' + this.camCard.sub_login + ':' + this.camCard.sub_pass +
                  '@' + this.camCard.sub_ip_address + ':' + this.camCard.sub_port + this.camCard.sub_url
          },
          cloud_recordStr () {
              let res
              if(this.camCard.cloud_record)
                  res = 'Записывать видео в облачный архив'
              else
                  res = 'Не записывать потоки с камеры'
              return res
          },
          /*
          archive_broadcastingStr() {
              let res
              if(this.camCard.archive_broadcasing)
                  res = 'Показ архива из облака'
              else
                  res = 'Показ локального архива видеорегистратора'
              return res
          }
           */

      },
    methods: {
        tabsHandler(mode) {
            this.tabsMode = mode;
        },
        addMeta() {
            this.showMeta = true;        
        },
        cancelMeta() {
            this.showMeta = false;
            this.meta = {
                meta: null,
                value: null
            };
        },

        saveMeta() {
            this.metadata.push(this.meta);
            this.cancelMeta();
        },
        deleteMeta(meta) {
            const index = this.metadata.findIndex( b => b.key == meta.key);
            this.metadata.splice(index, 1);
        },
        toggleShowRegisterSettings() {
            this.showRegisterSettings = !this.showRegisterSettings;
            console.log('showRegisterSettings', this.showRegisterSettings)
        },
        getPositions() {
            this.positions = [];
            if (this.camCard.ptz_enabled) {
                window.myAjax('GET', '/ptz/positions', {
                    camera_id: this.camCard.id
                }, this.getPositionsCallback);
            }
        },
        getPositionsCallback(error, response) {
            if (!error) {
                this.positions = response;
            }
        },
        openTreeObjects(obj) {
            window.$('div[node_id='+obj.id+']:first').addClass('opened')
            if(obj.parent_id)
            this.openTreeObjects(this.getParentObject(obj))
        },
        getParentObject(obj) {
            for(let i=0; i<window.myObjects.length; i++)
            if(window.myObjects[i].id==obj.parent_id)
                return window.myObjects[i]
            return null
        },
        toggleArchive_broadcasting() {              
            this.camCard.archive_broadcasting != this.camCard.archive_broadcasting;
        },
        toggleFlag(name) {
            const obj = {};
            obj[name] = this.camCard[name] ? 0 : 1;
            this.camCard = Object.assign({}, this.camCard, obj);
        },
        getZoneRoot(parent_id) {
            for (let i = 0; i < window.myObjects.length; i++) {
                const obj = window.myObjects[i];
                if (obj.id == parent_id) {
                    if (obj.object_type == 'object') {
                        return obj;
                    } else if (obj.object_type == 'zone') {
                        return this.getZoneRoot(obj.parent_id);
                    }
                }
            }
            return null;
        },
        commonRenewalClick(checked) {
            const obj = {};
            obj.auto_renewal = checked;
            this.commonSelected = Object.assign({}, this.commonSelected, obj);
        },
        autoRenewalClick2() {
            const obj = {};
            obj.auto_renewal = !this.archiveSettings.auto_renewal;
            this.archiveSettings = Object.assign({}, this.archiveSettings, obj);
        },
        // autoRenewalClick() {
        //     const obj = {};
        //     obj.auto_renewal = !this.selected.auto_renewal;
        //     this.selected = Object.assign({}, this.selected, obj);
        // },
        showCamCard(cam) {
            this.camCard = Object.assign({}, cam)
            this.camRunningSmarts = [];
            this.camRunningNewSmarts = [];
            this.camRunningArchives = {};
            this.camRunningArchivesSmart = {};
            this.useArchive = false;
            this.useSmart = false;
            this.channel2 = true;
            if (this.camCard.id) {
                window.myAjax('GET', '/api/camera', {
                    camera_id: this.camCard.id
                },(error, response) => {
                    if (!error) {
                        this.camCard = Object.assign({}, this.camCard, response.camera);
                        this.useLink = !!this.camCard.url;
                    }
                })
                window.myAjax('GET', '/archive/running', {
                    camera_id: this.camCard.id
                }, (error, response) => {
                    if (!error) {
                        this.useArchive = false;
                        this.camRunningArchives = ((response || {}).constructor || {}).name === 'Object' ? response : {};
                        for (let key in this.camRunningArchives) {
                            const run = this.camRunningArchives[key];
                            if (run.status !== 'stopped') {
                                this.useArchive = true;
                            }
                        }
                    }
                })

                window.myAjax('GET', '/smart_archive/running', {
                    camera_id: this.camCard.id
                }, (error, response) => {
                    if (!error) {
                        this.useSmart = false;
                        this.camRunningArchivesSmart = ((response || {}).constructor || {}).name === 'Object' ? response : {};
                        for (let key in this.camRunningArchivesSmart) {
                            const run = this.camRunningArchivesSmart[key];
                            if (run.status !== 'stopped') {
                                this.useSmart = true;
                            }
                        }
                    }
                })
            }
            
            if ((this.camCard || {}).id) {
                this.getPositions();
                window.myAjax('POST', '/preview/start', {
                    camera_id: this.camCard.id
                }, () => {});
            }
            this.addCam = true;
            if (!this.camCard.id) {
                this.useLink = true;
            }
            this.useSubLink = !!this.camCard.subUrl;
            this.camCard.imgSrc = '/preview/img?camera_id=' + this.camCard.id;
            this.camCard.newImage = new Image()
            this.camCard.imageId = 'myImg' + this.camCard.id;

            this.metadata = [];

            if (this.camCard.registrar_metadata) {
                const metadata = JSON.parse(this.camCard.registrar_metadata);
                for (const key in metadata) {
                    this.metadata.push({
                        key: key,
                        value: metadata[key]
                    })
                }
            }

            if (this.camCard.id === 0) {
                if ((this.selectedObj || {}).object_type === 'zone') {
                    const parent = this.getZoneRoot(this.selectedObj.parent_id);
                    this.camCard.object_id = parent.id;
                } else {
                    this.camCard.object_id = this.selectedObj.id;
                }
            }

            if (this.camCard.status == 'Онлайн') {
                this.timerId = window.setTimeout(this.updateImage, 3000);
            }
            else {
                window.clearTimeout(this.timerId)
            }

            if (this.camCard.object_id) {
                this.zones = this.allZones.filter(zone => zone.parent_id == this.camCard.object_id)
                if ((this.selectedObj || {}).object_type === 'zone') {
                    this.camCard.zone_id = this.selectedObj.id;
                }
            }
            if(this.camCard.sub_ip_address)
                this.channel2 = false
            else
                this.channel2 = true
        },
        updateImage() {
            if(this.camCard.newImage.complete && this.camCard.imageId) {
                document.getElementById(this.camCard.imageId).src = this.camCard.newImage.src;
                this.camCard.newImage = new Image();
                this.camCard.newImage.src = this.camCard.imgSrc+"&time=" + new Date();
            }
            this.timerId = window.setTimeout(this.updateImage, 3000);
        },
        showNewCamCard() {
            this.camCard = Object.assign({}, this.camNewCard)
            this.addCam = true
            if (this.camCard.object_id) {
                this.zones = this.allZones.filter(zone => zone.parent_id == this.camCard.object_id)
            }
        },
        toggleLink() {
            const tmpUseLink = !this.useLink;
            if (tmpUseLink) {
                window.myConfirm('Внимание!', 'Использование ссылки удалит данные для подключения к камере (IP, порт, логин, пароль).', () => {
                    this.camCard.port = null;
                    this.camCard.ip_address = null;
                    this.camCard.login = null;
                    this.camCard.pass = null;
                    this.camCard.url = '';
                    this.useLink = true;
                })
            } else {
                window.myConfirm('Внимание!', 'Ссылка будет удалена.', () => {
                    this.camCard.port = '';
                    this.camCard.ip_address = '';
                    this.camCard.login = '';
                    this.camCard.pass = '';
                    this.camCard.url = null;
                    this.useLink = false;
                })
            }
            
        },
        checkInfo() {
            if (this.useLink) {
                this.checking = true;
                window.myAjax('GET', '/api/camera/info', {
                    url: this.itogUrl,
                }, (error, response) => {
                    this.checking = false;
                    if (!error) {
                        window.myAlert((response || {}).message ? (response || {}).message || 'Не можем подключится по ссылке, проверьте правильность.' : 'Ссылка рабочая');
                    } else {
                        window.myAlert('Не можем подключится к камере, проверьте правильность данных.');
                    }
                });
            }
        },
        checkLink() {
            if (this.useLink) {
                this.checking = true;
                window.myAjax('GET', '/api/camera/test_url', {
                    url: this.itogUrl,
                }, (error) => {
                    this.checking = false;
                    if (!error) {
                        this.showFrame = true;
                        this.frameSrc = `/api/camera/test_url?url=${this.itogUrl}`;                        
                    } else {
                        window.myAlert('Не можем подключится по ссылке, проверьте правильность данных.');
                    }
                    // this.checking = false;
                    // if (!error) {
                    //     if (response) {
                    //         this.frameSrc = 'data:image/jpeg;base64,' + Buffer.from(response).toString('base64');
                    //     }
                    // } else {
                    //     window.myAlert('Не можем подключится по ссылке, проверьте правильность данных.');
                    // }
                });
            } else {
                this.checking = true;
                window.myAjax('GET', '/api/camera/onvif_availability', {
                    ip: this.camCard.ip_address,
                    port: this.camCard.port,
                    login: this.camCard.login,
                    password: this.camCard.pass
                }, (error, response) => {
                    this.checking = false;
                    if (!error) {
                        window.myAlert(response.is_available ? 'Ссылка рабочая' : 'Не можем подключится по ONVIF, проверьте правильность данных.');
                    } else {
                        window.myAlert('Не можем подключится по ONVIF, проверьте правильность данных.');
                    }
                });
            }
        },
        hideCamCard() {
            this.addCam = false
            this.useSmart = false;
            this.useArchive = false;
            if ((this.camCard || {}).id) {
                window.myAjax('POST', '/preview/stop', {
                    camera_id: this.camCard.id
                }, () => {});
            }
        },
        showObjectCard(obj) {
            if(obj==null)
                this.showNewCamCard()
            else {
                this.object = obj
                this.hideCamCard()
            }
        },
        changeInputClass() {
            this.saveClass='changed'
            if(this.object.name.length)
                this.nameClass='filled'
            else
                this.nameClass=''
            if(this.object.address.length)
                this.addressClass='filled'
            else
                this.addressClass=''
            if(this.object.city.length)
                this.cityClass='filled'
            else
                this.cityClass=''
        },
        toggleCamStatus(camera) {
            window.myAjax('GET', '/api/camera', {
                camera_id: camera.id
            },(error, response) => {
                if (!error) {
                    const updatedCamera = Object.assign({}, response.camera);
                    updatedCamera.status = updatedCamera.status === 'Онлайн' ? 'Оффлайн' : 'Онлайн';
                    window.myAjax('PUT', '/api/camera', updatedCamera, (updateError, updateResponse) => {
                        if (updateError) {
                            window.myAlert(updateResponse.message)
                        } else {
                            this.getAllCams();
                        }
                    })
                }
            })
        },
        saveCam() {
            if (this.camCard.id) {
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения камеры?', this.saveCam2)
            } else {
                this.saveCam2()
            }
        },
        saveCam2() {
            if(this.valideCam()) {
                let url = '/api/camera',
                    method = 'POST';
                if (this.camCard.id) {
                    url = '/api/camera';
                    method = 'PUT';
                }
                if (this.camCard.cloud_record)
                    this.camCard.cloud_record = 1
                else
                    this.camCard.cloud_record = 0
                if (this.channel2) {
                    this.camCard.sub_ip_address = null
                    this.camCard.sub_login = null
                    this.camCard.sub_pass = null
                    this.camCard.sub_port = null
                    this.camCard.sub_url = null
                }
                window.clearTimeout(this.timerId)
                this.camCard.newImage = null
                if (this.camCard.object_id === 'null') {
                    if (this.object.id !== 0 && this.object.object_type === "object") {
                        this.camCard.object_id = this.object.id;
                    } else {
                        this.camCard.object_id = null;
                    }
                }
                if (this.camCard.zone_id === 'null') {
                    if (this.object.id !== 0 && this.object.object_type === "zone") {
                        this.camCard.zone_id = this.object.id;
                    } else {
                        this.camCard.zone_id = null
                    }
                }
                
                
                if (this.metadata.length) {
                    const metadata = {};
                    this.metadata.forEach( meta => {
                        metadata[meta.key] = meta.value;
                    });
                    this.camCard.registrar_metadata = JSON.stringify(metadata);
                } else {
                    this.camCard.registrar_metadata = null;
                }
                if (this.useArchive) {
                    this.$refs['ArchiveSettingsRef'].save();
                } else {
                    for (let key in this.camRunningArchives) {
                        const run = this.camRunningArchives[key];
                        if (run.status !== 'stopped') {
                            window.myAjax('POST', '/archive/stop', {
                                record_id: run.record_id,
                                camera_id: this.camCard.id
                            }, () => {});
                        }
                    }
                }
                if (this.useSmart) {
                    this.$refs['SmartArchiveSettingsRef'].save();
                } else {
                    for (let key in this.camRunningArchivesSmart) {
                        const run = this.camRunningArchivesSmart[key];
                        if (run.status !== 'stopped') {
                            window.myAjax('POST', '/smart_archive/stop', {
                                record_id: run.record_id,
                                camera_id: this.camCard.id
                            }, () => {});
                        }
                    }
                }
                window.myAjax(method, url, this.camCard, this.myCallbackSaveCam)
            }
        },
        myCallbackSaveCam (error, response) {
            window.myAlert(response.message)
            if (!response.error) {
                this.getAllCams()
                if(!this.camCard.id)
                    this.camCard.id = response.camera.id
//                this.$emit('updateObjectsListFromObjects', false)
                this.addCam = false;
                window.myAjax('POST', '/preview/stop', {
                    camera_id: this.camCard.id
                }, () => {});
            }
            this.camCard.newImage = new Image()
            this.timerId = window.setTimeout(this.updateImage, 3000);
        },
        deleteCam() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить камеру?', this.deleteCam2)
        },
        filterCamerasHandler(search_str) {
            this.search_str = search_str;
        },
        deleteCam2() {
            let url = '/api/camera',
                method = 'DELETE';
            window.clearTimeout(this.timerId)
            this.camCard.newImage = null
            window.myAjax(method, url, this.camCard, this.myCallbackDeleteCam)
        },
        myCallbackDeleteCam (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myAlert(response.message)
//                this.$emit('updateObjectsListFromObjects', false)
//                this.$emit('addRootObjectFromObjects', false)
            }
            this.getAllCams()
            this.addCam = false;
            if ((this.camCard || {}).id) {
                window.myAjax('POST', '/preview/stop', {
                    camera_id: this.camCard.id
                }, () => {});
            }
        },
        getAllCams() {
            let data = {},
                url = '/api/client-cameras',
                method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartC)
        },
        myCallbackGetStartC (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myCams = response.client_cameras
                this.getCams()
            }
        },
        myCallbackEmpty (error, response) {
            if(error) {
                window.myAlert(response.message)
            }
        },

        getCams() {
            this.cams = window.myCams
        },

        isUrlBased() {
            return ((!this.camCard.login || this.camCard.login.length == 0) && (!this.camCard.ip_address || this.camCard.ip_address.length == 0) && (this.camCard.url && this.camCard.url.length != 0))
        },

        valideCam() {
            if(this.isUrlBased())  { return true }

            let res = true;
            if(this.camCard.name.length) {
                this.camCard.errorName = ''
            } else {
                res = false
                this.camCard.errorName = 'Не указано название'
            }
            if(this.camCard.ip_address.length) {
                this.camCard.errorAddress = ''
            } else {
                res = false
                this.camCard.errorAddress = 'Не указан ip камеры'
            }
            if(this.camCard.port.length) {
                this.camCard.errorPort = ''
            } else {
                res = false
                this.camCard.errorPort = 'Не указан порт'
            }
            if(this.camCard.object_id !== null) {
                this.camCard.errorObject = ''
            } else {
                res = false
                this.camCard.errorObject = 'Не указан объект. Если необходимо - укажите зону'
            }
            if(this.camCard.login.length) {
                this.camCard.errorLogin = ''
            } else {
                res = false
                this.camCard.errorLogin = 'Не указан логин'
            }
            if(this.camCard.pass.length) {
                this.camCard.errorPass = ''
            } else {
                res = false
                this.camCard.errorPass = 'Не указан пароль'
            }
            if(!this.useLink || this.camCard.url.length) {
                this.camCard.errorUrl = ''
            } else {
                res = false
                this.camCard.errorUrl = 'Не указан url'
            }

            if(!this.channel2) {
                if(this.camCard.sub_ip_address.length) {
                    this.camCard.errorSubAddress = ''
                } else {
                    res = false
                    this.camCard.errorSubAddress = 'Не указан ip камеры'
                }
                if(this.camCard.sub_port.length) {
                    this.camCard.errorSubPort = ''
                } else {
                    res = false
                    this.camCard.errorSubPort = 'Не указан порт'
                }
                if(this.camCard.sub_login.length) {
                    this.camCard.errorSubLogin = ''
                } else {
                    res = false
                    this.camCard.errorSubLogin = 'Не указан логин'
                }
                if(this.camCard.sub_pass.length) {
                    this.camCard.errorSubPass = ''
                } else {
                    res = false
                    this.camCard.errorSubPass = 'Не указан пароль'
                }
                if(!this.useSubLink || this.camCard.sub_url.length) {
                    this.camCard.errorSubUrl = ''
                } else {
                    res = false
                    this.camCard.errorSubUrl = 'Не указан url'
                }
            }
            return res
        },

        getObjects() {
            this.allZones = window.myObjects.filter(object=>object.object_type=='zone')
            this.realObjects = window.myObjects.filter(object=>object.object_type=='object')
        },
        setSelectObjectZones() {
            this.camCard.zone_id = null
            this.zones = this.allZones.filter(zone=>zone.parent_id==this.camCard.object_id)
        },
        getObjectName(id) {
            for(let i=0; i<this.allZones.length; i++) {
                if(this.allZones[i].id==id)
                    return this.allZones[i].name
            }
            return ''
        },
        showModalCamFlags(show) {
            window.$("#modalCamFlags").modal(show)
        },


        getSchedule(archive) {
            const step = archive.step;
            let result = '';
            //минуты
            if (step.seconds) {
                result += '* ';
            } else {
                if (step.minutes) {
                    result += '*' + step.value + ' ';
                } else {
                    result += '0 ';
                }
            }
            //часы
            const hours = [];
            const startTime = parseInt(archive.startTime.slice(0, 2));
            const startEnd = parseInt(archive.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(archive.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.filter( d => (!!d || d === 0 || d === '0') ).sort().join(',');
            } else {
                result += '* ';
            }
            //секунды
            if (step.seconds) {
                result += ' *' + step.value;
            }
            return result;
        },
        
        convertDateToUTC(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },
        getFormat(date) {
            return this.getFormatValue(date.getFullYear()) + "-"
                + this.getFormatValue((date.getMonth()+1))  + "-"
                + this.getFormatValue(date.getDate()) + " "
                + this.getFormatValue(date.getHours()) + ":"
                + this.getFormatValue(date.getMinutes()) + ":"
                + this.getFormatValue(date.getSeconds());
        },
        getFormatValue(timeValue) {
            const str = timeValue.toString();
            return str.length === 1 ? '0' + str : str;
        },

        stopSmartArchive(id) {
            window.myAjax('POST', '/smart_archive/stop', {
                record_id: id,
                camera_id: this.camCard.id
            }, () => {});
        },

        removeNewSmart(smart, revert) {
            const index = this.camRunningNewSmarts.findIndex( b => b.id == smart.id);
            if (index < 0 ) {
                return;
            }
            this.camRunningNewSmarts.splice(index, 1);
            const deleted = Object.assign({}, smart, {
                ui_status: revert ? null : 'deleted'
            });
            this.camRunningNewSmarts.splice(index, 0, deleted)
        },

        removeSmart(smart, revert) {
            const index = this.camRunningSmarts.findIndex( b => b.id == smart.id);
            if (index < 0 ) {
                return;
            }
            this.camRunningSmarts.splice(index, 1);
            const deleted = Object.assign({}, smart, {
                ui_status: revert ? null : 'deleted'
            });
            this.camRunningSmarts.splice(index, 0, deleted)
        },

        postAllShedule() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите применить расписание ко всем камерам?', () =>{
                const days = {}
                // const days = [];
                let alldays = true;
                for (const [key, value] of Object.entries(this.days)) {
                    if (value === true) {
                        console.log(key)
                        alldays = false;
                        break;
                    }
                }
                for (const [key, value] of Object.entries(this.days)) {
                    if (value || alldays) {
                        days[key] = true;
                    }
                }
                const smart = Object.assign({}, this.selected, {
                    ui_days: days,
                    days: this.days,
                });
                const schedule = this.getSchedule(smart);

                if (this.camCard) {
                    this.camCard.smart_archive_schedule = schedule;
                }

                window.myAjax('POST', '/smart_archive/schedule', {
                    schedule: schedule
                }, this.postAllSheduleCallback);
            })
        },

        postAllSheduleCallback(error) {
            if(error) {
                window.myAlert('Произошла ошибка. Попробуйте позже.');
            } else {
                this.getAllCams();
                window.myAlert('Расписание применено.');
            }
        },
        closeSmartModal() {
            this.showSmartModal = false;
            this.selecting = false;
            this.selectingCameras = [];
            this.selectingCamerasCards = [];
        },
        closeStopSmartModal() {
            this.showStopSmartModal = false;
            this.selecting = false;
            this.selectingCameras = [];
            this.selectingCamerasCards = [];
        },
        
        stopSmarts() {
            this.smartsToStop = [];
            window.myAjax('GET', '/smart_archive/running', {}, (error, response) => {
                if (!error) {
                    for (const taskId in response) {
                        if (this.selectingCamerasCards.findIndex( camera => camera.id == response[taskId].camera_id) >= 0) {
                            this.smartsToStop.push({
                                record_id: taskId,
                                camera_id: response[taskId].camera_id,
                            });
                        }
                    }
                    this.stopOneCamSmart();
                }
            })
        },
        saveSmarts() {
            this.smartsToSave = [];
            this.selectingCamerasCards.forEach( camera => {
                this.smartsToSave.push(camera.id);
            })
            this.saveOneCamSmart();
            console.log('schedule:', this.commonSelected);
        },
        stopOneCamSmart() {
            if (this.smartsToStop.length) {
                const _smartsToStop = this.smartsToStop.pop();
                window.myAjax('POST', '/smart_archive/stop', _smartsToStop, () => {
                    this.stopOneCamSmart();
                });
            } else {
                window.myAlert('Смарт-архивы остановлены.');
                this.closeStopSmartModal();
            }
        },
        saveOneCamSmart() {
            if (this.smartsToSave.length) {
                const _smartsToSave = this.smartsToSave.pop();

                let startTime = this.convertDateToUTC(new Date(this.commonSelected.startDate + 'T' + this.commonSelected.startTime));
                let endTime = this.convertDateToUTC(new Date(this.commonSelected.endDate + 'T' + this.commonSelected.endTime));
                if ( endTime.getTime() < startTime.getTime() ) {
                    window.myAlert('Неверные даты.');
                    return;
                }

                let data = Object.assign({
                    start_datetime: this.getFormat(startTime),
                    stop_datetime: this.getFormat(endTime),
                    auto_renewal: this.commonSelected.auto_renewal,
                    schedule: this.createSchedule(this.commonSelected),
                    camera_id: _smartsToSave
                }, {});
                console.log('saveOneCamSmart', _smartsToSave, data);
                window.myAjax('POST', '/smart_archive/start', data, (error) => {
                    if (!error) {
                        this.saveOneCamSmart();
                    }
                });
            } else {
                window.myAlert('Смарт-архивы запущенны.');
                this.closeSmartModal();
            }
        },
        setCommonDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.commonSelected.days['mon'] = this.commonSelected.days['tue'] = this.commonSelected.days['wed'] = this.commonSelected.days['thu'] = this.commonSelected.days['fri'] = true;
                this.commonSelected.days['sat'] = this.commonSelected.days['sun'] = false;
            } else if (dayKey === 'all') {
                for (const [id] of Object.entries(WEEKDAYS)) {
                    this.commonSelected.days[id] = true;
                }
            } else {
                const keyDays = {};
                keyDays[dayKey] = !this.commonSelected.days[dayKey];
                this.commonSelected.days = Object.assign({}, this.commonSelected.days, keyDays);
            }
        },
        selectActionHandler(data) {
            console.log('selectActionHandler', data, this.selectingCameras);
            switch(data) {
                case 'start-smart-archive':
                    this.showSmartModal = true;
                    this.selectingCamerasCards = [];

                    this.commonSelected = Object.assign({}, {
                        step: this.steps[5],
                        startDate: null,
                        endDate: null,
                        startTime: '08:00',
                        endTime: '21:00',
                        auto_renewal: false,
                        days: {
                            'mon': false,
                            'tue': false,
                            'wed': false,
                            'thu': false,
                            'fri': false,
                            'sat': false,
                            'sun': false,
                        }
                    })
                    
                    var now = new Date();
                    now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
                    this.commonSelected.startDate = now.toISOString().slice(0,10);
                    now.setMinutes(now.getMinutes() + 60);
                    this.commonSelected.endDate = now.toISOString().slice(0,10);

                    this.cams.forEach(cam => {
                        if (this.selectingCameras.includes(cam.id)) {
                            this.selectingCamerasCards.push(Object.assign({}, cam));
                        }
                    });
                    break;
                case 'stop-smart-archive':
                    this.showStopSmartModal = true;
                    this.selectingCamerasCards = [];
                    this.cams.forEach(cam => {
                        if (this.selectingCameras.includes(cam.id)) {
                            this.selectingCamerasCards.push(Object.assign({}, cam));
                        }
                    });
                    break;
                default:
                    break;
            }
        },
        userCard() {
            // todo
        },
        allUsersList() {
            // todo
        },
        filterUsers() {
            // todo
        },
        toggleSelecting() {
            this.selecting = !this.selecting;
            this.selectingCameras = [];
            this.selectedAll = false;
        },
        selectingAllHandler() {
            if (this.selecting) {
                this.selectedAll = !this.selectedAll;
            }
            const activeCamerasIds = this.$refs['CameraListRef'].getFilteredCameras();
            this.selectingCameras = [];
            if (this.selectedAll) {
                this.cams.filter( camera => camera.status === 'Онлайн' ).forEach( camera => {
                    if (activeCamerasIds.includes(camera.id) ) {
                        this.selectingCameras.push(camera.id);
                    }
                })
            }
        },
        selectingHandler(data) {
            this.selectingCameras = data;
            const activeCamerasIds = this.$refs['CameraListRef'].getFilteredCameras();
            const availableCameras = this.cams.filter( camera => camera.status === 'Онлайн' && activeCamerasIds.includes(camera.id) );
            this.selectedAll = availableCameras.length === this.selectingCameras.length;
        },
        createSchedule(task) {
            const step = task.step;
            let result = '';
            //минуты
            if (step.seconds) {
                result += '* ';
            } else {
                if (step.minutes) {
                    result += '*' + step.value + ' ';
                } else {
                    result += '0 ';
                }
            }
            //часы
            const hours = [];
            const startTime = parseInt(task.startTime.slice(0, 2));
            const startEnd = parseInt(task.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd + 1; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(task.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.filter( d => (!!d || d === 0 || d === '0') ).sort().join(',');
            } else {
                result += '* ';
            }
            //секунды
            if (step.seconds) {
                result += ' *' + step.value;
            }
            return result;
        },

        updateResolution() {
            window.myAjax('POST', '/api/camera/update_info', {
                camera_id: this.camCard.id
            },(error, response) => {
                if (!error) {
                    window.myAlert('Успешно обновлено разрешение камеры')
                } else {
                    window.myAlert((response || {}).message || 'Не удалось обновить информацию о разрешении камеры. Проверьте правильность введенных данных.')
                }
            })
        }
    }
}
