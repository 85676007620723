import CheckboxListDropdown from './../../../base/checkboxListDropdown';
import ModalNew from './../../../base/modalNew';
import DigressionsSchedule from './../../../reports/digressionsSchedule';

export default {
    name: 'CamerasMonitoringList',
    components: {
        CheckboxListDropdown,
        ModalNew,
        DigressionsSchedule,
    },
    props: {
        activeObject: {
            type: Object,
            default: null
        },
        clientId: {
            type: Number,
            default: null
        },
    },
    data: () => ({
        loading: false,
        camerasLogs: [],
        filteredCamerasLogs: [],
        minTime: Number.MAX_SAFE_INTEGER,
        maxTime: 0,
        start_date: 0,
        end_date: 0,
        type: 0,

        objects: [],
        selectedObjects: [],

        modalSchedule: false,
    }),
    watch: {
        cameras: {
            handler() {
                // 
            }
        },
        activeObject: {
            handler() {
                // this.filterLogs();
            }
        }
    },
    mounted() {
        this.objects = window.myObjects.filter(object => object.object_type=='object');
        this.end_date = new Date();
        this.start_date = new Date();
        this.start_date.setHours(this.start_date.getHours() - 2 * 24);
        this.start_date = this.start_date.toISOString().substring(0,10);
        this.end_date = this.end_date.toISOString().substring(0,10);
        this.getReports();
    },
    methods: {
        selectedObjectsHandler(data) {
            this.selectedObjects = data;
        },
        applyFilter() {
            this.getReports();
        },
        getReports() {
            if (this.start_date > this.end_date) {
                window.myAlert('Недопустимый диапазон дат');
                return;
            }
            const start = new Date(this.start_date + ' 00:00:00');
            const end = new Date(this.end_date + ' 23:59:59');
            this.loading = true;
            window.myAjax('GET', '/analytic/camera_log/report_data', {
                start: start.toISOString().slice(0,19).replace(/T/g," "),
                end: end.toISOString().slice(0,19).replace(/T/g," "),
                object_ids: JSON.stringify(this.selectedObjects),
            },(error, response) => {
                this.loading = false;
                this.reports = [];
                if (this.type == 0) {
                    this.reports = response || [];
                    this.reports.forEach(report => {
                        (report.cameras || []).forEach(camera => {
                            if (camera.total) {
                                camera.percent = Math.floor( (camera.available_count / camera.total) * 10000 ) / 100;
                            }
                        })
                    })
                } else if (this.type == 1) {
                    (response || []).forEach(report => {
                        this.reports.push(Object.assign({}, report));
                        this.reports[this.reports.length-1].cameras = [];
                        (report.cameras || []).forEach(camera => {
                            camera.percent = Math.floor( (camera.available_count / camera.total) * 10000 ) / 100;
                            if (
                                camera.percent <= 80 ||
                                camera.wrong_link ||
                                camera.port_unavailable
                            ) {
                                this.reports[this.reports.length-1].cameras.push(camera);
                            }
                        })
                    })
                }
                if (error && error != 'Not Found') {
                    window.myAlert('Не удалось получить информацию. Пожалуйста, обратитесь к администратору.');
                }
            })
        },

        getExcel() {
            if (this.start_date > this.end_date) {
                window.myAlert('Недопустимый диапазон дат');
                return;
            }
            const start = new Date(this.start_date + ' 00:00:00');
            const end = new Date(this.end_date + ' 23:59:59');
            window.myAjax('POST', '/analytic/camera_log/excel', {
                start: start.toISOString().slice(0,19).replace(/T/g," "),
                end: end.toISOString().slice(0,19).replace(/T/g," "),
                object_ids: JSON.stringify(this.selectedObjects),
            }, (error, response) => {
                if(error) {
                    window.myAlert((response || {}).message || 'Произошла ошибка. Попробуйте позже')
                } else {
                    const url = response.excel_url;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `cameras_logs.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                }
            });
        },
    }
}
