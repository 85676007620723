<style scoped>
    .cam_check {
        max-width: 24px;
        height: 24px;
        padding: 0px;
        content: '';
        border: 2px solid #C2C2C2;
        border-radius: 20px;
        cursor: pointer;
    }
    .cam_check.checked {
        background: url('../../assets/img/galka.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #4C974A;
    }
    input {
        background-color: white;
        border: 1px solid #ccc;
        padding: 7px;
        padding-left: 15px;
        width: 100%;
    }
</style>


<template>
    <div class="devCard row mb-8 mx-0 px-7 py-4">

        <div class="col-12 px-0 my_label">
            {{ $t('Название шаблона') }}
        </div>
        <div class="col-11 px-0 py-2 mx-0">
            <input v-model="template.name">
        </div>
        <div :title="$t('шаблон по умолчанию')" class="cam_check checked col-2 py-0 m-auto"
             v-if="template.is_default"
             @click="template.is_default = 0"></div>
        <div :title="$t('сделать шаблоном по умолчанию')" class="cam_check col-2 py-0 m-auto"
             v-else @click="template.is_default = 1"></div>
        <div class="col-1">
        </div>

        <hr class="col-12 mt-0 mt-4 px-0"/>
        <div class="col-12 pr-0 row mx-0 justify-content-end">
            <div class="my_btn btn_delete mb-3" @click="hideTemplate">{{ $t('Отмена') }}</div>
            <div class="my_btn btn_green ml-8 mb-3" :title="$t('сохранить шаблон')" @click="saveTemplate">{{ $t('Сохранить') }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Template',
        props: ['template'],
    data: () => ({
    }),
    mounted() {
    },
    methods: {
        working() {
//            window.myAlert('работаем...')
        },

        saveTemplate() {
            if (this.template.id)
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения шаблона?', this.saveTemplate2)
            else
                this.saveTemplate2()
        },

        saveTemplate2() {
            if(this.template.name.length) {
                let method = 'POST';
                if (this.template.id) {
                    method = 'PUT';
                }
                window.myAjax(method, '/api/user-template', this.template, this.myCallbackSaveTemplate)
            } else
                window.myAlert('Не указано название шаблона')
        },
        myCallbackSaveTemplate (error, response) {
            window.myAlert(response.message)
            if(!error) {
                if(!this.template.id)
                    this.template.id = response.userTemplate.id
//                if(this.template.is_default)
  //                  this.resetDefaultTemplates()
                this.hideTemplate()
            }
        },

//        resetDefaultTemplates() {
  //          for(let i=0; i<window.myTemplates.length; i++)
    //            if(window.myTemplates[i].id!=this.template.id)
      //              window.myTemplates[i].is_default = false
        //},

        hideTemplate() {
            this.$emit('getTemplates')
        }

    }
  }
</script>
