export default {
    name: 'PricesObjectCard',
    props: {
        selectedObj: {
            type: Object,
            default: null
        },
        object: {
            type: Object,
            default: null
        },
        banner: {
            type: Object,
            default: null
        }
    },
    components: {
        // todo
    },
    watch: {
        // todo
    },
    data: () => ({
        selected: {
            title: '',
            address_in_city: '',
            competitor_id: null,
            object_id: null,
            format_id: null
        },
        loading: false,
        userObjects: [],
        userFormats: [],
        selectedObjectId: 0,
        selectedFormatId: 0
    }),
    mounted() {
        if (this.object) {
            this.selected = Object.assign({}, this.object);
            if (this.selected.competitor_id) {
                this.selectedObjectId = this.selected.competitor_id;
            }
            if (this.selected.format_id) {
                this.selectedFormatId = this.selected.format_id;
            }
        }
        this.getBanners();
        this.getFormats();
    },
    methods: {
        getFormats() {
            window.myAjax('GET', '/price-tag/monitoring_object_formats', {}, this.getFormatsHandler);
        },
        getFormatsHandler(error, response) {
            if (!error) {
                this.userFormats = response;
            }
        },
        getBanners() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/competitors', {}, this.getBannersHandler);
        },
        getBannersHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.userObjects = response;
            }
        },
        cancel() {
            this.$emit('saved')
        },
        isValid() {
            if (!this.selectedObjectId) {
                window.myAlert('Выберите конкурента.')
                return false;
            } else if (!this.selectedFormatId) {
                window.myAlert('Выберите формат.')
                return false;
            } else if (
                (this.selected.title || '').length < 1
            ) {
                return false;
            }
            return true;
        },
        save() {
            if (!this.isValid() || this.loading) {
                return;
            }
            this.loading = true;
            this.selected.object_id = this.selectedObj.id;
            this.selected.competitor_id = this.selectedObjectId;
            this.selected.format_id = this.selectedFormatId;
            window.myAjax(this.selected.id ? 'PUT' : 'POST', '/price-tag/monitoring_object', Object.assign({}, this.selected, {
                monitoring_object_id: this.selected.id
            }), this.saveHandler);
        },
        saveHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        },

        // удаление
        showConfirm() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить объект?', this.remove)
        },
        remove() {
            this.loading = true; 
            const url = '/price-tag/monitoring_object';
            window.myAjax('DELETE', url, {
                monitoring_object_id: this.selected.id
            }, this.removeHandler);
        },
        removeHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        }
    }
}
