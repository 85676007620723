<style scoped>
    .settingsAdmin {
        background: url('../../assets/img/settings.svg');
    }
    .profile_icon {
        background: url('../../assets/img/profile_icon.svg');
        position: relative;
        filter: none;
    }
    .sub_menu {
        position: absolute;
        background-color: #ffffff00;
        padding-top: 25px;
        left: -30px;
        z-index: 15;
    }
    .sub_menu>div {
        border-radius: 7px;
        background-color: #ffffffa0;
        padding: 3px;
        min-width: 89px;
    }
    .sub_menu > div:not(:last-child) {
        border-bottom: 1px solid #dddddd;
    }
    .chat_flag {
        position: absolute;
        border-radius: 10px;
        width: 10px;
        height: 10px;
        background-color: #f00;
        /* filter: blur(1px); */
        -webkit-animation: blink 1.3s;
        -webkit-animation-iteration-count: infinite;
        animation: blink 1.3s;
        animation-iteration-count: infinite;
        right: -3px;
    }
    .menus>div {
        font-size: 20px;
        color: #888;
        cursor: pointer;
    }
    .menus>div:hover {
        color: #096866;
    }
    .menus>div.active {
        color: #000;
        cursor: default;
    }
    @-webkit-keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

</style>

<template>
<div class='row px-0 mx-0' style='min-height:60px'>
    <div class='col-7 mx-0 py-0 my-auto'>
    <div class="menus row mx-0 pl-2">
		<div :class="['mr-4', content === 'Clients' ? 'active' : '']" @click="$emit('changed', 'Clients')">{{ $t('Клиенты') }}</div>
		<div :class="['mr-4', content === 'Sensors' ? 'active' : '']" @click="$emit('changed','Sensors')">{{ $t('Устройства') }}</div>
		<div :class="['mr-4', content === 'Features' ? 'active' : '']" @click="$emit('changed','Features')">{{ $t('Клиентский функционал') }}</div>
		<div :class="['mr-4', content === 'ModulePaths' ? 'active' : '']" @click="$emit('changed','ModulePaths')">{{ $t('Пути и видеокарты') }}</div>
	</div>
    </div>
	<div class='col-5 d-flex justify-content-end py-0'>
<!--      <div class='controls calendar ml-4 mr-2 dev'></div> -->
      <div class='my-auto'>{{ date }}</div>
        <div class='controls profile_icon ml-5' v-on:mouseover='show_sub_menu = true'>
            <!-- <div v-if="chat_flag" class="chat_flag"></div> -->
            <div class="sub_menu text-center" v-if="show_sub_menu"
                 v-on:mouseleave='show_sub_menu = false'>
                <div>
                    <!--
                    <div class="the_link" v-on:click='chat_flag = !chat_flag'>
                        Чаты
                    </div>
                    -->
                    <div class="the_link" v-on:click='Logout'>
                        Выход
                    </div>
                </div>
            </div>
        </div>
        <div class="my-auto">{{ userFio }}</div>
	</div>
</div>
</template>

<script>  
  export default {
    name: 'Header',
    components: {
        // todo
    },
    props: ['content'],
    data: () => ({
    date: 'test',
        userFio: '',
        chat_flag: true,
        show_sub_menu: false
    }),
    mounted() {
        this.userFio = window.dolgAndFio
      this.ticTac()
    },
    methods: {
        menuNav(event) {
            this.$emit('contentToggle', event);
        },
        Logout() {
          this.$emit('logoutUser');
//        window.location.href = '/video';
        },
        working() {
//            window.myAlert('работаем')
        },
        ticTac() {
            setTimeout(this.ticTac, 2500);
            this.date = this.dateStr();
        },
        dateStr() {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(),

            options = {
              year: 'numeric',
                month: 'long',
//              month: 'numeric',
              day: 'numeric',
//              weekday: 'long',
              timezone: 'UTC',
              hour: 'numeric',
              minute: 'numeric'//,
              //second: 'numeric'
            },
                date_str = date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            //date_str = date.toLocaleString("en", options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            return date_str;
        }
    }

  }


</script>
