const STATUSES = [
    'Недоступен',
    'Доступен',
    'Садится батарейка'
]

const TYPES = {
    'temp': '°C',
    'hum': '%'
}

export default {
    name: 'SensorList',
    components: {
        // todo
    },
    props: {
        activeObject: {
            type: Object,
            default: {}
        },
        activeSensor: {
            type: Object
        },
        layout: {
            type: Number,
            default: 3
        }
    },
    data: () => ({
        loading: false,
        sensorsQueue: [],
        statesQueue: [],
        devices: [],
        sensors: [],
        items: [],
        cameraToTemp: null
    }),
    watch: {
        activeObject: {
            handler() {
                if ((this.activeObject || {}).object_type === 'object') {
                    this.getDevices();
                }
            }
        },
    },
    mounted() {
        if ((this.activeObject || {}).object_type === 'object') {
            this.getDevices();
        }
    },
    beforeDestroy() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId)
        }
    },
    methods: {
        selectTemp(camera_id) {
            this.cameraToTemp = camera_id;
            if (!this.loading && this.items.length) {
                const tempCameraSensor = this.items.find(
                    sensor => sensor.cameraId === this.cameraToTemp && sensor.type === 'temp'
                );
                this.cameraToTemp = null;
                if (tempCameraSensor) {
                    this.$emit('seleted', tempCameraSensor);
                }
            }
        },
        getDevices() {
            this.loading = true;
            window.myAjax('GET', '/sensor/unimon/devices', {}, this.getDevicesCallback);
        },
        getDevicesCallback(error, response) {
            this.devices = [];
            this.sensors = [];
            this.items = [];
            if (!error) {
                (response || []).forEach( device => {
                    if (device.object_id === (this.activeObject || {}).id) {
                        this.devices.push(device);
                    }
                });
                this.sensorsQueue = [];
                this.statesQueue = [];
                this.loading = true;
                this.devices.forEach(device => {
                    this.sensorsQueue.push(device.device_id);
                    this.statesQueue.push(device.device_id);
                });
                this.getSensors();
            }
        },
        getSensors() {
            if (this.sensorsQueue.length > 0) {
                const deviceId = this.sensorsQueue.pop();
                window.myAjax('GET', `/sensor/unimon/device/sensors`, {
                    device_id: deviceId
                }, (error, response) => {
                    if (!error) {
                        this.items = [];
                        (response || []).forEach(sensor => {
                            this.sensors.push(sensor)
                        });
                    }
                    this.getSensors();
                });
            } else {
                this.loading = false;
                if (this.sensors.length) {
                    const tempCameraSensor = this.sensors.find(
                        sensor => sensor.camera_id === this.cameraToTemp && sensor.type === 'temp'
                    );
                    this.cameraToTemp = null;
                    if (tempCameraSensor) {
                        this.$emit('seleted', tempCameraSensor);
                    }
                }
                this.startUpdateStates();
            }
        },

        startUpdateStates() {
            this.statesQueue = [];
            this.devices.forEach(device => {
                this.statesQueue.push(device.device_id);
            });
            this.updateStates()
            if (this.timeoutId) {
                window.clearTimeout(this.timeoutId)
            }
            this.timeoutId = window.setTimeout(() => {
                this.startUpdateStates()
            }, 60000);
        },
        
        updateStates() {
            if (this.statesQueue.length > 0) {
                const deviceId = this.statesQueue.pop();
                window.myAjax('GET', `/sensor/unimon/device_last_state`, {
                    device_id: deviceId
                }, (error, response) => {
                        if (!error) {
                            this.setValues(response);
                        }
                        this.updateStates();
                    }
                );
            }
        },
        setValues(lastStates) {
            this.sensors.forEach( sensor => {
                if ((lastStates[sensor.code] || {}).v && sensor.camera_id) {
                    const camera = window.myCams.find(cam => cam.id === sensor.camera_id);
                    const item = this.items.find( item => item.code === sensor.code && item.type === sensor.type )
                    if (item) {
                        item.value = lastStates[sensor.code].v;
                        item.status = lastStates[sensor.code].a;
                        item.statusTitle = STATUSES[lastStates[sensor.code].a];
                    } else {
                        this.items.push({
                            id: `${sensor.device_id.toString()}-${sensor.code.toString()}`,
                            device_id: sensor.device_id,
                            code: sensor.code,
                            type: sensor.type,
                            typeCaption: TYPES[sensor.type],
                            caption: sensor.caption,
                            cameraId: sensor.camera_id,
                            cameraName: camera ? camera.name : 'Без камеры',
                            cameraStatus: camera ? camera.status : null,
                            loading: true,
                            value: lastStates[sensor.code].v, // текущее значение
                            status: lastStates[sensor.code].a, // доступность (0 - недоступен, 1 - доступен, 2 - садится батарейка)
                            statusTitle: STATUSES[lastStates[sensor.code].a], 
                        })
                    }
                }
            });
        }
    }
}
