import { WEEKDAYS } from './../../neural/utils'; 

export default {
    name: 'ScheduleDays',
    components: {
        // todo
    },
    props: {
        selectedDays: {
            type: Array,
            default: []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        days: WEEKDAYS,
    }),
    watch: {
        selectedDays: {
            handler() {
                // todo
            },
        },
    },
    mounted() {
        // todo
    },
    destroyed() {
        // todo
    },
    methods: {
        setDay(dayIndex) {
            if (dayIndex === 'working') {
                this.$emit('update:selectedDays', [1,2,3,4,5]);
                return;
            } else if (dayIndex === 'weekend') {
                this.$emit('update:selectedDays', [6,0]);
                return;
            } else if (dayIndex === 'all') {
                this.$emit('update:selectedDays', [0,1,2,3,4,5,6]);
                return;
            }
            let updatedSelectedDays = [...this.selectedDays];
            if (updatedSelectedDays.includes(dayIndex)) {
                updatedSelectedDays.splice(updatedSelectedDays.indexOf(dayIndex), 1);
            } else {
                updatedSelectedDays.push(dayIndex);
            }
            this.$emit('update:selectedDays', updatedSelectedDays);
        }
    }
}
