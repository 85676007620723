import TabsGroup from './../../base/tabsGroup';
import MonitoringList from './monitoringList';
import MonitoringSchedule from './monitoringSchedule';
import CamerasTaskList from './../../reports/camerasTaskList';

export default {
    name: 'CamerasMonitoring',
    components: {
        TabsGroup,
        MonitoringList,
        MonitoringSchedule,
        CamerasTaskList,
    },
    props: {
        activeObject: {
            type: Object,
            default: null
        },
        clientId: {
            type: Number,
            default: null
        },
    },
    data: () => ({
        tabs: [
            { id: 'list', caption: 'Результаты проверок камер' },
            { id: 'reports', caption: 'Расписания отправки отчетов' },
            { id: 'schedule', caption: 'Расписания проверок камер' },
        ],
        tabsMode: 'list',
    }),
    watch: {
        // todo
    },
    mounted() {
        // todo
    },
    methods: {
        tabsHandler(mode) {
            this.tabsMode = mode;
        },
    }
}
