<style scoped>
    .ierarhia {
        padding-left: 20px;
    }
</style>

<template>
  <div class="ierarhia ml-1">
      <TreeNodes
        :noCamsObjects="noCamsObjects"
        :mode="mode" 
        :noCams="noCams"
        :nodes="rootNodes" :ids="ids" v-if="treeBuilded" :obj="obj"
                 @showObject="showObjectC"/>
  </div>
</template>

<script>

  import TreeNodes from '../../components/leftTree/TreeNodes';
  export default {
    name: 'Ierarchia',
    props: ['rootNodes', 'treeBuilded', 'ids', 'obj', 'mode', 'noCams', 'myCams'],
    components: {
      TreeNodes
    },
    data: () => ({
      noCamsObjects: []
    }),
    mounted() {
      this.myCams = window.myCams;
    },
    watch: {
        myCams: {
            handler(value) {
                this.noCamsObjects = [];
                if (this.rootNodes.length) {
                    (value || []).forEach(cam => {
                        const objectPath = this.getSearchObjects(cam.object_id);
                        // if (cam.zone_id) {
                        //   this.noCamsObjects.push(cam.zone_id);
                        // }
                        Array.prototype.push.apply(this.noCamsObjects, objectPath);
                    });
                    (window.myObjects || []).forEach( obj => {
                      if (obj.object_type === 'zone') {
                        this.noCamsObjects.push(obj.id);
                      }
                    })
                }
            }
        },
        rootNodes: {
            handler() {
                this.noCamsObjects = [];
                if (this.myCams.length) {
                    (this.myCams || []).forEach(cam => {
                        const objectPath = this.getSearchObjects(cam.object_id);
                        // if (cam.zone_id) {
                        //   this.noCamsObjects.push(cam.zone_id);
                        // }
                        // console.log('objectPath', objectPath);
                        Array.prototype.push.apply(this.noCamsObjects, objectPath);
                        // this.rootNodes
                        // console.log('nodes', this.nodes)
                    });
                    (window.myObjects || []).forEach( obj => {
                      if (obj.object_type === 'zone') {
                        this.noCamsObjects.push(obj.id);
                      }
                    })
                }
            }
        },
        treeBuilded: {
            handler() {
                if (this.rootNodes.length && this.myCams.length) {
                    (this.myCams || []).forEach(cam => {
                        const objectPath = this.getSearchObjects(cam.object_id);
                        // if (cam.zone_id) {
                        //   this.noCamsObjects.push(cam.zone_id);
                        // }
                        // console.log('objectPath', objectPath);
                        Array.prototype.push.apply(this.noCamsObjects, objectPath);
                        // this.rootNodes
                        // console.log('nodes', this.nodes)
                    });
                    (window.myObjects || []).forEach( obj => {
                      if (obj.object_type === 'zone') {
                        this.noCamsObjects.push(obj.id);
                      }
                    })
                }
            }
        }
    },
    methods: {
      showObjectC(obj) {
        this.$emit('showObjectCa', obj)
      },
      getSearchObjects(object_id) {
        if (!this.rootNodes.length) {
          return null;
        }
        const resultIds = [];
        const object = window.myObjects.find(obj => obj.id === object_id);
        if (!object) {
          return null;
        }
        resultIds.push(object.id);
        if (object.parent_id) {
          const parentResults = this.getSearchObjects(object.parent_id);
          if (parentResults) {
            parentResults.forEach(res => {
              resultIds.push(res);
            })
          }
        }
        return resultIds;
        // const resultIds = [];
        // this.rootNodes.forEach( node => {
        //   resultIds.push(node.id);
        //   if (node.id === object_id) {
        //     // todo
        //     return;
        //   } else if (node.childrenNodes.length) {
        //     resultIds.push(this.getSearchObjects(object_id))
        //   } else {
        //     resultIds.pop();
        //   }
        // })
        // return resultIds;
      },
    }
  }

</script>
