export default {
    name: 'PricesProductCard',
    props: {
        product: {
            type: Object,
            default: null
        },
        parent: {
            type: Object,
            default: null
        }
    },
    components: {
        // todo
    },
    data: () => ({
        selected: {
            title: '',
            article: '',
            measure_unit: 0,
            measure_unit_id: 0,
            measure_value: null,
            category_id: null,
            barcode_ids: []
        },
        loading: false,
        categoryParent: null,
        barcodes: [],
        barcodesTemp: [],
        selectedBarcode: null,
        units: [],
        selectedBrandId: null,
        selectedUnitId: 0,
        products: [],
        brands: [],
    }),
    mounted() {
        if (this.product) {
            this.selected = Object.assign({}, this.product);
            if (!this.selected.barcode_ids) {
                this.selected.barcode_ids = [];
            }
            if (this.product.measure_unit_id) {
                this.selectedUnitId = this.product.measure_unit_id;
            }
            this.selectedBrandId = this.product.brand_id;
            this.getBarcodes();
        }
        this.categoryParent = this.parent;
        this.getUnits();
        this.getCategories();
    },
    destroyed() {
        // todo
        
    },
    watch: {
        product: {
            deep: true,
            handler(newValue) {
                if (this.product) {
                    this.selected = Object.assign({}, newValue);
                    if (this.product.measure_unit_id) {
                        this.selectedUnitId = this.product.measure_unit_id;
                    }
                    this.selectedBrandId = this.product.brand_id;
                    this.getBarcodes();
                }
            }
        },
        parent: {
            deep: true,
            handler(newValue) {
                this.categoryParent = newValue;
            }
        }
    },
    methods: {
        getCategories() {
            window.myAjax('GET', '/price-tag/categories', {}, (error, response) => {
                if (!error) {
                    this.categories = response;
                    this.getBrands();
                }
            });
        },
        getBrands() {
            window.myAjax('GET', '/price-tag/brands', {
                limit: 0,
            }, (error, response) => {
                if (!error) {
                    const categoryId = this.selected.category_id ?
                        this.selected.category_id :
                        ( this.categoryParent ? this.categoryParent.id : (this.parent || {}).id );
                    const rootCategoryId = (this.findRootCategory(categoryId) || {}).id;
                    console.log(categoryId, rootCategoryId)
                    this.brands = (response || []).filter(brand => 
                        brand.category_id === null ||
                        brand.category_id === rootCategoryId
                    );
                }
            });
        },
        findRootCategory(categoryId) {
            const categories = this.categories;
            for ( let i = 0; i < categories.length; i++ ) {
                const category = categories[i];
                if (category.id === categoryId) {
                    return category;
                }
                if (this.findRootFromChildren(categoryId, category.children)) {
                    return category;
                }
            }
            return null;
        },
        findRootFromChildren(categoryId, categories) {
            for ( let i = 0; i < categories.length; i++ ) {
                const category = categories[i];
                if (category.id === categoryId) {
                    return true;
                }
                if (this.findRootFromChildren(categoryId, category.children)) {
                    return true;
                }
            }
            return false;
        },
        getUnits() {
            window.myAjax('GET', '/price-tag/measure_units', {}, this.getUnitsHandler);
        },
        getUnitsHandler(error, response) {
            if (!error) {
                this.units = response;
            }
        },
        getBarcodes() {
            this.barcodes = [];
            (this.selected.barcodes || []).forEach( barcode => {
                this.barcodes.push({
                    id: barcode[0],
                    value: barcode[1],
                })
            })
            // window.myAjax('GET', '/price-tag/barcodes', {}, this.getBarcodesHandler);
        },
        getBarcodesHandler(error, response) {
            this.loading = false;
            if (!error) {
                const slef = this;
                response.forEach( barcode => {
                    slef.selected.barcode_ids.forEach(id => {
                        if (barcode.id == id) {
                            slef.barcodes.push(barcode);
                        }
                    }); 
                })
            }
        },
        createBarcode() {
            this.selectedBarcode = Object.assign({}, { value: '' });
        },
        saveBarcode() {
            this.barcodesTemp.unshift(this.selectedBarcode);
            this.selectedBarcode = null;
        },
        removeBarcode(barcode) {
            const index = this.barcodesTemp.findIndex( b => b.value == barcode.value);
            this.barcodesTemp.splice(index, 1);
            this.selectedBarcode = null;
        },
        openObjectHandler(data) {
            this.$emit('openObject', data);
        },
        showConfirm() {
            if (this.loading) {
                return;
            }
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить товар?', this.remove)
        },
        remove() {
            this.loading = true;
            const url = '/price-tag/product';
            window.myAjax('DELETE', url, {
                product_id: this.selected.id
            }, this.removeHandler);
        },
        removeHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        },
        isValid() {
            if (
                (this.selected.title || '').length < 1
            ) {
                return false;
            }
            return true;
        },
        save() {
            if (!this.isValid() || this.loading) {
                return;
            }
            if (!this.selected.id && (this.categoryParent || this.parent)) {
                this.selected.category_id = this.categoryParent ? this.categoryParent.id : (this.parent || {}).id;
            }
            this.selected.barcode_ids = [];
            this.barcodes.forEach( barcode => {
                this.selected.barcode_ids.push(barcode.id);
            });
            this.selected.measure_unit_id = this.selectedUnitId;
            this.selected.brand_id = this.selectedBrandId;
            this.selected.measure_unit = this.units.find( u => u.id === this.selectedUnitId ).title;
            const data = Object.assign(this.selected, {
                product_id: this.selected.id
            });
            data.barcode_ids = JSON.stringify(data.barcode_ids);
            this.loading = true;
            window.myAjax(this.selected.id ? 'PUT' : 'POST', '/price-tag/product', data, this.saveProductHandler);
        },
        saveProductHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            } else {
                window.myAlert((response || {}).message || ((response || {}).error || {}).message || 'Произошла ошибка. Попробуйте позже.');
            }
        },
        openBarcodes() {
            this.barcodesTemp = [];
            this.barcodes.forEach( barcode => {
                this.barcodesTemp.push(barcode);
            })
            window.$("#dsv-barcodes-modal").modal('show');
            // window.myAjax('GET', '/price-tag/barcodes', {}, this.getProductBarcodesHandler);
        },
        getProductBarcodesHandler(error) {
            if (!error) {
                this.barcodesTemp = [];
                this.barcodes.forEach( barcode => {
                    this.barcodesTemp.push(barcode);
                })
                window.$("#dsv-barcodes-modal").modal('show');
            }
        },
        cancelBarcode() {
            this.selectedBarcode = null;
        },
        cancelBarcodes() {
            this.selectedBarcode = null;
            this.barcodesTemp = [];
            window.$("#dsv-barcodes-modal").modal('hide');
        },
        saveBarcodes() {
            const toRemove = [];
            for (let j = 0; j < this.barcodesTemp.length; j++) {
                const barcode = this.barcodesTemp[j];
                if (!barcode.id) {
                    window.myAjax('POST', '/price-tag/barcode', { value: barcode.value }, (error, response) => {
                        if (!error) {
                            this.barcodes.push(response)
                        }
                    });
                }
            }
            for (let i = 0; i < this.barcodes.length; i++) {
                const barcodeSelected = this.barcodes[i];
                let inTemp = false;
                for (let j = 0; j < this.barcodesTemp.length; j++) {
                    const barcodeTemp = this.barcodesTemp[j];
                    if (barcodeSelected.id === barcodeTemp.id) {
                        inTemp = true;
                    }
                }
                if (!inTemp) {
                    toRemove.push(barcodeSelected);
                    window.myAjax('DELETE', '/price-tag/barcode', {
                        barcode_id: barcodeSelected.id
                    }, () => {});
                }
            }
            for (let i = 0; i < toRemove.length; i++) {
                const index = this.barcodes.findIndex( b => b.id == toRemove[i].id);
                this.barcodes.splice(index, 1);
            }
            this.cancelBarcodes();
        },
    }
}
