import Vue from 'vue'
import App from './app.vue'
import vuetify from './plugins/vuetify';
import store from './store';
import Vuex from 'vuex'

Vue.config.productionTip = false;
Vue.use(Vuex);

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
