/**
 * @typedef {object} updateParams
 * @property {string} caption Название чек-листа.
 * @property {number} parent_id Id родителя, если создавать root - то ничего не передавать (null).
 * @property {number} id Id узла, который необходимо обновить.
 * @property {string} type Один из трех типов: 'root', 'subgroup', 'point' (корень, узел и лист дерева).
 * @property {number[]} available_digressions Массив id доступных отклонений.
 * Заполняется только для point, для остальных ничего (null).
 */
/**
 * Создание нового чек-листа.
 * @param {updateParams} params Параметры.
 * @param {function} callback Функция обратного вызова при успешном вызове. 
 * @description
 * Обновляет как override, т.е. если при создании указали какое-то поле,
 * а потом оставили пустым - поле перезатрётся пустым значением.
 */
 export function updateChecklist(params, callback) {
    const data = Object.assign({}, params);
    if (params.available_digressions) {
        data.available_digressions = JSON.stringify(params.available_digressions);
    }
    window.myAjax('PUT', '/api/checklist', data, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    });
}
