import BaseButton from '../../../base/button';
import DevNodes from './../_templates/node';
import { getDigressionsTree } from './../../../Utils/getDigressionsTree';
import { filter } from './../../../Utils/Object/filter';

const TITLE = {
    NEW: 'Создание нового пункта чек-листа',
    EDIT: 'Редактирование пункта чек-листа'
}

export default {
    name: 'EditGroup',
    components: {
        BaseButton,
        DevNodes
    },
    props: {
        point: Object
    },
    data: () => ({
        title: TITLE.NEW,
        caption: null,
        summary: null,
        description: null,
        rootNodes: null,
        selected: {},
        newSelected: {},
    }),
    mounted() {
        this.getDigressionsTreeCallback = this.getDigressionsTreeCallback.bind(this);
        this.update = this.update.bind(this);
        if (this.point) {
            this.selected = [];
            this.newSelected = [];
            this.title = TITLE.EDIT;
            this.caption = this.point.caption;
            this.summary = this.point.title;
            this.description = this.point.description;
            this.digressions = this.point.available_digressions || [];
            if (this.digressions.length) {
                for (let i = 0; i < this.digressions.length; i++) {
                    const digression = this.digressions[i];
                    this.selected[digression.digression_id] = true;
                    this.newSelected[digression.digression_id] = true;
                }
            }
        }
        document.getElementById('dsv-checklist-edit-point').addEventListener('update', (event) => {
            this.update(event.detail);
        });
    },
    methods: {
        update(detail) {
            if (detail.point) {
                this.selected = [];
                this.newSelected = [];
                this.point = detail.point;
                this.caption = detail.point.caption;
                this.summary = detail.point.title;
                this.description = detail.point.description;
                this.title = TITLE.EDIT;
                this.digressions = this.point.available_digressions || [];
                if (this.digressions.length) {
                    for (let i = 0; i < this.digressions.length; i++) {
                        const digression = this.digressions[i];
                        this.selected[digression.digression_id] = true;
                        this.newSelected[digression.digression_id] = true;
                    }
                }
            }
        },

        /** Обработчик клика по кнопке Отменить. */
        cancelHandler() {
            this.$emit('cancel');
        },

        /** Обработчик клика по кнопке Удалить. */
        deleteHandler() {
            this.$emit('delete', {
                point: true
            });
        },

        /** Обработчик клика по кнопке Сохранить. */
        saveHandler() {
            this.$emit('save', {
                point: this.point,
                caption: this.caption,
                title: this.summary,
                description: this.description,
                digressions: this.getDigressionIds()
            });
        },

        getDigressionIds() {
            return Object.keys(filter(this.selected, value => value));
        },

        selectDevs() {
            this.selected = this.newSelected;
            // todo сохранить выбранные отклонения
            this.manageDevModal('hide');
        },

        manageDevModal(param) {
            if (param === 'show') {
                getDigressionsTree(this.getDigressionsTreeCallback);
            }
            window.$("#dsv-checklist-edit-modal").modal(param);
        },

        getDigressionsTreeCallback(response) {
            this.rootNodes = response;
        },

        cancelModal() {
            this.newSelected = this.selected;
            this.manageDevModal('hide');
        },

        selectNode(node) {
            this.newSelected[node.id] = !this.newSelected[node.id];
            this.newSelected = Object.assign({}, this.newSelected, {});
        }
    }
}
