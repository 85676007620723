import { getChecklist } from './../Utils/getChecklist';
import { setChecklistLog } from './../Utils/setChecklistLog';
import { create } from './../../Utils/guid';
import BaseButton from './../../base/button';

const SUBGROUP_NAME = 'subgroup';
const POINT_NAME = 'point';

/**
 * @name ChecklistCard
 * @description
 * Компонент карточка пункта чек-листа.
 * Отображает название и описания пункта, позволяет отметить пункт отработанным, создать ручное отклонение.
 */
export default {
    name: 'ChecklistPanelCard',
    components: {
        BaseButton
    },
    props: {
        /** Идентификатор текущего чек-листа. */
        rootIds: String,
        /** Идентификатор выбранного пункта. Если передан null, то карточка не показывается. */
        pointIds: Number
    },
    data: () => ({
        rootId: null,
        pointId: null,
        dataLoaded: false,
        checklist: null,
        id: '',
        isEdited: true,
        checklistCaption: null,
        loading: false
    }),
    mounted() {
        this.getChecklistCallback = this.getChecklistCallback.bind(this);
        this.findPointIntoGroup = this.findPointIntoGroup.bind(this);
        this.isRequiredPoint = this.isRequiredPoint.bind(this);
        document.getElementById('dsv-checklist-card').addEventListener('pointSelected', (event) => {
            this.update(event.detail);
        });
    },
    unmounted() {
        window.checklistCardPoint = null;
        if (document.getElementById('dsv-checklist-tree')) {
            const updateEvent = new CustomEvent('reset', { detail: {} });
            document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
        }
    },
    methods: {
        update(detail) {
            const params = {
                id: detail.root.id
            }
            this.rootId = detail.root.id;
            this.pointId = detail.point.id;
            this.isEdited = detail.status;
            this.loading = true;
            getChecklist(params, this.getChecklistCallback);
        },

        getChecklistCallback(response) {
            this.dataLoaded = true;
            this.loading = false;
            const self = this;
            const checklist = response.check_list;
            this.checklistCaption = checklist.caption;
            this.checklist = this.findPointIntoGroup(checklist.children, self);
            console.log(this.checklist)
            window.checklistCardPoint = this.checklist;
            if (!this.checklist) {
                this.dataLoaded = false;
                window.checklistCardPoint = null;
            }
        },

        toggleEditHandler() {
            this.isEdited = !this.isEdited;
            // const params = {
            //     primary_id: this.pointId,
            //     status: 'ok',
            //     session_id: window.sessionId || create()
            //     // digressions: []
            // }
            // setChecklistLog(params, this.setChecklistLogCallback);
        },

        findPointIntoGroup(groups, self) {
            for (let i = 0; i < groups.length; i++) {
                const child = groups[i];
                if (child.type === SUBGROUP_NAME) {
                    const finded = self.findPointIntoGroup(child.children, self);
                    if (finded) {
                        return finded;
                    }
                } else if (child.type === POINT_NAME) {
                    if (self.isRequiredPoint(child, self)) {
                        return child;
                    }
                }
            }
        },

        isRequiredPoint(group, self) {
            return group.id === self.pointId;
        },

        acceptHandler() {
            const params = {
                primary_id: this.pointId,
                status: 'ok',
                object_id: window.selectedObjId || null,
                session_id: window.sessionId || create()
            }
            setChecklistLog(params, this.setChecklistLogCallback);
        },

        setChecklistLogCallback() {
            const params = { 
                id: this.rootId,
                log: true,
                next: true
            };
            this.dataLoaded = false;
            const updateEvent = new CustomEvent('update', { detail: params });
            document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
        },

        createDevHandler() {
            const params = {
                readOnly: true
            };
            const updateEvent = new CustomEvent('reset', { detail: params });
            document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
            this.$emit('createDev', false);
        }
    }
}
