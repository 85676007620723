import CheckboxListDropdown from './../../base/checkboxListDropdown';
import ModalNew from './../../base/modalNew';
import DigressionsSchedule from './../digressionsSchedule';


export default {
    name: 'CamerasReport',
    components: {
        CheckboxListDropdown,
        ModalNew,
        DigressionsSchedule,
        //todo
    },
    props: [],
    data: () => ({
        startDate: null,
        endDate: null,

        cameras: [],
        selectedCameras: [],
        camerasLoading: false,

        objects: [],
        selectedObjects: [],
        objectsLoading: false,

        reports: [],
        filteredReports: [],
        selectedReports: [],
        reportsLoading: [],

        showedGroups: [],

        modalSchedule: false,
    }),
    watch: {
        selectedObjects: {
            handler() {
                this.filterReports();
            }
        },
        selectedChecklists: {
            handler() {
                this.filterReports();
            }
        },
    },
    mounted() {
        this.resetFilter();
        this.getLogs();
        this.getObjects();
        this.getReports();
    },
    methods: {
        filterReports() {
            let result = [...new Set(this.reports)];
            if (this.selectedObjects.length) {
                result = result.filter( report => this.selectedObjects.indexOf(report.objectId) >= 0 );
            }
            if (this.selectedChecklists.length) {
                result = result.filter( report => this.selectedChecklists.indexOf(report.checklistId) >= 0 );
            }
            result = result.filter( report => report.loaded && report.completed !== false);
            this.filteredReports = [...new Set(result)];
        },
        toggleShowedGroups(reportId) {
            const index = this.showedGroups.findIndex( groupReportId => groupReportId === reportId );
            if (index > -1) {
                this.showedGroups.splice(index, 1);
            } else {
                this.showedGroups.push(reportId);
            }
        },
        updateSelectedCamerasHandler(data) {
            this.selectedCameras = data;
        },
        updateSelectedObjectsHandler(data) {
            this.selectedObjects = data;
        },
        resetFilter() {
            this.startDate = new Date();
            this.startDate.setDate(this.startDate.getDate() - 1);
            this.startDate = this.startDate.toISOString().slice(0,10);
            this.endDate = new Date();
            this.endDate = this.endDate.toISOString().slice(0,10);
            this.selectedChecklists = [];
            this.selectedObjects = [];
            this.getReports();
        },
        getReports() {
            this.reports = [];
            this.reportsLoading = true;

            // let start_date = new Date(this.startDate);
            // start_date.setHours(0, 0, 0);
            // let end_date = new Date(this.endDate);
            // end_date.setHours(23, 59, 59);
            // window.myAjax('GET', '/analytic/checklist/report_data', {
            //     start_date: Math.floor(start_date.valueOf() / 1000),
            //     end_date: Math.floor(end_date.valueOf() / 1000),
            //     checklist_ids: JSON.stringify(this.selectedChecklists || []),
            //     object_ids: JSON.stringify(this.selectedObjects || []),
            // }, (error, response) => {
            //     // this.objectsLoading = false;
            //     if (error) {
            //         window.myAlert(response.message || 'Ошибка загрузки аналитики.');
            //     } else {
            //         console.log('report_data', response);
            //     }
            // });

            
            window.myAjax('GET', '/api/checklist-logs', {
                start: this.startDate + ' 00:00:00',
                end: this.endDate + ' 23:59:59',
            }, (error, response) => {
                if (error) {
                    // window.myAlert(response.message || 'Ошибка загрузки аналитики.');
                } else {
                    this.reportsLoading = false;
                    const dsvLocale = window.getCookie('dsvLocale');
                    const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
                    const reports = [];
                    if (Array.isArray(response.check_list_logs)) {
                        (response.check_list_logs || []).forEach( log => {
                            const data = this.getDataFromLogs(log, short);
                            reports.push(data);
                        });
                        this.reports = reports.sort( (a,b) => b.date - a.date );
                    }
                    this.checklistsToLoad = [];
                    this.reports.forEach( report => {
                        const index = this.checklistsToLoad.indexOf(report.checklistId);
                        if (index < 0) {
                            this.checklistsToLoad.push(report.checklistId);
                        }
                    })
                    this.getChecklistElements();
                    this.filterReports();
                    // [{
                    //     id: 1,
                    //     name: 'группа1',
                        
                    //     index: 2,
                    //     maxIndex: 2,
                    //     percentIndex: Math.round(100 * 0.42142),
                    // }, {
                    //     id: 2,
                    //     name: 'группа2',
                    //     index: 2,
                    //     maxIndex: 4,
                    //     percentIndex: Math.round(100 * 0.42142),
                    // }]

                }
            });
        },
        getChecklistElements() {
            if (this.checklistsToLoad.length) {
                const nextChecklistElementId = this.checklistsToLoad.pop();
                window.myAjax('GET', '/api/checklist', {
                    checklist_id:  nextChecklistElementId
                }, (error, response) => {
                    if (!error) {
                        const obj = {};
                        obj[nextChecklistElementId] = response.check_list;
                        this.checklistElements = Object.assign({}, this.checklistElements, obj);

                        this.addGroupsByChecklist(response.check_list);
                    }
                    this.getChecklistElements();
                })
            } else {
                //
            }
        },
        addGroupsByChecklist(rootChecklist) {
            (rootChecklist.children || []).forEach( child => {
                const childPoints = this.getPoints(child) || [];
                const childPointsIds = childPoints.map( child => child.id );
                this.reports.filter( report => report.checklistId === rootChecklist.id ).forEach( report => {
                    const childLogs = (report.logs || []).filter( log => childPointsIds.includes(log.primary_id) );
                    const group = {
                        id: child.id,
                        name: child.caption,
                        index: childLogs.filter( log => log.status === 'ok' || log.status === 'resolved' ).length,
                        maxIndex: childPoints.length,
                        completed: childLogs.length === childPoints.length,
                    }
                    group.percentIndex = group.maxIndex > 0 ? Math.round(100 * group.index / group.maxIndex) : 0;
                    report.groups.push(group);

                    report.index += group.index;
                    report.maxIndex += group.maxIndex;
                    report.percentIndex = report.maxIndex > 0 ? Math.round(100 * report.index / report.maxIndex) : 0;
                    if (!group.completed) {
                        report.completed = false;
                    }
                })
            });
            this.reports.filter( report => report.checklistId === rootChecklist.id ).forEach( report => {
                report.loaded = true;
            })
            this.filterReports();
        },
        getPoints(log) {
            if (log.type !== 'point') {
                let children = [];
                for (let i = 0; i < log.children.length; i++) {
                    const child = log.children[i];
                    children = children.concat(this.getPoints(child));
                }
                return children;
            } else {
                return log;
            }
        },
        getChildrenPoints(node, path = [], result = []) {
            if (!(node.children || []).length) {
                if (node.type === 'point') {
                    result.push(path.concat(node));
                }
            }
            for (const child of node.children) {
                this.getChildrenPoints(child, path.concat(node), result);
            }
            return result;
        },
        // traverse(node, path = [], result = []){
        //     if (!node.children.length) 
        //        result.push(path.concat(node.item));
        //     for (const child of node.children)
        //         traverse(child, path.concat(node.item), result);
        //     return result;
        // },
        getDataFromLogs(log, short) {

            const createdAt = new Date(log.logs[0].created_at);

            let checklist = {
                caption: 'Неизвестный чек-лист',
                id: Math.floor(Math.random() * 1000)
            };
            if (log.logs[0].root_id) {
                checklist = this.checklists.find( checklist => checklist.id === log.logs[0].root_id );
            }

            const object = this.objects.find( object => object.id === log.logs[0].object_id );

            const responsible = window.myUsers.find( user => user.id === log.logs[0].user_id );
            const role = window.myRoles.find( role => role.id === responsible.roles[0] );

            const data = {
                id: log.session_id,
                date: createdAt,
                dateDay: createdAt.toLocaleDateString(short),
                dateWeekday: createdAt.toLocaleDateString(short, { weekday: 'short' }),
                dateTime: createdAt.toLocaleTimeString(short, { hour: 'numeric', minute: 'numeric' }),

                checklistId: checklist.id,
                checklistName: checklist.caption,
                checklistPercentIndex: checklist.report_index || 100,

                objectId: object.id,
                objectName: object.name,

                responsibleId: responsible.id,
                responsibleName: `${(responsible.profile || {}).last_name} ${(responsible.profile || {}).first_name}`,
                responsibleRole: role.name,

                index: 0,
                maxIndex: 0,
                percentIndex: 0,


                logs: log.logs,
                groups: [],
            };
            return data;
        },
        getLogs() {
            this.checklistLoading = true;
            window.myAjax('GET', '/api/checklists', {}, (error, response) => {
                this.checklistLoading = false;
                if (error) {
                    window.myAlert(response.message || 'Ошибка загрузки чек-листов.');
                } else {
                    this.checklists = response.check_lists;
                }
            });
        },
        getObjects() {
            this.objectsLoading = true;
            window.myAjax('GET', '/api/objects', {}, (error, response) => {
                this.objectsLoading = false;
                if (error) {
                    window.myAlert(response.message || 'Ошибка загрузки объектов.');
                } else {
                    this.objects = (response.objects || []).filter( object => object.object_type === 'object');
                }
            });
        },
        getExcel() {
            let start_date = new Date(this.startDate);
            start_date.setHours(0, 0, 0);
            let end_date = new Date(this.endDate);
            end_date.setHours(23, 59, 59);
            window.myAjax('POST', '/analytic/checklist/excel', {
                start_date: Math.floor(start_date.valueOf() / 1000),
                end_date: Math.floor(end_date.valueOf() / 1000),
                checklist_ids: JSON.stringify(this.selectedChecklists || []),
                object_ids: JSON.stringify(this.selectedObjects || []),
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка составления отчёта по чек-листам.');
                } else {
                    const url = response.excel_url;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `Отчёт ${start_date.toLocaleDateString()} ${end_date.toLocaleDateString()}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                }
            });
        },
        
        getReportExcel(report) {
            let start_date = new Date(report.date);
            start_date.setHours(0, 0, 0);
            let end_date = new Date(report.date);
            end_date.setHours(23, 59, 59);
            window.myAjax('POST', '/analytic/checklist/excel', {
                start_date: Math.floor(start_date.valueOf() / 1000),
                end_date: Math.floor(end_date.valueOf() / 1000),
                checklist_ids: JSON.stringify([report.checklistId]),
                object_ids: JSON.stringify([report.objectId]),
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка составления отчёта по чек-листам.');
                } else {
                    const url = response.excel_url;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `Отчёт ${start_date.toLocaleDateString()} ${end_date.toLocaleDateString()}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                }
            });
        }
    }
}