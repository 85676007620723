import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import LeftIcon from '../components/cams/Calendar/Icons/LeftIcon'
import RightIcon from '../components/cams/Calendar/Icons/RightIcon'

Vue.use(Vuetify);

export default new Vuetify({
    ang: {
        current: 'ru',
    },
    icons: {
        iconfont: 'mdiSvg',
        values: {
            CalendarListLeftIcon: {
                component: LeftIcon,
            },
            CalendarListRightIcon: {
                component: RightIcon,
            },
        }
    },
    theme: { disable: true },
});
