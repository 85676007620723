import CheckboxNew from './../../base/checkboxNew';

const AVAILABLE_SETTINGS = {
    'default_cameras_mode': {
        name: 'default_cameras_mode',
        title: 'Смарт-режим для камер выбран по умолчанию'
    },
    'default_preview_mode': {
        name: 'default_preview_mode',
        title: 'Суб-поток для превью выбран по умолчанию'
    },
    'default_tags_mode': {
        name: 'default_tags_mode',
        title: 'Подгружать тэги для кадров смарт-архива'
    }
}

export default {
    name: 'UserSettings',
    components: {
        CheckboxNew
    },
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    watch: {
        user() {
            // if ((value || {}).profile) {
            //     this.profile = value.profile || {};
            //     this.role = value.roles[0] || {};
            // }
            // if ((value || {}).permissions) {
            //     this.canGetSetting = (value.permissions || []).findIndex( p => p.name === 'get users settings') > -1;
            //     this.canEditSettings = (value.permissions || []).findIndex( p => p.name === 'edit users settings') > -1;
            // }
        }
    },
    data: () => ({
        loading: false,
        profile: {},
        role: {},

        canGetSetting: false,
        canEditSettings: false,

        availableSettings: [],
        checks: {}
    }),
    mounted() {
        this.profile = (this.user || {}).profile || {};
        this.role = (this.user || {}).roles[0] || {};
        if ((this.user || {}).permissions) {
            this.canGetSetting = true; //(this.user.permissions || []).findIndex( p => p.name === 'get users settings') > -1;
            this.canEditSettings = true; //(this.user.permissions || []).findIndex( p => p.name === 'edit users settings') > -1;
        }
        this.getSettings();
        // if (this.canGetSetting) {
            // this.getSettings();
            // this.getAvailableSettings();
        // }
    },
    destroyed() {
        // todo
    },
    methods: {
        getAvailableSettings() {
            this.loading = true;
            window.myAjax('GET', '/api/available_users_settings' , {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    this.availableSettings = [];
                    response.forEach( item => {
                        const setting = AVAILABLE_SETTINGS[item.name];
                        if (setting) {
                            this.availableSettings.push(setting);
                        }
                    })
                    this.getSettings();
                }
            })
        },
        getSettings() {
            this.loading = true;
            this.checks = {};
            window.myAjax('GET', '/api/users_settings', {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    this.availableSettings = [];
                    for (let key in AVAILABLE_SETTINGS) {
                        this.availableSettings.push(AVAILABLE_SETTINGS[key]);
                        // console.log()
                    }
                    // for (const [key, value] of Object.entries(AVAILABLE_SETTINGS)) {
                    //     this.availableSettings.push(value)
                    // } 
                    this.availableSettings.forEach( setting => {
                        const userSetting = (response || []).find( us => us.name === setting.name);
                        this.checks[setting.name] = userSetting ? userSetting.value === 'true' : false;
                    });
                    // (response || []).forEach( userSetting => {
                    //     const avSetting = AVAILABLE_SETTINGS[userSetting.name];
                    //     if (avSetting) {
                    //         this.availableSettings.push(avSetting);
                    //         this.checks[userSetting.name] = userSetting ? userSetting.value === 'true' : false;
                    //     }
                    // })
                    // const setting = AVAILABLE_SETTINGS[item.name];
                    //     if (setting) {
                    //         this.availableSettings.push(setting);
                    //     }
                }
            })
        },
        toggleChecks(name, checked) {
            const obj = {};
            obj[name] = checked;
            this.checks = Object.assign({}, this.checks, obj);
        },
        saveUserSettings() {
            this.loading = true;
            const data = {};
            for (let name in this.checks) {
                data[name] = this.checks[name].toString();
            }
            window.myAjax('POST', '/api/users_settings', {
                settings_dict: JSON.stringify(data)
            }, (error) => {
                this.loading = false;
                if (!error) {
                    window.myAlert('Настройки сохранены.');
                } else {
                    window.myAlert('Ошибка сохранения.');
                } 
            });
        }
    }
}
