<style scoped>
    .day {
        position: relative;
        background-color: #fff;
        border-radius: 5px;
        padding: 11px;
        height: 245px;
        overflow: hidden;
        cursor: pointer;
    }
    .day:hover {
        background: url('../../assets/img/grid_gray.png');
        background-color: #ccc;
    }
    .other_month {
        background: url('../../assets/img/grid_gray.png');
        background-color: #f8f8f8;
    }
    .day_name {
        position: absolute;
        bottom: 4px;
        font-size: 22px;
        font-weight: bold;
    }
    .day_text {
        border-radius: 10px;
        color: #fff;
        height: 54px;
        overflow: hidden;
        font-weight: 700;
        font-size: 14px;
        padding: 5px;
        padding-left: 12px;
        margin-bottom: 6px;
    }
    .day_text1 {
        background: #F2C94C;
    }
    .day_text2 {
        background: #F2994A;
    }
    .day_text3 {
        background: #1861C4;
    }
    .show_more {
        position: absolute;
        bottom: 8px;
/*        right: 47px; */
        right: 11px;
        padding: 1px;
        min-width: 27px;
        text-align: center;
        background: #EB5757;
        border-radius: 20px;
        color: #fff;
    }
    .add_task {
        position: absolute;
        bottom: 8px;
        right: 11px;
        padding: 1px;
        min-width: 27px;
        text-align: center;
        background: #096866;
        border-radius: 20px;
        color: #fff;
        cursor: pointer;
    }
    .add_task:hover {
        color: #00acc1;
    }
    .sunday {
        width: 82%;
    }
    .dsv-calendar__month-col {
        position: relative;
        width: 100%;
        min-height: 1px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 13.9%;
        flex: 0 0 13.9%;
        max-width: 13.9%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media (max-width: 1024px) {
        .day.dsv-calendar__month-day {
            max-height: 125px;
        }
        .sunday {
            width: 100%;
        }
        .col-2.dsv-calendar__month-col {
            position: relative;
            width: 100%;
            min-height: 1px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 14.2857%;
            flex: 0 0 14.2857%;
            max-width: 14.2857%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .col-10.dsv-calendar__month-week {
            flex: 0 0 83.7%;
            max-width: 83.7%;
        }
        .col-2.px-1.py-1.dsv-calendar__month-sunday {
            flex: 0 0 16.2%;
            max-width: 16.2%;
        }
    }
</style>

<template>
    <div class="mx-2 mx-lg-6 mb-8">
        <div class="row mx-0 px-0 my_bold">
            <div class="col-12 row mx-0 px-0">
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Понедельник') }}</div>
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Вторник') }}</div>
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Среда') }}</div>
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Четверг') }}</div>
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Пятница') }}</div>
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Суббота') }}</div>
                <div class="dsv-calendar__month-col px-1 px-lg-4">{{ $t('Воскресенье') }}</div>
            </div>
        </div>
        <div v-for="(week, i) in weeks"
             v-bind:key="i"
             class="row mx-0 px-0">
            <div class="col-10 row mx-0 px-0 dsv-calendar__month-week">
                <div v-for="(day, j) in week.days"
                     v-bind:key="j"
                     class="col-2 px-1 py-1">
                    <div :class="['day dsv-calendar__month-day', day.out_range ? 'other_month' : '']" @click="showDay(day)">
                        <div v-for="(task, z) in day.tasks"
                             v-bind:key="z">
                            <div v-if="z<3" :class="['day_text', z==0 ? 'day_text1': z==1 ? 'day_text2' : 'day_text3']">
                                {{ task.start_time }} - {{ task.finish_time }} <br>
                                {{ task.fio }}
                            </div>
                        </div>
                        <div class="day_name">{{ day.name }}</div>
<!--                        <div class="add_task" @click.stop="working">+</div> -->
                        <div v-if="day.tasks.length>3" class="show_more">+{{ (day.tasks.length - 3) }}</div>
                    </div>
                </div>
            </div>
            <div class="col-2 px-1 py-1 dsv-calendar__month-sunday">
                <div :class="['day sunday dsv-calendar__month-day', week.sunday.out_range ? 'other_month' : '']" @click="showDay(week.sunday)">
                    <div v-for="(task, z) in week.sunday.tasks"
                         v-bind:key="z">
                        <div v-if="z<3" :class="['day_text', z==0 ? 'day_text1': z==1 ? 'day_text2' : z==2 ? 'day_text3': '']">
                            {{ task.start_time }} - {{ task.finish_time }} <br>
                            {{ task.fio }}
                        </div>
                    </div>
                    <div class="day_name">{{ week.sunday.name }}</div>
<!--                    <div class="add_task" @click.stop="working">+</div> -->
                    <div v-if="week.sunday.tasks.length>3" class="show_more">+{{ (week.sunday.tasks.length - 3) }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Month',
        props: ['weeks'],
    data: () => ({
    }),
    mounted() {
    },
    methods: {
        working() {
//            window.myAlert('работаем...')
        },
        showDay(day) {
            this.$emit('showDay', day)
        }
    }
  }
</script>
