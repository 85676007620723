/**
 * @typedef {object} deleteParams
 * @property {number} id Id узла, который хотим удалить.
 */
/**
 * Удаление узла и вложенных чек-листов.
 * @param {deleteParams} params Параметры.
 * @param {function} callback Функция обратного вызова при успешном вызове. 
 */
export function deleteChecklist(params, callback) {
    window.myAjax('DELETE', '/api/checklist', params, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    });
}