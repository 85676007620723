import CreateDev from './../../devs/CreateDev';

export default {
    name: 'DigressionManager',
    components: {
        CreateDev
    },
    props: ['selectedObj', 'activeCam', 'snapshots','checklistMode', 'isSmart'],
    data: () => ({
        createdDevs: [],
        active: 1,
        snapshotSelected: {
            img_id: null,
            img_src: null,
            video_src: null,
            name: null,
            type_id: 1
        }
    }),
    watch: {
        snapshots: {
            handler(value) {
                this.createdDevs.push({id: this.createdDevs.length + 1, status: 'new', snaps: value, data: {}});
                // this.createdDevs.push({id: this.createdDevs.length + 1, status: 'new'});
                this.active = this.createdDevs[this.createdDevs.length - 1].id;
            }
        }
    },
    mounted() {
        this.createdDevs.push({id: 1, status: 'new', snaps: this.snapshots});
        // todo
    },
    beforeDestroy() {
        window.archiveDate = null;
    },
    methods: {
        setActive(cardId) {
            if (this.active) {
                const data = this.$refs['CreateDevRef' + this.active][0].getData();
                const activeIndex = this.createdDevs.findIndex(card => card.id === this.active);
                if (activeIndex >= 0) {
                    if (data === 'cancel') {
                        this.createdDevs.splice(cardIndex, 1);
                    } else {
                        this.createdDevs[activeIndex].data = data;
                    }
                }
            }
            this.active = cardId;
            const cardIndex = this.createdDevs.findIndex(card => card.id === cardId);
            if (cardIndex >= 0) {
                const card = this.createdDevs[cardIndex];
                if (card.status === 'saved') {
                    this.$refs['CreateDevRef' + card.id][0].showDevCard(card.data);
                } 
            }
        },
        showDevCard() {
            // this.$refs['CreateDevRef'].showDevCard(data);
        },
        goBack() {
            if (this.createdDevs.filter( s => s.status === 'new').length) {
                window.myConfirm('Внимание!', 'Не все отклонения сохранены. Вернуться к камерам?', () => {
                    this.$emit('goToCam');
                })
            } else {
                this.$emit('goToCam');
            }
        },
        cancelDev(cardId) {
            console.log('cancelDev', cardId);
            this.active = null;
            const cardIndex = this.createdDevs.findIndex(card => card.id === cardId);
            if (cardIndex >= 0) {
                const status = this.createdDevs[cardIndex].status;
                this.active = null;
                if (status === 'new') {
                    this.createdDevs.splice(cardIndex, 1);
                    if (this.createdDevs.length <= 0) {
                        this.$emit('cancelDev');
                    }
                }
            }
        },
        startNew(params) {
            const data = params || {};
            if (data.addNew) {
                const activeIndex = this.createdDevs.findIndex(card => card.id === this.active);
                if (activeIndex >= 0) {
                    this.createdDevs[activeIndex].status = 'saved';
                    window.myAjax('GET', '/api/digressions-record', {
                        digression_record_id: data.digression.id
                    }, (error, response) => {
                        if (!error) {
                            // this.createdDevs[activeIndex].data = response.digression;
                            const a = response.digression;
                            this.createdDevs[activeIndex].data = Object.assign({}, a, {
                                objectName: ((window.myObjects || []).find( o => o.id == a.object_id) || {}).name,
                                cameraName: ((window.myCams || []).find( o => o.id == a.camera_id) || {}).name,
    
                                digressionName: ((window.myDevs || []).find( o => o.id == a.digression_id) || {}).name,
                                createdBy: (((window.myUsers || []).find( o => o.id == a.created_by) || {}).profile || {}).last_name + ' ' + (((window.myUsers || []).find( o => o.id == a.created_by) || {}).profile || {}).first_name,
                                resp: (((window.myUsers || []).find( o => o.id == a.responsible_id) || {}).profile || {}).last_name + ' ' + (((window.myUsers || []).find( o => o.id == a.responsible_id) || {}).profile || {}).first_name, 
                            })
                            this.active = null;
                        }
                    });
                }
                if (!window.fromArchive || this.isSmart) {
                    this.$emit('showTag');
                }
            } else if (data.fromSave) {
                const activeIndex = this.createdDevs.findIndex(card => card.id === this.active);
                if (activeIndex >= 0) {
                    this.createdDevs[activeIndex].status = 'saved';
                    window.myAjax('GET', '/api/digressions-record', {
                        digression_record_id: data.digression.id
                    }, (error, response) => {
                        if (!error) {
                            // this.createdDevs[activeIndex].data = response.digression;
                            const a = response.digression;
                            this.createdDevs[activeIndex].data = Object.assign({}, a, {
                                objectName: ((window.myObjects || []).find( o => o.id == a.object_id) || {}).name,
                                cameraName: ((window.myCams || []).find( o => o.id == a.camera_id) || {}).name,
    
                                digressionName: ((window.myDevs || []).find( o => o.id == a.digression_id) || {}).name,
                                createdBy: (((window.myUsers || []).find( o => o.id == a.created_by) || {}).profile || {}).last_name + ' ' + (((window.myUsers || []).find( o => o.id == a.created_by) || {}).profile || {}).first_name,
                                resp: (((window.myUsers || []).find( o => o.id == a.responsible_id) || {}).profile || {}).last_name + ' ' + (((window.myUsers || []).find( o => o.id == a.responsible_id) || {}).profile || {}).first_name, 
                            })
                            this.active = null;
                        }
                    });
                }
                if (this.createdDevs.length <= 1) {
                    this.$emit('goToCam');
                }
                window.myAlert('Отклонение сохранено.')
            } else {
                this.cancelDev(this.active)
            }
        },

    }
}
