export function RectangleTool(canvas, context, scale, parent) {
    this.canvas = canvas;
    this.context = context;
    this.context.lineWidth = 6;
    this.context.strokeStyle="rgb(255,1,0)";
    this.context.fillStyle="rgb(255,1,0)";
    this.offsetTop = canvas.offsetTop || 0;
    this.offsetLeft = canvas.offsetLeft || 0;
    this.scale = scale;
    this.isMouseDown = false;
    this.fakeRectangle = document.getElementById('dsv-imageEditor__fake-border');
    if (!this.fakeRectangle) {
        this.fakeRectangle = document.createElement('div');
        this.fakeRectangle.style.display = 'none';
        this.fakeRectangle.className = this.fakeRectangle.id = 'dsv-imageEditor__fake-border';
        parent.appendChild(this.fakeRectangle);
    }
    this.start = { x: 0, y: 0 };
    this.end = { x: 0, y: 0 };
    this.isMouseDown = false;

    this.getX = function(event) {
        return (
            event.offsetX ||
            event.touches[0].offsetX
        ) / this.scale;
    }
  
    this.getY = function(event) {
        return ((
            event.offsetY ||
            event.touches[0].offsetY
        ) / this.scale);
    }
  
    this.onmousedown = function(event) {
        event.preventDefault();
        this.isMouseDown = true;
        this.start.y = this.getY(event) * this.scale;
        this.fakeRectangle.style.top = this.start.y + 'px';
        this.start.x = this.getX(event) * this.scale;
        this.end = {
            x: this.start.x,
            y: this.start.y
        };
        this.fakeRectangle.style.left = this.start.x + 'px';
        this.fakeRectangle.style.display = 'block';
        this.drawRectangleAxis(this.start.x, this.start.x, 'width', 'left');
        this.drawRectangleAxis(this.start.y, this.start.y, 'height', 'top');
    }
  
    this.onmousemove = function(event) {
        if (!this.isMouseDown) {
            return;
        }
        const x = this.getX(event) * this.scale;
        const y = this.getY(event) * this.scale;
        this.end = { x: x, y: y };
        this.drawRectangleAxis(this.start.x, this.end.x, 'width', 'left');
        this.drawRectangleAxis(this.start.y, this.end.y, 'height', 'top');
    }
  
    this.onmouseup = function() {
        this.isMouseDown = false;
        this.fakeRectangle.style.display = 'none';
        if (this.start.x === this.end.x && 
            this.start.y === this.end.y) {
            return;
        }
        const top = this.start.y;
        const left = this.start.x;
        const width = this.end.x - this.start.x;
        const height = this.end.y - this.start.y;
        this.context.strokeRect(
            left / this.scale,
            top / this.scale,
            width / this.scale,
            height / this.scale
        );
        this.start = { x: 0, y: 0 };
        this.end = { x: 0, y: 0 };
    }

    this.drawRectangleAxis = function(start, end, sizeStyle, paddingStyle) {
        if ( end < start ) {
            this.fakeRectangle.style[sizeStyle] = start - end + 'px';
            this.fakeRectangle.style[paddingStyle] = end + 'px';
        } else {
            this.fakeRectangle.style[sizeStyle] = end - start + 'px';
        }
    }
}