<style scoped>
    .selected_month {
        background-color: #fff;
        border-radius: 7px;
        width: 96%;
    }
    .selected_month>div {
        cursor: pointer;
    }
    .month_before {
        min-width: 40px;
        height: 40px;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        background: url('../../assets/img/page_first.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
    .month_after {
        min-width: 40px;
        height: 40px;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        background: url('../../assets/img/page_last.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    .day {
        position: relative;
        background-color: #fff;
        border-radius: 25px;
        border: 1px solid #fff;
        padding: 9px;
        text-align: center;
        height: 44px;
        width: 44px;
        color: #333333;
        cursor: pointer;
    }
    .day:hover {
        border: 1px solid #ccc;
        background-color: #fff;
    }
    .day.selected {
        border: 1px solid #064443;
        background-color: #064443;
        color: #fff;
    }
    .day.filled {
        border: 1px solid #096866;
        background-color: #096866;
        color: #fff;
    }
    .sunday {
        width: 82%;
    }
</style>

<template>
    <div class="col-lg-4 mx-0 mx-lg-6 mb-8">
        <div class="d-flex justify-content-between selected_month">
            <div class="month_before" @click="setMonth(-1)"></div>
            <div class="pt-2 my_bold" @click="working">{{ theMonth }}</div>
            <div class="month_after" @click="setMonth(1)"></div>
        </div>

        <div class="errors text-center">{{ error_dates }}</div>

        <div class="row mx-0 px-0 my_bold">
            <div class="col-10 row mx-0 px-0">
                <div class="col-2 px-4">{{ $t('Пн') }}</div>
                <div class="col-2 px-4">{{ $t('Вт') }}</div>
                <div class="col-2 px-4">{{ $t('Ср') }}</div>
                <div class="col-2 px-4">{{ $t('Чт') }}</div>
                <div class="col-2 px-4">{{ $t('Пт') }}</div>
                <div class="col-2 px-4">{{ $t('Сб') }}</div>
            </div>
            <div class="col-2 px-4">
                <div class="sunday">{{ $t('Вс') }}</div>
            </div>
        </div>
        <div v-for="(week, i) in weeks"
             v-bind:key="i"
             class="row mx-0 px-0">
            <div class="col-10 row mx-0 px-0">
                <div v-for="(day, j) in week.days"
                     v-bind:key="j"
                     class="col-2 px-1 py-1">
                    <div v-if="!day.out_range" :class="['day', day.selected ? 'selected' : day.in_range ? 'filled' : '']" @click="selectDay(day)">
                        {{ day.name }}
                    </div>
                </div>
            </div>
            <div class="col-2 px-1 py-1">
                <div v-if="!week.sunday.out_range" :class="['day sunday', week.sunday.selected ? 'selected' : week.sunday.in_range ? 'filled' : '']" @click="selectDay(week.sunday)">
                    {{ week.sunday.name }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'CalendarSmall',
        props: ['dateP', 'error_dates', 'dates', 'calendar_template_id',
            'task_start_date', 'task_end_date'],
    data: () => ({
        months: [ 'Январь', 'Февраль', 'Март', 'Апрель',
            'Май', 'Июнь', 'Июль', 'Август',
            'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
        ],
        date: new Date(),
        now: new Date(),
        selected_day1: null,
        selected_day2: null,
        weeks: [],
        theMonth: '',
    }),
    mounted() {
        this.now.setTime(this.now.getTime()-86400000)
        this.date = new Date(this.dateP.getTime())
        if(this.task_start_date) {
            this.selected_day1 = { ts: new Date(this.task_start_date) }
            this.selected_day2 = { ts: new Date(this.task_end_date) }
            this.selected_day1.ts.setHours(0,0,0,0)
            this.selected_day2.ts.setHours(0,0,0,0)
        }
        this.setMonth(0)
    },
    methods: {
        working() {
//            window.myAlert('работаем...')
        },
        selectDay(day) {
            this.dates.start = null
            this.dates.end = null
            if(day.ts.getTime()<this.now.getTime())
                window.myAlert('Прошлые даты недопустимы')
            else if(this.task_start_date) {
                this.selected_day2 = Object.assign({}, day)
                this.setRangeDays()
            } else if(this.selected_day1 && !this.selected_day2) {
                if(day.ts>this.selected_day1.ts)
                    this.selected_day2 = Object.assign({}, day)
                else {
                    this.selected_day2 = Object.assign({}, this.selected_day1)
                    this.selected_day1 = Object.assign({}, day)
                }
                this.setRangeDays()
            } else {
                this.deselectDays()
                this.selected_day1 = Object.assign({}, day)
                this.selected_day2 = null
                day.selected = true
            }
        },
        setRangeDays() {
            if(this.selected_day2) {
                this.dates.start = this.selected_day1.ts
                this.dates.end = this.selected_day2.ts
                for(let j=0; j<this.weeks.length; j++) {
                    for(let i=0; i<this.weeks[j].days.length; i++) {
                        if (this.templateFilterTask(this.calendar_template_id, this.weeks[j].days[i].ts)) {
                            this.weeks[j].days[i].in_range = false
                            this.weeks[j].days[i].selected = false
                        } else if (this.weeks[j].days[i].ts.getTime() == this.selected_day1.ts.getTime() ||
                            this.weeks[j].days[i].ts.getTime() == this.selected_day2.ts.getTime()) {
                            this.weeks[j].days[i].selected = true
                        } else if (this.weeks[j].days[i].ts.getTime() > this.selected_day1.ts.getTime() &&
                            this.weeks[j].days[i].ts.getTime() < this.selected_day2.ts.getTime() + 86400000) {
                            this.weeks[j].days[i].in_range = true
                            this.weeks[j].days[i].selected = false
                        } else {
                            this.weeks[j].days[i].in_range = false
                            this.weeks[j].days[i].selected = false
                        }
                    }
                    if(this.templateFilterTask(this.calendar_template_id, this.weeks[j].sunday.ts)) {
                        this.weeks[j].sunday.selected = false
                        this.weeks[j].sunday.in_range = false
                    } else if(this.weeks[j].sunday.ts.getTime()==this.selected_day1.ts.getTime() ||
                        this.weeks[j].sunday.ts.getTime()==this.selected_day2.ts.getTime())
                        this.weeks[j].sunday.selected = true
                    else if(this.weeks[j].sunday.ts.getTime()>this.selected_day1.ts.getTime() &&
                        this.weeks[j].sunday.ts.getTime()<this.selected_day2.ts.getTime() + 86400000) {
                        this.weeks[j].sunday.in_range = true
                        this.weeks[j].sunday.selected = false
                    }  else {
                        this.weeks[j].sunday.in_range = false
                        this.weeks[j].sunday.selected = false
                    }
                }
            }
        },
        deselectDays() {
            for(let j=0; j<this.weeks.length; j++) {
                for(let i=0; i<this.weeks[j].days.length; i++) {
                    this.weeks[j].days[i].in_range = false
                    this.weeks[j].days[i].selected = false
                }
                this.weeks[j].sunday.in_range = false
                this.weeks[j].sunday.selected = false
            }
        },
        setMonth(i) {
            this.date.setMonth(this.date.getMonth() + i)
            this.theMonth = this.months[this.date.getMonth()] + ' ' + this.date.getFullYear()
            this.showCalendar()
            this.setRangeDays()
        },
        showCalendar() {
            let nextStep = true,
                newWeek = [],
                tempDate = new Date(this.date.getTime())
            tempDate.setDate(1)
            let weekDay = tempDate.getDay()
            if(weekDay==0)
                weekDay = 7
            tempDate.setTime(tempDate.getTime()-86400000*(weekDay-1))
            this.weeks = []
            while(nextStep) {
                if(tempDate.getTime()>this.date.getTime() &&
                    tempDate.getMonth()!=this.date.getMonth() &&
                    tempDate.getDay()==1)
                    nextStep = false
                else {
                    let newDay = {out_range: tempDate.getMonth()!=this.date.getMonth(),
                        in_range: false,
                        selected: false,
                        name: tempDate.getDate(),
                        ts: new Date(tempDate.getTime())
                    }
                    if(newDay.name<10)
                        newDay.name = '0' + newDay.name
                    if(tempDate.getDay()==1)
                        newWeek = {
                            days: [newDay],
                            sunday: null
                        }
                    else if(tempDate.getDay()==0) {
                        newWeek.sunday = newDay
                        this.weeks.push(newWeek)
                    } else
                        newWeek.days.push(newDay)
                    tempDate.setTime(tempDate.getTime()+86400000)
                }
            }

        },

        templateFilterTask(calendar_template_id, day) {
            let res = false
            if(calendar_template_id) {
                for(let j=0; j<window.myCalendarTemplates.length; j++) {
                    if(window.myCalendarTemplates[j].id==calendar_template_id) {
                        switch(window.myCalendarTemplates[j].name) {
                            case 'Будние дни':
                                if(day.getDay()==0 || day.getDay()==6)
                                    res = true
                                break
                            case 'Выходные':
                                if(day.getDay()>0 && day.getDay()<6)
                                    res = true
                                break
                            case 'Четные дни':
                                if(day.getDate()%2)
                                    res = true
                                break
                            case 'Нечетные дни':
                                if(day.getDate()%2==0)
                                    res = true
                                break
                        }
                    }
                    if(res)
                        break
                }
            }
            return res
        },


    }
  }
</script>
