import { getAllChecklists } from '../Utils/getAllChecklists';
import { deleteChecklist } from '../Utils/deleteChecklist';
import { createChecklist } from '../Utils/createChecklist';
import { updateChecklist } from '../Utils/updateChecklist';
import { getChecklist } from '../Utils/getChecklist';
import EditChecklist from './../edit/editChecklist';
import EditGroup from './../edit/editGroup';
import EditPoint from './../edit/editPoint';
import PanelList from './../panelList';
import PanelCard from './../panelCard';

export default {
    name: 'ChecklistContent',
    components: {
        EditChecklist,
        EditGroup,
        EditPoint,
        PanelList,
        PanelCard,
    },
    data: () => ({
        /** Список чек-листов. */
        checklists: null,
        /** Текущий выбранный чек-лист. */
        checklist: null,
        /** Выбранная группа/корень. */
        group: null,
        /** Выбранный пукнт чек-листа. */
        point: null,
        /** Вид панели: 'default', 'root', 'subgroup', 'point'. */
        mode: 'default',
        /** Создается ли корневая группа или подгруппа. */
        isCreatedRoot: false,
        /** Текущая группа. */
        parent: null,
        copyMap: {},
        toCopySave: [],
        copyLoading: false,
    }),
    mounted() {
        this.getAllChecklistsCallback = this.getAllChecklistsCallback.bind(this);
        this.deleteChecklistCallback = this.deleteChecklistCallback.bind(this);
        this.saveChecklistCallback = this.saveChecklistCallback.bind(this);
        this.getChecklistCallback = this.getChecklistCallback.bind(this);
        this.createGroupHandler = this.createGroupHandler.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.updatePoint = this.updatePoint.bind(this);
        this.update = this.update.bind(this);
        getAllChecklists(this.getAllChecklistsCallback);
        document.getElementById("dsv-checklist-content").addEventListener("pointSelected", (event) => {
            this.update(event.detail);
        });
        window.$('#app').addClass('dsv-checkmode');
        const templatesTree = document.getElementById('template_list_tmp');
        if (templatesTree) { templatesTree.style.display = 'none'; }
        const searchBlock = document.getElementById('search_block');
        if (searchBlock) { searchBlock.style.display = 'none'; }
        
        const objectsHeader = document.getElementById('dsv-leftNavi__header');
        if (objectsHeader) { objectsHeader.style.display = 'none'; }
        const objectsTree = document.getElementById('templates-tree');
        if (objectsTree) { objectsTree.style.display = 'none'; }
    },
    destroyed() {
        this.checklistOff();
    },
    methods: {
        checklistOff() {
            window.checklistCardPoint = null;
            const updateEvent = new CustomEvent("update", { detail: null });
            document.getElementById("dsv-checklist-tree").dispatchEvent(updateEvent);
            // todo понормально сделать!
            window.$('#app').removeClass('dsv-checkmode');
            const templatesTree = document.getElementById('template_list_tmp');
            if (templatesTree) { templatesTree.style.display = 'block'; }
            const searchBlock = document.getElementById('search_block');
            if (searchBlock) { searchBlock.style.display = 'flex'; }
            const objectsHeader = document.getElementById('dsv-leftNavi__header');
            if (objectsHeader) { objectsHeader.style.display = 'block'; }
            const objectsTree = document.getElementById('templates-tree');
            if (objectsTree) { objectsTree.style.display = 'block'; }
            const contactBlock = document.querySelector('div[name=Contacts]')
            if (contactBlock) { contactBlock.style.display = 'none'; }
            const reports = document.querySelector('div[name=Reports]');
            if (reports) { reports.style.display = 'block'; }
            const calendar = document.querySelector('div[name=Calendar]');
            if (calendar) { calendar.style.display = 'block'; }
            const analyticReports = document.querySelector('div[name=AnalyticReports]');
            if (analyticReports) { analyticReports.style.display = 'block'; }
            const journal = document.querySelector('div[name=IZK]');
            if (journal) { journal.style.display = 'block'; }
            const neural = document.querySelector('div[name=Neural]');
            if (neural) { neural.style.display = 'block'; }
        },
        /** Сообщить дереву в левом меню обновиться. */
        updateTree(params) {
            const updateEvent = new CustomEvent("update", {
                detail: params
            });
            document.getElementById("dsv-checklist-tree").dispatchEvent(updateEvent);
        },

        /** Обновляет панель настроек в зависимости от выбранного элемента чек-листа. */
        update(detail) {
            switch (detail.type) {
                case 'root':
                    this.checklist = detail;
                    this.mode = 'default';
                    this.point = null;
                    this.group = null;
                    break;
                case 'subgroup':
                    this.group = detail;
                    this.point = null;
                    this.mode = 'subgroup';
                    break;
                case 'point':
                    this.point = detail;
                    this.mode = 'point';
                    break;
                default:
                    break;
            }
        },

        /** Скрыть карточки редактирования и показать список доступных чек-листов или панель настройки выбранного. */
        resetMode(params) {
            this.point = null;
            this.group = null;
            this.mode = 'default';
            this.updateTree(params);
        },

        // колбэки

        /** Функция обратного вызова при получении списках всех чек-листов. */
        getAllChecklistsCallback(response) {
            this.checklists = response.check_lists;
        },

        /** Функция обратного вызова при получении выбранного чек-листа. */
        getChecklistCallback(response) {
            this.checklist = response.check_list;
            const params = { 
                id: this.checklist.id
            }
            this.resetMode(params);
        },

        /** Функция обратного вызова при создании чек-листа (корня). */
        saveChecklistCallback(response) {
            let id;
            if (this.checklist) {
                id = this.checklist.id;
            } else {
                id = response.checklist_elem.id;
            }
            this.parent = null;
            getAllChecklists(this.getAllChecklistsCallback);
            getChecklist({id: id}, this.getChecklistCallback);
            this.resetMode({id: id});
        },

        /** Функция обратного вызова при удалении. */
        deleteChecklistCallback() {
            getAllChecklists(this.getAllChecklistsCallback);
            this.checklist = null;
            this.resetMode();
        },

        deleteCallback() {
            this.mode = 'default';
            this.group = null;
            this.point = null;
            getAllChecklists(this.getAllChecklistsCallback);
            getChecklist({id: this.checklist.id}, this.getChecklistCallback);
        },

        // обработчики

        backHandler() {
            this.checklist = null;
            this.resetMode();
            getAllChecklists(this.getAllChecklistsCallback);
        },

        deleteHandler(options) {
            if (options) {
                if (options.group) {
                    deleteChecklist({id: this.group.id}, this.deleteCallback)
                } else if (options.point) {
                    deleteChecklist({id: this.point.id}, this.deleteCallback)
                }
            }
        },

        /** Клик по чек-листу из списка. */
        checklistClickHandler(options) {
            window.$('.dsv-checklistTree__item').removeClass('active');
            const params = {
                id: options.id
            };
            getChecklist(params, this.getChecklistCallback);
        },

        /** Клик по чек-листу из списка. */
        copyClickHandler() {
            this.copyLoading = true;
            window.$('.dsv-checklistTree__item').removeClass('active');
            window.myAjax('GET', '/api/checklist', {
                checklist_id: this.checklist.id
            }, (error, response) => {
                if (error) {
                    window.myAlert('Ошибка, при получении списка чек-листов.');
                } else {
                    const toCopy = response.check_list;
                    // this.toCopySave = [];
                    this.toCopySave = this.getAllChildren(toCopy.children);
                    // this.saveCopyChildren();
                    // const children = this.getAllChildren(toCopy.children);

                    // const toCopyGroups = children.filter( item => item.type === 'subgroup' );
                    // const toCopyPoints = children.filter( item => item.type === 'point' );


                    window.myAjax('POST', '/api/checklist', {
                        type: toCopy.type,
                        caption: toCopy.caption + ' (копия)',
                        report_index: toCopy.report_index,
                    }, (error2, resp2) => {
                        if (error2) {
                            window.myAlert('Ошибка копирования');
                        } else {
                            this.copyMap[toCopy.id] = resp2.checklist_elem.id;
                            this.saveCopyChildren();
                        }
                    });
                }
            })
        },
        saveCopyChildren() {
            if (this.toCopySave.length) {
                const toCopy = this.toCopySave.shift()
                window.myAjax('POST', '/api/checklist', {
                    type: toCopy.type,
                    caption: toCopy.caption + ' (копия)',
                    title: toCopy.title,
                    parent_id: this.copyMap[toCopy.parent_id],
                    description: toCopy.description,
                    available_digressions: toCopy.available_digressions,
                    report_index: toCopy.report_index,
                }, (error, resp) => {
                    if (error) {
                        window.myAlert('Ошибка копирования');
                    } else {
                        this.copyMap[toCopy.id] = resp.checklist_elem.id;
                        this.saveCopyChildren();
                    }
                });
            } else {
                this.copyLoading = false;
                window.myAlert('Копия чек-листа создана');
                this.backHandler();
            }
        },

        getAllChildren(items) {
            let result = [];
            (items || []).forEach( item => {
                result.push(item);
                if (item.type === 'subgroup') {
                    const children = this.getAllChildren(item.children);
                    result.push.apply(result, children);
                }
            })
            return result;
        },
        updateChecklistHandler() {
            window.myAjax('PUT', '/api/checklist', this.checklist, (error) => {
                if (error) {
                    window.myAlert('Ошибка сохранения чек-листа');
                } else {
                    window.myAlert('Чек-лист сохранен');
                    this.backHandler();
                }
            });
        },

        /** Клик по "Создать новый чек-лист". */
        createChecklistHandler() {
            this.mode = 'checklist';
        },

        /** Клик по кнопкам "Создать подгруппу" и "Создать пункт". */
        createHandler(options) {
            if (options.isGroup) {
                this.createGroupHandler(false);
            } else {
                this.mode = 'point';
            }
        },

        /** Клик по "Создать новую группу". */
        createGroupHandler(isRoot) {
            this.isCreatedRoot = isRoot;
            if (this.mode === 'subgroup') {
                this.$refs['GroupRef'].update();
                this.parent = this.group;
                this.group = null;
            } else {
                this.mode = 'subgroup';
            }
        },

        /** Клик по "Удалить чек-лист". */
        deleteChecklistHandler() {
            deleteChecklist({id: this.checklist.id}, this.deleteChecklistCallback)
        },

        /** Клик по "Отмена". */
        cancelHandler() {
            this.resetMode({ id: this.checklist ? this.checklist.id : null });
        },

        /** Клик по "Сохранить чек-лист". */
        saveChecklistHandler(options) {
            const params = {
                caption: options.caption,
                type: 'root',
                available_digressions: null
            }
            createChecklist(params, this.saveChecklistCallback);
        },

        /** Клик по "Сохранить группу". */
        saveGroupHandler(options) {
            let params = {};
            if (options.group) {
                params = options.group;
                params.caption = options.caption;
                updateChecklist(params, this.updatePoint);
            } else {
                params = {
                    caption: options.caption,
                    parent_id: this.parent ? this.parent.id : this.checklist.id,
                    type: 'subgroup'
                }
                createChecklist(params, this.saveChecklistCallback);
            }
        },

        /** Клик по "Сохранить пункт". */
        savePointHandler(options) {
            let params = {};
            if (options.point) {
                params = options.point;
                params.caption = options.caption;
                params.title = options.title;
                params.description = options.description;
                params.available_digressions = options.digressions;
                updateChecklist(params, this.updatePoint);
            } else {
                params = {
                    parent_id: this.group.id,
                    type: 'point',
                    caption: options.caption,
                    title: options.title,
                    description: options.description,
                    available_digressions: options.digressions
                }
                createChecklist(params, this.saveChecklistCallback);
            }
        },

        updatePoint() {
            getAllChecklists(this.getAllChecklistsCallback);
            getChecklist({id: this.checklist.id}, this.getChecklistCallback);
            this.resetMode({id: this.checklist.id});
        }
    }
}
