/**
 * @param {*} id
 */
export function getComments(params, callback) {
    const url = '/api/digression-record-comments';
    const method = 'GET';
    window.myAjax(method, url, {
        digression_list_id: params.id
    }, function(error, response) {
        if (error) {
            window.myAlert(response.message || 'Ошибка получения комментариев.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_list_id
 * @param {*} comment
 */
export function addComment(params, callback) {
    const url = '/api/digression-record-comments';
    const method = 'POST';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert(response.message || 'Ошибка отправки комментария.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_list_comment_id
 * @param {*} comment
 */
export function updateComment(params, callback) {
    const url = '/api/digression-record-comments';
    const method = 'PUT';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert(response.message || 'Ошибка обновления комментария.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_list_comment_id
 */
 export function deleteComment(params, callback) {
    const url = '/api/digression-record-comments';
    const method = 'DELETE';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert(response.message || 'Ошибка удаления комментария.');
        } else {
            callback(response);
        }
    });
}
