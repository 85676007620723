export default {
    name: 'SmartArchive',
    components: {
        // todo
    },
    props: {
        activeCam: {
            type: Object,
            default: () => ({}),
        },
        object: {
            type: Object,
            default: () => ({}),
        }
    },
    data: () => ({
        getRecordsData: {
            camera_id: null,
            start_time_in_sec: null,
            end_time_in_sec: null
        },
        currentRecordId: null,
        //todo
    }),
    watch: {
        //todo
    },
    mounted() {
        console.log('SmartArchive mounted')
        var start = new Date();
        start.setUTCHours(0,0,0,0);
        var end = new Date();
        end.setUTCHours(23,59,59,999);
        this.getRecordsData = Object.assign({
            camera_id: this.activeCam.id,
            start_time_in_sec: Math.floor(start.valueOf() / 1000),
            end_time_in_sec: Math.floor(end.valueOf() / 1000)
        })
        this.getRecords();
    },
    updated() {
        //todo
    },
    beforeDestroy() {
        //todo
    },
    methods: {
        getRecords() {
            window.myAjax('GET', '/smart_archive/records', this.getRecordsData, this.getRecordsCallback);
        },
        getRecordsCallback(error, response) {
            if (!error) {
                console.log('getRecordsCallback response', response);
                // if (response.le)
            }
        },
        updateRecord() {
            // window.myAjax('GET', '/smart_archive/snapshot', this.getRecordsData, this.getRecordsCallback);
            
        }
    }
}
