export default {
    name: 'PricesUnitList',
    props: {
        // todo
    },
    components: {
        // todo
    },
    data: () => ({
        loading: false,
        units: [],
        selected: null
    }),
    watch: {
        // todo
    },
    computed:{
        // todo
    },
    mounted() {
        this.getUnits();
    },
    destroyed() {
        // todo
    },
    methods: {
        getUnits() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/measure_units', {}, this.getUnitsHandler);
        },
        getUnitsHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.units = response;
            }
        },
        create() {
            this.selected = {
                title: ''
            };
        },
        cancel() {
            this.selected = null;
        },
        save() {
            window.myAjax('POST', '/price-tag/measure_unit', this.selected, this.savedHandler);
        },
        savedHandler() {
            this.getUnits();
            this.cancel();
        },
        remove(unit) {
            window.myAjax('DELETE', '/price-tag/measure_unit', {
                measure_unit_id: unit.id
            }, this.removeHandler);
        },
        removeHandler() {
            this.getUnits();
        }
    }
}
