<style scoped>
    .ierarhia div>div>p {
        cursor: pointer;
        transition: all .2s ease-out;
    }
    .ierarhia div>div>p.active {
        font-weight: bold;
        color: #2A6162;
    }
    .ierarhia div>div>p.object.active {
        color: #096866;
    }
    .ierarhia div>div>p:hover {
        color: #999;
    }
    .ierarhia div>div>p.disabled:hover {
        color: inherit;;
    }
    .ierarhia>div>div span {
        overflow: hidden;
        display: block;
        transition: all .5s ease-out;
        height: 0;
    }
    .ierarhia div.opened>span {
        height: auto;
    }
    .ierarhia .dsv-nav__tree-object {
        position: relative;
        padding-left: 20px;
    }
    .ierarhia div.hasChildren::before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        top: 1px;
        left: 0px;
        margin-left: -6px;

        background: url('../../assets/img/dropdown_arrow.svg') center no-repeat;
        background-size: 12px;
        /* filter: grayscale(1); */
    }
    .ierarhia div.hasChildren.opened::before {
        top: 2px;
        filter: none;
        -moz-transform: rotate(180deg); /* Для Firefox */
        -ms-transform: rotate(180deg); /* Для IE */
        -webkit-transform: rotate(180deg); /* Для Safari, Chrome, iOS */
        -o-transform: rotate(180deg); /* Для Opera */
        transform: rotate(180deg);
    }

    @media (max-width: 1024px) {
        .dsv-nav__tree-object {
            margin-top: 8px;
            font-size: 20px;
        }

        .dsv-nav__tree-object-caption {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .dsv-nav__tree-object_objName {
        margin: 0;
        font-size: 18px;
        position: fixed;
        top: 0;
        left: 0;
        width: 418px;
        padding: 33px 16px;
        background: white;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        color: #171E1E;
        display: flex;
    }
    .dsv-nav__tree-object_hide {
        display: none;
    }
</style>

<template>
    <div v-if="obj">
        <span class="dsv-nav__tree-object_objName">{{ obj.name }}</span>
    </div>
    <div v-else>
        <div v-for="node in nodes"  v-bind:key="node.id">
            <div
                :mode="mode"
                :class="[
                    node.hasChildren, 'dsv-nav__tree-object', getContainerClassName(),
                    !noCams || (noCams && noCamsObjects.includes(node.id)) ? '' : 'dsv-nav__tree-object_hide'
                ]"
                v-bind:node_id="node.id">
                <p :class="[node.object_type=='object'? 'object':'', 'mb-0', getObjClassName(node)]"
                    @click="showObject(node)">{{ node.name }}</p>
                <span v-if="node.childrenNodes.length">
                    <TreeNodes
                        :nodes="node.childrenNodes"
                        :ids="ids"
                        :noCams="noCams"
                        :noCamsObjects="noCamsObjects"
                        @showObject="showObject" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TreeNodes',
    props: ['nodes', 'ids', 'obj', 'mode', 'noCams', 'noCamsObjects'],
    data: () => ({
        // noCamsObjects: [],
        // todo
    }),
    mounted() {
        // todo
    },
    methods: {
        showObject(obj) {
            this.$emit('showObject', obj)
        },
        getContainerClassName() {
            let classNames = [];
            // if (window.checklistsMode) {
            //     classNames.push('opened');
            // }
            return classNames.join(' ');
        },
        getObjClassName(node) {
            let classNames = [];
            if (window.checklistsMode) {
                if (node.object_type !== 'zone') {
                    classNames.push('disabled');
                }
            }
            return classNames.join(' ');
        },
        inIds() {
            // if (!window.checklistsMode) {
            return true;
            // }
            // return false;
        },

    }
}
</script>
