<style scoped>
  .object_right_panel {
    background: #FFFFFF;
    box-shadow: 0px 0.747159px 2.13079px rgba(0, 0, 0, 0.0196802), 0px 1.79553px 5.12058px rgba(0, 0, 0, 0.0282725), 0px 3.38082px 9.64159px rgba(0, 0, 0, 0.035), 0px 6.0308px 17.199px rgba(0, 0, 0, 0.0417275), 0px 11.28px 32.1688px rgba(0, 0, 0, 0.0503198), 0px 27px 17px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 100%;
  }
</style>

<template>
    <div style='background: #f2f1f1;'>
        <div class='p-0 w-100'>
            <Header :content="content"
                @logoutUser="logoutUser"
                @changed="toggleContent($event)"/>
            <div class="content_admin">
                <div class="scrolling_content">
                    <Clients v-if="content == 'Clients'"
                        @enterToClient="enterToClient"/>
                    <div v-else-if="content == 'Sensors'" class="mx-6 mt-7">
                        <div class="row py-0 mx-0 px-0">
                            <div class="my_h1 mt-2 mb-3">{{ $t('Устройства') }}</div>
                        </div>
                        <div class="object_right_panel mb-12 pt-4">
                            <Sensors />
                        </div>
                    </div>
                    <div v-else-if="content == 'Features'" class="mx-6 mt-7">
                        <div class="row py-0 mx-0 px-0">
                            <div class="my_h1 mt-2 mb-3">{{ $t('Клиентские настройки') }}</div>
                        </div>
                        <div class="object_right_panel mb-12 pt-4">
                            <Features />
                        </div>
                    </div>
                    <div v-else-if="content == 'ModulePaths'" class="mx-6 mt-7">
                        <ModulePaths />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Header from './Header';
  import Clients from './Clients';
  import Sensors from './sensorsSettings';
  import Features from './featuresSettings';
  import ModulePaths from './../mlPaths/settingsPage'

  export default {
    name: 'LkMainSuper',
    components: {
      Header,
      Clients,
      Sensors,
      Features,
      ModulePaths,
    },
    data: () => ({
      settings: false,
      content: 'Clients',
    }),
    mounted() {
      window.$('.content_admin').css('min-height', window.innerHeight-60)
      // window.$('.scrolling_content').css('max-height', window.innerHeight-60)
      
        window.resizeScrollingContent();
    },
    methods: {
      logoutUser() {
        this.$emit('logoutUser');
        window.setTimeout(window.logoutUser, 200)
      },
      toggleContent(menuPunkt) {
        this.content = menuPunkt
      },
        enterToClient() {
            this.$emit('enterToClient')
            window.setTimeout(window.loginUser, 200)
        },
    }
  }

</script>
