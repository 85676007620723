import Pagination from './../../Pagination';

const PAGE_LIMIT = 100;

export default {
    name: 'BaseScript',
    components: {
        Pagination
    },
    props: {
        
        // сюда будут передаваться параметры
    },
    data: () => ({
        loading: false,
        categories: [],
        brands: [],
        edited: [],
        showAdded: false,
        added: {
            title: '',
            category_id: null,
        },
        page: 0,
        pages: [],
    }),
    mounted() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/categories', {}, (error, response) => {
                if (!error) {
                    this.loading = false;
                    this.categories = response;
                    this.getBrands();
                }
            });
        },
        getBrands() {
            this.brands = [];
            this.loading = true;
            window.myAjax('GET', '/price-tag/brands', {
                limit: PAGE_LIMIT,
                page: this.page,
            }, (error, response) => {
                if (!error) {
                    this.loading = false;
                    this.brands = response;
                    const total = this.brands[0] ? this.brands[0].total : 1;
                    this.pages = [];
                    for (let i = 1; i < Math.ceil(total / PAGE_LIMIT); i++) {
                        this.pages.push(i);
                    }
                }
            });
        },
        save(brand) {
            window.myAjax((brand || {}).id ? 'PUT' : 'POST', '/price-tag/brand', Object.assign(brand || {}, {}), (error) => {
                if (!error) {
                    this.getBrands();
                    if (!(brand || {}).id) {
                        this.added = {
                            title: '',
                            category_id: null,
                        }
                    }
                }
            });
        },

        remove(brand) {
            window.myConfirm('Внимание!', 'Удалить бренд?', () => {
                if ((brand || {}).id) {
                    window.myAjax('DELETE', '/price-tag/brand', {
                        brand_id: brand.id
                    }, (error) => {
                        if (!error) {
                            this.getBrands();
                        }
                    });
                }
            })
        },
        cancel(brand) {
            const index = this.edited.indexOf(brand.id);
            if (index >= 0) {
                this.edited.splice(index, 1);
            } else {
                this.showAdded = false;
            }
        },

        changePage(page) {
            this.page = page;
            this.getBrands();
        }
    }
}

