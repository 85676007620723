export default {
    name: 'PricesFormatList',
    props: {
        // todo
    },
    components: {
        // todo
    },
    data: () => ({
        loading: false,
        formats: [],
        selected: null
    }),
    watch: {
        // todo
    },
    computed:{
        // todo
    },
    mounted() {
        this.getFormats();
    },
    destroyed() {
        // todo
    },
    methods: {
        getFormats() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/monitoring_object_formats', {}, this.getFormatsHandler);
        },
        getFormatsHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.formats = response;
            }
        },
        create() {
            this.selected = {
                title: ''
            };
        },
        cancel() {
            this.selected = null;
        },
        save() {
            window.myAjax('POST', '/price-tag/monitoring_object_format', this.selected, this.savedHandler);
        },
        savedHandler() {
            this.cancel();
            this.getFormats();
        },
        remove(format) {
            window.myAjax('DELETE', '/price-tag/monitoring_object_format', {
                monitoring_object_format_id: format.id
            }, this.removeHandler);
        },
        removeHandler() {
            this.getFormats();
        }
    }
}
