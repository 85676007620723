import ProductList from './../productList';

export default {
    name: 'PricesCategoryList',
    components: {
        ProductList
    },
    data: () => ({
        loading: false,
        // lists
        categories: [],
        filteredCategories: [],
        openedCategories: {},
        products: [],

        filter: {
            name: ''
        },

        // selected
        selectedCategory: null
    }),
    watch: {
        clonedFilter: {
            deep: true,
            handler() {
                this.filterChanged();
            }
        }
    },
    computed:{
        clonedFilter: function(){
           return JSON.parse(JSON.stringify(this.filter))
        }
    },
    mounted() {
        this.getCategories();
    },
    destroyed() {
        // todo
    },
    methods: {
        toggleAllCategories(value) {
            const opened = {};
            this.filteredCategories.forEach( cat => {
                opened[cat.id] = typeof(value) === 'boolean' ? value : true;
            });
            this.openedCategories = Object.assign({}, opened);
        },
        toggleOpenedCategory(category) {
            const opened = {}
            opened[category.id] = !this.openedCategories[category.id];
            this.openedCategories = Object.assign({}, this.openedCategories, opened)
        },
        editCategory(category) {
            if (category) {
                this.selectedCategory = category;
            } else {
                this.selectedCategory = Object.assign({}, {
                    title: ''
                })
            }
        },
        filterChanged() {
            this.filteredCategories = [];
            const searchString = this.filter.name.trim();
            if (searchString.length) {
                this.categories.forEach(category => {
                    if (category.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                        this.filteredCategories.push(category);
                    }
                });
            } else {
                this.filteredCategories = this.categories;
            }
            // this.toggleAllCategories(true);
        },
        getCategories() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/categories', {}, this.getCategoriesHandler);
        },
        getCategoriesHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.categories = response;
                this.filteredCategories = response;
                this.toggleAllCategories(false);
                // this.getProducts();
            }
        },
        // getProducts() {
        //     window.myAjax('GET', '/price-tag/products', {}, this.getProductsHandler);
        // },
        // getProductsHandler(error, response) {
        //     if (!error) {
        //         this.products = response;
        //     }
        // },
        isValidCategory() {
            if (((this.selectedCategory || {}).title || '').length === 0) {
                return false;
            }
            return true;
        },
        saveCategory() {
            if (!this.isValidCategory()) {
                return;
            }
            const url = '/price-tag/category' + (this.selectedCategory.id ? ('/' + this.selectedCategory.id) : '');
            window.myAjax('POST', url, this.selectedCategory, this.saveCategoryHandler);
            this.selectedCategory = null;
        },
        saveCategoryHandler(error) {
            if (!error) {
                this.getCategories();
            }
        },
        showConfirm(category) {
            this.selectedCategory = category;
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить категорию?', this.deleteCategory)
        },
        deleteCategory() {
            window.myAjax('DELETE', '/price-tag/category', {
                category_id: this.selectedCategory.id
            }, this.deleteCategoryHandler);
        },
        deleteCategoryHandler(error) {
            if (!error) {
                this.getCategories();
            }
        },
    }
}
