import TabsGroup from './../../base/tabsGroup';
import PersonsList from './../../persons/personsList';
import PersonsCard from './../../persons/personsCard';
import PersonsHeader from './../../persons/personsHeader';
import PersonsFilter from './../../persons/personsFilter';

export default {
    name: 'NeuralSettings',
    components: {
        TabsGroup,
        PersonsList,
        PersonsCard,
        PersonsHeader,
        PersonsFilter
        // todo
    },
    props:{
        // todo
    },
    data: () => ({
        tabs: [
            {id: 'employee', caption: 'Сотрудники'},
            {id: 'blacklist', caption: '"Черный" список'},
            {id: 'loyal customer', caption: 'Постоянные покупатели'},
            // {id: 'none', caption: 'Без типа'}
        ],
        tabsMode: 'employee',

        selectedPerson: null,
        showCard: false,

        filter: ''
    }),
    computed: {
        // todo
    },
    watch: {
        activeObject: {
            handler() {
                // todo
            }
        }
    },
    mounted() {
        // todo
    },
    methods: {
        tabsHandler(mode) {
            this.tabsMode = mode;
            this.showCard = false;
            this.selectedPerson = null;
        },
        listHandler(data) {
            this.selectedPerson = data;
            this.showCard = true;
        },
        filterHandler(data) {
            this.filter = data;
        }
    }
}
