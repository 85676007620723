import PreviewCamera from './../previewCamera';

export default {
    name: 'PreviewList',
    components: {
        PreviewCamera,
    },
    props: {
        // templates: {
        //     type: Array,
        //     default: [],
        // },
        activeCamera: {
            type: Object,
            default: () => ({})
        },
        subMode: {
            type: Boolean,
            default: false
        },
        cameras: {
            type: Array,
            default: [],
        },
        mode: {
            type: String,
            default: null,
        },
        layout: {
            type: Number,
            default: 3,
        },
        usualRecords: {
            type: Object,
            default: () => ({})
        },
        smartRecords: {
            type: Object,
            default: () => ({})
        },
        sensorRecords: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
        loading: false,
        templates: [],
        // cameras: [],
    }),
    watch: {
        cameras: {
            deep: false,
            handler() {
                console.log(this.cameras);
            }
        }
        // selectedObj: {
        //     handler() {
        //         this.filterObjects();
        //     }
        // }
    },
    mounted() {
        this.getTemplates();
        console.log(this.cameras);
        // todo
    },
    methods: {
        camToTemplate(template, camera) {
            this.$emit('camToTemplate', template, camera);
            setTimeout(() => {
                this.getTemplates();
            }, 500);
        },
        deleteCamFromTemplate(template, camera) {
            this.$emit('deleteCamFromTemplate', template, camera);
            setTimeout(() => {
                this.getTemplates();
            }, 500);
        },
        getTemplates() {
            window.myAjax('GET', '/api/user-templates', {}, (error, response) => {
                if (!error) {
                    this.templates = response.userTemplates;
                }
            })
        },
    }
}
