<style scoped>
  #objects-list-title {
    margin-bottom: 12px;
    display: none;
    font-size: 14px;
    font-weight: 400;
    padding-left: 12px;
  }
  .template {
    cursor: pointer;
    transition: color .2s ease-out;
  }
  .template:hover {
    color: #999;
  }
  .template.active {
    font-weight: 700;
  }
  .delete_template {
    background: url(../../../assets/img/add.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    height: 22px;
    transition: all .5s ease-out;
    cursor: pointer;

    -moz-transform: rotate(-45deg); /* Для Firefox */
    -ms-transform: rotate(-45deg); /* Для IE */
    -webkit-transform: rotate(-45deg); /* Для Safari, Chrome, iOS */
    -o-transform: rotate(-45deg); /* Для Opera */
    transform: rotate(-45deg);

  }
  .delete_template:hover {
    -moz-transform: rotate(45deg); /* Для Firefox */
    -ms-transform: rotate(45deg); /* Для IE */
    -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
    -o-transform: rotate(45deg); /* Для Opera */
    transform: rotate(45deg);
  }
  .edit_template {
    background: url(../../../assets/img/pen.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    height: 22px;
    transition: all .5s ease-out;
    cursor: pointer;
    filter: grayscale(1);
  }
  .edit_template:hover {
    filter: none;
  }
  .search_block {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    /* url('../../../assets/img/left_top_search2.png'); */
    background: #cee5b7;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 94px;
    padding: 0!important;
  }
  input.search {
    background: url('../../../assets/img/search.svg');
    background-repeat: no-repeat;
    background-position: 100%;
    background-color: #f6feee;
    font-size: 12px;
    padding: 15px;
    width: 80%;
    padding-left: 18px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .search_results {
    position: absolute;
    background-color: #fff;
    width: calc(100% - 34px)!important;
    top: 76px;
    z-index: 5;
    box-shadow: 0 8px 6px rgb(0 0 0 / 10%);
    border: 1px solid #A2A5A5;
    border-radius: 12px;
  }
  .search_results>div {
    color: #111;
    padding: 4px;
    /* padding-left: 14px; */
    cursor: pointer;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #A2A5A588;
  }
  .search_results>div:first-child {
    padding-top: 10px;
  }
  .search_results>div:last-child {
    border-bottom: none;
    padding-bottom: 10px;
  }
  .search_results>div:hover,
  .search_results>div.my_hover{
    background-color: #ffffff;
    font-weight: 700;
  }

  /* -- */
  ::-webkit-scrollbar-button {
    width:4px;
    height:4px;
  }

  ::-webkit-scrollbar-track {
    background-color:#eaeaea;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 2px solid #f4f4f4;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color:#aaa;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  .dsv-nav__container {
    position: relative;
  }

  .dsv-checkmode .dsv-nav__container {
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    max-width: 418px;
  }

  .dsv-leftNavi__header {
    display: flex;
    position: relative;
    width: auto;
    background: #e7f6f5!important;
    align-items: center;
  }

  .dsv-leftNavi-header-eye {
    background-image: url('../../../assets/img/eye.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 17px;
    width: 40px;
    height: 24px;
    cursor: pointer;
    position: relative;
    margin-left: auto;
    margin-right: 4px;
    filter: saturate(0);
    opacity: 0.6;
  }

  .dsv-leftNavi-header-eye_closed:after {
    display: block;
    position: absolute;
    content: "";
    top: 9px;
    left: 4px;
    background: #2a6162;
    width: 28px;
    height: 2px;
    transform: rotate(315deg);
    box-sizing: content-box;
    border-radius: 2px;
    border: 2px solid #e7f6f5;
  }

  
  /* --- --- */
</style>

<template>
  <div class="dsv-nav__container" id="left-navi">
    <div id="search_block" class="search_block pb-4">
        <input :disabled="header=='Иерархия отклонений' || header=='Роли пользователей'"
            class="search"
            v-model="search"
            placeholder="Поиск"
            @keyup="showSearchResults($event.key)"
            @blur="cancelSearch2"
        >
      <div class="search_results" v-if="show_search && search_objects.length" @mouseover="cancelMyHover" @mouseleave="mouse_over_search_results=false">
            <div v-for="search_object in search_objects" v-bind:key="search_object.id" @click="setActiveObject(search_object)">
                {{ search_object.name || search_object.title}}
            </div>
      </div>
    </div>

    <div v-if="showBanners">
      <BannerTree ref="BannerTree" @selected="selectedBannerHandler"/>
    </div>

    <div v-else-if="showCategories">
      <CategoryTree ref="CategoryTree" @selected="selectedCategoryHandler"/>
    </div>

    <div v-else class="scrolling_content_left" id="templates-tree">
      <div id="template_list_tmp" class="template_list" v-if="show_templates">
        <h5 class="" style="position: relative">
          {{ $t('Шаблоны') }}
          <div class="controls add_object_icon" :title="$t('Создать новый шаблон')" @click="showTemplate(null)"></div>
        </h5>

        <div class="row mx-0" v-for="template in templates" v-bind:key="template.id">
          <div :class="[template.active? 'active': '', 'col template pr-1']" @click="showTemplateCams(template)">{{ template.name }}</div>
          <div :title="$t('редактировать шаблон')" class="col-1 edit_template pr-0" @click="showTemplate(template)"></div>
          <div :title="$t('удалить шаблон')" class="col-1 delete_template pr-4" @click="deleteTemplate(template)"></div>
        </div>
      </div>

      <div id="objects-list-title">Выберите объект из списка ниже</div>
      <h5 class="dsv-leftNavi__header" style="position: relative" >
        <div class="mt-3" id="dsv-leftNavi__objects_header">
          {{ $t(header) }}
        </div>
        <div v-if="header==='Список объектов'"
          :class="['dsv-leftNavi-header-eye', noCams ? 'dsv-leftNavi-header-eye_closed' : '' ]"
          :title="noCams ? $t('показать все') : $t('скрыть объекты без камер')"
          @click="toggleNoCamsObjects"></div>
        <div v-if="settings && header!='Роли пользователей'" class="controls add_object_icon2" :title="rootTitle" @click="addRootObject"></div>
      </h5>

      <LeftControlChats v-if="chats"
                        @showChat="showObjectCar"
      />
      <IerarchiaObjects v-else
        :mode="mode"
        :noCams="noCams"
        :myCams="myCams"
        :ids="ids"
        :obj="checklistObj"
        :rootNodes="theRootNodes"
        :treeBuilded="treeBuilde"
        @showObjectCa="showObjectCar"
      />

      <div v-if="mainSuperviser" class="border-top mt-4 template_list">
        <h5 class="ml-4 mt-3" style="position: relative">
          {{ $t('Группы') }}
          <div class="controls add_object_icon" :title="$t('Создать новую группу супервайзеров')" @click="showGroup(null)"></div>
        </h5>

        <div class="row mx-0" v-for="group in groups" v-bind:key="group.id">
          <div :class="[group.active? 'active': '', 'col template pr-1']" @click="showGroup(group)">{{ group.name }}</div>
          <div :title="$t('удалить группу')" class="col-1 delete_template pr-4" @click="deleteGroup(group)"></div>
        </div>

        <hr/>
      </div>
    </div>

    <div v-if="!isMobile" class="dsv-checklistTree__container">
      <ChecklistTree
        :obj="obj"></ChecklistTree>
    </div>
  </div>
</template>

<script>
  import IerarchiaObjects from '../../../components/leftTree/Ierarchia';
  import LeftControlChats from "../../../components/chats/LeftControlsChats";
  import ChecklistTree from '../../../components/checklist/tree';
  import BannerTree from '../../../components/prices/bannerTree';
  import CategoryTree from '../../../components/prices/categoryTree';

  export default {
    name: 'LeftNaviObjects',
    props: ['treeBuilde', 'theRootNodes', 'header', 'rootTitle', 'show_templates', 'settings', 'mode', 'myCams'],
    components: {
      LeftControlChats,
      IerarchiaObjects,
      ChecklistTree,
      BannerTree,
      CategoryTree
    },
    watch: {
        header: {
            handler(value) {
              if (value !== 'Список объектов') {
                this.noCams = false;
              }
            }
        },
        settings: {
            handler() {
              if (this.header !== 'Список объектов') {
                this.noCams = false;
              }
            }
        }
    },
    data: () => ({
      noCams: false,
      chats: false,
      firstEnter: true,
      templates: [],
      mainSuperviser: false,
      groups: [],
      show_search: false,
      search: '',
      search_object_hover_index: null,
      mouse_over_search_results: false,
      search_objects: [],
      obj: null,
      isMobile: false,
      ids: [],
      checklistObj: null,
      showBanners: false,
      showCategories: false
    }),
    mounted() {
      window.$('.scrolling_content_left').css('max-height', window.innerHeight-83)
      this.update = this.update.bind(this);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      }
      this.firstEnter = true
      this.getTemplates()
      if(window.getCookie('userBaseRole')=='Руководитель супервайзеров')
        this.mainSuperviser = true
      else
        this.mainSuperviser = false
      if (this.mainSuperviser) {
        this.getGroups();
      }
      
      document.getElementById('left-navi').addEventListener('update', (event) => {
          this.update(event.detail);
      });
    },
    methods: {
      toggleNoCamsObjects() {
        this.noCams = !this.noCams;
      },
      changeMenu(data) {
        this.showBanners = false;
        this.showCategories = false;
        if (!(data || {}).type) {
          return;
        }
        if (data.type === 'banners') {
          this.showBanners = true;
        } else if (data.type === 'categories') {
          this.showCategories = true;
        }
      },
      setActiveBanner(data) {
        if (this.$refs['BannerTree']) {
          this.$refs['BannerTree'].setActiveBanner(data);
        }
      },
      selectedBannerHandler(data) {
        this.$emit('selectedBanner', data);
      },
      setActiveCategory(data) {
        if (this.$refs['CategoryTree']) {
          this.$refs['CategoryTree'].setActiveCategory(data);
        }
      },
      selectedCategoryHandler(data) {
        this.$emit('selectedCategory', data);
      },
      update(detail) {
        console.log('update', detail)
        if (detail.checklistObj) {
          this.checklistObj = detail.checklistObj;
        } else {
          this.checklistObj = null;
        }
      },

      findIntoChilds(node, searchId) {
        const result = [];
        if (node.id === searchId){
          result.push(node);
          return result;
        }
        if (node.hasChildren === 'hasChildren') {
          for (let i = 0; i < node.childrenNodes.length; i++) {
            const child = node.childrenNodes[i];
            const finded = this.findIntoChilds(child, searchId);
            if (finded.length) {
              if (finded.length === 1) {
                const zones = this.getZones(node.childrenNodes);
                for (let x = 0; x < zones.length; x++) {
                  result.push(zones[x]);
                }
              }
              result.push(node);
              for (let j = 0; j < finded.length; j++) {
                result.push(finded[j]);
              }
              return result;
            }
          }
        }
        return result;
      },
      getZones(nodes) {
        const result = [];
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          if (node.object_type === 'zone') {
            result.push(node);
          }
        }
        return result;
      },
      showSearchResults(keyCode) {
        if(this.search.length > 1) {
          if(keyCode=='ArrowUp' && this.search_objects.length) {
            this.setHoverSearchObject(-1)
          } else if(keyCode=='ArrowDown' && this.search_objects.length) {
            this.setHoverSearchObject(1)
          } else if(keyCode=='Enter' && this.search_objects.length) {
            let obj = this.getHoverObject()
            if (this.showCategories) {
                this.setActiveObject(obj);
            } else if (obj) {
                this.setActiveObject(obj)
            }
          } else if(keyCode=='Escape') {
            this.cancelSearch()
          } else {
            this.search_objects = []
            let searchUpperCase = this.search.toUpperCase()
            this.search_object_hover_index = null;

            if (this.showCategories) {
                const availableCategories = this.$refs['CategoryTree'].getCategoriesTree() || [];
                let availableCategoriesArray = [];
                for (let i = 0; i < availableCategories.length; i++) {
                    let availableCategorie = availableCategories[i];
                    this.getChildrenPoints(availableCategorie, availableCategoriesArray);
                }
                availableCategoriesArray.forEach( category => {
                    let nameUpperCase = category.title.toUpperCase()
                    if (nameUpperCase.indexOf(searchUpperCase) != -1) {
                        this.search_objects.push(category)
                    }
                })
                this.search_objects = this.search_objects.sort( (a, b) => {
                    return a.title > b.title ? 1 : -1
                })
            } else {
                for (let i=0; i<window.myObjects.length; i++) {
                    let nameUpperCase = window.myObjects[i].name.toUpperCase()
                    if (nameUpperCase.indexOf(searchUpperCase)!=-1) {
                        this.search_objects.push(window.myObjects[i])
                    }
                }
            }
            this.show_search = true
          }
        } else
          this.show_search = false
      },
        getChildrenPoints(node, result = []) {
            // if (!(node.children || []).length) {
            //     result.push(path.concat(node));
            // }
            result.push(node);
            for (const child of node.children) {
                this.getChildrenPoints(child, result);
            }
            return result;
        },
      setHoverSearchObject(index) {
        if(this.search_object_hover_index==null) {
          if(index==1)
            this.search_object_hover_index  = 0
          else
            this.search_object_hover_index  = this.search_objects.length-1
        } else
          this.search_object_hover_index += index

        if(this.search_object_hover_index<0)
          this.search_object_hover_index = this.search_objects.length-1
        else if(this.search_object_hover_index>this.search_objects.length-1)
          this.search_object_hover_index = 0
        let ind = this.search_object_hover_index
        window.$('div.search_results:first>div').each(function (i) {
          if(i==ind)
            window.$(this).addClass('my_hover')
          else
            window.$(this).removeClass('my_hover')
        })
      },
      cancelMyHover() {
        this.search_object_hover_index = null
        this.mouse_over_search_results = true
        window.$('div.search_results:first>div').removeClass('my_hover')
      },
      getHoverObject() {
        if(this.search_object_hover_index == null)
          return null
        else
          return this.search_objects[this.search_object_hover_index ]
      },
      setActiveObject(obj) {
        if (this.showCategories) {
            if (obj.id.toString().indexOf('p') > -1) {
                let productId = obj.id.split('p');
                this.$refs['CategoryTree'].selectCategory({category: Object.assign(obj, {
                    id: productId[0]
                })});
            } else {
                this.$refs['CategoryTree'].selectCategory({category: obj});
            }
        } else if (obj) {
          // раскрываем дерево объектов слева и активируем выбранный
          this.openActiveObject(obj)
          // отображаем соответствующий контент справа
          this.showObjectCar(obj)
        }
        this.cancelSearch()
      },

      /**
       * Обработчик клика по крестику в меню.
       */
      closeMenu() {
        this.$emit('closeMenu', true);
      },

      openActiveObject(obj) {
        window.$('.ierarhia p').removeClass('active');
        window.$('div[node_id='+obj.id+']:first').addClass('opened')
        window.$('div[node_id='+obj.id+']:first>p:first').addClass('active')
        // раскрываем дерево родительских узлов/объектов
        if(obj.parent_id) {
          this.openTreeObjects(this.getParentObject(obj))
        }
        var myContainer = window.$('.dsv-page__menu')
        var scrollTo = window.$('div[node_id='+obj.id+']:first>p:first');
        myContainer.animate({
          scrollTop: scrollTo.offset().top - myContainer.offset().top + myContainer.scrollTop() - 94
        });
      },
      openTreeObjects(obj) {
        window.$('div[node_id='+obj.id+']:first').addClass('opened')
        // раскрываем дерево родительских узлов/объектов
        if(obj.parent_id)
          this.openTreeObjects(this.getParentObject(obj))
      },
      getParentObject(obj) {
        for(let i=0; i<window.myObjects.length; i++)
          if(window.myObjects[i].id==obj.parent_id)
            return window.myObjects[i]
        return null
      },
      cancelSearch2() {
        if(!this.mouse_over_search_results)
          this.cancelSearch()
      },
      cancelSearch() {
        this.search_objects = []
        this.search_object_hover_index = null
        this.search = ''
        this.show_search = false
      },

      addRootObject() {
        this.$emit('addRootObject', true)
      },
      showObjectCar(obj) {
        this.$emit('showObjectCard', obj)
        this.obj = obj;
        // if (window.checklistMode) {
        //   this.update({detail: { checklistObj: obj }});
        // }
        // деактивируем активный шаблон слева
        for(let i=0; i<this.templates.length; i++)
          this.templates[i].active = false
//        window.$('.template_list:first>div>div.active').removeClass('active')
      },

      getTemplates() {
        window.myAjax('GET', '/api/user-templates', {}, this.myCallbackGetTemplates)
      },
      myCallbackGetTemplates (error, response) {
        if(error)
          window.myAlert(response.message)
        else {
          window.myTemplates = response.userTemplates
          for(let i=0; i<window.myTemplates.length; i++)
            window.myTemplates[i].active = false
          this.templates = window.myTemplates
          if(this.firstEnter)
            window.setTimeout(this.showDefaultCams, 700)
        }
      },
      showDefaultCams() {
        this.firstEnter = false
        for(let i=0; i<this.templates.length; i++)
          if(this.templates[i].is_default) {
            this.showTemplateCams(this.templates[i])
            break
          }
      },

      deleteTemplate(template) {
        window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить шаблон?', this.deleteTemplate2, template)
      },
      deleteTemplate2(template) {
        window.myAjax('DELETE', '/api/user-template', {
            template_id: template.id
        }, this.myCallbackDeleteTemplate)
      },
      myCallbackDeleteTemplate (error, response) {
        if(error)
          window.myAlert(response.message)
        else {
          this.getTemplates()
//          let templateIndex = this.getTemplateIndex(response.userTemplate.id)
  //        if (templateIndex)
    //        this.templates.splice(templateIndex, 1)
        }
      },
      getTemplateIndex(template_id) {
        for(let i=0; i<this.templates.length; i++)
          if(this.templates[i].id==template_id)
            return i
        return  false
      },

      showTemplate(template) {
        this.$emit('showTemplate', template)
      },

      showTemplateCams(template) {
        for(let i=0; i<this.templates.length; i++)
          this.templates[i].active = false
        template.active = true
        this.$emit('showTemplateCams', template)
        // деактивируем активный объект слева
        window.$('.ierarhia p.active').removeClass('active')
      },

      // методы работы с группами руксупа
      getGroups() {
        let url = '/api/view-user-groups',
                method = 'GET';
        window.myAjax(method, url, {}, this.myCallbackGetGroups)
      },
      myCallbackGetGroups (error, response) {
        if(error)
          window.myAlert(response.message)
        else {
          window.myGroups = response.groups
          this.groups = window.myGroups
          for(let i=0; i<this.groups.length; i++) {
            this.groups[i].active = false
            this.groups[i].users_id = []
            for(let j=0; j<this.groups[i].users.length; j++)
              this.groups[i].users_id.push(this.groups[i].users[j].user_id)
          }
        }
      },

      deleteGroup(group) {
        window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить группу?', this.deleteGroup2, group)
      },
      deleteGroup2(group) {
        let url = '/api/delete-user-group',
                method = 'POST';
        window.myAjax(method, url, {id: group.id}, this.myCallbackDeleteGroup)
      },
      myCallbackDeleteGroup (error, response) {
        window.myAlert(response.message)
        if(!error)
          this.getGroups()
      },
      getGroupIndex(group_id) {
        for(let i=0; i<this.groups.length; i++)
          if(this.groups[i].id==group_id)
            return i
        return false
      },

      showGroup(group) {
        this.$emit('showGroup', group)
        // деактивируем все разделы навигации вверху
        window.$('.dsv-nav__item').removeClass('active')
      },

      // конец методов работы с группами руксупа

    }
  }
</script>
