import BaseButton from './../../../base/button';

const TITLE = {
    ROOT: 'Создание нового корневого узла чек-листа',
    GROUP: 'Создание новой группы',
    EDIT: 'Редактирование элемента чек-листа'
}

export default {
    name: 'EditGroup',
    components: {
        BaseButton
        // ...
    },
    props: {
        root: {
            type: Boolean,
            default: false
        },
        group: {
            type: Object
        }
    },
    data: () => ({
        title: TITLE.GROUP,
        caption: null
    }),
    mounted() {
        this.update = this.update.bind(this);
        if (this.group) {
            this.caption = this.group.caption;
            this.title = TITLE.EDIT;
        } else if (this.root) {
            this.title = TITLE.ROOT;
        }
        document.getElementById('dsv-checklist-edit-group').addEventListener('update', (event) => {
            this.update(event.detail);
        });
    },
    methods: {
        update(detail) {
            if (detail && detail.group) {
                this.group = detail.group;
                this.caption = this.group.caption;
                this.title = TITLE.EDIT;
            } else {
                this.group = null;
                this.caption = '';
                this.title = TITLE.GROUP;
            }
        },
        /** Обработчик клика по кнопке Отменить. */
        cancelHandler() {
            this.$emit('cancel');
        },

        /** Обработчик клика по кнопке Удалить. */
        deleteHandler() {
            this.$emit('delete', {
                group: true
            });
        },

        /** Обработчик клика по кнопке Сохранить. */
        saveHandler() {
            this.$emit('save', {
                group: this.group,
                caption: this.caption
            });
        },

        /** Клик по кнопкам "Создать подгруппу" и "Создать пункт". */
        createHandler(isGroup) {
            this.$emit('create', {
                isGroup: isGroup
            });
        }
    }
}
