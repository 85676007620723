export function PencilTool(canvas, context, scale) {
    this.canvas = canvas;
    this.context = context;
    this.context.lineWidth = 6;
    this.context.strokeStyle="rgb(255,1,0)";
    this.context.fillStyle="rgb(255,1,0)";
    this.scale = scale;
    this.isMouseDown = false;

    this.onmousedown = function(event) {
        event.preventDefault();
        this.isMouseDown = true;
        this.context.beginPath();
        this.context.moveTo(this.getX(event), this.getY(event));
    }

    this.ontouchstart = function(event) {
      onmousedown(event);
    }
    
    this.getX = function(event) {
        return (
            event.offsetX ||
            event.touches[0].offsetX
        ) / this.scale;
    }
  
    this.getY = function(event) {
        return ((
            event.offsetY ||
            event.touches[0].offsetY
        ) / this.scale);
    }

    this.onmousemove = function(event) {
        if (this.isMouseDown) {
            this.context.lineTo(this.getX(event), this.getY(event));
            this.context.stroke();
        }
    }

    this.ontouchmove = function(event) {
        onmousemove(event);
    }

    this.onmouseup = function() {
        this.isMouseDown = false;
        this.context.closePath();
    }

    this.ontouchend = function(event) {
        onmouseup(event);
    }
}
