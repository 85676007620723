
export default {
    name: 'PricesExcelButtons',
    props: {
        path: {
            type: String,
            default: null
        },
        getCaption: {
            type: String,
            default: null
        },
        uploadCaption: {
            type: String,
            default: null
        },
    },
    components: {
        // todo
    },
    watch: {
        // todo
    },
    data: () => ({
        // todo
        button: null
    }),
    mounted() {
        const self = this;
        this.button = document.getElementById('dsv-prices-input:' + this.path);
        this.button.addEventListener('change', function() {
            self.postExcelTemplate(this.files);
        }, false);
    },
    methods: {
        getButtonHandler() {
            this.getExcelTemplate();
        },
        getExcelTemplate() {
            if (!this.path) {
                return;
            }
            const self = this;
            let userToken = window.getCookie('userToken');
            window.$.ajax({
                type: 'GET',//"POST",
                url: '/price-tag/' + this.path + '-excel-template',
                headers: {
                    "Authorization":"Bearer " + userToken
                },
                data: {},
                // cache: false,
                // dataType: 'json',
                success: function (response) {
                    self.getExcelTemplateHandler(null, response);
                },
                error: function (response, statusText, error) {
                    self.getExcelTemplateHandler(error, response, statusText);
                }
            });
        },
        getExcelTemplateHandler(error, response) {
            if (!error) {
                this.getFile(response);
            } else {
                if ((response || {}).responseText) {
                    this.getFile(response.responseText);
                }
            }
        },
        getFile(link) {
            const url = link;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'template.xlsx';
            document.body.appendChild(a);
            a.click();
        },
        postExcelTemplate(files) {
            if (!this.path) {
                return;
            }
            if (!files.length) {
                return;
            }
            var formdata = new FormData();
            formdata.append("file", files[0]);
            window.myAjaxFile('POST', '/price-tag/' + this.path + '-from-excel', formdata, this.postExcelTemplateHandler);
        },
        postExcelTemplateHandler(error, response) {
            if (!error) {
                this.$emit('posted');
            } else {
                console.log(error, response);
            }
        }
    }
}
