 
export default {
    name: 'HeaderBurger',
    components: {
    },
    props: [
        'menuOpened'
        // todo
    ],
    data: () => ({
        // todo
    }),
    watch: {
        // todo
    },
    mounted() {
        // todo
    },
    methods: {
        // todo
    }
}
