import { render, staticRenderFns } from "./userSettings.vue?vue&type=template&id=3948a59a&scoped=true&"
import script from "./_userSettings/userSettings.js?vue&type=script&lang=js&"
export * from "./_userSettings/userSettings.js?vue&type=script&lang=js&"
import style0 from "./_userSettings/userSettings.scss?vue&type=style&index=0&id=3948a59a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3948a59a",
  null
  
)

export default component.exports