import {
    getDigressionTypeWatchers,
    addDigressionTypeWatchers,
    removeDigressionTypeWatchers,
    getContacts
} from './../watchers';

import CheckboxNew from './../../base/checkboxNew';

export default {
    name: 'Devs',
    props: ['addRootObject'],
    components: {
        CheckboxNew
    },
    data: () => ({
        attachToAppUser: true,
        nameClass: '',
        priorClass: '',
        cityClass: '',
        mapClass: 'addMap',
        addressClass: '',
        saveClass: '',
        camsClass: '',
        usersClass: '',
        devClass: '',
        select1: 1,
        select2: 5,
        select3: 3,
        select1Class: 'selectRed',
        select2Class: 'selectGreen',
        select3Class: 'selectYellow',
        showCams: false,
        showUsers: false,
        showDev: false,
        priors: [
            {
                id: 1,
                colorClass: 'selectRed',
                name: 'Высокий'
            },
            {
                id: 2,
                colorClass: 'selectYellow',
                name: 'Средний'
            },
            {
                id: 3,
                colorClass: 'selectGreen',
                name: 'Низкий'
            },
            {
                id: 4,
                colorClass: 'selectWhite',
                name: 'Чек-Лист'
            },
        ],
        objects: [],
        objectNew: {
            id: null,
            parent_id: null,
            name: '',
            priority_id: null,
            time_days: 0,
            time_hours: 0,
            time_minutes: 0,
            priorClass: '',
            icon_id: null,
            icon_file_url: null
        },
        objectRoot: {
            id: 0,
            parent_id: 0,
            parentType: 'node',
            name: '',
            object_type: 'object',
            city: '',
            address: ''
        },
        object: {
            id: null,
            parent_id: null,
            name: '',
            priority_id: null,
            priorClass: '',
            time_days: 0,
            time_hours: 0,
            time_minutes: 0,
            icon_id: null,
            icon_file_url: null
        },
        devs: [
            {
                id: 111,
                parent_id: 0,
                name: 'Кассы',
                objects_id: [1],
                priority_id: 1,
                priorClass: 'selectRed'
            }
        ],
        errorName: '',
        errorTime: '',
        days: [1,2,3,4,5,6,7],
        hours: [1,2,3,4,5,6,7,8,9,10],
        minutes: [1,5,10,15,20,30,45],
        showContacts: false,
        contacts: [],
        watchers: {},
        watchersObjects: [],
        showContactObjects: []
    }),
    mounted() {
        this.watchersObjects = (window.myObjects || []).filter( obj => obj.object_type === 'object');
        this.getContacts();
    },
    methods: {
        objectCard(objectTemp) {
            this.$emit('addRootObjectFromObjects', false)
            this.object = Object.assign({}, objectTemp)
            this.errorName = ''
            window.$('div.addMap:first').css({'background':'url(/storage/digression_icons/systems/skrepka.svg) center center no-repeat'})
        },
        newChild(dev) {
            this.$emit('addRootObjectFromObjects', false)
            let parent_id = this.object.id
            this.object = Object.assign({}, this.objectNew)
            window.$('div.addMap:first').css({'background':'url(/storage/digression_icons/systems/skrepka.svg) center center no-repeat'})
            if (dev) {
                this.object.priority_id = 1
            }
            this.object.parent_id = parent_id
            this.errorName = ''
            this.errorTime = ''
        },
        changeSelectColor() {
            this.object.priorClass = this.priors[this.object.priority_id-1].colorClass
        },
        showObjectCard(obj) {
            this.showContacts = false;
            this.watchers = {};
            window.$('div.addMap:first').css({'background':'url(/storage/digression_icons/systems/skrepka.svg) center center no-repeat'})
            if(obj==null) {
                window.$('.ierarhia p').removeClass('active');
                this.object = Object.assign({}, this.objectNew)
            } else {
                this.object = Object.assign({}, obj, {
                    icon_file_url: obj.icon_url
                })
                if(this.object.icon_file_url)
                    window.$('div.addMap:first').css({'background':'url('+this.object.icon_file_url+') center center no-repeat'})
            }
            this.errorName = ''
            this.errorTime = ''
        },
        changeInputClass() {
            if(this.object.name.length)
                this.nameClass='filled'
            else
                this.nameClass=''
        },
            chouseIcon() {
                this.$refs['dev_icon'].click()
        },

        createImage() {
            // this.object.icon_file_url = window.$('input[type=file]:first')[0].files[0];
            let file = window.$('input[type=file]:first')[0].files[0]; //this.object.icon_file_url;
            const self = this;
            if (file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    window.$('div.addMap:first').css({'background':'url(' + e.target.result + ') center center no-repeat'});
                    window.myAjax('POST', '/api/digression/icon', {
                        image: e.target.result
                    }, function(error, response) {
                        if (error) {
                            window.myAlert((response || {}).message || 'Ошибка при загрузке иконки. Попробуйте позже.');
                        } else {
                            self.object.icon_file_url = response
                        }
                    });
                };
                reader.readAsDataURL(file);
            }
        },

            saveObject() {
                if (this.object.id)
                    window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения отклонения?', this.saveObject2)
                else
                    this.saveObject2()
            },

        saveObject2() {
            if(this.valideObject()) {
                let url = '/api/digression';
                let method = this.object.id ? 'PUT' : 'POST';
    //                this.object.time = this.object.time_minutes + this.object.time_hours * 60 + this.object.time_days * 24*60
                let data = Object.assign({}, this.object);
                if (!this.object.id) {
                    data.attach_to_app_user = this.attachToAppUser;
                }
                if (!this.object.parent_id) {
                    delete data['parent_id'];
                }
                if (!this.object.priority_id) {
                    delete data['priority_id'];
                }
                if (!this.object.icon_url) {
                    delete data['icon_url'];
                }
                if (!this.object.time_days) {
                    delete data['time_days'];
                }
                if (!this.object.time_hours) {
                    delete data['time_hours'];
                }
                if (!this.object.time_minutes) {
                    delete data['time_minutes'];
                }
                if (!this.object.icon_file_url) {
                    delete data['icon_file_url'];
                }
                if (!this.object.icon_id) {
                    delete data['icon_id'];
                }
                if (!this.object.priorClass) {
                    delete data['priorClass'];
                }
                if (!this.object.updated_at) {
                    delete data['updated_at'];
                }
                if (!this.object.deleted_at) {
                    delete data['deleted_at'];
                }
                window.myAjax(method, url, data, this.myCallbackSaveObject)
            }
        },
        myCallbackSaveObject (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                if (!this.object.id) {
                    this.object.id = response.digression.id;
                }
                window.myAlert('Запись сохранена.');
                this.$emit('updateDevsListFromDevs', false)
            }
        },
            deleteObject() {
                window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить отклонение?', this.deleteObject2)
            },
        deleteObject2() {
            this.saveClass = ''
            const data = {
                id: this.object.id
            }
            window.myAjax('DELETE', '/api/digression', data, this.myCallbackDeleteObject)
        },
        myCallbackDeleteObject (error, response) {
            if(error) {
                window.myAlert(response.message || '');
            } else {
                window.myAlert('Запись удалена.');
                this.$emit('updateDevsListFromDevs', false)
    //                this.$emit('updateObjectsListFromDevs', false)
                this.showObjectCard(null)
            }
        },

        getCams() {
            let url = '/api/client-cameras',
                method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetCams)
        },
        myCallbackGetCams (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else if(response.code<300) {
                this.cams = response.client_cameras
            }
        },

        toggleLists(flag) {
            if(flag==1) {
                this.showCams=!this.showCams
                this.showUsers=false
                this.showDev=false
            } else if (flag==2) {
                this.showUsers=!this.showUsers
                this.showCams=false
                this.showDev=false
            } else if (flag==3) {
                this.showDev=!this.showDev
                this.showCams=false
                this.showUsers=false
            }
            if(this.showCams) {
                this.camsClass = 'active'
            } else {
                this.camsClass = ''
            }
            if(this.showUsers) {
                this.usersClass = 'active'
            } else {
                this.usersClass = ''
            }
            if(this.showDev) {
                this.devClass = 'active'
            } else {
                this.devClass = ''
            }
        },
        selectColor(index) {
            let colors = ['', 'selectRed', 'selectOrange', 'selectYellow', 'selectBlue', 'selectGreen']
            switch(index) {
                case 1:
                    this.select1Class = colors[this.select1]
                    break
                case 2:
                    this.select2Class = colors[this.select2]
                    break
                case 3:
                    this.select3Class = colors[this.select3]
                    break
            }
        },
        devFullName(id, fullName) {
            let dev = this.getDev(id)
            if(dev) {
                if (dev.parent_id)
                    fullName = this.devFullName(dev.parent_id, ' / ' + dev.name + fullName)
                else
                    fullName = dev.name + fullName
            }
            return fullName
        },
        getDev(id) {
            for(let i=0; i<this.devs.length; i++)
                if(this.devs[i].id==id)
                    return this.devs[i]
            return null
        },
        valideObject() {
            let res = true;
            if(this.object.name.length) {
                this.errorName = ''
            } else {
                res = false
                this.errorName = 'Не указано название'
            }
            if(this.object.priority_id!=null) {
                if(this.object.time_days>0 ||
                    this.object.time_hours>0 ||
                    this.object.time_minutes>0) {
                    this.errorTime = ''
                } else {
                    res = false
                    this.errorTime = 'Не указано время'
                }
            }
            return res
        },
        getObjects() {
            let data = {},
                url = '/api/objects',
                method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetObjects)
        },
        myCallbackGetObjects (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.objects = response.objects
            }
        },
        getObjectName(id) {
            for(let i=0; i<this.objects.length; i++) {
                if(this.objects[i].id==id)
                    return this.objects[i].name
            }
            return ''
        },

        camToObject(cam) {
            switch(this.object.object_type) {
                case 'object':
                    if(cam.object_id==null)
                        cam.object_id = this.object.id
                    else {
                        cam.object_id = null
                        cam.zone_id = null
                    }
                    break
                case 'zone':
                    if(cam.zone_id==null)
                        cam.zone_id = this.object.id
                    else {
                        cam.zone_id = null
                    }
            }
            let url = '/api/camera',
                method = 'PUT';
            window.myAjax(method, url, cam, this.myCallbackEmpty)
        },
        myCallbackEmpty (error, response) {
            if(error) {
                window.myAlert(response.message)
            }
        },

        // контакты
        getContacts() {
            getContacts({
                page: 0,
                limit: 100
            }, this.getContactsHandler)
        },
        getContactsHandler(data) {
            console.log('getContactsHandler', data);
            this.contacts = [];
            (data.contacts || []).forEach(element => {
                this.contacts.push(element);
            });
        },
        toggleShowContacts() {
            this.showContacts = !this.showContacts;
        },

        // работа с наблюдателями
        toggleWatcher(contact, watcherObject) {
            const obj = {};
            obj[contact.id + '-' + watcherObject.id] = !this.watchers[contact.id + '-' + watcherObject.id];
            this.watchers = Object.assign({}, this.watchers, obj);
            const params = [{
                digression_id: this.object.id,
                contact_id: contact.id,
                object_id: watcherObject.id
            }];
            if (obj[contact.id + '-' + watcherObject.id]) {
                addDigressionTypeWatchers({ watchers: JSON.stringify(params)});
            } else {
                removeDigressionTypeWatchers({ watchers: JSON.stringify(params)});
            }
        },
        toggleShowContactObjects(watchObject) {
            const checked = this.showContactObjects.includes(watchObject.id);
            if (!checked) {
                this.getWatchers(watchObject);
                this.showContactObjects.push(watchObject.id);
            } else {
                this.showContactObjects.splice(this.showContactObjects.indexOf(watchObject.id), 1);
            }
            
        },
        getWatchers(watchObj) {
            if ((this.object || {}).id) {
                getDigressionTypeWatchers({
                    digression_id: this.object.id,
                    object_id: watchObj.id,
                }, this.getWatchersHandler)
            }
        },
        getWatchersHandler(data) {
            this.watchers = {};
            (data || []).forEach( element => {
                this.watchers[element.contact_id + '-' + element.object_id] = true;
            })
        }
    }
}
