var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,class:[
        'dsv-prices__categoryTree-item',
        (_vm.active || {}).id === category.id ? 'dsv-prices__categoryTree-item_active' : '',
        _vm.categoryOpened.includes(category.id) ? 'dsv-prices__categoryTree-item_opened' : ''
    ],on:{"click":function($event){$event.stopPropagation();return _vm.clickHandler(category)}}},[_c('div',{staticClass:"dsv-prices__categoryTree-item-title"},[_vm._v(" "+_vm._s(category.title)+" ")]),(_vm.categoryOpened.includes(category.id))?_c('div',{staticClass:"ml-2 dsv-prices__categoryTree-item-children"},[_c('TreeTemplate',{attrs:{"categories":category.children,"active":_vm.active,"products":_vm.products,"activep":_vm.activep,"categoryOpened":_vm.categoryOpened},on:{"clickHandler":_vm.clickHandler}}),(_vm.categoryOpened.includes(category.id))?_c('div',{staticClass:"ml-2 dsv-prices__categoryTree-item-products"},_vm._l((_vm.products),function(product){return _c('div',{key:product.id},[(product.category_id === category.id)?_c('div',{class:[
                        'dsv-prices__categoryTree-item',
                        (_vm.activep || {}).id === product.id ? 'dsv-prices__categoryTree-item_active' : ''
                    ],on:{"click":function($event){$event.stopPropagation();return _vm.clickHandler(product)}}},[_c('div',{staticClass:"dsv-prices__categoryTree-item-title"},[_vm._v(" "+_vm._s(product.title)+" ")])]):_vm._e()])}),0):_vm._e()],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }