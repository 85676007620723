<template>
    <div>
        <div v-for="category in categories" v-bind:key="category.id" :class="[
            'dsv-prices__categoryTree-item',
            (active || {}).id === category.id ? 'dsv-prices__categoryTree-item_active' : '',
            categoryOpened.includes(category.id) ? 'dsv-prices__categoryTree-item_opened' : ''
        ]" @click.stop="clickHandler(category)">
            <div class="dsv-prices__categoryTree-item-title">
                {{ category.title }}
            </div>
            <div v-if="categoryOpened.includes(category.id)" class="ml-2 dsv-prices__categoryTree-item-children">
                <TreeTemplate
                    :categories="category.children"
                    :active="active"
                    :products="products"
                    :activep="activep"
                    :categoryOpened="categoryOpened"
                    @clickHandler="clickHandler"/>
                <div v-if="categoryOpened.includes(category.id)" class="ml-2 dsv-prices__categoryTree-item-products">
                    <div v-for="product in products" v-bind:key="product.id">
                        <div v-if="product.category_id === category.id" :class="[
                            'dsv-prices__categoryTree-item',
                            (activep || {}).id === product.id ? 'dsv-prices__categoryTree-item_active' : ''
                        ]" @click.stop="clickHandler(product)">
                            <div class="dsv-prices__categoryTree-item-title">
                                {{ product.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TreeTemplate',
        data: () => ({
            // 
        }),
        props: {
            categories: Array,
            categoryOpened: Array,
            products: Array,
            active: Object,
            activep: Object,
        },
        mounted() {
            // todo
        },
        methods: {
            clickHandler(item) {
                this.$emit('clickHandler', item)
            },
        }
    }
</script>
