<style scoped>
    .page {
        min-width: 40px;
        height: 40px;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        border-radius: 8px;
        color: #171E1E;
    }
    .page:hover {
        border: 1px solid #FFC807;
        /* color: #096866; */
        /* font-weight: bold; */
    }
    .page.active {
        border: 1px solid #2A6162;
        /* border: 2px solid #096866; */
        color: #2A6162;
        border-radius: 5px;
        padding-top: 5px;
    }
    .page_first {
        background: url('../assets/img/Frame 73.svg');
        transform: scaleX(-1);
        background-position: center;
        background-repeat: no-repeat;
    }
    .page_last {
        background: url('../assets/img/Frame 73.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
    .pages {
        cursor: pointer;
        width: 40px;
        filter: grayscale(1);
    }
    .pages:hover {
        filter: none;
    }

</style>

<template>
    <div class="pagination justify-center mb-12 mx-0 px-3 d-flex">
        <div class="pages page_first" @click="getPage(0)"></div>
        <div v-for="page in pages"
             v-bind:key="page">
            <div v-if="(page>request_page-number_of_pages_to_view || page>pages.length+1-number_of_pages_to_view*2) && (page<request_page+number_of_pages_to_view || page<number_of_pages_to_view*2)" :class="[page==request_page ? 'active': '', 'page']" @click="getPage(page-1)">{{page}}</div>
        </div>
        <div class="pages page_last" @click="getPage(pages.length-1)"></div>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
    props: ['request_page', 'pages'],
    data: () => ({
        number_of_pages_to_view: 4 // доступны 7 страниц, максимально 3 слева от активной и 3 справа
    }),
    methods: {
        getPage(page) {
            this.$emit('getPage', page)
        }
    }
  }
</script>
