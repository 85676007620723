import CheckboxListDropdown from './../../base/checkboxListDropdown';

export default {
    name: 'ModulesReports',
    components: {
        CheckboxListDropdown,
    },
    props: [],
    data: () => ({
        startDate: null,
        endDate: null,

        objects: [],
        selectedObjects: [],
        objectsLoading: false,
    }),
    watch: {
        // todo
    },
    mounted() {
        this.getObjects();
    },
    methods: {
        updateSelectedObjectsHandler(data) {
            this.selectedObjects = data;
        },
        resetFilter() {
            this.startDate = new Date();
            this.startDate.setDate(this.startDate.getDate() - 1);
            this.startDate = this.startDate.toISOString().slice(0,10);
            this.endDate = new Date();
            this.endDate = this.endDate.toISOString().slice(0,10);
            this.selectedObjects = [];
        },
        getObjects() {
            this.objectsLoading = true;
            window.myAjax('GET', '/api/objects', {}, (error, response) => {
                this.objectsLoading = false;
                if (error) {
                    window.myAlert(response.message || 'Ошибка загрузки объектов.');
                } else {
                    this.objects = (response.objects || []).filter( object => object.object_type === 'object');
                }
            });
        },
        getExcel() {
            let start_date = new Date(this.startDate);
            start_date.setHours(0, 0, 0);
            let end_date = new Date(this.endDate);
            end_date.setHours(23, 59, 59);
            window.myAjax('POST', '/analytic/ml_modules/excel', {
                start_date: Math.floor(start_date.valueOf() / 1000),
                end_date: Math.floor(end_date.valueOf() / 1000),
                object_ids: JSON.stringify(this.selectedObjects || []),
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка составления отчёта по работе модулей.');
                } else {
                    const url = response.excel_url;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `Отчёт работы нейромодулей.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                }
            });
        },
    }
}