import CheckboxNew from './../checkboxNew';
import CheckboxListGroup from './group';

export default {
    name: 'CheckboxList',
    components: {
        CheckboxNew,
        CheckboxListGroup
    },
    emits: ["update:value"],
    props: {
        value: {
            type: Array,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        // selectedAll: {
        //     type: Boolean,
        //     default: false,
        // },
        caption: {
            type: String,
            default: null
        },
        haveGroups: {
            type: Boolean,
            default: false
        },
        singleOption: {
            type: Boolean,
            default: false
        },
        hiddenGroupsType: {
            type: String,
            default: 'all'
        }
        // groups: {
        //     type: Array,
        //     default: []
        // }
    },
    data:() => ({
        filter: {
            name: ''
        },
        groups: {},

        items: [],

        filteredOptions: [],
        selectedAll: false,
        selectedAllGroup: [],
        filteredGroups: [],
        hiddenGroups: [],
        valueGroups: [],
        interimGroups: [],

        children: {},
    }),
    watch: {
        options: {
            deep: true,
            handler() {
                this.filteredOptions = [];
                this.options.forEach( option => {
                    this.filteredOptions.push(Object.assign({}, option))
                });
                // this.filteredOptions = [...this.filteredOptions, window.myDevs.filter( digression => !digression.priority_id)];
                this.filteredOptions = [...new Set(this.filteredOptions)];
                this.selectedAll = this.isSelectedAll();
                if (this.haveGroups) {
                    this.setGroups();
                    this.setSelectedAllGroup();
                }
            }

        },
        filter: {
            deep: true,
            handler() {
                this.filterChanged();
                this.selectedAll = this.isSelectedAll();
                // if (this.haveGroups) {
                //     this.setSelectedAllGroup();
                // }
            }
        },
        value: {
            deep: true,
            handler() {
                this.selectedAll = this.isSelectedAll();
                if (this.haveGroups) {
                    this.setSelectedAllGroup();
                }
            }
        }
    },
    mounted() {
        this.filteredOptions = [];
        this.options.forEach( option => {
            this.filteredOptions.push(Object.assign({}, option))
        });
        // this.filteredOptions = [...this.filteredOptions, window.myDevs.filter( digression => !digression.priority_id)];
        this.filteredOptions = [...new Set(this.filteredOptions)];
        this.items = [...new Set(this.filteredOptions)];
        this.selectedAll = this.isSelectedAll();
        if (this.haveGroups) {
            this.setGroups();
            this.setChildren();
            this.setSelectedAllGroup();
            // (this.items || []).forEach( item => {
            //     if (item.type === 'group') {
            //         if (!this.valueGroups.includes(item.id)) {
            //             this.hiddenGroups.push(item.id);
            //         }
            //     }
            // })
            this.setHiddenGroups();
            this.setInterimGroups();
        }
    },
    methods: {
        toggleHidden(groupId) {
            const findedIndex = this.hiddenGroups.indexOf(groupId);
            if (findedIndex < 0) {
                this.hiddenGroups.push(groupId);
            } else {
                this.hiddenGroups.splice(this.hiddenGroups.indexOf(groupId), 1);
            }
        },
        setHiddenGroups() {
            const toHide = [];
            switch (this.hiddenGroupsType) {
                case 'all':
                    (this.items || []).forEach( item => {
                        if (item.type === 'group') {
                            this.hiddenGroups.push(item.id);
                            (item.children || []).forEach(child => {
                                if (child.type === 'group') {
                                    this.hiddenGroups.push(child.id);
                                    (child.children || []).forEach(subchild => {
                                        if (subchild.type === 'group') {
                                            this.hiddenGroups.push(subchild.id);
                                        }
                                    });
                                }
                            });
                        }
                    })
                    break;
                case 'unselected':
                    // todo
                    this.hiddenGroups = [...new Set(toHide)];
                    break;
                case 'none':
                    break;
                default:
                    break;
            }
        },
        setChildren() {
            (this.items || []).forEach( item => {
                if (item.type === 'group') {
                    const children = this.getChildrenIds(item.children);
                    this.children[item.id] = [];
                    this.children[item.id] = children;
                }
            });
            (window.myDevs || []).forEach( item => {
                if (!item.priority_id) {
                    if (item.parent_id) {
                        if (!this.groups[item.parent_id]) {
                            this.groups[item.parent_id] = [];
                        }
                        this.groups[item.parent_id].push(item.id);
                    }
                }
            });
        },
        getChildrenIds(items) {
            let result = [];
            (items || []).forEach( item => {
                if (item.type === 'group') {
                    const children = this.getChildrenIds(item.children);
                    result.push.apply(result, children);
                    this.children[item.id] = [];
                    this.children[item.id] = children;
                } else {
                    result.push(item.id);
                }
            })
            return result;
        },
        setSelectedAllGroup() {
            this.valueGroups = [];
            for (let key in this.children) {
                let result = true;
                (this.children[key] || []).forEach( id => {
                    if (!this.value.includes(id)) {
                        result = false;
                    }
                })
                if (result) {
                    this.valueGroups.push(Number(key));
                }
            }
        },
    
        setGroups() {
            this.items = [];
            this.filteredGroups = [];
            this.filteredOptions.forEach( option => {
                if (!option.parent_id) {
                    this.items.push(Object.assign({}, option));
                    if (!option.priority_id) {
                        this.items[this.items.length - 1].type = 'group';
                        this.items[this.items.length - 1].children = this.getChildren(option.id);
                    }
                }
            });
            this.hideEmptyGroup();
        },
        getChildren(parentId) {
            const result = [];
            window.myDevs.forEach( option => {
                if (option.parent_id == parentId) {
                    if (!option.priority_id) {
                        result.push(option);
                        result[result.length - 1].type = 'group';
                        result[result.length - 1].children = this.getChildren(option.id);
                        if (!this.children[option.id]) {
                            this.children[option.id] = [];
                        }
                    } else {
                        if (this.filteredOptions.findIndex(opt => opt.id == option.id) >= 0) {
                            result.push(option);
                        } 
                    }
                }
            });
            return result;
        },
        hideEmptyGroup() {
            this.items.forEach( item => {
                if (item.type === 'group') {
                    item.hide = !this.itemHaveDigression(item.children);
                }
            });
        },
        itemHaveDigression(itemChildren) {
            let childrenHaveChild = false;
            for (let i = 0; i < itemChildren.length; i++) {
                const item = itemChildren[i];
                if (item.type === 'group') {
                    const haveChild = this.itemHaveDigression(item.children);
                    item.hide = !haveChild;
                    if (haveChild) {
                        childrenHaveChild = true;
                    } 
                } else {
                    return true;
                }
            }
            return childrenHaveChild;
        },
        check(optionId, checked) {
            let updatedValue = [...this.value];
            this.selectedAll = false;
            if (checked) {
                updatedValue.push(optionId);
            } else {
                updatedValue.splice(updatedValue.indexOf(optionId), 1);
            }
            let groupIndex = 0;
            for (let groupId in this.children) {
                if ((this.children[groupId] || []).includes(optionId)) {
                    groupIndex = parseInt(groupId);
                }
            }
            let valuedCount = 0;
            (this.children[groupIndex] || []).forEach( optionIndex => {
                if (updatedValue.includes(optionIndex)) {
                    valuedCount++;
                }
            })
            if (valuedCount > 0) {
                const interimIndex = this.interimGroups.indexOf(groupIndex);
                if (interimIndex === -1) {
                    this.interimGroups.push(groupIndex);
                }
            } else {
                const interimIndex = this.interimGroups.indexOf(groupIndex);
                if (interimIndex >= 0) {
                    this.interimGroups.splice(interimIndex, 1);
                }
            }
            this.setInterimGroupParent(groupIndex);
            this.$emit('update:value', updatedValue);
        },
        setInterimGroupChildren(groupId, value) {
            (this.groups[groupId] || []).forEach(groupId => {
                if (value) {
                    const interimIndex = this.interimGroups.indexOf(groupId);
                    if (interimIndex === -1) {
                        this.interimGroups.push(groupId);
                    }
                } else {
                    const interimIndex = this.interimGroups.indexOf(groupId);
                    if (interimIndex >= 0) {
                        this.interimGroups.splice(interimIndex, 1);
                    }
                }
                this.setInterimGroupChildren(groupId, value);
            })
        },
        setInterimGroupParent(groupId) {
            let parentGroupIndex = 0;
            for (let groupIndex in this.groups) {
                if ((this.groups[groupIndex] || []).includes(groupId)) {
                    parentGroupIndex = parseInt(groupIndex);
                }
            }
            if (parentGroupIndex !== 0) {
                let valuedCount = 0;
                (this.groups[parentGroupIndex] || []).forEach( groupIndex => {
                    if (this.interimGroups.includes(groupIndex)) {
                        valuedCount++;
                    }
                })
                if (valuedCount > 0) {
                    const interimIndex = this.interimGroups.indexOf(parentGroupIndex);
                    if (interimIndex === -1) {
                        this.interimGroups.push(parentGroupIndex);
                    }
                } else {
                    const interimIndex = this.interimGroups.indexOf(parentGroupIndex);
                    if (interimIndex >= 0) {
                        this.interimGroups.splice(interimIndex, 1);
                    }
                }
                this.setInterimGroupParent(parentGroupIndex);
            }
        },
        setInterimGroups() {
            for (let groupId in this.children) {
                let valuedCount = 0;
                (this.children[groupId] || []).forEach( optionId => {
                    if (this.value.includes(optionId)) {
                        valuedCount++;
                    }
                })
                if (valuedCount > 0) {
                    this.interimGroups.push(parseInt(groupId));
                }
            }
            this.interimGroups = [...new Set(this.interimGroups)];
        },
        isSelectedAll() {
            if (this.haveGroups) {
                return this.options.filter(option => !!option.priority_id).length === this.value.length;
            }
            return this.options.length === this.value.length;
        },
        filterChanged() {
            const searchString = this.filter.name.trim();
            this.filteredOptions = [];
            if (searchString.length) {
                this.options.forEach(option => {
                    if (this.haveGroups) {
                        if (option.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 || !option.priority_id) {
                            this.filteredOptions.push(Object.assign({}, option));
                        }
                    } else {
                        if (option.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                            this.filteredOptions.push(Object.assign({}, option));
                        }
                    }
                });
            } else {
                this.options.forEach( option => {
                    this.filteredOptions.push(Object.assign({}, option))
                });
            }
            
            this.filteredOptions = [...new Set(this.filteredOptions)];

            if (this.haveGroups) {
                this.setGroups();
            }
        },
        selectedAllChange(checked) {
            this.selectedAll = !this.selectedAll;
            let updatedValue = [];
            if (checked) {
                if (this.haveGroups) {
                    this.options.filter(option => !!option.priority_id).forEach( option => {
                        updatedValue.push(option.id);
                    });
                } else {
                    this.options.forEach( option => {
                        updatedValue.push(option.id);
                    });
                }
            }
            this.$emit('update:value', updatedValue);
        },
        selectedAllGroupCheck(groupId, checked) {
            this.selectedAll = false;
            let updatedValue = [...this.value];
            if (checked) {
                (this.children[groupId] || []).forEach( id => {
                    updatedValue.push(id);
                })
            } else {
                (this.children[groupId] || []).forEach( id => {
                    const findedIndex = updatedValue.indexOf(id);
                    if (findedIndex >= 0) {
                        updatedValue.splice(updatedValue.indexOf(id), 1);
                    }
                })
            }
            updatedValue = [...new Set(updatedValue)];
            if (checked) {
                const interimIndex = this.interimGroups.indexOf(groupId);
                if (interimIndex === -1) {
                    this.interimGroups.push(groupId);
                }
            } else {
                const interimIndex = this.interimGroups.indexOf(groupId);
                if (interimIndex >= 0) {
                    this.interimGroups.splice(interimIndex, 1);
                }
            }
            
            this.setInterimGroupChildren(groupId, checked);
            this.setInterimGroupParent(groupId);

            this.$emit('update:value', updatedValue);
        },
    },
}
