import { WEEKDAYS } from './../../../neural/utils'; 

export default {
    name: 'ArchiveList',
    components: {
        // todo
    },
    props: {
        tasks: {
            type: Array,
            default: []
        },
        type: {
            type: String,
            default: 'archive'
        }
    },
    data: () => ({
        weekdays: []
    }),
    mounted() {
        this.weekdays = WEEKDAYS;
    },
    methods: {
        removeTask(task, revert) {
            this.$emit('remove', task, revert);
        }
    }
}
