import DevCard from './../DevCard';
import FilesDropArea from './../../files/droparea'

export default {
    name: 'CreateDev',
    props: ['selectedObj', 'activeCam', 'snapshots','checklistMode', 'status', 'activeCard'],
    components: {
        DevCard,
        FilesDropArea
    },
    data: () => ({
        devsToUserObjects: [],
        devsToObject: [],
        devs: [],
        devSelected: false,
        object: null,
        selectedDev: null,
        resultDev: [],
        devCreated: false,
        isMobile: false,

        snapshotSelected: {
            name: null,
            img_src: null
        },
        filesLength: 0,
        filesCount: 0,
        showFiles: false,
        newSnapshots: []
    }),
    mounted() {
        if (
            (window.checklistsMode && window.checklistCardPoint) ||
            window.handDigression
        ) {
            this.showFiles = true;
        }
        this.newSnapshots = [...this.snapshots];
        this.devCreatedHandler = this.devCreatedHandler.bind(this);
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        // window.$('.scrolling_content').css('max-height', window.innerHeight - 60);
        let _devs = [];
        window.resizeScrollingContent();
        if (window.izkSelected) {
            _devs = window.myDevs;
        } else {
            const devS = this.getChecklistDevs();
            if (window.checklistCardPoint && devS === false) {
                this.goBack();
            }
            _devs = window.checklistCardPoint ? devS : window.myDevs;
        }
        const tmpIds = {};
        const tmpDevs = [];
        const object_id = this.activeCam ? this.activeCam.object_id : this.selectedObj.id;
        for (let i = 0; i < _devs.length; i++) {
            let objId = null;
            for(let o=0; o<window.myUserObjects.length; o++) {
                const obj = window.myUserObjects[o];
                if (obj.object_id == object_id) {
                    objId = obj.id;
                }
            }
            if (objId) {
                for (let j = 0; j < window.myDevsToUserObjects.length; j++) {
                    const relation = window.myDevsToUserObjects[j];                    
                    if (!tmpIds[i]) {
                        if (
                            relation.digression_id == _devs[i].id &&
                            relation.digression_type == 'create' &&
                            relation.user_object_id == objId
                        ) {
                            tmpIds[i] = true;
                            tmpDevs.push(_devs[i]);
                            const parents = this.getParents(_devs[i]);
                            for (let p = 0; p < parents.length; p++) {
                                tmpDevs.push(parents[p]);
                            }
                        }
                    }
                }
            }
        }
        this.devs = [...new Set(tmpDevs)];
        if (this.devs.length === 0 && window.checklistCardPoint) {
            window.myAlert("Отклонения пункта чек-листа не назначены данному объекту/пользователю. Обратитесь к вашему Администратору платформы.");
            this.goBack();
        }
        this.devs.sort(function(a, b) {
            if ( a.name < b.name ){
                return -1;
            }
            if ( a.name > b.name ){
                return 1;
            }
            return 0;
        });
        this.resultDev = [];
        this.devSelected = false;
        this.object = Object.assign({}, this.getObjectById(object_id));
        this.getObjDevs();
    },
    methods: {
        getObjectById(id) {
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i].id == id) {
                    return window.myObjects[i];
                }
            }
            return {}
        },
        filesChangedHandler(data) {
            const self = this;
            
            this.filesLength = data.length;
            this.filesCount = 0;
            for (let i = 0; i < data.length; i++) {
                let url = '/preview/snapshot',
                    method = 'POST';
                window.myAjax(method, url, {image: data[i].result}, function(error, response) {
                    if (!error) {
                        self.sendImageCallback(response, data[i].file)
                    } else {
                        window.myAlert('Ошибка загрузки изображения.')
                    }
                });
            }
        },
        showScreenshot(snapshot) {
            this.snapshotSelected = snapshot
            window.$("#modalViewScreenShot").modal('show')
        },
        closeScreenShotModal() {
            window.$("#modalViewScreenShot").modal('hide')
        },
        deleteSnap(snapshot) {
            for(let i = 0; i < this.newSnapshots.length; i++) {
                if (snapshot.img_id == this.newSnapshots[i].img_id) {
                    this.newSnapshots.splice(i, 1);
                }
            }
            window.myAjax('DELETE', '/preview/snapshot', {
                image_id: snapshot.img_id
            }, function() { /* ... */ })
        },
        sendImageCallback(response, file) {
            this.filesCount++;
            if (this.filesCount >= this.filesLength) {
                this.filesLength = 0;
                this.filesCount = 0;
            }
            const _file = {
                type_id: 1,
                img_id: response.id,
                img_src: response.link,
                name: file.name || 'Изображение'
            } 
            this.addFile(_file);
        },
        addFile(file) {
            this.newSnapshots.push(file);
        },


        getChecklistDevs() {
            window.wrongChecklistDevs = false;
            var resultDevs = [];
            if (!(window.checklistCardPoint && window.checklistCardPoint.available_digressions)) {
                return window.myDevs;
            }
            const digressions = window.checklistCardPoint.available_digressions;
            window.myDevs.filter( item => {
                return !!digressions.find(dig => dig.digression_id === item.id);
            }).forEach( (item) => {
                this.getParents(item).forEach( (res) => {
                    if (!resultDevs.find(dev => dev.id === res.id)) {
                        resultDevs.push(res);
                    }
                });
            });
            if (!resultDevs.length) {
                window.wrongChecklistDevs = true;
                window.myAlert("К данному пункту чек-листа не привязаны отклонения. Обратитесь к вашему Администратору платформы.");
                return false;
            }
            return resultDevs;
        },

        getParents(item) {
            var result = [];
            if (item.parent_id) {
                var parent = window.myDevs.find(dev => dev.id === item.parent_id);
                this.getParents(parent).forEach( (res) => { result.push(res)});
            }
            result.push(item);
            return result;
        },
        devHead(dev) {
            let res = ''
            if(dev.parent_id==null)
                res = 'Тип отклонений'
            else if(dev.priority_id==null)
                res = 'Группа отклонений'
            else
                res = 'Отклонение'
            return res
        },
        clickDev(dev) {
            this.resultDev.push(dev)
            if (dev.priority_id) {
                const object_id = this.activeCam ? this.activeCam.object_id : this.selectedObj.id;
                this.object = this.getObject(object_id);
                this.devSelected = true
            }
        },
        devCreatedHandler() {
            this.devCreated = true;
            this.goBack();
            if (window.checklistsMode) {
                setTimeout(function() {
                    if (document.getElementById('dsv-checklist-tree')) {
                        const updateEvent = new CustomEvent('update', { detail: {next: true, log: true} });
                        document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                    }
                }, 50);
            }
        },
        goToCam() {
            this.$emit('goToCam');
            if (!this.devCreated) {
                setTimeout(function() {
                    if (document.getElementById('dsv-checklist-tree')) {
                        const updateEvent = new CustomEvent('reset', { detail: { readOnly: false } });
                        document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                    }
                }, 150);
            }
        },
        goBack() {
            if (this.devCreated) {
                this.goToCam()
            }
            else {
                this.devSelected = false
                if(this.resultDev.length)
                    this.resultDev.pop()
                else
                    this.goToCam()
            }
        },

        checkIsThereGroup() {
            let res = false
            for(let i=0; i<this.devs.length; i++) {
                if(this.devs[i].parent_id==this.resultDev[this.resultDev.length-1].id && this.devs[i].priority_id==null) {
                    res = true
                    break
                }
            }
            return res
        },
        checkIsThereDev() {
            let res = false
            for(let i=0; i<this.devs.length; i++) {
                if(this.devs[i].parent_id==this.resultDev[this.resultDev.length-1].id && this.devs[i].priority_id) {
                    res = true
                    break
                }
            }
            return res
        },

        getObjDevs() {
            window.myAjax('GET', '/api/object-digressions', {
                object_id: this.object.id
            }, this.myCallbackGetObjDevs);
        },
        myCallbackGetObjDevs(error, response) {
            if(!error) {
                this.devsToObject = response.object_digressions
                this.getUserObjDevs()
            }
        },
        getUserObjDevs() {
            window.myAjax('GET', '/api/user-digression-relations', {
                user_id: window.getCookie('userId')
            }, this.myCallbackGetUserObjDevs);
        },
        myCallbackGetUserObjDevs(error, response) {
            this.devsToUserObjects = []
            if (error) {
                window.myAlert(response.message);
            } else {
                for (let i=0; i<response.relations.length; i++) {
                    if (
                        this.objectHasDev(response.relations[i].digression_id) && 
                        this.objectInTheUserObjectRelation(response.relations[i].user_object_id)
                    ) {
                        if (response.relations[i].digression_type === 'create') {
                            this.devsToUserObjects.push(response.relations[i]);
                        }
                    }
                }
            }
        },

        userHasTheDev(dev) {
            if(this.objectHasDev(dev.id))
                for(let i=0; i<this.devsToUserObjects.length; i++)
                    if(this.devsToUserObjects[i].digression_id==dev.id)
                        return true
            return false
        },

        objectHasDev(dev_id) {
            for(let i=0; i<this.devsToObject.length; i++)
                if(this.devsToObject[i].digression_id==dev_id)
                    return true
            return false
        },
        objectInTheUserObjectRelation(user_object_id) {
            for(let i=0; i<window.myUserObjects.length; i++)
                if(window.myUserObjects[i].id==user_object_id) {
                    if(window.myUserObjects[i].object_id==this.object.id)
                        return true
                    break
                }
            return false
        },

        getObject(obj_id) {
            for(let i=0; i<window.myObjects.length; i++)
                if(window.myObjects[i].id==obj_id)
                    return window.myObjects[i]
            return null
        },
        devCreatedTwoHandler(data) {
            this.$emit('startNew', data);
        },
        showDevCard(dev) {
            this.devSelected = true;
            this.devCreated = true;
            this.object = this.getObject(dev.object_id);
            this.resultDev = this.fullResultDev(dev.digression_id);
            this.selectedDev = dev;
            window.setTimeout(this.showDev, 100);
        },
        showDev() {
            this.$refs['refDevCard'].fullDevCard(this.selectedDev)
        },
        getData() {
            return this.devSelected ? this.$refs['refDevCard'].getData() : 'cancel';
        },
        fullResultDev(dev_id) {
            let res = [],
                arr = []
            for(let i=0; i<window.myDevs.length; i++) {
                if(window.myDevs[i].id==dev_id) {
                    arr.push(window.myDevs[i])
                    if(window.myDevs[i].parent_id) {
                        dev_id = window.myDevs[i].parent_id
                        i = -1
                    }
                }
            }
            for(let i=arr.length-1; i>-1; i--)
                res.push(arr[i])
            return res
        },
        cancelDev() {
            if (this.checklistMode) {
                this.goBack();
            } else {
                this.$emit('cancelDev')
            }
        }
    }
}
