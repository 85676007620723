<style scoped>
>>> .v-date-picker-header__value button {
  text-transform: capitalize;
  font-size: 20px;
}

>>> .v-date-picker-table--date td {
  width: 55px;
}

>>> .v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon {
  height: 13px;
  font-family: 'Poppins Regular', sans-serif
}

>>> .v-date-picker-table .v-btn {
  font-size: 18px;
  font-weight: bold;
}

>>> .v-date-picker-table--date .v-btn {
  height: 45px;
  width: 45px;
}

>>> button:focus {
  outline: none;
}

>>> .theme--light.v-slider .v-slider__track-background, .theme--light.v-slider .v-slider__track-fill, .theme--light.v-slider .v-slider__thumb {
  background-color: #0968664d;
}

>>> .theme--light.v-slider .v-slider__track-fill.primary {
  background-color: rgba(9, 104, 102, 0.6) !important;
}

>>> .v-slider--horizontal .v-slider__track-container {
  height: 12px;
}

>>> .v-date-picker-table {
  height: auto;
}

>>> .v-date-picker-table thead th {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #333333;
  padding-top: 40px;
  padding-bottom: 20px;
}

>>> .theme--light.v-input input, .theme--light.v-input textarea {
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;

  color: #333333;
}

.from-to {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #333333;

  padding: 10px
}

.buttons >>> .v-btn__content {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
>>> .prev_month .v-date-picker-table__current.v-btn--rounded.v-btn--outlined {
  border: none;
}
</style>

<template>
  <v-card flat>
    <div class="row my-10 mx-7">
      <div class="col">
        <v-date-picker
            v-model="picked"
            :allowed-dates="allowedDates"
            :first-day-of-week="1"
            :next-icon="nextIcon"
            :prev-icon="prevIcon"
            :weekday-format="getDay"
            color="#096866"
            flat
            locale="ru"
            no-title
            width="350"
            @dblclick:date="dblClick"
            @input="setSelectedDay(picked)"
        />
      </div>
    </div>
    <div class="row my-3 mx-7 buttons" style="align-items: end; justify-content: center;">
      <v-btn color="rgba(51, 51, 51, 0.5)" text @click="cancelBTN" class="cbtn">{{ $t('Отмена') }}</v-btn>
      <span style="width: 50px"/>
      <v-btn color="#096866" text @click="setupDateTimes" class="zbtn">{{ $t('Задать') }}</v-btn>
    </div>
  </v-card>
</template>
<script>
import mask from '@/directives/mask'

export default {
  name: 'Calendar',
  directives: {
    mask: mask,
  },
  data: () => ({
    weakDaysHead: [ "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб" ],
    picked: null,
    day: '2020-07-10',
    prevIcon: '$vuetify.icons.CalendarListLeftIcon',
    nextIcon: '$vuetify.icons.CalendarListRightIcon',
    time1: '00:00',
    time2: '23:59',
  }),
  mounted() {
    this.day = this.formatDate(new Date())
  },
  computed: {
      datesWithArchive() {
          return this.$store.getters.ALLOWED_DATES;
      }
  },
  methods: {
    dblClick(day) {
      this.day = day;
      this.setupDateTimes();
    },
    // new methods
    clearTime(time) {
      time.match('time1') ? this.time1 = "" : '';
      time.match('time2') ? this.time2 = "" : '';
    },
    restoreTime(defaultTime, time) {
      if (time.match('time1') && this.time1 === "") this.time1 = defaultTime;
      if (time.match('time2') && this.time2 === "") this.time2 = defaultTime;
    },
    cancelBTN() {
      this.$emit('closeArchive')
      window.$('#modalDateTimesArchive').modal('hide')
    },
    getNowTime() {
      let nowInSec = new Date();
      nowInSec.setMinutes(nowInSec.getMinutes() - 15)
      let time = nowInSec.toLocaleTimeString().split(":");
      return `${time[0]}:${time[1]}`
    },
    setSelectedDay(day) {
      this.day = day;
    },
    getDay(date) {
      let day = new Date(date).getDay(date)
      return this.weakDaysHead[day]
    },
    monthBefore(date) {
      if (!date) return null;
      date.setMonth(date.getMonth() - 1);
      return this.formatDate(date)
    },
    formatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.toLocaleDateString().split('.');
      return `${year}-${month}-${day}`
    },
    allowedDates(val) {
      if (this.datesWithArchive.length) {
        for (let i = 0; i < this.datesWithArchive.length; i++) {
          if (this.datesWithArchive[i].toString().replace(/_/g, '-').match(val)) {
            return true
          }
        }
      } else return false;
    },
    setupDateTimes() {
      this.$emit('setupDateTimes', {day: this.day, time1: this.time1, time2: this.time2})
      window.$('#modalDateTimesArchive').modal('hide')
    },
  }
}
</script>
