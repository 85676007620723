<style scoped>
    .head_dev {
        font-size: 36px;
        font-weight: 300;
    }
    .selected_month {
        background-color: #fff;
        border-radius: 7px;
        width: 230px;
    }
    .selected_month>div {
        cursor: pointer;
    }
    .month_before {
        min-width: 40px;
        height: 40px;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        background: url('../../assets/img/page_first.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
    .month_after {
        min-width: 40px;
        height: 40px;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        background: url('../../assets/img/page_last.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
    .my_bold {
        font-weight: bold;
    }
    .day {
        background-color: #fff;
        padding: 7px;
        border-radius: 7px;
    }
    .dev_back {
        background: url('../../assets/img/dev_back.svg');
        background-position-x: 50%;
        background-repeat: no-repeat;
        background-size: 13px;
        height: 17px;
        width: 21px;
        filter: grayscale(1);
        cursor: pointer;
    }
    .dev_back:hover {
        filter: none;
    }
    .add_node {
        background: url('../../assets/img/new_role.svg');
        height: 40px;
    }
</style>

<template>
    <div>
        <div class="row mt-3 mt-lg-7 ml-3 ml-lg-6 mb-2 mb-lg-5">
            <div v-if="showDay || showTask" class="dev_back my-auto mr-lg-3" @click="goBack"></div>
            <div class="head_dev col-9 col-lg-auto my-auto">
                {{ $t('График') }}
                <span v-if="showDay && !showTask">{{ $t('за') }} {{selectedDate}}</span>
            </div>
            <div v-if="!showTask" class='controls mt-2 mb-1 ml-6 add_node' :title="$t('создать новое расписание работы')" @click="taskCard(null)"></div>
        </div>

        <Task v-if="showTask" :task="task" :date="date"
              :adminBaseRole="adminBaseRole"
              :rucsupBaseRole="rucsupBaseRole"
              @cancelTask="showTask = false"
              @getCalendarTasks="getCalendarTasks"
        />

        <div v-else>
            <div v-if="!showTask" class="mb-6 mx-0 px-3 row">
                <div class="px-0 px-lg-4 col-lg-4">
                    <div v-if="!showDay" class="d-flex justify-content-between selected_month">
                        <div class="month_before" @click="setMonth(-1)"></div>
                        <div class="pt-2 my_bold" @click="working">{{ $t(theMonth) }} {{ theYear }}</div>
                        <div class="month_after" @click="setMonth(1)"></div>
                    </div>
                </div>
                <div v-if="adminBaseRole" class="col-4 text-right">
                    <select class="day" v-model="selected_role_id" @change="filterUsers">
                        <option value=0 selected>{{ $t('Все роли') }}</option>
                        <option v-for="role in base_roles"
                                v-bind:key="role.id"
                                :value="role.id"
                        >{{role.name}}</option>
                    </select>
                </div>
                <div v-else-if="rucsupBaseRole" class="col-4 text-right">
                    <select class="day" v-model="selected_group_id" @change="filterUsers2">
                        <option value=0 selected>{{ $t('Все группы') }}</option>
                        <option v-for="group in groups"
                                v-bind:key="group.id"
                                :value="group.id"
                        >{{group.name}}</option>
                    </select>
                </div>
                <div v-if="adminBaseRole" class="col-4 pr-12 text-right">
                    <select class="day" v-model="user_id" @change="setUser">
                        <option value=0 selected>{{ $t('Все пользователи') }}</option>
                        <option v-for="user in users"
                                v-bind:key="user.id"
                                :value="user.id"
                        >{{ fio(user) }}</option>
                    </select>
                </div>
                <div v-else-if="rucsupBaseRole" class="col-4 pr-12 text-right">
                    <select class="day" v-model="user_id" @change="setUser">
                        <option value=0 selected>{{ $t('Все супервайзеры') }}</option>
                        <option v-for="user in users"
                                v-bind:key="user.id"
                                :value="user.id"
                        >{{fio(user)}}</option>
                    </select>
                </div>
            </div>
            <Day v-if="showDay"
                 :tasks="tasks"
                 @taskCard="taskCard"
            />
            <Month v-else :weeks="weeks"
                   @showDay="show_Day"
            />
        </div>
    </div>
</template>

<script>
    import Month from '../../components/calendar/Month';
    import Day from '../../components/calendar/Day';
    import Task from '../../components/calendar/Task';

    export default {
        name: 'Reports',
        components: {
            Month,
            Day,
            Task
        },
    data: () => ({
        months: [ 'Январь', 'Февраль', 'Март', 'Апрель',
            'Май', 'Июнь', 'Июль', 'Август',
            'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
        ],
        monthsRP: [ 'Января', 'Февраля', 'Марта', 'Апреля',
            'Мая', 'Июня', 'Июля', 'Августа',
            'Сентября', 'Октября', 'Ноября', 'Декабря'
        ],
        selected_role_id: 0,
        base_roles: [],
        selected_group_id: 0,
        groups: [],
        task: null,
        showTask: false,
        showDay: false,
        all_events: [],
        users: [],
        user_id: 0,
        selectedDate: '',
        tasks: [],
        allTasks: [],
        date: new Date(),
        now: new Date(),
        theMonth: '',
        theYear: '',
        adminBaseRole: false,
        rucsupBaseRole: false,
        object: null,
        weeks: []
    }),
    mounted() {
        this.date.setHours(0, 0, 0)
        this.setMonth(0)
        
        window.resizeScrollingContent();
        // window.$('.scrolling_content').css('max-height', window.innerHeight-60)
        if(window.getCookie('userBaseRole')=='Администратор') {
            this.adminBaseRole = true
            this.base_roles = []
            for(let i=0; i<window.myRoles.length; i++) {
                if(window.myRoles[i].parent_id==null)
                    this.base_roles.push(window.myRoles[i])
            }
            this.filterUsers()
        } else if(window.getCookie('userBaseRole')=='Руководитель супервайзеров') {
            this.rucsupBaseRole = true
            this.groups = window.myGroups
            this.filterUsers2()
        }
    },
    methods: {
        working() {
//            window.myAlert('работаем...')
        },
        fio(user) {
            if(user.profile) {
                let res = user.profile.last_name + ' ' + user.profile.first_name
                if(user.profile.middle_name)
                    res += ' ' + user.profile.middle_name
                return res
            } else
                return user.fio
        },
        filterUsers2() {
            this.users = [{
                    id: window.getCookie('userId'),
                    fio: window.getCookie('userFio')
                }]
            if(this.selected_group_id>0) {
                let group = this.getSelectedGroup()
                if(group)
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id)
                                this.users.push(window.myUsers[i])
            } else {
                for (let k = 0; k < window.myGroups.length; k++) {
                    let group = window.myGroups[k]
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id && this.userNotInUsers(window.myUsers[i]))
                                this.users.push(window.myUsers[i])
                }
            }
        },
        getSelectedGroup() {
            for (let k = 0; k < window.myGroups.length; k++)
                if(window.myGroups[k].id==this.selected_group_id)
                    return window.myGroups[k]
            return null
        },
        userNotInUsers(user) {
            for (let k = 0; k < this.users.length; k++)
                if(this.users[k].id==user.id)
                    return false
            return true
        },

        filterUsers() {
            this.users = []
            for (let i = 0; i < window.myUsers.length; i++) {
                if (window.myUsers[i].profile != undefined && (this.selected_role_id == 0 || this.userHasBaseRole(window.myUsers[i])))
                    this.users.push(window.myUsers[i])
            }
        },
        userHasBaseRole(user) {
            for (let i = 0; i < user.roles.length; i++)
                if (this.selected_role_id == this.getBaseRoleId(user.roles[i]))
                    return true
            return false
        },
        getBaseRoleId(role) {
            if (role.parent_id == null)
                return role.id
            for (let i = 0; i < window.myRoles.length; i++)
                if (window.myRoles[i].id == role.parent_id)
                    return this.getBaseRoleId(window.myRoles[i])
            return 0
        },

        setMonth(i) {
            this.date.setMonth(this.date.getMonth() + i)
            this.theMonth = this.months[this.date.getMonth()];
            this.theYear = this.date.getFullYear();
            this.showCalendar()
            this.getCalendarTasks()
        },
        showCalendar() {
            let nextStep = true,
                newWeek = [],
                tempDate = new Date(this.date.getTime())
            tempDate.setDate(1)
            let weekDay = tempDate.getDay()
            if (weekDay == 0)
                weekDay = 7
            tempDate.setTime(tempDate.getTime() - 86400000 * (weekDay - 1))
            this.weeks = []
            while (nextStep) {
                if (tempDate.getTime() > this.date.getTime() &&
                    tempDate.getMonth() != this.date.getMonth() &&
                    tempDate.getDay() == 1)
                    nextStep = false
                else {
                    let newDay = {
                        out_range: tempDate.getMonth() != this.date.getMonth(),
                        in_range: false,
                        selected: false,
                        name: tempDate.getDate(),
                        ts: new Date(tempDate.getTime()),
                        tasks: []
                    }
                    if (newDay.name < 10)
                        newDay.name = '0' + newDay.name
                    if (tempDate.getDay() == 1)
                        newWeek = {
                            days: [newDay],
                            sunday: null
                        }
                    else if (tempDate.getDay() == 0) {
                        newWeek.sunday = newDay
                        this.weeks.push(newWeek)
                    } else
                        newWeek.days.push(newDay)
                    tempDate.setTime(tempDate.getTime() + 86400000)
                }
            }

        },
        setObject(obj) {
            if(obj.object_type=='node')
                this.object = null
            else
                this.object = obj
            this.tasksToCalendar()
        },
        setUser() {
            this.tasksToCalendar()
        },
        getCalendarTasks() {
            let url = '/api/calendar-period-events/' +
                parseInt(this.weeks[0].days[0].ts.getTime() / 1000) + '/' + // startTS_PHP
                parseInt(this.weeks[this.weeks.length - 1].sunday.ts.getTime() / 1000 + 86400), // endTS_PHP
                method = 'GET'
            window.myAjax(method, url, this.request, this.myCallbackGetCalendarTasks)
            this.showTask = false
        },
        myCallbackGetCalendarTasks(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                this.allTasks = response.events
                this.tasksToCalendar()
            }
        },
        templateFilterTask(task, day) {
            let res = false
            if((this.object!=null && this.object.id!=task.object_id && this.object.id!=task.zone_id) || // фильтр задач по объекту
                (this.user_id!=0 && this.user_id!=task.user_id)) // фильтр задач по пользователю
                res = true
            else if(task.calendar_template_id) {
                let start_datetime = new Date(task.start_datetime),
                    end_datetime = new Date(task.end_datetime)
                let start_hours_minutes_time = start_datetime.getHours()*60 + start_datetime.getMinutes(),
                    end_hours_minutes_time = end_datetime.getHours()*60 + end_datetime.getMinutes()
                for(let j=0; j<window.myCalendarTemplates.length; j++) {
                    if(window.myCalendarTemplates[j].id==task.calendar_template_id) {
                        switch(window.myCalendarTemplates[j].name) {
                            case 'Будние дни':
                                if(start_hours_minutes_time<end_hours_minutes_time)
                                    if (day.getDay()==0 || day.getDay()==6)
                                        res = true
                                else if (day.getDay()==0)
                                        res = true
                                break
                            case 'Выходные':
                                if(start_hours_minutes_time<end_hours_minutes_time)
                                    if(day.getDay()>0 && day.getDay()<6)
                                        res = true
                                else if(day.getDay()>1 && day.getDay()<6)
                                    res = true
                                break
                            case 'Четные дни':
                                if(start_hours_minutes_time<end_hours_minutes_time &&
                                    day.getDate()%2)
                                    res = true
                                break
                            case 'Нечетные дни':
                                if(start_hours_minutes_time<end_hours_minutes_time &&
                                    !day.getDate()%2)
                                    res = true
                                break
                        }
                    }
                    if(res)
                        break
                }
            }
            return res
        },
        setDayTasks(day) {
            // очищаем старые расписания
            day.tasks = []
            for(let k=0; k<this.allTasks.length; k++) {
                if( this.templateFilterTask(this.allTasks[k], day.ts)) // фильтр задач по их шаблонам
                    continue
                let calendarTemplateName = null
                for(let j=0; j<window.myCalendarTemplates.length; j++) {
                    if(window.myCalendarTemplates[j].id==this.allTasks[k].calendar_template_id) {
                        calendarTemplateName = window.myCalendarTemplates[j].name
                        break
                    }
                }
                let start_datetime = new Date(this.allTasks[k].start_datetime),
                    end_datetime = new Date(this.allTasks[k].end_datetime)
                if(end_datetime.getTime()>day.ts.getTime() &&
                    start_datetime.getTime()<day.ts.getTime()+86400000) {
                    let start_hours_minutes_time = start_datetime.getHours()*60 + start_datetime.getMinutes(),
                        end_hours_minutes_time = end_datetime.getHours()*60 + end_datetime.getMinutes()
                    if(start_hours_minutes_time<end_hours_minutes_time) {
                        day.tasks.push({
                            task: this.allTasks[k],
                            fio: this.getFio(this.allTasks[k].user.profile),
                            object_name: this.getObjectName(this.allTasks[k].object_id),
                            zone_name: this.getObjectName(this.allTasks[k].zone_id),
                            start_time: this.timeStr(start_datetime),
                            finish_time: this.timeStr(end_datetime),
                            corners: {
                                left: false,
                                right: false
                            }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                            leftMarginAndWidth: {
                                marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                width: (end_hours_minutes_time-start_hours_minutes_time)*100/(24*60)+'%'
                            }
                        })
                    } else {
                        switch(calendarTemplateName) {
                            case 'Будние дни':
                                if(day.ts.getDay()==1) { // понед
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: this.timeStr(start_datetime),
                                        finish_time: '00:00',
                                        corners: {
                                            left: false,
                                            right: true
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                            width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                        }
                                    })
                                } else if(day.ts.getDay()==6) { // суббота
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: '00:00',
                                        finish_time: this.timeStr(end_datetime),
                                        corners: {
                                            left: true,
                                            right: false
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: '0%',
                                            width: end_hours_minutes_time*100/(24*60)+'%'
                                        }
                                    })
                                } else if(day.ts.getDay()>1 && day.ts.getDay()<6) { // вт, ср, чт, пт
                                    if(day.ts.getTime()<end_datetime.getTime()-86400000)
                                        day.tasks.push({
                                            task: this.allTasks[k],
                                            fio: this.getFio(this.allTasks[k].user.profile),
                                            object_name: this.getObjectName(this.allTasks[k].object_id),
                                            zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                            start_time: this.timeStr(start_datetime),
                                            finish_time: '00:00',
                                            corners: {
                                                left: false,
                                                right: true
                                            }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                            leftMarginAndWidth: {
                                                marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                                width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                            }
                                        })
                                    if(day.ts>start_datetime)
                                        day.tasks.push({
                                            task: this.allTasks[k],
                                            fio: this.getFio(this.allTasks[k].user.profile),
                                            object_name: this.getObjectName(this.allTasks[k].object_id),
                                            zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                            start_time: '00:00',
                                            finish_time: this.timeStr(end_datetime),
                                            corners: {
                                                left: true,
                                                right: false
                                            }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                            leftMarginAndWidth: {
                                                marginLeft: '0%',
                                                width: end_hours_minutes_time*100/(24*60)+'%'
                                            }
                                        })
                                }
                                break
                            case 'Выходные':
                                if(day.ts.getDay()==1) { // понед
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: '00:00',
                                        finish_time: this.timeStr(end_datetime),
                                        corners: {
                                            left: true,
                                            right: false
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: '0%',
                                            width: end_hours_minutes_time*100/(24*60)+'%'
                                        }
                                    })
                                } else if(day.ts.getDay()==6) { // суббота
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: this.timeStr(start_datetime),
                                        finish_time: '00:00',
                                        corners: {
                                            left: false,
                                            right: true
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                            width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                        }
                                    })
                                } else if(day.ts.getDay()==0) { // воскр
                                    if(day.ts.getTime()<end_datetime.getTime()-86400000)
                                        day.tasks.push({
                                            task: this.allTasks[k],
                                            fio: this.getFio(this.allTasks[k].user.profile),
                                            object_name: this.getObjectName(this.allTasks[k].object_id),
                                            zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                            start_time: this.timeStr(start_datetime),
                                            finish_time: '00:00',
                                            corners: {
                                                left: false,
                                                right: true
                                            }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                            leftMarginAndWidth: {
                                                marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                                width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                            }
                                        })
                                    if(day.ts>start_datetime)
                                        day.tasks.push({
                                            task: this.allTasks[k],
                                            fio: this.getFio(this.allTasks[k].user.profile),
                                            object_name: this.getObjectName(this.allTasks[k].object_id),
                                            zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                            start_time: '00:00',
                                            finish_time: this.timeStr(end_datetime),
                                            corners: {
                                                left: true,
                                                right: false
                                            }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                            leftMarginAndWidth: {
                                                marginLeft: '0%',
                                                width: end_hours_minutes_time*100/(24*60)+'%'
                                            }
                                        })
                                }
                                break
                            case 'Четные дни':
                                if(day.ts.getDate()%2==0) {
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: this.timeStr(start_datetime),
                                        finish_time: '00:00',
                                        corners: {
                                            left: false,
                                            right: true
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                            width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                        }
                                    })
                                } else {
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: '00:00',
                                        finish_time: this.timeStr(end_datetime),
                                        corners: {
                                            left: true,
                                            right: false
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: '0%',
                                            width: end_hours_minutes_time*100/(24*60)+'%'
                                        }
                                    })
                                }
                                break
                            case 'Нечетные дни':
                                if(day.ts.getDate()%2) {
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: this.timeStr(start_datetime),
                                        finish_time: '00:00',
                                        corners: {
                                            left: false,
                                            right: true
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                            width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                        }
                                    })
                                } else {
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: '00:00',
                                        finish_time: this.timeStr(end_datetime),
                                        corners: {
                                            left: true,
                                            right: false
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: '0%',
                                            width: end_hours_minutes_time*100/(24*60)+'%'
                                        }
                                    })
                                }
                                break
                            default:
                                if(day.ts.getTime()<end_datetime.getTime()-86400000)
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: this.timeStr(start_datetime),
                                        finish_time: '00:00',
                                        corners: {
                                            left: false,
                                            right: true
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: start_hours_minutes_time*100/(24*60)+'%',
                                            width: (24*60-start_hours_minutes_time)*100/(24*60)+'%'
                                        }
                                    })
                                if(day.ts>start_datetime)
                                    day.tasks.push({
                                        task: this.allTasks[k],
                                        fio: this.getFio(this.allTasks[k].user.profile),
                                        object_name: this.getObjectName(this.allTasks[k].object_id),
                                        zone_name: this.getObjectName(this.allTasks[k].zone_id),
                                        start_time: '00:00',
                                        finish_time: this.timeStr(end_datetime),
                                        corners: {
                                            left: true,
                                            right: false
                                        }, //this.getCorners(start_datetime, end_datetime, this.weeks[i].days[j].ts),
                                        leftMarginAndWidth: {
                                            marginLeft: '0%',
                                            width: end_hours_minutes_time*100/(24*60)+'%'
                                        }
                                    })
                                break

                        }

                    }
                }
            }
        },
        tasksToCalendar() {
            for(let i=0; i<this.weeks.length; i++) {
                for(let j=0; j<this.weeks[i].days.length; j++)
                    this.setDayTasks(this.weeks[i].days[j])
                this.setDayTasks(this.weeks[i].sunday)
            }
        },
        getObjectName(obj_id) {
            let obj_name = ''
            for (let i = 0; i < window.myObjects.length; i++)
                if (window.myObjects[i].id == obj_id) {
                    obj_name = window.myObjects[i].name
                    break
                }
            return obj_name
        },
        getFio(profile) {
            let fio = ''
            if(profile) {
                fio = profile.last_name + ' ' +
                    profile.first_name.substring(0, 1) + '.'
                if(profile.middle_name)
                    fio += profile.middle_name.substring(0, 1) + '.'
            }
            return fio
        },
        timeStr(date) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            
            let options = {
                    hour: 'numeric',
                    minute: 'numeric'//,
                }
            return date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00;
        },

        show_Day(day) {
            this.showDay = true
            this.selectedDate = day.name + ' ' + this.$t(this.monthsRP[day.ts.getMonth()]) + ' ' + day.ts.getFullYear()
            this.tasks = day.tasks
        },
        goBack() {
            if(this.showTask)
                this.showTask = false
            else
                this.showDay = false
        },
        taskCard(task) {
            if(task==null)
                task = {
                    id: 0,
                    calendar_template_id: 0,
                    start_datetime: null,
                    end_datetime: null,
                    creator_comment: null,
                    responsible_comment: null,
                    object_id: 0,
                    zone_id: 0,
                    user_id: 0,
                    user_ids: [],
                    fio: 'Васюков И.П.',
                    object_name: 'Замечательный объект',
                    zone_name: 'Замечательная зона',
                    left_corners: false,
                    right_corners: false,
                    leftMarginAndWidth: {
                        marginLeft: 8*100/24+'%',
                        width: (17-8)*100/24+'%'
                    },
                    start_time: '08:00',
                    finish_time: '17:00'
                }
            this.task = task
            this.showTask = true
//            this.showDay = false
        }
    }
  }
</script>
