/**
 * Получение истории статусов отклонения.
 * @param {*} digression_list_id - Id отклонения.
 */
export function getHistory(params, callback) {
   window.myAjax('GET', '/api/digression-record-status-history', {
    digression_record_id: params.id
   }, function(error, response) {
       if (!error) {
           callback(response);
       }
   });
}
