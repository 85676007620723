export default [
    {
        id: 'perm1',
        name: 'Отклонения',
        subs: [
            {
                id: 'perm1_1',
                name: 'Создание и редактирование списка отклонений (вкл. связи)',
                ids: [42, 43, 44, 45, 46, 47, 48, 49, 52, 53, 54, 55, 74, 75, 76]
            },
            {
                id: 'perm1_2',
                name: 'Заполнение карточек отклонений',
                ids: [42, 47, 48, 49, 52, 74]
            },
        ]
    },
    {
        id: 'perm3',
        name: 'Журнал',
        subs: [
            {
                id: 'perm3_1',
                name: 'Просмотр журнала по своим объектам',
                ids: [47]
            },
            {
                id: 'perm3_2',
                name: 'Просмотр журнала по всем объектам',
                ids: [47]
            },

            
        ]
    },
    {
        id: 'perm5',
        name: 'Контакты',
        subs: [
            {
                id: 'perm5_1',
                name: 'Создание и редактирование контактов',
                ids: [26, 27, 28, 29]
            },
            {
                id: 'perm5_2',
                name: 'Просмотр контактов',
                ids: [29]
            }
        ]
    },
    {
        id: 'perm7',
        name: 'Отчёты об активности пользователей',
        subs: [
            {
                id: 'perm7_1',
                name: 'Просмотр отчётов об активности пользователей',
                ids: [79]
            },
            {
                id: 'perm7_2',
                name: 'Выгрузка отчётов (вручную и настройка рассылки)',
                ids: []
            }
        ]
    },
    {
        id: 'perm2',
        name: 'Чек-листы',
        subs: [
            {
                id: 'perm2_1',
                name: 'Создание и редактирование чек-листов (вкл. связи)',
                ids: [99, 100, 101, 102, 103, 104, 105, 106, 107, 108]
            },
            {
                id: 'perm2_2',
                name: 'Заполнение чек-листов',
                ids: [100, 104, 106]
            },

            
        ]
    },
    {
        id: 'perm4',
        name: 'Аналитика и отчёты',
        subs: [
            {
                id: 'perm4_1',
                name: 'Просмотр аналитики по своим объектам',
                ids: []
            },
            {
                id: 'perm4_2',
                name: 'Просмотр аналитики по всем объектам',
                ids: []
            },
            {
                id: 'perm4_3',
                name: 'Выгрузка отчётов (вручную и настройка рассылки)',
                ids: [97]
            }
        ]
    },
    {
        id: 'perm6',
        name: 'Доступ Пользователей к Объектам',
        subs: [
            {
                id: 'perm6_1',
                name: 'Предоставление Пользователям доступа к Объектам',
                ids: [56]
            }
        ]
    },
    {
        id: 'perm8',
        name: 'Нейромодули',
        subs: [
            {
                id: 'perm8_1',
                name: 'Запуск автоматизированных нейромодулей',
                ids: []
            }
        ]
    }
]
