export default {
    name: 'ListColumnItem',
    components: {
        // todo
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        caption: {
            type: String,
            default: ''
        },
        labelStyle: {
            type: String,
            default: {}
        },
        captionStyle: {
            type: String,
            default: {}
        },
        content: {
            type: String,
            default: null
        }
    },
    data: () => ({
        // todo
    }),
    mounted() {
        // todo
    },
    methods: {
        // todo
    }
}
