export default {
    name: 'ModeTumblr',
    components: {
    },
    props: {
        mode: {
            type: String,
            default: null
        },
        checklistsEnabled: {
            type: Boolean,
            default: false
        },
        priceTagsEnabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showed: false,
        // todo
    }),
    watch: {
        // todo
    },
    mounted() {
        // todo
    },
    methods: {
        toggleShowed() {
            this.showed = !this.showed;
        },
        changeMode(mode) {
            this.showed = false;
            this.$emit('changeMode', { mode: mode });
        }
    }
}
