<style scoped>
.control1 {
    background: url('../../assets/img/cams_control1.svg');
}
.control2 {
    background: url('../../assets/img/cams_control2.svg');
}
.control3 {
    background: url('../../assets/img/cams_control3.svg');
}
.control4 {
    background: url('../../assets/img/cams_control4.svg');
}
.control5 {
    background: url('../../assets/img/cams_control5.svg');
}
.control6 {
    background: url('../../assets/img/cams_control6.svg');
}
.control7 {
    background: url('../../assets/img/cams_control7.svg');
}
.control8 {
  background: url('../../assets/img/cams_control8.svg');
}
.archive {
  background: url('../../assets/img/archive.svg');
  filter: none;
}
</style>

<template>
  <div>
      <div v-for="chat in chats" v-bind:key="chat.id">
        <div class='the_link' @click='theChat(chat)'>{{ chat.name }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LeftControlsChats',
    data: () => ({
      chats: [
        {
          id: 1,
          name: 'Общий',
          digression_id: null,
          num_new_msgs: 0
        }
      ]
    }),
    methods: {
      theChat(chat) {
        this.$emit('showChat', chat)
      }
    }
  }
</script>
