import {
    getComments,
    addComment,
    updateComment,
    deleteComment,
    getHistory
} from './../utils';

import rolesUtils from './../../roles/utils';

const HISTORY_MESSAGES = {
    1: 'Создал',
    2: 'Принял к исполнению',
    3: 'Взял на проверку',
    4: 'Вернул на доработку',
    5: 'Выполнил'
};

export default {
    name: 'DigressionThreads',
    props: ['digression'],
    data: () => ({
        comments: [],
        newComment: '',
        canPost: false,
        userId: null
    }),
    mounted() {
        this.getComments();
        const userId = window.getCookie('userId');
        this.userId = userId;
        this.canPost =
            this.digression.created_by == userId ||
            this.digression.responsible_id == userId ||
            rolesUtils.isRoleContained(['Администратор', 'Супервайзер']);
    },
    methods: {
        /** Получение пользователя по id. */
        getUser(id) {
            return (window.myUsers || []).find(user => user.id == id) || {};
        },
        /** Получение почты пользователя. */
        getUserEmail(user) {
            if (user.id) {
                return user.email;
            }
            return null;
        },
        /** Получение имени пользователя. */
        getUserName(user) {
            let result = 'Скрытый пользователь';
            if (user.id) {
                const profile = user.profile;
                result = (profile.last_name || '') + ' ' +
                    (profile.first_name || '') + ' ' +
                    (profile.middle_name || '');
                return result;
            }
            return result;
        },
        resize() {
            const { textarea } = this.$refs;
            textarea.style.height = textarea.scrollHeight + 2 + 'px';
        },
        /** Кнопка "Отправить" [новый коммент]. */
        postClickHandler() {
            if (this.newComment) {
                this.addComment(this.newComment);
            }
        },
        /**
         * Удаление коммента.
         * @param {*} comment - объект комментария.
         */
        deleteClickHandler(comment) {
            this.deleteComment(comment)
        },

        // Функции по работе с комментами
        getComments() {
            getComments({id: this.digression.id}, this.getCommentsHandler)
        },
        addComment(comment) {
            addComment({
                digression_list_id: this.digression.id,
                comment: comment
            }, this.addCommentHandler);
        },
        updateComment(comment) {
            if (this.newComment) {
                updateComment({
                    digression_list_comment_id: comment.id,
                    comment: this.newComment
                }, this.updateCommentHandler)
            }
        },
        deleteComment(comment) {
            deleteComment({
                digression_list_comment_id: comment.id
            },  this.deleteCommentHandler);
        },
        setHistory() {
            if (this.digression.id) {
                getHistory({id: this.digression.id}, this.getHistoryCallback);
            }
        },

        // Обработчики
        getCommentsHandler(data) {
            this.comments = [];
            for (let key in data) {
                const _comment = data[key];
                const user = this.getUser(_comment.user_id);
                const date = new Date(_comment.created_at);
                const now = new Date();
                let diff = now.getTimezoneOffset()/ -60;
                date.setHours(date.getHours() + diff);
                this.comments.push({
                    id: _comment.id,
                    userId: _comment.user_id,
                    message: _comment.comment,
                    dateStr: date.toLocaleDateString() + ', ' + date.toLocaleTimeString(),
                    date: date,
                    name: this.getUserName(user),
                    email: this.getUserEmail(user)
                });
            }
            this.setHistory()
        },
        addCommentHandler() {
            this.newComment = '';
            this.getComments();
        },
        updateCommentHandler() {
            this.getComments();
        },
        deleteCommentHandler() {
            this.getComments();
        },
        getHistoryCallback(data) {
            for (let key in data.history) {
                const _comment = data.history[key];
                const user = this.getUser(_comment.user_id);
                const date = new Date(_comment.created_at);
                const now = new Date();
                let diff = now.getTimezoneOffset()/ -60;
                date.setHours(date.getHours() + diff);
                this.comments.push({
                    id: 'h' + _comment.id,
                    userId: _comment.user_id,
                    message: HISTORY_MESSAGES[_comment.status_id],
                    dateStr: date.toLocaleDateString() + ', ' + date.toLocaleTimeString(),
                    date: date,
                    name: this.getUserName(user),
                    email: this.getUserEmail(user),
                    history: true
                });
            }
            this.comments = this.comments.sort( (a, b) => a.date - b.date);
        }
    }
}
