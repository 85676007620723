import CheckboxListDropdown from './../../base/checkboxListDropdown';

export default {
    name: 'userActivityReports',
    components: {
        CheckboxListDropdown,
    },
    props: ['clientId'],
    data: () => ({
        loading: false,

        startDate: null,
        endDate: null,
        periodInactivity: '5',

        users: [],
        usersOptions: [],
        usersSelected: [],

        rolesOptions: [],
        rolesSelected: [],

        objectsOptions: [],
        objectsSelected: [],
    }),
    watch: {
        rolesSelected: {
            deep: true,
            handler() {
                if (this.rolesSelected.length) {
                    this.usersOptions = this.users.filter( user => {
                        let result = false;
                        this.rolesSelected.forEach( roleId => {
                            if ((user.roles || []).includes(roleId)) {
                                result = true;
                            } 
                        });
                        return result;
                    })
                } else {
                    this.usersOptions = this.users; 
                }
            }
        },
    },
    mounted() {
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
        this.startDate = now.toISOString().slice(0,10);
        now.setMinutes(now.getMinutes() + 60);
        this.endDate = now.toISOString().slice(0,10);

        this.loading = true;
        window.myAjax('GET', '/api/users', {}, (error, response) => {
            if (!error) {
                response.users.forEach( user => {
                    this.users.push(Object.assign(user, {
                        fio: ((user.profile || {}).last_name || '') + ' ' + ((user.profile || {}).first_name || '')
                    }))
                })
                this.usersOptions = this.users;
                window.myAjax('GET', '/api/roles', {}, (error, response) => {
                    if (!error) {
                        this.rolesOptions = response.roles;
                        window.myAjax('GET', '/api/objects', {}, (error, response) => {
                            if (!error) {
                                this.objectsOptions = response.objects;
                                this.loading = false;
                            }
                        })
                    }
                })
            }
        })
    },
    methods: {
        updateSelectedUsers(data) {
            this.usersSelected = data;
        },
        updateSelectedRoles(data) {
            this.rolesSelected = data;
        },
        updateSelectedObjects(data) {
            this.objectsSelected = data;
        },
        resetFilter() {
            var now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
            this.startDate = now.toISOString().slice(0,10);
            now.setMinutes(now.getMinutes() + 60);
            this.endDate = now.toISOString().slice(0,10);
            this.rolesSelected = [];
            this.usersSelected = [];
            this.objectsSelected = [];
        },
        getExcel() {
            this.loading = true;
            window.myAjax('POST', '/analytic/user_activity/excel', {
                start_date: this.startDate ? new Date(this.startDate).getTime() / 1000 : null,
                end_date: this.endDate ? ((new Date(this.endDate).getTime() / 1000) + 86399) : null,
                period_inactivity: parseInt(this.periodInactivity) * 60,
                object_ids: JSON.stringify(this.objectsSelected),
                role_ids: JSON.stringify(this.rolesSelected),
                user_ids: JSON.stringify(this.usersSelected),
            }, (error, response) => {
                this.loading = false;
                if (error) {
                    window.myAlert(response.message || 'Ошибка составления отчёта по чек-листам.');
                } else {
                    const url = response.excel_url;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `Отчёт ${this.startDate} ${this.endDate}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
        }
    }
}
