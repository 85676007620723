
const SUBGROUP_NAME = 'subgroup';
const POINT_NAME = 'point';

/**
 * Шаблон отображения корня или подгруппы чеклиста.
 * Выводит дочерние элементы.
 * 
 * Опции:
 * - `list` - корневой чеклист.
 * - `adminMode` - режим админки.
 * 
 * События:
 * - `click` - при выборе элемента чек-листа.
 */
export default {
    name: 'RootTemplate',
    data: () => ({
        caption: '',
        children: []
    }),
    props: ['list', 'adminMode', 'readOnly'],
    mounted() {
        this.findPointIntoGroup = this.findPointIntoGroup.bind(this);
        this.caption = this.list.caption;
        if (this.list.children) {
            for (let i = 0; i < this.list.children.length; i++) {
                if (this.list.children[i]) {
                    const data = {};
                    Object.assign(data, this.list.children[i]);
                    let status = 'none';
                    if (data.log) {
                        if (data.log[0]) {
                            if (window.sessionId === data.log[0].session_id) {
                                if (data.log[0].status === 'ok') {
                                    status = 'ok';
                                }
                                if (data.log[0].status === 'resolved') {
                                    status = 'resolved';
                                }
                                if (data.log[0].digressions) {
                                    if (data.log[0].digressions.length > 0) {
                                        status = 'ok';
                                    }
                                }
                            }
                        }
                    }
                    if (data.type === 'point') {
                        const self = this;
                        const currentPoint = this.findPointIntoGroup(this.list.children, self, data.id);
                        if ((currentPoint || {}).log) {
                            const notLogPoint = this.findPointIntoGroup(window.currentChecklist2.children, self, data.id);
                            if ((notLogPoint.available_digressions || []).length === 0) {
                                data.nodeg = true;
                                status = 'ok';
                            }
                        } else {
                            if (currentPoint.available_digressions) {
                                if ((currentPoint.available_digressions || []).length === 0) {
                                    data.nodeg = true;
                                    status = 'ok';
                                }
                            } else {
                                data.nodeg = true;
                                status = 'ok';
                            }
                        }
                    }
                    data.status = status;
                    this.children.push(data);
                }
            }
        }
    },
    methods: {
        clickHandler(list) {
            if (this.readOnly) {
                return;
            }
            this.$emit('click', list)
        },

        findPointIntoGroup(groups, self, findedId) {
            for (let i = 0; i < groups.length; i++) {
                const child = groups[i];
                if (child.type === SUBGROUP_NAME) {
                    const finded = self.findPointIntoGroup(child.children, self, findedId);
                    if (finded) {
                        return finded;
                    }
                } else if (child.type === POINT_NAME) {
                    if (self.isRequiredPoint(child, findedId)) {
                        return child;
                    }
                }
            }
        },

        isRequiredPoint(group, findedId) {
            return group.id === findedId;
        },
    }
}
