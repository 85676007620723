import Checkbox from './../../../base/checkbox';

export default {
    name: 'RelationsSelector',
    components: {
        Checkbox
    },
    props:{
        users: {
            type: Array,
            default: []
        },
        sample: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        mode: 'objects', // objects | digressions | checking
        selecting: true,
        loading: false,
        expandedObject: {},

        objects: [], // const
        zones: [], // const
        cameras: [], // const

        selectedObjects: [],
        selectedZones: [],
        selectedCameras: [],

        digressionsRelations: [], // const
        selectedObjectToDigression: null,
        selectedDigressionsToCreate: [],
        selectedDigressionsToExecute: [],
        availableDigressions: [],

        selectedDigressionsByObjects: {},

        allSelectedCreate: false,
        allSelectedExecute: false,

        getUsersStack: [],
        saveUsersStack: [],
        usersData: {},
        usersInfo: [],

        currentUpdateSource: {},
        currentUpdatedUser: null
    }),
    watch: {
        sample: {
            handler(value) {
                if (value && this.mode !== 'checking') {
                    this.getSampleRelations()
                }
            }
        },
        mode: {
            handler(value) {
                this.$emit('changeMode', value === 'checking' ? 2 : 1);
            }
        }
    },
    mounted() {
        if (this.sample) {
            this.getSampleRelations()
        }
        (window.myObjects || []).forEach(object => {
            if (object.object_type === 'object') {
                this.objects.push(object);
            } else if (object.object_type === 'zone') {
                this.zones.push(object);
            }
        });
        (window.myCams || []).forEach(camera => {
            this.cameras.push(camera);
        });
    },
    methods: {
        toggleExpandedObject(objectId) {
            const obj = {};
            obj[objectId] = !this.expandedObject[objectId];
            this.expandedObject = Object.assign({}, this.expandedObject, obj);
            // this.$emit('update:value', updatedValue);
        },

        objectHandler(objectId, checked) {
            if (checked) {
                this.selectedObjects.push(objectId);
            } else {
                this.selectedObjects.splice(this.selectedObjects.indexOf(objectId), 1);
            }
        },
        zoneHandler(zoneId, checked) {
            if (checked) {
                this.selectedZones.push(zoneId);
            } else {
                this.selectedZones.splice(this.selectedZones.indexOf(zoneId), 1);
            }
        },
        cameraHandler(cameraId, checked) {
            if (checked) {
                this.selectedCameras.push(cameraId);
            } else {
                this.selectedCameras.splice(this.selectedCameras.indexOf(cameraId), 1);
            }
        },
        digressionHandler(digressionId, type, checked) {
            if (type === 'create') {
                if (checked) {
                    this.selectedDigressionsToCreate.push(digressionId);
                } else {
                    this.selectedDigressionsToCreate.splice(this.selectedDigressionsToCreate.indexOf(digressionId), 1);
                }
            } else if (type === 'execute') {
                if (checked) {
                    this.selectedDigressionsToExecute.push(digressionId);
                } else {
                    this.selectedDigressionsToExecute.splice(this.selectedDigressionsToExecute.indexOf(digressionId), 1);
                }
            }
        },
        openDigressions(objectId) {
            this.selectedObjectToDigression = Object.assign({}, (window.myObjects || []).find(obj => obj.id === objectId));
            if (this.selectedObjectToDigression.id) {
                this.selectedDigressionsToCreate = [];
                this.selectedDigressionsToExecute = [];
                this.getAvailableDigressions();
                // this.$emit('update:mode', 3);
            }
        },
        closeDigressions() {
            const objectId = this.selectedObjectToDigression.id;
            this.selectedDigressionsByObjects[objectId] = [];

            this.selectedDigressionsToCreate.forEach( digressionId => {
                this.selectedDigressionsByObjects[objectId].push({
                    digressionId: digressionId,
                    digressionType: 'create'
                });
            })

            this.selectedDigressionsToExecute.forEach( digressionId => {
                this.selectedDigressionsByObjects[objectId].push({
                    digressionId: digressionId,
                    digressionType: 'execute'
                });
            })            

            this.availableDigressions = [];
            this.selectedDigressionsToCreate = [];
            this.selectedDigressionsToExecute = [];

            this.selectedObjectToDigression = null;
            this.mode = 'objects';
        },
        getAvailableDigressions() {
            this.loading = true;
            this.availableDigressions = [];
            window.myAjax('GET', `/api/object-digressions`, {
                object_id: this.selectedObjectToDigression.id
            }, (error, response) => {
                this.loading = false;
                if(!error) {
                    (response.object_digressions || []).forEach( relation => {
                        // const digression = (window.myDevs || []).find( dev => dev.id === relation.)
                        this.availableDigressions.push(relation.digression);
                    });
                    // this.availableDigressions.forEach( digression => {
                        const relations = this.selectedDigressionsByObjects[this.selectedObjectToDigression.id] || [];
                        relations.forEach( relation => {
                            if (relation.digressionType === 'create') {
                                this.selectedDigressionsToCreate.push(relation.digressionId);
                            } else if (relation.digressionType === 'execute') {
                                this.selectedDigressionsToExecute.push(relation.digressionId);
                            }
                        })
                        // const relations = this.digressionsRelations.filter( relation => relation.digression_id === digression.id);
                        // relations.forEach( relation => {
                        //     if (relation.digression_type === 'create') {
                        //         this.selectedDigressionsToCreate.push(digression.id);
                        //     } else if (relation.digression_type === 'execute') {
                        //         this.selectedDigressionsToExecute.push(digression.id);
                        //     }
                        // })
                    // })
                    if (this.availableDigressions.length <= this.selectedDigressionsToCreate.length) {
                        this.allSelectedCreate = true;
                    }
                    if (this.availableDigressions.length <= this.selectedDigressionsToExecute.length) {
                        this.allSelectedExecute = true;
                    }
                    this.mode = 'digressions';
                }
            });
        },
        
        toggleAllDigressions(type) {
            if (type === 'create') {
                this.selectedDigressionsToCreate = [];
                if (!this.allSelectedCreate) {
                    this.availableDigressions.forEach( digression => {
                        this.selectedDigressionsToCreate.push(digression.id);
                    })
                }
                this.allSelectedCreate = !this.allSelectedCreate;
            } else if (type === 'execute') {
                this.selectedDigressionsToExecute = [];
                if (!this.allSelectedExecute) {
                    this.availableDigressions.forEach( digression => {
                        this.selectedDigressionsToExecute.push(digression.id);
                    })
                }
                this.allSelectedExecute = !this.allSelectedExecute;
            }
        },

        getSampleRelations() {
            const user = this.sample;
            if (!user) {
                return;
            }
            const id = user.id;
            this.loading = true;
            window.myAjax('GET', '/api/user-object-relations', {
                user_id: id
            }, (error, response) => {
                if(!error) {
                    this.selectedObjects = [];
                    this.selectedZones = [];
                    response.user_objects.forEach(relation => {
                        const object = (window.myObjects || []).find(obj => obj.id === relation.object_id);
                        if (!object) {
                            return;
                        }
                        if (object.object_type === 'object') {
                            this.selectedObjects.push(object.id);
                        } else if (object.object_type === 'zone') {
                            this.selectedZones.push(object.id);
                        }
                    })
                    window.myAjax('GET', '/api/user-camera-relations', {
                        user_id:  id
                    }, (error2, response2) => {
                        if(!error2) {
                            this.selectedCameras = [];
                            response2.user_cameras.forEach(relation => {
                                const camera = (window.myCams || []).find(cam => cam.id === relation.camera_id);
                                if (!camera) {
                                    return;
                                }
                                this.selectedCameras.push(camera.id);
                            })
                            window.myAjax('GET', '/api/user-digression-relations', {
                                user_id: id
                            }, (error3, response3) => {
                                if(!error3) {
                                    this.digressionsRelations = response3.relations;
                                    this.selectedDigressionsByObjects = {};

                                    this.digressionsRelations.forEach( relation => {
                                        const userObjectRelation = response.user_objects.find(userObject => userObject.id === relation.user_object_id);
                                        const objectId = userObjectRelation.object_id;
                                        if (!this.selectedDigressionsByObjects[objectId]) {
                                            this.selectedDigressionsByObjects[objectId] = [];
                                        }
                                        this.selectedDigressionsByObjects[objectId].push({
                                            digressionId: relation.digression_id,
                                            digressionType: relation.digression_type
                                        });
                                    })
                                    this.loading = false;
                                }
                            })
                        }
                    })
                }
            })
        },

        saveRelations() {
            this.mode = 'checking';
            this.loading = true;
            this.getUsersStack = [];
            this.users.forEach( u => {
                this.getUsersStack.push(u);
            })
            this.usersData = {};
            // console.log('selectedObjects', this.selectedObjects);
            // console.log('selectedZones', this.selectedZones);
            // console.log('selectedCameras', this.selectedCameras);
            // console.log('selectedDigressionsByObjects', this.selectedDigressionsByObjects);
            this.getUserRelations();
            // this.$emit('close');
        },
        getUserRelations() {
            const user = this.getUsersStack.pop();
            this.usersData[user.id] = {
                objectsRelations: [],
                camerasRelations: [],
                digressionsRelations: {},
                user: user,
                info: {}
            };
            window.myAjax('GET', '/api/user-object-relations', {
                user_id: user.id
            }, (error, response) => {
                if(!error) {
                    this.usersData[user.id].objectsRelations = response.user_objects;
                    window.myAjax('GET', '/api/user-camera-relations', {
                        user_id:  user.id
                    }, (error2, response2) => {
                        if(!error2) {
                            this.usersData[user.id].camerasRelations = response2.user_cameras;
                            window.myAjax('GET', '/api/user-digression-relations', {
                                user_id: user.id
                            }, (error3, response3) => {
                                if(!error3) {
                                    response3.relations.forEach( relation => {
                                        const userObjectRelation = response.user_objects.find(userObject => userObject.id === relation.user_object_id);
                                        const objectId = userObjectRelation.object_id;
                                        if (!this.usersData[user.id].digressionsRelations[objectId]) {
                                            this.usersData[user.id].digressionsRelations[objectId] = [];
                                        }
                                        this.usersData[user.id].digressionsRelations[objectId].push(relation);
                                    })
                                    if (this.getUsersStack.length) {
                                        this.getUserRelations();
                                    } else {
                                        this.getUserRelationsHandler();
                                    }
                                }
                            })
                        }
                    })
                }
            })
        },
        getUserRelationsHandler() {
            this.usersInfo = [];
            for (let userId in this.usersData) {
                const objectsToAdd = [];
                const objectsToRemove = [];
                const objectsToRemoveIds = [];
                this.selectedObjects.forEach( objectId => {
                    if (this.usersData[userId].objectsRelations.findIndex(rel => rel.object_id === objectId) < 0) {
                        const obj = (window.myObjects || []).find( object => object.id === objectId);
                        if (obj) {
                            objectsToAdd.push(obj);
                        }
                    }
                });
                console.log('objectsToAdd')
                this.selectedZones.forEach( zoneId => {
                    if (this.usersData[userId].objectsRelations.findIndex(rel => rel.object_id === zoneId) < 0) {
                        const zone = (window.myObjects || []).find( object => object.id === zoneId);
                        if (zone) {
                            objectsToAdd.push(zone);
                        }
                    }
                });
                console.log('objectsToAdd', objectsToAdd.length)
                this.usersData[userId].objectsRelations.forEach( relation => {
                    if (!this.selectedObjects.includes(relation.object_id) && !this.selectedZones.includes(relation.object_id)) {
                        const obj = (window.myObjects || []).find( object => object.id === relation.object_id);
                        if (obj) {
                            objectsToRemove.push(obj);
                            objectsToRemoveIds.push(relation.id);
                        }
                        // console.log()
                        // objectsToRemove.push();
                        // objectsToRemoveIds.push(relation.id);
                    }
                });
                console.log('objectsToRemove', objectsToRemove.length)
                const camerasToAdd = [];
                const camerasToRemove = [];
                const camerasToRemoveIds = [];
                this.selectedCameras.forEach( cameraId => {
                    if (this.usersData[userId].camerasRelations.findIndex(rel => rel.camera_id === cameraId) < 0) {
                        camerasToAdd.push((window.myCams || []).find( cam => cam.id === cameraId) || {
                            id: cameraId,
                            name: '-'
                        });
                    }
                });
                console.log('camerasToAdd', camerasToAdd.length)
                this.usersData[userId].camerasRelations.forEach( relation => {
                    if (!this.selectedCameras.includes(relation.camera_id)) {
                        camerasToRemove.push((window.myCams || []).find( cam => cam.id === relation.camera_id) || {
                            id: relation.camera_id,
                            name: '-'
                        });
                        camerasToRemoveIds.push(relation.id);
                    }
                });
                console.log('camerasToRemove', camerasToRemove.length)

                const digressionToAdd = [];
                const digressionToRemove = [];
                for (let objectKey in this.selectedDigressionsByObjects) {
                    const objectId = parseInt(objectKey);
                    if (this.selectedObjects.includes(objectId) || this.selectedZones.includes(objectId)) {
                        (this.selectedDigressionsByObjects[objectId] || []).forEach( dig => {
                            if ( (this.usersData[userId].digressionsRelations[objectId] || []).findIndex( rel => rel.digression_id === dig.digressionId && rel.digression_type === dig.digressionType ) < 0 ) {
                                digressionToAdd.push({
                                    digressionId: dig.digressionId,
                                    digressionType: dig.digressionType,
                                    objectId: objectId
                                });
                            }
                        }) 
                    }
                }
                for (let objectKey in this.usersData[userId].digressionsRelations) {
                    const objectId = parseInt(objectKey);
                    (this.usersData[userId].digressionsRelations[objectId] || []).forEach(rel => {
                        if ( (this.selectedDigressionsByObjects[objectId] || []).findIndex( dig => rel.digression_id === dig.digressionId && rel.digression_type === dig.digressionType ) < 0 ) {
                            digressionToRemove.push(rel);
                        }
                    })
                }

                this.usersInfo.push({
                    id: userId,
                    user: this.usersData[userId].user,
                    objectsToAdd: objectsToAdd,
                    objectsToRemove: objectsToRemove,
                    objectsToRemoveIds: objectsToRemoveIds,
                    camerasToAdd: camerasToAdd,
                    camerasToRemove: camerasToRemove,
                    camerasToRemoveIds: camerasToRemoveIds,
                    digressionToAdd: [...new Set(digressionToAdd)],
                    digressionToRemove: [...new Set(digressionToRemove)],
                    empty: (objectsToAdd.length + objectsToRemove.length +
                            camerasToAdd.length + camerasToRemove.length + 
                            digressionToAdd.length + digressionToRemove.length) === 0
                })
            }
            this.loading = false;
        },
        saveAll() {
            this.currentUpdateSource = {};
            this.usersInfo.forEach( u => {
                this.saveUsersStack.push(u);
                this.currentUpdateSource[u.id] = [];
            });
            this.updateUserRelations();
            // for (let userId in this.usersInfo) {
            //     this.saveUsersStack.push(this.usersInfo[userId]);
            // }
        },
        updateUserRelations() {
            if (this.saveUsersStack.length) {
                this.currentUpdatedUser = this.saveUsersStack.pop();
                this.deleteDigressionRelations();
            } else {
                this.loading = true;
                window.myAlert('Изменения сохранены');
                this.$emit('close');
            }
        },

        deleteDigressionRelations() {
            const data = [];
            this.currentUpdatedUser.digressionToRemove.forEach( rel => {
                data.push(rel);
            })
            if (data.length) {
                window.myAjax('DELETE', '/api/user-digression-relations', {
                    objects: JSON.stringify(data)
                }, (error) => {
                    if(!error) {
                        this.deleteCameraRelations();
                    }
                });
            } else {
                this.deleteCameraRelations();
            }
        },

        deleteCameraRelations() {
            if (this.currentUpdatedUser.camerasToRemoveIds.length) {
                const idToDelete = this.currentUpdatedUser.camerasToRemoveIds.pop();
                window.myAjax('DELETE', '/api/user-camera-relation', {
                    id: idToDelete
                }, (error) => {
                    if(!error) {
                        this.deleteCameraRelations();
                    }
                });
            } else {
                this.deleteObjectRelations();
            }
        },

        deleteObjectRelations() {
            if (this.currentUpdatedUser.objectsToRemoveIds.length) {
                const idToDelete = this.currentUpdatedUser.objectsToRemoveIds.pop();
                window.myAjax('DELETE', '/api/user-object-relation', {
                    id: idToDelete
                }, (error) => {
                    if(!error) {
                        this.deleteObjectRelations();
                    }
                });
            } else {
                this.createObjectRelations();
            }
        },

        createObjectRelations() {
            if (this.currentUpdatedUser.objectsToAdd.length) {
                const objectToAdd = this.currentUpdatedUser.objectsToAdd.pop();
                window.myAjax('POST', '/api/user-object-relation', {
                    object_id: objectToAdd.id,
                    user_id: this.currentUpdatedUser.id
                }, (error, response) => {
                    if(!error) {
                        this.currentUpdateSource[this.currentUpdatedUser.id].push(response.relation);
                        this.createObjectRelations();
                    }
                });
            } else {
                this.createCameraRelations();
            }
        },

        createCameraRelations() {
            if (this.currentUpdatedUser.camerasToAdd.length) {
                const cameraToAdd = this.currentUpdatedUser.camerasToAdd.pop();
                window.myAjax('POST', '/api/user-camera-relation', {
                    camera_id: cameraToAdd.id,
                    user_id: this.currentUpdatedUser.id
                }, (error) => {
                    if(!error) {
                        this.createCameraRelations();
                    }
                });
            } else {
                this.createDigressionRelations();
            }
        },

        createDigressionRelations() {
            if (this.currentUpdatedUser.digressionToAdd.length) {
                const data = [];
                this.currentUpdatedUser.digressionToAdd.forEach( dig => {
                    let relation = this.currentUpdateSource[this.currentUpdatedUser.id].find( rel => rel.object_id === dig.objectId);
                    if (!relation) {
                        relation = ((this.usersData[this.currentUpdatedUser.id] || {}).objectsRelations || []).find(rel => rel.object_id === dig.objectId);
                    }
                    if (relation) {
                        data.push({
                            user_object_id: relation.id,
                            digression_id: dig.digressionId,
                            digression_type: dig.digressionType
                        });
                    }
                });
                if (data.length) {
                    window.myAjax('POST', '/api/user-digression-relations', {
                        objects: JSON.stringify(data)
                    }, (error) => {
                        if(!error) {
                            this.updateUserRelations();
                        }
                    });
                } else {
                    this.updateUserRelations();
                }
            } else {
                this.updateUserRelations();
            }
        }
    }
}
