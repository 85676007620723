// const PADDING = 24;

export default {
    name: 'Graph',
    components: {
        //todo
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        items: {
            type: Object,
            default: {}
        },
        type: { 
            type: String,
            default: 'dates'
        },
        startDate: { 
            type: String,
            default: null
        },
        endDate: { 
            type: String,
            default: null
        }
        //todo
    },
    data: () => ({
        horizontal: [],
        vertical: [],

        data: [],
        maxCount: 0,
        minCount: 0,

        activeItem: null,
        activeItemKey: null,
        aValue: 0,
        bValue: 0
        //todo
    }),
    watch: {
        items() {
            this.initData();
            this.setHorizontal();
            this.setVertical();
            setTimeout( () => {
                this.initCanvas();
            }, 10);
        }
        // startDate() {
        //     setHorizontal
        // }
    },
    mounted() {
        // this.initCanvas();
        window.addEventListener('resize', this.resizeHandler);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        initCanvas() {
            if (this.data.length <= 1) {
                return;
            }
            if (this.type === 'dates') {

                const width = this.$el.clientWidth;
                const height = this.$el.clientHeight;
                const canvas = this.$refs['canvas'];
                if (!canvas) {
                    return;
                }
                canvas.width = width - 24 - 16;
                canvas.height = height - 24 - 24;
                const context = canvas.getContext('2d');
                const widthDiff = (this.data[0].width / 100) * canvas.width / 2;

                context.lineWidth = 1;
                context.strokeStyle = "#37445e";
                context.lineCap = "round";
                context.lineJoin = "round";
                context.beginPath();
                const a0 = (this.bValue + this.aValue) / this.maxCount;
                const a1 = (this.bValue * this.data.length + this.aValue) / this.maxCount;
                context.moveTo( 0, 
                    canvas.height - canvas.height * a0, 
                );
                context.lineTo( canvas.width,
                    canvas.height - canvas.height * a1
                );
                context.stroke();
                context.lineWidth = 2;
                context.strokeStyle = "#46629c";
                context.lineCap = "round";
                context.lineJoin = "round";
                context.beginPath();
                context.moveTo(
                    (this.data[0].left / 100) * canvas.width + widthDiff,
                    canvas.height - (this.data[0].height / 100) * canvas.height
                );
                
                for (let i = 1; i < this.data.length; i++) {
                    const x = (this.data[i].left / 100) * canvas.width + widthDiff;
                    const y = canvas.height - (this.data[i].height / 100) * canvas.height;
                    context.lineTo(x, y);
                }
                context.stroke();
            } else if (this.type === 'hours') {
                const width = this.$el.clientWidth;
                const height = this.$el.clientHeight;
                const canvas = this.$refs['canvas'];
                if (!canvas) {
                    return;
                }
                canvas.width = width - 24 - 16;
                canvas.height = height - 24 - 24;
                const context = canvas.getContext('2d');
                const widthDiff = (this.data[0].width / 100) * canvas.width / 2;

                context.lineWidth = 1;
                context.strokeStyle = "#46629c";
                context.lineCap = "round";
                context.lineJoin = "round";
                context.beginPath();
                context.moveTo(
                    (this.data[0].left / 100) * canvas.width + widthDiff - 2,
                    canvas.height - (this.data[0].bottom / 100) * canvas.height
                );
                
                for (let i = 1; i < this.data.length; i++) {
                    const x = (this.data[i].left / 100) * canvas.width + widthDiff;
                    const y = canvas.height - (this.data[i].bottom / 100) * canvas.height;
                    context.lineTo(x, y);
                }
                context.stroke();
            }
        },
        resizeHandler() {
            this.initCanvas();
            this.setHorizontal();
            this.setVertical();
        },
        mouseMoveHandler(event) {
            let clientRect = event.target.getBoundingClientRect();
            const currentPosition = event.clientX - clientRect.x;
            const diffProcent = currentPosition / clientRect.width;
            const dataItemIndex = Math.floor(diffProcent * this.data.length);
            this.activeItemKey = dataItemIndex;
            if (this.activeItemKey < 0) {
                this.activeItemKey = 0;
            }
            this.activeItem = this.data[dataItemIndex];
        },

        initData() {
            if (this.type === 'dates') {
                this.data = [];
                // const prevData = {};
                this.maxCount = 1;
                for (let key in this.items) {
                    this.maxCount = Math.max(this.maxCount, this.items[key]);
                }

                const oneDay = 24 * 60 * 60 * 1000;
                const firstDate = new Date(this.startDate);
                const secondDate = new Date(this.endDate);
                const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

                const width = 100 * 1 / (diffDays + 1);

                for (let i = 0; i <= diffDays; i++) {

                    const date = new Date(firstDate.getTime() + i * oneDay);
                    // const day = date.getDay();
                    // const month = date.getMonth();

                    const key = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
                    let count = 0;
                    if (this.items[key]) {
                        count = this.items[key];
                    }

                    const item = {
                        caption: date.toLocaleDateString(),
                        key: i,
                        value: key,
                        width: width,
                        height: (100 * count) / this.maxCount,
                        count: count
                    }
                    item.left = (100 * i) / (diffDays + 1);
                    this.data.push(item);

                    // console.log(date.getDay());
                }

                const xArray = [];
                const yArray = [];
                let sumX = 0;
                let sumY = 0;
                this.data.forEach( (item, index) => {
                    xArray.push(index + 1);
                    yArray.push(item.count);
                    sumX += index + 1;
                    sumY += item.count;
                })
                let sumXY = 0;
                let sumXX = 0;
                xArray.forEach( (value, index) => {
                    sumXY += value * yArray[index];
                    sumXX += value * value;
                });
                this.bValue = (sumXY * xArray.length - sumX * sumY) / ( sumXX * xArray.length - Math.pow(sumX, 2));
                this.aValue = (sumY - sumX * this.bValue) / xArray.length;

            } else if (this.type === 'times') {
                this.data = [];
                const prevData = {};

                const oneDay = 24 * 60 * 60 * 1000;
                const firstDate = new Date(this.startDate);
                const secondDate = new Date(this.endDate);
                const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                
                const d = new Date();
                let diff = d.getTimezoneOffset()/ -60;
                for (let key in this.items) {
                    this.items[key];

                    (this.items[key] || []).forEach(time => {
                        let hourKey = parseInt( time.split(':')[0], 10) + diff;
                        hourKey = hourKey % 24;
                        if (!prevData[hourKey]) {
                            prevData[hourKey ] = [];
                        }
                        prevData[hourKey].push(time)
                    });
                }
                const width = 100 * 1 / 24;
                this.maxCount = 2;
                for (let i = 0; i < 24; i++) {
                    const count = (prevData[i] || []).length / (diffDays + 1);
                    this.maxCount = Math.max(this.maxCount, count);
                }
                for (let i = 0; i < 24; i++) {
                    const count = (prevData[i] || []).length / (diffDays + 1);
                    const item = {
                        caption: `${i}:00 - ${i + 1}:00`,
                        key: i,
                        value: i * 60,
                        width: width,
                        height: (100 * count) / this.maxCount,
                        count: Number.parseFloat(count.toFixed(2))
                    }
                    item.left = (100 * item.value) / (24 * 60);
                    this.data.push(item);
                }
            } else if (this.type === 'hours') {
                this.data = [];
                this.maxCount = 0;
                this.minCount = 0;

                this.items.forEach( state => {
                    const value = parseFloat(state.value);
                    if (value > this.maxCount) {
                        this.maxCount = value;
                    }
                });
                this.maxCount = 1 + Math.floor(this.maxCount * 1.2);
                this.minCount = this.maxCount;
                this.items.forEach( state => {
                    const value = parseFloat(state.value);
                    if (value < this.minCount) {
                        this.minCount = value;
                    }
                });
                this.minCount = Math.floor(this.minCount * 0.8);
                const d = new Date();
                let diff = d.getTimezoneOffset()/ -60;
                this.items.forEach( (state, index) => {
                    if (!state.available) {
                        return;
                    }
                    const value = parseFloat(state.value);
                    const date = new Date(state.created_at);
                    date.setHours(date.getHours() + diff);
                    const day = ('0' + date.getDate()).slice(-2);
                    const month = ('0' + (date.getMonth() + 1)).slice(-2);
                    const hour = ('0' + date.getHours()).slice(-2);
                    const minute = ('0' + date.getMinutes()).slice(-2);
                    const item = {
                        caption: `${day}.${month} ${hour}:${minute}`, //Градусов, °C
                        key: index,
                        value: value,
                        width: 100 / this.items.length,
                        bottom: (100 * (value - this.minCount)) / (this.maxCount - this.minCount),
                        count: value,
                        created_at: state.created_at
                    }
                    item.left = (100 * index) / (this.items.length);
                    this.data.push(item);
                })
            }
        },

        setVertical() {
            if (!this.data.length) {
                return;
            }
            this.vertical = [];
            const availableHeight = this.$el.clientHeight - 24 - 22;
            const avaliableCounts = Math.floor( availableHeight / 24 );
            const itemsCount = Math.max(Math.min(avaliableCounts, this.maxCount), this.minCount);
            const itemsCountStep = (this.maxCount - this.minCount) / itemsCount;
            const itemHeight = availableHeight / itemsCount;
            for (let i = this.minCount; i <= this.maxCount; i += itemsCountStep ) {
                const item = {
                    value: i,
                    height: itemHeight,
                    caption: Math.round(i)
                }
                item.bottom = (100 * (i - this.minCount)) / (this.maxCount - this.minCount);
                this.vertical.push(item);
            }
        },

        setHorizontal() {
            if (!this.data.length) {
                return;
            }
            this.horizontal = [];
            if (this.type === 'dates') {
                const availableWidth = this.$el.clientWidth - 24 - 16;
                const avaliableCounts = Math.floor( availableWidth / 30 );
                const itemsCount = Math.min(avaliableCounts, this.data.length);
                const itemsCountStep = Math.round(this.data.length / itemsCount);
                const itemWidth = 100 / this.data.length;
                for (let i = 0; i < this.data.length; i += itemsCountStep ) {
                    const date = new Date(this.data[i].value);
                    const day = ('0' + date.getDate()).slice(-2);
                    const month = ('0' + (date.getMonth() + 1)).slice(-2);
                    const item = {
                        caption: `${day}.${month}`,
                        value: this.data[i].value,
                        width: itemWidth
                    } 
                    item.left = (100 * i) / (this.data.length);
                    item.left += (itemWidth / 2);
                    this.horizontal.push(item);
                }
            } else if (this.type === 'times') {
                const width = 100 * 1 / 24;
                for (let i = 0; i < 24; i++) {
                    const item = {
                        caption: i + ':00',
                        value: i * 60,
                        width: width
                    }
                    item.left = (100 * item.value) / (24 * 60);
                    this.horizontal.push(item);
                }
            } else if (this.type === 'hours') {
                const availableWidth = this.$el.clientWidth - 24 - 16;
                const avaliableCounts = Math.floor( availableWidth / 60 );
                const itemsCount = Math.min(avaliableCounts, this.data.length);
                const itemsCountStep = Math.round(this.data.length / itemsCount);
                const itemWidth = 100 / itemsCount;
                for (let i = 0; i < this.data.length; i += itemsCountStep ) {
                    const item = {
                        caption: this.data[i].caption,
                        value: i,
                        width: itemWidth
                    } 
                    item.left = (100 * i) / (this.data.length);
                    item.left += (itemWidth / 2);
                    this.horizontal.push(item);
                }
            }
        }
    }
}
