// const HELPER_BOUND_WEIGHT = 26;

export default {
    name: 'BaseHelperList',
    props: {
        captionItems: {
            type: Array,
            default: []
        },
        width: {
            type: Number,
            default: 250
        }
    },
    data: () => ({
        hover: false,
        isBottom: true,
        vertical: 0,
        left: 0
    }),
    methods: {
        mouseOverHandler() {
            this.hover = true;
        },
        mouseLeaveHandler() {
            this.hover = false;
        }
    }
};
