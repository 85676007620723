<style scoped>
    .head_dev {
        font-size: 36px;
        font-weight: 300;
    }
    .cam_check {
        max-width: 24px;
        height: 24px;
        padding: 0px;
        content: '';
        border: 2px solid #C2C2C2;
        border-radius: 20px;
        cursor: pointer;
    }
    .cam_check.checked {
        background: url('../../assets/img/galka.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #4C974A;
    }
    input {
        background-color: white;
        border: 1px solid #ccc;
        padding: 7px;
        padding-left: 15px;
        width: 100%;
    }
    .add_node {
        background: url('../../assets/img/new_role.svg');
        background-size: 30px;
    }
    input {
        border-radius: 7px;
    }

</style>


<template>
    <div class="devCard row mb-8 mx-0 px-6">

        <div class="col-12 head_dev mb-5 mt-7 pl-0">{{ $t('Группа') }}</div>

        <div class="col-12 px-0 mx-0">
            <input v-model="group.name" placeholder="Введите название группы">
        </div>

        <div class="pt-4 col-12">
            <div class="row py-2 my_bold">
                <div class="col-1 py-0">#</div>
                <div class="col-5 py-0">{{ $t('ФИО') }}</div>
                <div class="col-5 py-0">{{ $t('Объекты') }}</div>
                <div class="col-1 py-0 pr-0">
                    <div v-if="group.id" class='controls add_node mx-auto my-0' :title="$t('добавить супервайзеров')" @click="show_all_users = !show_all_users"></div>
                </div>
            </div>
            <div v-for="user in users" :key="user.id">
                <div v-if="user.in_group || show_all_users" class="row border-top py-2">
                    <div class="col-1 my-auto py-0">{{ user.id }}</div>
                    <div class="col-5 my-auto py-0">{{ userFio(user.profile) }}</div>
                    <div class="col-5 my-auto py-0">
                        <div class="mb-1" v-for="(object, i) in user.user_objects_in_rucsup" v-bind:key="i">{{object.name}}</div>
                    </div>
                    <div v-if="show_all_users" class="col-1 pr-0 my-auto">
                        <div :class="[user.in_group ? 'checked': '', 'cam_check col-1 py-0 m-auto']"
                             :title="user.in_group ? 'Удалить супервайзера из группы' : 'Добавить супервайзера в группу'"
                             @click="user.in_group = !user.in_group"></div>
                    </div>
                </div>
            </div>
        </div>



        <hr class="col-12 mt-0 mt-4 px-0"/>
        <div class="col-12 pr-0 row mx-0 justify-content-end">
            <div class="my_btn btn_delete mb-3" @click="hideGroup">{{ $t('Отмена') }}</div>
            <div class="my_btn btn_green ml-8 mb-3" :title="$t('сохранить шаблон')" @click="saveGroup">{{ $t('Сохранить') }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Group',
        props: ['group'],
    data: () => ({
        users: [],
        show_all_users: true
    }),
    mounted() {
        // window.$('.scrolling_content').css('max-height', window.innerHeight-60)
        window.resizeScrollingContent();
        if(this.group.id)
            this.show_all_users = false
        this.users = []
        for(let i=0; i<window.myUsers.length; i++) {
            let user = Object.assign({}, window.myUsers[i])
            user.in_group = false
            for(let j=0; j<this.group.users_id.length; j++) {
                if (this.group.users_id[j] == user.id) {
                    user.in_group = true
                    break
                }
            }
            this.users.push(user)
        }
        this.getSupervisors()
    },
    methods: {
        getSupervisors() {
            let url = '/api/get-related-supervisors',
                method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartSup)
        },
        myCallbackGetStartSup (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myUsers = response.users
                for(let j=0; j<window.myUsers.length; j++) {
                    window.myUsers[j].user_objects_in_rucsup = []
                    for(let k=0; k<window.myUsers[j].objects.length; k++) {
                        for(let i=0; i<window.myObjects.length; i++) {
                            if(window.myObjects[i].id==window.myUsers[j].objects[k].object_id) {
                                if(window.myObjects[i].object_type=='zone') {
                                    let parentObjName = this.getParentObjectName(window.myObjects[i])
                                    window.myUsers[j].user_objects_in_rucsup.push({
                                        name: parentObjName + '/' + window.myObjects[i].name
                                    })
                                } else
                                    window.myUsers[j].user_objects_in_rucsup.push({
                                        name: window.myObjects[i].name
                                    })
                                break
                            }
                        }
                    }
                }

                this.users = []
                for(let i=0; i<window.myUsers.length; i++) {
                    let user = Object.assign({}, window.myUsers[i])
                    user.in_group = false
                    for(let j=0; j<this.group.users_id.length; j++) {
                        if (this.group.users_id[j] == user.id) {
                            user.in_group = true
                            break
                        }
                    }
                    this.users.push(user)
                }
            }
        },
        getParentObjectName(obj) {
            for(let i=0; i<window.myObjects.length; i++)
                if(obj.parent_id==window.myObjects[i].id)
                    return window.myObjects[i].name
            return ''
        },

        working() {
//            window.myAlert('работаем...')
        },

        refreshUsers() {
            if(this.group.id)
                this.show_all_users = false
            else
                this.show_all_users = true
            for(let i=0; i<this.users.length; i++) {
                this.users[i].in_group = false
                for(let j=0; j<this.group.users_id.length; j++)
                    if (this.group.users_id[j] == this.users[i].id) {
                        this.users[i].in_group = true
                        break
                    }
            }
        },

        saveGroup() {
            if (this.group.id)
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения группы?', this.saveGroup2)
            else
                this.saveGroup2()
        },


        saveGroup2() {
            if(this.group.name.length) {
                let url = '/api/create-user-group',
                    method = 'POST';
                if (this.group.id)
                    url = '/api/update-user-group'
                this.group.users_id = []
                for(let i=0; i<this.users.length; i++)
                    if(this.users[i].in_group)
                        this.group.users_id.push(this.users[i].id)
                window.myAjax(method, url, this.group, this.myCallbackSaveGroup)
            } else
                window.myAlert('Не указано название группы')
        },
        myCallbackSaveGroup (error, response) {
            window.myAlert(response.message)
            if(!error) {
                if(!this.group.id)
                    this.group.id = response.group.id
                this.hideGroup()
            }
        },

        hideGroup() {
            this.$emit('getGroups')
        },
        userFio(user_profile) {
            let res
            if(!user_profile)
                res = 'Профиль пользователя не создан'
            else {
                res = user_profile.last_name + ' ' + user_profile.first_name
                if(user_profile.middle_name)
                    res += ' ' + user_profile.middle_name
            }
            return res
        },


    }
  }
</script>
