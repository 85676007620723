export default {
    name: 'BaseButton',
    props: {
        caption: String,
        title: String,
        icon: String,
        viewMode: String
    },
    data: () => ({
    }),
    methods: {
        clickHandler() {
            this.$emit('click');
        }
    }
};
