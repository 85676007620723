/**
 * @typedef {object} setLogParams
 * @property {number} primary_id Id листа (point), который логируем.
 * @property {string} status Один из 'ok', 'has_digressions' (соотв, если 'has_digressions', то передаём и `digressions`).
 * @property {number[]} digressions Массив доступных отклонений, либо тull, если статус 'ok'.
 */
/**
 * Логирование (изменение статуса) пункта чек-листа.
 * @param {setLogParams} params Параметры.
 * @param {function} callback Функция обратного вызова при успешном вызове. 
 */
 export function setChecklistLog(params, callback) {
    const url = '/api/checklist-log';
    const method = 'POST';
    const data = Object.assign({}, params);
    if (params.digressions) {
        data.digressions = JSON.stringify(params.digressions);
    }
    window.myAjax(method, url, data, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    });
}
