// const TYPES = ['digressions', 'smart_archive', 'archive'];
// const SOURCES_TITLES = {
//     'digressions': 'Отклонения',
//     'smart_archive': 'Смарт-архив',
//     'archive': 'Архив'
// };

export default {
    name: 'GlobalUserSettings',
    components: {
        //todo
    },
    props: [],
    data: () => ({
        loading: false,
        settings: [],
        toDelete: [],
        toAdd: [],
    }),
    mounted() {
        this.getUsersSettings();
    },
    methods: {
        addSetting() {
            this.toAdd.push({
                value: '',
                id: this.toAdd.length ? (this.toAdd[this.toAdd.length - 1].id + 1) : 1
            });
        },
        removeSetting(value) {
            this.toDelete.push(value);
        },
        removeAdded(value) {
            const index = this.toAdd.findIndex( v => v.value === value);
            if (index > -1) {
                this.toAdd.splice(index, 1);
            }
        },
        getUsersSettings() {
            this.toAdd = [];
            this.toDelete = [];
            this.loading = true;
            window.myAjax('GET', '/api/available_users_settings' , {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    this.settings = [];
                    response.forEach( item => {
                        this.settings.push({value: item.name});
                    })
                }
            })
        },
        saveUserSettings() {
            if (this.toDelete.length) {
                this.deleteUserSettings();
            } else {
                this.postUserSettings();
            }
        },
        deleteUserSettings() {
            this.loading = true;
            if (this.toDelete.length) {
                const data = [];
                this.toDelete.forEach( v => {
                    data.push(v)
                })
                window.myAjax('DELETE', '/api/available_users_settings' , {
                    names: JSON.stringify(data)
                }, (error) => {
                    this.loading = false;
                    if (!error) {
                        this.postUserSettings();
                    } else {
                        window.myAlert('Ошибка сохранения.');
                    }
                })
            }
        },
        postUserSettings() {
            if (this.toAdd.length) {
                this.loading = true;
                if (this.toAdd.length) {
                    const data = [];
                    this.toAdd.forEach( v => {
                        data.push(v.value)
                    })
                    window.myAjax('POST', '/api/available_users_settings' , {
                        names: JSON.stringify(data)
                    }, (error) => {
                        this.loading = false;
                        if (!error) {
                            this.getUsersSettings();
                            window.myAlert('Настройки сохранены.');
                        } else {
                            window.myAlert('Ошибка сохранения.');
                        }
                    })
                }
            } else {
                this.getUsersSettings();
                window.myAlert('Настройки сохранены.');
            }
        },
    }
}
