export default {
    name: 'MonitoringReports',
    components: {
        // todo
    },
    data: () => ({
        start_date: null,
        end_date: null,
        report: {
            total: 0,
            viewed: 0,
            edited: 0,
            v_percent: 0,
            e_percent: 0
        },
        isLoading: false
    }),
    mounted() {
        this.resetFilter();
        this.getReports();
    },
    methods: {
        resetFilter() {
            this.end_date = new Date();
            this.start_date = new Date();
            this.start_date.setMonth(this.start_date.getMonth() - 1);
            this.start_date = this.start_date.toISOString().substring(0,10);
            this.end_date = this.end_date.toISOString().substring(0,10);
        },
        applyFilter() {
            this.getReports();
        },
        getReports() {
            this.isLoading = true;
            const data = {
                start_timestamp: this.start_date ? new Date(this.start_date).getTime() / 1000 : null,
                end_timestamp: this.end_date ? ((new Date(this.end_date).getTime() / 1000) + 86400) : null
            }
            if (data.end_timestamp && data.start_timestamp > data.end_timestamp) {
                window.myAlert('Дата ОТ больше даты ДО')
                return
            }
            window.myAjax('GET', '/analytic/price_tag/verification_statistic', data, this.getReportsCallback)
        },
        getReportsCallback(error, response) {
            this.isLoading = false;
            if(!error) {
                this.report = response;
                if (!Number.isInteger(this.report.viewed)) {
                    this.report.viewed = 0;
                }
                if (!Number.isInteger(this.report.edited)) {
                    this.report.edited = 0;
                }
                this.report.v_percent = this.report.total > 0 ? 
                    parseFloat((100 * this.report.viewed / this.report.total).toFixed(2)) : 0;
                
                this.report.e_percent = this.report.total > 0 ? 
                    parseFloat((100 * this.report.edited / this.report.total).toFixed(2)) : 0;
            }
        }
    }
}