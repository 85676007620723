import TreeTemplate from './treeTemplate'

export default {
    name: 'PricesCategoryTree',
    props: {
        hide: {
            type: Boolean,
            default: false,
        }
        // todo
    },
    components: {
        TreeTemplate
        // todo
    },
    data: () => ({
        categories: [],
        products: [],
        loading: false,
        active: null,
        activep: null,
        categoryOpened: [],
    }),
    mounted() {
        this.getCategories();
    },
    destroyed() {
        // todo
    },
    watch: {
        // todo
    },
    methods: {
        getCategories() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/categories', {}, this.getCategoriesHandler);
        },
        getCategoriesHandler(error, response) {
            if (!error) {
                this.categories = (response || []);
                this.sortChildren(this.categories);
                this.loading = false;
            }
        },
        sortChildren(children) {
            if (children) {
                children = children.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
                children.forEach(child => this.sortChildren(child.children));
            }
        },
        getProducts(category) {
            window.myAjax('GET', '/price-tag/products', {
                limit: 999,
                category_ids: (category || {}).id ? JSON.stringify([(category || {}).id]) : JSON.stringify([]),
            }, this.getProductsHandler);
        },
        getProductsHandler(error, response) {
            if (!error) {
                this.products = response;
            }
        },
        getCategoriesTree() {
            return this.categories;
        },
        setActiveCategory(data) {
            this.active = null;
            if ((data || {}).category) {
                this.active = Object.assign({}, data.category);
                if (!this.hide) {
                    this.getProducts(data.category);
                }
            }
        },
        selectCategory(data) {
            this.selectHandler(data.category);
        },
        selectHandler(category) {
            if (!category) {
                this.active = null;
                this.activep = null;
                this.$emit('selected', {category: null});
                return;
            }
            if (category.children) {
                if (this.categoryOpened.includes(category.id)) {
                    this.categoryOpened.splice(this.categoryOpened.indexOf(category.id), 1);
                    // this.getProducts(category);
                } else {
                    this.categoryOpened.push(category.id);
                    this.setParentCategoryOpened(category.id);
                    this.getProducts(category);
                }
                this.active = Object.assign({}, category);
                this.activep = null;
            } else {
                this.active = null;
                this.activep = Object.assign({}, category);
            }
            this.$emit('selected', {category: category});
        },
        setParentCategoryOpened(category_id) {
            console.log('setParentCategoryOpened', this.getParentIds(category_id));
            (this.getParentIds(category_id) || []).forEach( category_id => {
                if (!this.categoryOpened.includes(category_id)) {
                    this.categoryOpened.push(category_id);
                }
            });
        },

        getParentIds(id) {
            let result = [];
            for (let i = 0; i < this.categories.length; i++) {
                if (this.checkParent(id, this.categories[i], result)) {
                    return result;
                }
            }
            return result;
        },
        checkParent(id, category, result) {
            if (category.id === id) {
                return true;
            }
            if (category.children) {
                for (let i = 0; i < category.children.length; i++) {
                    if (this.checkParent(id, category.children[i], result)) {
                        result.unshift(category.id);
                        return true;
                    }
                }
            }
            return false;
        },
    }
}
