export default {
    name: 'SensorSettings',
    components: {
        // todo
    },
    props: {
        // todo
    },
    data: () => ({
        saving: false,
        loadingDevices: false,
        loadingSensors: false,
        sensorsQueue: [],
        devices: [],
        sensors: [],
        showed: [],


        filter: '',

        cameras: [],
        objects: [],

        deviceCameras: {},

        oldSensors: [],
        oldDevices: [],

        sensorsToUpdate: [],
        devicesToUpdate: [],

        filteredDevices: [],
        filteredSensors: []
    }),
    watch: {
        filter: {
            handler() {
                this.filterChanged();
            }
        },
    },
    mounted() {
        this.cameras = window.myCams;
        this.objects = (window.myObjects || []).filter( object => object.object_type === "object");
        this.getDevices();
    },
    methods: {
        filterChanged() {
            const searchString = this.filter.trim();
            console.log('searchString', searchString);
            if (searchString.length) {
                this.filteredDevices = [];
                this.filteredSensors = [];

                this.sensors.forEach(sensor => {
                    if (sensor.code.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                        this.filteredSensors.push(sensor);
                        this.filteredDevices.push( this.devices.find(d => d.device_id === sensor.device_id) );
                    }
                })

                this.devices.forEach(device => {
                    if (
                        device.device_id.toString().indexOf(searchString.toLowerCase()) >= 0 ||
                        device.imei.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        this.filteredDevices.push(device);
                    }
                })
                
                this.filteredDevices = [...new Set(this.filteredDevices)];
            } else {
                this.filteredDevices = this.devices;
                this.filteredSensors = this.sensors;
            }
        },
        toggleShowed(deviceId) {
            const checked = this.showed.includes(deviceId);
            if (checked) {
                this.showed.splice(this.showed.indexOf(deviceId), 1);
            } else {
                this.showed.push(deviceId);
            }
        },
        objectHandler(deviceId, value) {
            const obj = {};
            if (value) {
                obj[deviceId] = (this.cameras || []).filter( cam => cam.object_id == value );
            } else {
                obj[deviceId] = [];
            }
            this.sensors.filter( sensor => sensor.device_id == deviceId).forEach( sensor => sensor.camera_id = null);
            Object.assign(this.deviceCameras, obj);
        },
        getDevices() {
            this.loadingDevices = true;
            window.myAjax('GET', '/sensor/unimon/devices', {}, this.getDevicesCallback);
        },
        getDevicesCallback(error, response) {
            this.loadingDevices = false;
            this.devices = [];
            this.sensors = [];
            this.oldSensors = [];
            this.oldDevices = [];
            this.filteredDevices = [];
            this.filteredSensors = [];
            this.filter = '';
            if (!error) {
                this.oldDevices = response || [];
                (response || []).forEach( device => {
                    this.devices.push(Object.assign({}, device));
                    if (device.object_id) {
                        this.objectHandler(device.device_id, device.object_id);
                    }
                });
                this.sensorsQueue = [];
                this.loadingSensors = true;
                this.devices.forEach(device => {
                    this.sensorsQueue.push(device.device_id);
                });
                // this.loadingSensors = false;
                // this.sensors = [
                //     {
                //         device_id: 1,
                //         code: 1,
                //         camera_id: null,
                //         type: null,
                //         caption: null
                //     },
                //     {
                //         device_id: 2,
                //         code: 2,
                //         camera_id: null,
                //         type: null,
                //         caption: null
                //     },
                //     {
                //         device_id: 2,
                //         code: 3,
                //         camera_id: null,
                //         type: null,
                //         caption: null
                //     }
                // ];
                // this.devices = [
                //     {
                //         "client_id": null,
                //         "device_id": 1,
                //         "object_id": null
                //     },
                //     {
                //         "client_id": null,
                //         "device_id": 2,
                //         "object_id": null
                //     },
                //     {
                //         "client_id": null,
                //         "device_id": 3,
                //         "object_id": null
                //     }
                // ];
                this.getSensors();
                this.filterChanged();
            }
        },
        shallowEqual(object1, object2) {
            const keys1 = Object.keys(object1);
            const keys2 = Object.keys(object2);
            if (keys1.length !== keys2.length) {
              return false;
            }
            for (let key of keys1) {
              if (object1[key] !== object2[key]) {
                return false;
              }
            }
            return true;
        },
        getSensors() {
            if (this.sensorsQueue.length > 0) {
                const deviceId = this.sensorsQueue.pop();
                window.myAjax('GET', `/sensor/unimon/device/sensors`, {
                    device_id: deviceId
                }, (error, response) => {
                    if (!error) {
                        this.oldSensors = response || [];
                        (response || []).forEach( sensor => {
                            this.sensors.push(Object.assign({}, sensor));
                        });
                    }
                    this.getSensors();
                });
            } else {
                this.loadingSensors = false;
                this.filterChanged();
            }
        },
        reset() {
            if (this.saving) {
                return;
            }
            this.devices = [];
            this.oldDevices.forEach( device => {
                this.devices.push(Object.assign({}, device));
                this.objectHandler(device.device_id, device.object_id);
            });
            this.sensors = [];
            this.oldSensors.forEach( sensor => {
                this.sensors.push(Object.assign({}, sensor));
            });
            this.filterChanged();
        },
        save() {
            if (this.saving) {
                return;
            }
            this.devicesToUpdate = [];
            this.oldDevices.forEach( device => {
                const changedDevice = this.devices.find( d => d.device_id === device.device_id);
                if (!this.shallowEqual(device, changedDevice)) {
                    this.devicesToUpdate.push(changedDevice);
                }
            })
            this.sensorsToUpdate = [];
            this.oldSensors.forEach( sensor => {
                const changedSensor = this.sensors.find( s => s.code === sensor.code);
                if (!this.shallowEqual(sensor, changedSensor)) {
                    this.sensorsToUpdate.push(changedSensor);
                }
            })

            if (this.devicesToUpdate.length) {
                this.updateDevices();
            } else if (this.sensorsToUpdate.length) {
                this.updateSensors();
            }
        },

        updateDevices() {
            if (this.devicesToUpdate.length) {
                const device = this.devicesToUpdate.pop();
                this.saving = true;
                window.myAjax('POST', `/sensor/unimon/device`, {
                    device_id: device.device_id,
                    object_id: device.object_id,
                    client_id: device.client_id,
                }, (error) => {
                    if (!error) {
                        this.updateDevices();
                    } else {
                        window.myAlert('Ошибка сохранения.')
                        this.saving = false;
                        this.getDevices();
                    }
                });
            } else if (this.sensorsToUpdate.length) {
                this.updateSensors();
            } else {
                this.saving = false;
                this.getDevices();
            }
        },

        updateSensors() {
            if (this.sensorsToUpdate.length) {
                const sensor = this.sensorsToUpdate.pop();
                this.saving = true;
                window.myAjax('POST', `/sensor/unimon/device_sensor`, sensor, (error) => {
                    if (!error) {
                        this.updateSensors();
                    } else {
                        window.myAlert('Ошибка сохранения.')
                        this.saving = false;
                        this.getDevices();
                    }
                });
            } else {
                this.saving = false;
                this.getDevices();
            }
        }
    }
}
