const USER_ITEMS = [
    { id: 'IZK', title: 'Журнал' },
    { id: 'Contacts', title: 'Контакты' },
    { id: 'AnalyticReports', title: 'Аналитика' },
    { id: 'Neural', title: 'Нейромодули' },
    { id: 'Reports', title: 'Отчёты' }
]

const ADMIN_ITEMS = [
    { id: 'Objects', title: 'Объекты' },
    { id: 'Cams', title: 'Камеры' },
    { id: 'Devs', title: 'Отклонения' },
    { id: 'Users', title: 'Пользователи' },
    { id: 'Checklist', title: 'Чек-листы' },
    { id: 'PriceTag', title: 'Мониторинг цен' },
]

export default {
    name: 'HeaderItems',
    components: {
    },
    props: {
        isAdminSettings: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            require: true
        },
        active: {
            type: String,
            require: true
        }
    },
    data: () => ({
        items: []
    }),
    watch: {
        isAdminSettings(value) {
            this.items = value ? ADMIN_ITEMS : USER_ITEMS;
        }
    },
    mounted() {
        this.items = USER_ITEMS;
    },
    methods: {
        selectItemHandler(key) {
            this.$emit('itemSelected', key);
        }
    }
}
