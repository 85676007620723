import CheckboxNew from './../../base/checkboxNew';

export default {
    name: 'PricesJournalCard',
    components: {
        CheckboxNew
    },
    props: {
        selected: {
            type: Object,
            default: {}
        },
    },
    data: () => ({
        result: {},
        showFilters: false,
        results: [],
        selected: null,
        showCard: false,
        filter: {
            page: 0,
            limit: 10,
            objectId: '0',
            dateStart: null,
            dateEnd: null,
            verification_required_price: '0'
        },
        isMobile: false,
        screenshotResult: {},
        objectsFilter: [],
        pages: [],
        showInputs: false
    }),
    watch: {
        selected: {
            handler() {
                this.result = this.selected;
                console.log('watch', this.result);
                // todo
            }
        }
    },
    mounted() {
        this.result = this.selected;
        window.journalFromNotify = false;
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
    },
    methods: {
        saveResultReq() {
            this.result.verification_required_price = false;
        },
        imageChecked() {
            this.result.image_low_quality = true;
            this.result.verification_required_price = false;
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        rublesChanged() {
            if (this.result.manual_price_integer) {
                if (!this.result.manual_price_fraction) {
                    this.result.manual_price_fraction = '00';
                }
            }
        },
        promRublesChanged() {
            if (this.result.manual_before_promotion_integer) {
                if (!this.result.manual_before_promotion_fraction) {
                    this.result.manual_before_promotion_fraction = '00';
                }
            }
        },
        addRublesChanged() {
            if (this.result.manual_additional_integer) {
                if (!this.result.manual_additional_fraction) {
                    this.result.manual_additional_fraction = '00';
                }
            }
        },
        openScreenshot(result) {
            if (!(result.photo_path || {}).length) {
                return;
            }
            this.screenshotResult = result;
            window.$("#modalViewScreenShot2").modal('show')
        },
        closeScreenshot() {
            this.screenshotResult = {};
            window.$("#modalViewScreenShot2").modal('hide')
        },
        closeCard() {
            this.$emit('close');
        },
        saveResult() {
            if (this.result.manual_price_integer || this.result.manual_price_fraction) {
                if (this.result.manual_price_integer === null) {
                    this.result.manual_price_integer = 0;
                }
                if (this.result.manual_price_fraction === null) {
                    this.result.manual_price_fraction = 0;
                }
                this.result.verification_required_price = false;
            }
            if (this.result.manual_before_promotion_fraction || this.result.manual_before_promotion_integer) {
                if (this.result.manual_before_promotion_fraction === null) {
                    this.result.manual_before_promotion_fraction = 0;
                }
                if (this.result.manual_before_promotion_integer === null) {
                    this.result.manual_before_promotion_integer = 0;
                }
                this.result.verification_required_price = false;
            }
            
            if (this.result.manual_additional_fraction || this.result.manual_additional_integer) {
                if (this.result.manual_additional_fraction === null) {
                    this.result.manual_additional_fraction = 0;
                }
                if (this.result.manual_additional_integer === null) {
                    this.result.manual_additional_integer = 0;
                }
                this.result.verification_required_price = false;
            }
            window.myAjax('PUT', '/price-tag/recognition-result', Object.assign({}, this.result, {result_id: this.result.id}), this.saveResultHandler);
        },
        saveResultHandler(error, response) {
            if (error) {
                window.myAlert((response || {}).message || 'Ошибка при сохранении.');
            } else {
                this.closeCard();
                window.notifyFromJournal = true;
                this.$emit('updateNotifier');
            }
        },
        getFormatedPrice(priceInteger, priceFraction) {
            if (
                (priceInteger === undefined && priceFraction === undefined) ||
                (priceInteger === null && priceFraction === null)
            ) {
                return;
            }
            let integer = priceInteger || 0;
            let fraction = priceFraction || 0;
            const priceIntegerStr = integer.toString();
            const priceFractionStr = fraction.toString();
            return `${ priceIntegerStr }.${ priceFractionStr.length === 1 ? '0' + priceFractionStr : priceFractionStr }`;
        },
        remove() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить результат?', () => {
                window.myAjax('DELETE', '/price-tag/recognition-result', { result_id: this.result.id }, () => {
                    this.closeCard();
                })
            });
        },
    }
}
