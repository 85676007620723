export default {
    name: 'BaseCheckbox',
    props: {
        text: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
    }),
    methods: {
        clickHandler() {
            this.$emit('click');
        }
    }
};
