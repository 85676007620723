import Checkbox from './../../../base/checkbox';

export default {
    name: 'UserFilter',
    components: {
        Checkbox
    },
    props:{
        filter: {
            type: String,
            default: ''
        },
        selecting: {
            type: Boolean,
            default: false
        },
        selectingCameras: {
            type: Array,
            default: []
        }
    },
    data: () => ({
        // search_str: '',
        action_id: '0',
        smartArchiveEnabled: false
        // todo
    }),
    computed: {
        // fullname() {
        //     return this.user.last_name + ' ' + this.user.first_name + ' ' + (this.user.middle_name || '');
        // }
    },
    watch: {
        filter: {
            handler() {
                // this.search_str = value;
            }
        }
    },
    mounted() {
        // this.search_str = this.filter;
        this.smartArchiveEnabled = (window.features.find( feature => feature.feature_name === 'smart archive') || {}).enabled;
        // todo
    },
    methods: {
        actionChange(action) {
            if (action !== '0') {
                this.$emit('selectAction', action);
                setTimeout( () => {
                    this.action_id = '0';
                }, 10);
            }
        },
        iconHandler(eventType) {
            this.$emit(eventType);
        },
        inputHandler(value) {
            this.$emit('filterChanged', value);
        }
    }
}
