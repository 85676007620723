export function createRelations(params, callback) {
    const url = '/api/user-checklist-relations';
    const method = 'POST';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    })
}

export function deleteRelations(params, callback) {
    const url = '/api/user-checklist-relations';
    const method = 'DELETE';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    })
}

export function getRelations(options, callback) {
    const url = '/api/user-checklist-relations';
    const method = 'GET';
    window.myAjax(method, url, options.params || {}, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    })
}

export function getRelationsByUser(options, callback) {
    const url = '/api/user-checklist-relations';
    const method = 'GET';
    window.myAjax(method, url, options.params || {}, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    })
}
