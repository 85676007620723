import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules'
import vuexI18n from "vuex-i18n";

import ru from "./../locale/ru";
import en from "./../locale/en";

Vue.use(Vuex);

let state = {
    curLanguage: null,
    languages: [
        {
            short: "ru",
            long: "Russian",
            caption: "Русский"
        },
        {
            short: "en",
            long: "English",
            caption: "English"
        }
    ]
};

state.curLanguage = state.languages[0];

const mutations = {
    setLanguage(state, lang) {
        state.curLanguage = lang;
    }
};

const store = new Vuex.Store({
    modules,
    mutations,
    state
});

Vue.use(vuexI18n.plugin, store);

Vue.i18n.add("ru", ru);
Vue.i18n.add("en", en);

export default store;
