import ListColumnItem from './../listColumnItem';

export default {
    name: 'ListColumn',
    components: {
        ListColumnItem
        // todo
    },
    props: {
        items: {
            type: Array,
            default: []
        }
    },
    data: () => ({
        // todo
    }),
    mounted() {
        // todo
    },
    methods: {
        // todo
    }
}
