export default {
    name: 'CardList',
    components: {
        //todo
    },
    props: {
        loading: Boolean,
        items: Array,
    },
    data: () => ({
        cards: [],
        added: {
            name: '',
            host: '',
            lot_size: '',
        },
        showAdded: false,
        edited: [],
    }),
    watch: {
        items: {
            handler() {
                if ((this.items || []).length) {
                    this.cards = [];
                    this.items.forEach(item => {
                        this.cards.push(item);
                    });
                }
            }
        }
        //todo
    },
    mounted() {
        //todo
    },
    methods: {
        cancel(card) {
            const index = this.edited.indexOf(card.id);
            if (index >= 0) {
                this.edited.splice(index, 1);
            } else {
                this.showAdded = false;
            }
        },
        remove(card) {
            window.myConfirm('Внимание!', 'Удалить видеокарту из списка?', () => {
                if ((card || {}).id) {
                    window.myAjax('DELETE', '/ml/card', {
                        video_card_id: card.id
                    }, (error) => {
                        if (!error) {
                            this.$emit('saved');
                        }
                    });
                }
            })
        },
        save(card) {
            window.myAjax((card || {}).id ? 'UPDATE' : 'POST', '/ml/card', card, (error) => {
                if (!error) {
                    this.$emit('saved');
                    if (!(card || {}).id) {
                        this.added = {
                            name: '',
                            host: '',
                            lot_size: '',
                        }
                    }
                }
            });
        },
    }
}
