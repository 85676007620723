import MenuNav from './../MenuNav';
import Notifier from './notifier';
import { mapState } from 'vuex';
import Vue from 'vue';


import ModalNew from './../base/modalNew';
import UserSettings from './../users/userSettings';

export default {
    name: 'MyHeader',
    components: {
        MenuNav,
        ModalNew,
        UserSettings,
        Notifier
    },
    props: ['show_left_menu', 'mode'],
    data() {
        return {
            selectedLanguage: {},
            isMobile: false,
            isHidden: false,
            date: 'test',
            pushOn: false,
            pushSupport: false,
            messaging: null,
            userFio: '',
            superAdmin: false,
            settingsOn: false,
            settings_on: false,
            chat_flag: false,
            show_sub_menu: false,
            ticTacTimer: null,
            ticTacCount: 0,
            monitoringTotal: 0,
            user: null,
            showUserSettings: false,
            canGetUsersSetting: false
        }
    },
    created() {
        if (this.$store.state.curLanguage === 'ru') {
            this.selectedLanguage = {
                short: "ru",
                long: "Russian",
                caption: "Русский"
            }
        } else {
            this.selectedLanguage = {
                short: "en",
                long: "English",
                caption: "English"
            }
        }
    },
    mounted() {this.userFio = window.dolgAndFio
        if (window.userBaseRole === 'Администратор') {
            this.superAdmin = true;
            this.settingsOn = true;
        }
        if (window.innerWidth <= 960) {
            this.isMobile = true;
            this.isHidden = true;
        }
        this.ticTacCount = 0;
        this.ticTac();
        // const user = myUsers.find(u => u.id == window.getCookie('userId'));
        // if (user) {
        //     if ( (user.permissions || []).findIndex( p => p.name === 'edit users settings') > -1 ) {
        //         this.canGetUsersSetting = false;
        //     }
        // }
        this.pushSupport = !!(
            'Notification' in window &&
            'serviceWorker' in navigator &&
            'localStorage' in window &&
            'PushManager' in window
        );
        if (this.pushSupport) {
            this.pushOn = (window.localStorage.getItem('pushToken') || '').length > 1;
            if (!window.firebase.apps.length) {
                window.firebase.initializeApp({
                    messagingSenderId: '99899101317'
                });
            }
            this.messaging = window.firebase.messaging();
            this.messaging.onMessage(function(payload) {
                // фейковый воркер для работы в мобилках
                navigator.serviceWorker.register('/firebase-messaging-sw.js');
                Notification.requestPermission(function(permission) {
                    if (permission === 'granted') {
                        navigator.serviceWorker.ready.then(function(registration) {
                            payload.data.data = JSON.parse(JSON.stringify(payload.data));
                            registration.showNotification(payload.data.title, payload.data);
                        }).catch(function(error) {
                            console.error(error);
                        });
                    }
                });
            });
        }
    },
    destroyed() {
        window.clearTimeout(this.ticTacTimer)
    },
    computed: mapState(['languages']),
    watch: {
        'selectedLanguage': function(newLang) {
            Vue.i18n.set(newLang.short);
            this.$store.commit('setLanguage', newLang.short);
            window.setCookieTime('dsvLocale', newLang.short);
        }
    },
      methods: {
        modeChangeHandler(data) {
            this.$emit('modeChange', data);
        },
        menuNav(event, data) {
            this.$emit('settingsTo', event, data);
        },
        // showChats() {
        //     window.showMainMenu()
        // },
        toggleMenu() {
            this.$emit('settingsToggle');
            // window.$('.dsv-nav__item').removeClass('active');
            window.$('.dsv-nav__item').removeClass('active');
            window.checklistCardPoint = null;

            // window.toggleAdminMenu();
            window.checklistsMode = false;
            this.settings_on = !this.settings_on;
            if (this.settings_on) {
                window.$('.dsv-nav__item[name=Objects]').addClass('active');
            } else {
                window.$('.dsv-nav__item[name=MainCams]').addClass('active');
            }
            const updateEvent = new CustomEvent("update", { detail: { id: null, break: true } });
            document.getElementById("dsv-checklist-tree").dispatchEvent(updateEvent);
        },
        openUserPage() {
            this.user = window.myUsers.find( user => user.id == window.getCookie('userId'));
            this.showUserSettings = true;
        },
        Logout() {
          this.$emit('logoutUser');
        },
        working() {
            //todo
        },
        ticTac() {
            this.ticTacCount++
            this.ticTacTimer = setTimeout(this.ticTac, 2500);
            //if(this.ticTacCount%600==0)
              //  this.refreshChatFlag();
            this.date = this.dateStr();
        },
        refreshNotifier() {
            this.$refs['NotifierRef'].refreshNotifier();
        },
        refreshChatFlag() {
              let url = '/api/messages/getChatFlag',
                  method = 'GET';
              window.myAjax(method, url, {}, this.myCallbackGetChatFlag)
          },
          myCallbackGetChatFlag (error, response) {
              if(!error) {
                  this.chat_flag = response.flag
              }
          },

        dateStr() {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            
            let date = new Date(),

            options = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
//              weekday: 'long',
              timezone: 'UTC',
              hour: 'numeric',
              minute: 'numeric'//,
              //second: 'numeric'
            },
            date_str = date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            return date_str;
        },
        toggleLeftMenu() {
            this.$emit('toggleLeftMenu')
        },
        
        pushClick() {
            if (this.pushOn) {
                this.deleteToken();
            } else {
                this.subscribe();
            }
        },
        subscribe() {
            const self = this;
            this.messaging.requestPermission()
                .then(function () {
                    self.messaging.getToken()
                        .then(function (currentToken) {
                            if (currentToken) {
                                self.sendTokenToServer(currentToken);
                                self.pushOn = true;
                            } else {
                                self.unsubscribe();
                            }
                        })
                        .catch(function () {
                            self.unsubscribe();
                        });
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        unsubscribe() {
            this.sendTokenToServer('0');
            this.pushOn = false;
        },

        sendTokenToServer(token) {
            if (!this.isCurrentPushToken(token)) {
                var url = '/api/token';
                window.myAjax('POST', url, {
                    token: token
                }, function() { /* todo */ });
                this.setPushToken(token);
            }
        },

        isCurrentPushToken(currentToken) {
            return window.localStorage.getItem('pushToken') === currentToken;
        },

        setPushToken(currentToken) {
            window.localStorage.setItem( 'pushToken', currentToken ? currentToken : '' );
        },

        deleteToken() {
            const self = this;
            this.messaging.getToken().then((currentToken) => {
                self.messaging.deleteToken(currentToken).then(() => {
                    self.unsubscribe();
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
            });
        },
        goToJournalHandler() {
            window.journalType = 'monitoring';
            window.journalFromNotify = true;
            this.$emit('settingsTo', 'IZK');
        },
        goToJournalHandler2() {
            window.journalType = 'monitoring2';
            window.journalFromNotify = true;
            this.$emit('settingsTo', 'IZK');
        },

        
        totalHandler(data) {
            this.monitoringTotal = data.total;
        }
    }
}