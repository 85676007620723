import Map from '../../../../components/locationMap/locationMap';
import ListCams from './../listCams'; 
import CheckboxList from './../../../base/checkboxList';
import TabsGroup from './../../../base/tabsGroup';
import FormatList from './../formatList';
import Checkbox from './../../../base/checkbox';
import CheckboxNew from './../../../base/checkboxNew';
import ModalNew from './../../../base/modalNew';

export default {
    name: 'Objects',
    components: {
        CheckboxList,
        ListCams,
        TabsGroup,
        FormatList,
        Map,
        Checkbox,
        CheckboxNew,
        ModalNew,
    },
    props: ['addRootObject', 'selectedObj'],
    data: () => ({
        selectedDigressions: [],
        showMap: false,
        nameClass: '',
        cityClass: '',
        mapClass: 'addMap',
        addressClass: '',
        saveClass: '',
        camsClass: '',
        usersClass: '',
        devClass: '',
        fileSelected: false,
        select1: 1,
        select2: 5,
        select3: 3,
        select1Class: 'selectRed',
        select2Class: 'selectGreen',
        select3Class: 'selectYellow',
        showCams: false,
        showUsers: false,
        showDev: false,
        objects: [],
        showDigressionsModal: false,
        nodeRoot: {
            id: 0,
            parent_id: 0,
            parentType: 'node',
            name: '',
            object_type: 'node',
            city: '',
            address: '',
            description: ''
        },
        objectRoot: {
            id: 0,
            parent_id: 0,
            parentType: 'node',
            name: '',
            object_type: 'object',
            city: '',
            address: '',
            description: ''
        },
        object: {
            id: 0,
            parent_id: 0,
            parentType: 'node',
            name: '',
            object_type: 'object',
            map_path: null,
            city: '',
            address: '',
            description: ''
        },
        cams: [],
        users: [
            {
                id: 111,
                name: 'Иванов Иван Иваныч',
                roles: ['Супервайзер по кассам'],
                objects_id: [1],
            },
            {
                id: 113,
                name: 'Петров Петр Петрович',
                roles: ['Исполнитель клининг'],
                objects_id: [1, 2],
            },
            {
                id: 121,
                name: 'Сидоров Сидор Сидорович',
                roles: ['Супервайзер на складе','Супервайзер торгового зала','Супервайзер по клинингу'],
                objects_id: [],
            },
        ],
        devs: [
            {
                id: 111,
                parent_id: 0,
                name: 'Кассы',
                objects_id: [1],
                prior: 1,
                priorClass: 'selectRed'
            },
            {
                id: 112,
                parent_id: 111,
                name: 'Большая очередь',
                objects_id: [],
                prior: 2,
                priorClass: 'selectOrange'
            },
            {
                id: 113,
                parent_id: 111,
                name: 'Конфликтная ситуация',
                objects_id: [1],
                prior: 1,
                priorClass: 'selectRed'
            },
            {
                id: 114,
                parent_id: 0,
                name: 'Клининг',
                objects_id: [],
                prior: 3,
                priorClass: 'selectYellow'
            },
            {
                id: 115,
                parent_id: 114,
                name: 'Торговый зал',
                objects_id: [1],
                prior: 3,
                priorClass: 'selectYellow'
            },
            {
                id: 116,
                parent_id: 114,
                name: 'Раздевалка сотрудников',
                objects_id: [],
                prior: 4,
                priorClass: 'selectGreen'
            },
        ],
        priors: [
            {
                id: 1,
                colorClass: 'selectRed',
                name: 'Высокий'
            },
            {
                id: 2,
                colorClass: 'selectYellow',
                name: 'Средний'
            },
            {
                id: 3,
                colorClass: 'selectGreen',
                name: 'Низкий'
            },
            {
                id: 4,
                colorClass: 'selectWhite',
                name: 'Чек-Лист'
            },
        ],
        devsToObjects: [],
        objectUsers: [],
        zoneUsers: [],
        objectCams: [],
        objectDevs: [],
        zoneDevs: [],
        digressions: [],
        errorName: '',
        errorCity: '',
        errorAddress: '',
        usersLoaded: false,
        digressionsLoaded: false,
        digressions2: [],
        tabs: [
            {id: 'objects', caption: 'Объекты'},
            {id: 'formats', caption: 'Справочник форматов'}
        ],
        tabsMode: 'objects',
        formats: [],
        action_id: '0',
        selecting: false,
        selectedObjects: [],
        modalSelectedDigressions: [],
    }),
    mounted() {
        this.fileSelected = null;
        this.checklistOff();
        this.getStart();
        this.getFormats();
        if ((this.selectedObj || {}).object_type) {
            window.$('div[node_id='+this.selectedObj.id+']:first').addClass('opened');
            window.$('div[node_id='+this.selectedObj.id+']:first>p:first').addClass('active')
            this.showObjectCard(this.selectedObj)
            if(this.selectedObj.parent_id) {
                this.openTreeObjects(this.getParentObject(this.selectedObj))
            }
        }
        if (window.myDevs.length === 0) {
            window.myAjax('GET', '/api/digressions', {}, (error, response) => {
                if (!error) {
                    this.digressions2 = (response.digressions || []).filter( digression => (!!digression.priority_id || !digression.parent_id) );
                    this.digressions2.sort((a, b) => a.name.localeCompare(b.name))
                }
            });
        } else {
            this.digressions2 = (window.myDevs || []).filter( digression => (!!digression.priority_id || !digression.parent_id) );
            this.digressions2.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    methods: {
        getFormats() {
            window.myAjax('GET', '/api/formats', {}, (error, response) => {
                if (!error) {
                    this.formats = response.formats;
                }
            })
        },
        tabsHandler(mode) {
            this.tabsMode = mode;
            this.mode = 'list';
        },
        digressionCheckHandler(data) {
            this.selectedDigressions = data;
        },
        openTreeObjects(obj) {
            window.$('div[node_id='+obj.id+']:first').addClass('opened')
            if(obj.parent_id)
            this.openTreeObjects(this.getParentObject(obj))
        },
        toggleMap() {
            this.showMap = !this.showMap;
        },
        checklistOff() {
            window.checklistCardPoint = null;
            const updateEvent = new CustomEvent("update", { detail: null });
            document.getElementById("dsv-checklist-tree").dispatchEvent(updateEvent);
            // todo понормально сделать!
            window.$('#app').removeClass('dsv-checkmode');
            const templatesTree = document.getElementById('template_list_tmp');
            if (templatesTree) { templatesTree.style.display = 'block'; }
            const searchBlock = document.getElementById('search_block');
            if (searchBlock) { searchBlock.style.display = 'flex'; }
            const objectsHeader = document.getElementById('dsv-leftNavi__header');
            if (objectsHeader) { objectsHeader.style.display = 'block'; }
            const ContactsHeader =  document.querySelector('div[name=Contacts]');
            if (ContactsHeader) { ContactsHeader.style.display = 'block'; }
            const reports = document.querySelector('div[name=Reports]');
            if (reports) { reports.style.display = 'block'; }
            const calendar = document.querySelector('div[name=Calendar]');
            if (calendar) { calendar.style.display = 'block'; }
            const analyticReports = document.querySelector('div[name=AnalyticReports]');
            if (analyticReports) { analyticReports.style.display = 'block'; }
            const journal = document.querySelector('div[name=IZK]');
            if (journal) { journal.style.display = 'block'; }
            const neural = document.querySelector('div[name=Neural]');
            if (neural) { neural.style.display = 'block'; }
            // window.$('.ierarhia p').removeClass('active');
            this.$emit('addRootObjectFromObjects', true);
        },
        cancelCard() {
            if(this.object.parent_id)
                this.$emit('setActiveObject', this.getParentObject(this.object))
            else {
                window.$('.ierarhia p').removeClass('active');
                this.$emit('addRootObjectFromObjects', true)
            }
        },
        objectCard(objectTemp) {
            this.$emit('addRootObjectFromObjects', false)
            this.object = Object.assign({}, objectTemp)
            this.errorName = ''
            this.errorCity = ''
            this.errorAddress = ''
            this.showCams = false
            this.showDev = false
            this.showUsers = false
            this.usersLoaded = true;
            this.digressionsLoaded = true;

            if(this.object.object_type=='object') {
                this.camsClass = ''
                this.usersClass = ''
                this.devClass = ''

                this.objectUsers = []
                this.objectDevs = []
                for(let i=0; i<this.devs.length; i++)
                    if(this.devs[i].priority_id)
                        this.objectDevs.push({digression_id: this.devs[i].id, priority_id: this.devs[i].priority_id})
                this.initObjectUsersCamsDevs()
            }
        },
        newChild(object_type) {
            this.selectedDigressions = [];
            this.$emit('addRootObjectFromObjects', false)
            this.object = {
                id: 0,
                parent_id: this.object.id,
                parentType: this.object.object_type,
                name: '',
                object_type: object_type,
                city: this.object.city,
                map_path: null,
                address: this.object.address
            }
            if(this.object.city=='null')
                this.object.city = ''
            if(this.object.address=='null')
                this.object.address = ''
            this.errorName = ''
            this.errorCity = ''
            this.errorAddress = ''
            this.showCams = false
            this.showDev = false
            this.showUsers = false

            this.camsClass = ''
            this.usersClass = ''
            this.devClass = ''

            if(this.object.object_type=='object') {
                this.objectUsers = []
                this.objectDevs = []
                for(let i=0; i<this.devs.length; i++)
                    if(this.devs[i].priority_id)
                        this.objectDevs.push({digression_id: this.devs[i].id, priority_id: this.devs[i].priority_id})
                this.initObjectUsersCamsDevs()
            } else if(this.object.object_type=='zone') {
                this.zoneUsers = []
                this.zoneDevs = this.objectDevs
                this.initObjectUsersCamsDevs()
            }
        },
        showObjectCard(objectTemp) {
            this.getFormats();
            this.selectedDigressions = [];
            this.$emit('addRootObjectFromObjects', false)
//            this.object.map_path = null
            let notSaveObj = true
            if(this.object.id==objectTemp.id)
                notSaveObj = false
            this.fileSelected = null
            this.object = Object.assign({}, objectTemp)
            this.errorName = ''
            this.errorCity = ''
            this.errorAddress = ''
            
            this.usersLoaded = true;
            this.digressionsLoaded = true;
            this.selectedDigressions = [];

            if(this.object.object_type=='node') {
                this.showCams = false
                this.showDev = false
                this.showUsers = false

                this.camsClass = ''
                this.usersClass = ''
                this.devClass = ''

                this.objectDevs = []
                this.objectUsers = []
                this.objectCams = []
            } else {
                this.usersLoaded = false;
                this.digressionsLoaded = false;
                if(notSaveObj) {
                    this.users = []
                    this.cams = []
                    this.digressions = []
                }
                this.getObjectDevs()
            }
        },

        getParentObject(obj) {
            for(let i=0; i<window.myObjects.length; i++)
                if(window.myObjects[i].id==obj.parent_id)
                    return window.myObjects[i]
            return null
        },
        devLinkedToObject(dev) {
            dev.on = false
            for(let i=0; i<this.objectDevs.length; i++)
                if(this.objectDevs[i].digression_id==dev.id) {
                    dev.on = true
                    dev.priority_id = this.objectDevs[i].priority_id
                    return true
                }
            return false
        },
        devLinkedToZone(dev) {
            dev.on = false
            for(let i=0; i<this.zoneDevs.length; i++)
                if(this.zoneDevs[i].digression_id==dev.id) {
                    dev.on = true
                    dev.priority_id = this.zoneDevs[i].priority_id
                    break
                }
        },
        userLinkedToObject(user) {
            for(let i=0; i<this.objectUsers.length; i++)
                if(this.objectUsers[i].id==user.id)
                    return true
            return false
        },
        userLinkedToZone(user) {
            for(let i=0; i<this.zoneUsers.length; i++)
                if(this.zoneUsers[i].id==user.id)
                    return true
            return false
        },

        initObjectUsersCamsDevs() {
            let parentObject = null
            if(this.object.object_type=='zone')
                parentObject = this.getParentObject(this.object)

            this.users = []
            for(let i=0; i<window.myUsers.length; i++) {
                if(this.object.object_type=='object') {
                    let user = Object.assign({}, window.myUsers[i])
                    if (this.userLinkedToObject(window.myUsers[i]))
                        user.on = true
                    else
                        user.on = false
                    this.users.push(user)
                } else if(this.object.object_type=='zone' &&
                    this.userLinkedToObject(window.myUsers[i])) {
                    let user = Object.assign({}, window.myUsers[i])
                    if (this.userLinkedToZone(window.myUsers[i]))
                        user.on = true
                    else
                        user.on = false
                    this.users.push(user)
                }
            }

            this.cams = []
            this.objectCams = []
            for(let i=0; i<window.myCams.length; i++) {
                if(this.object.object_type=='object') {
                    if(window.myCams[i].object_id) {
                        if(window.myCams[i].object_id==this.object.id) {
                            let cam = Object.assign({}, window.myCams[i])
                            cam.on = true
                            this.cams.push(cam)
                            this.objectCams.push(window.myCams[i])
                        }
                    } else { // непривязанная к объектам камера
                        let cam = Object.assign({}, window.myCams[i])
                        cam.on = false
                        this.cams.push(cam)
                    }
                } else if(this.object.object_type=='zone' && window.myCams[i].object_id==parentObject.id) {
                    if(window.myCams[i].zone_id) {
                        if(window.myCams[i].zone_id==this.object.id) {
                            let cam = Object.assign({}, window.myCams[i])
                            cam.on = true
                            this.cams.push(cam)
                        }
                    } else { // непривязанная к зонам камера
                        let cam = Object.assign({}, window.myCams[i])
                        cam.on = false
                        this.cams.push(cam)
                    }
                }
            }
            this.selectedDigressions = [];

            this.digressions = []
            
            if (this.object.id) {
                for(let i=0; i<this.devs.length; i++) {
                    let dev = Object.assign({}, this.devs[i])
                    if (this.objectDevs.findIndex(relation => relation.digression_id == dev.id) >= 0) {
                        if(this.object.object_type=='object') {
                            this.selectedDigressions.push(dev.id);
                            // this.devLinkedToObject(dev)
                            // dev.select_priority_id = false
                            // this.digressions.push(dev)
                        } else if(
                            this.object.object_type=='zone' &&
                            this.devLinkedToObject(dev)
                        ) {
                            this.selectedDigressions.push(dev.id);
                            // dev.select_priority_id = false
                            // this.devLinkedToZone(dev)
                            // this.digressions.push(dev)
                        }
                    }
                }
            } else {
                this.selectedDigressions = [];
            }

            
        },
        selectedMapFile() {
            this.fileSelected = window.$('input[type=file]')[0].files[0]
            window.setTimeout(this.selectedMapFile2, 100)
        },
        selectedMapFile2() {
            for(let i=0; i<this.objectCams.length; i++) {
                if(this.$refs['RefMap']!=undefined)
                    this.$refs['RefMap'].removeCamera(this.objectCams[i])
                this.objectCams[i].coordX = null
                this.objectCams[i].coordY = null
                this.objectCams[i].rotate = null
            }
        },
        changeInputClass() {
            this.saveClass='changed'
            if(this.object.name!=null && this.object.name.length)
                this.nameClass='filled'
            else
                this.nameClass=''
            if(this.object.address!=null && this.object.address.length)
                this.addressClass='filled'
            else
                this.addressClass=''
            if(this.object.city!=null && this.object.city.length)
                this.cityClass='filled'
            else
                this.cityClass=''
        },
        hideDeleteMap() {
            window.$('#modaldeletemap').modal('hide');
        },
        showDeleteMap() {
            window.$('#modaldeletemap').modal('show');
        },
            deleteMap() {
                this.hideDeleteMap();
                this.object.map_path = null
                this.fileSelected = null
                window.$("input[type=file]:first").val('')
                for(let i=0; i<this.objectCams.length; i++) {
                    this.objectCams[i].coordX = null
                    this.objectCams[i].coordY = null
                    this.objectCams[i].rotate = null
                }
            },
        inputMap() {
            if(this.object.object_type=='object')
                this.$refs['map'].click()
            else
                window.myAlert('Заменить карту объекта можно только в карточке объекта')
        },

        saveObject() {
            if (this.object.id)
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения объекта?', this.saveObject2)
            else
                this.saveObject2()
        },

        saveObject2() {
            if(this.valideObject()) {
                this.saveClass = '';

                const data = Object.assign({}, this.object)
                if (this.fileSelected) {
                    this.saveMap();
                    return;
                }
                data.object_id = data.id;
                data.object_name = data.name;
                const _cams = [];
                this.cams.filter( (cam) => cam.on).forEach( item => {
                    _cams.push({
                        id: item.id,
                        coordX: item.coordX,
                        coordY: item.coordY,
                        rotate: item.rotate
                    })
                })
                data.cams = JSON.stringify(_cams);
                const _users = [];
                this.users.filter( (user) => user.on).forEach( user => {
                    _users.push(user.id)
                })
                data.users = JSON.stringify(_users);
                data.ancestry = JSON.stringify(data.ancestry);
                const digressions = [];
                for (let i = 0; i < this.selectedDigressions.length; i++) {
                    digressions.push({
                        digression_id: this.selectedDigressions[i],
                        priority_id: 1
                    });
                }
                data.digressions = JSON.stringify(digressions);
                // console.log(data);
                window.myAjax(this.object.id ? 'PUT' : 'POST', '/api/object', data, this.myCallbackSaveObject)
            }
        },
        saveMap() {
            let reader = new FileReader();
            reader.onload = (e) => {
                // window.$('div.addMap:first').css({'background':'url(' + e.target.result + ') center center no-repeat'});
                window.myAjax('POST', '/api/object/map', {
                    image: e.target.result
                }, this.saveMapCallback);
            };
            reader.readAsDataURL(this.fileSelected);
            // window.myAjax('POST', '/api/object/map', {map_file: this.fileSelected}, this.saveMapCallback)
        },
        saveMapCallback(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                let url = '/api/object',
                    method = 'POST';
                if (this.object.id) {
                    url = '/api/object'
                    method = 'PUT';
                }
                const data = Object.assign({}, this.object)
                data.map_path = '' + response + '';
                const _cams = [];
                this.cams.filter( (cam) => cam.on).forEach( item => {
                    _cams.push({
                        id: item.id,
                        coordX: item.coordX,
                        coordY: item.coordY,
                        rotate: item.rotate
                    })
                })
                data.cams = JSON.stringify(_cams);
                const _users = [];
                this.users.filter( (user) => user.on).forEach( user => {
                    _users.push(user.id)
                })
                data.users = JSON.stringify(_users);
                data.object_id = data.id;
                data.object_name = data.name;
                data.ancestry = JSON.stringify(data.ancestry);
                const digressions = [];
                for (let i = 0; i < this.selectedDigressions.length; i++) {
                    digressions.push({
                        digression_id: this.selectedDigressions[i],
                        priority_id: 1
                    });
                }
                data.digressions = JSON.stringify(digressions);
                window.myAjax(method, url, data, this.myCallbackSaveObject)
            }

        },
        myCallbackSaveObject (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.object = Object.assign({}, response.object);
                this.showDev = false;
                window.myAlert(response.message)
                this.$emit('updateObjectsListFromObjects', false)
                // обновляем список всех камер клиента
                this.getAllCams()
                // обновляем список связей типов отклонений
                this.getDevsToObjects();
                window.setTimeout(this.selectNewObject, 1000)
                window.setTimeout(this.getStart, 3000)
            }
        },
        selectNewObject() {
            this.$emit('setActiveObject', this.object)
        },

        deleteObject() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить объект?', this.deleteObject2)
        },

        deleteObject2() {
            this.saveClass = ''
            let data = {
                    object_id: this.object.id
                },
                url = '/api/object',
                method = 'DELETE';
            window.myAjax(method, url, data, this.myCallbackDeleteObject)
        },
        myCallbackDeleteObject (error, response) {
            window.myAlert(response.message)
            if(!error) {
                // обновляем список всех камер клиента
                this.getAllCams()

                this.$emit('updateObjectsListFromObjects', false)
                this.$emit('addRootObjectFromObjects', false)
            }
        },

        getDevsToObjects() {
            let data = {},
                url = '/api/objects-digressions',
                method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetStartDevsToObjects)
        },
        myCallbackGetStartDevsToObjects (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myDevsToObjects = response.object_digressions
                window.setTimeout(this.getStart, 1000)
            }
        },

        getStart() {
    //            this.cams = window.myCams
            this.objects = window.myObjects
            this.devs = window.myDevs
    //            this.devsToObjects = window.myDevsToObjects
        },

        toggleLists(flag) {
            if(flag==1) {
                this.showCams=!this.showCams
                this.showUsers=false
                this.showDev=false
            } else if (flag==2) {
                this.showUsers=!this.showUsers
                this.showCams=false
                this.showDev=false
            } else if (flag==3) {
                this.showDev=!this.showDev
                this.showCams=false
                this.showUsers=false
            }
            if(this.showCams) {
                this.camsClass = 'active'
            } else {
                this.camsClass = ''
            }
            if(this.showUsers) {
                this.usersClass = 'active'
            } else {
                this.usersClass = ''
            }
            if(this.showDev) {
                this.devClass = 'active'
            } else {
                this.devClass = ''
            }
        },
        devFullName(id, fullName) {
            let dev = this.getDev(id)
            if(dev) {
                if (dev.parent_id)
                    fullName = this.devFullName(dev.parent_id, ' / ' + dev.name + fullName)
                else
                    fullName = dev.name + fullName
            }
            return fullName
        },
        getDev(id) {
            for(let i=0; i<this.devs.length; i++)
                if(this.devs[i].id==id)
                    return this.devs[i]
            return null
        },
        valideObject() {
            let res = true;
            if(this.object.name.length) {
                this.errorName = ''
            } else {
                res = false
                this.errorName = 'Не указано название'
            }
            if(this.object.object_type=='object') {
                if(this.object.city.length) {
                    this.errorCity = ''
                } else {
                    res = false
                    this.errorCity = 'Не указан город'
                }
                if(this.object.address.length) {
                    this.errorAddress = ''
                } else {
                    res = false
                    this.errorAddress = 'Не указан адрес'
                }
            }

            return res
        },
        getObjectName(id) {
            for(let i=0; i<this.objects.length; i++) {
                if(this.objects[i].id==id)
                    return this.objects[i].name
            }
            return '-'
        },

        updateMap(camId) {
            const updatedCams = [...this.cams];
            const index = updatedCams.findIndex(({ id }) => id === camId);
            if (index > -1) {
                // updatedCams[index].coordX = updatedCams[index].coordX == null ? 1 : null;
                if (updatedCams[index].coordX == null) {
                    this.camToMap(updatedCams[index]);
                } else {
                    this.removeCamFormMap(updatedCams[index])
                }
            }
        },

        camToMap(cam) {
            cam.coordX = 1
            this.$refs['RefMap'].addingCamera(cam)
        },
        removeCamFormMap(cam) {
            cam.coordX = null
            cam.coordY = null
            cam.rotate = null
            this.$refs['RefMap'].removeCamera(cam)
        },
        getObjectDevs() {
            let obj = this.object
            if(this.object.object_type=='zone') {
                obj = this.getParentObject(this.object);
            }
            window.myAjax('GET', '/api/object-digressions', {
                object_id: obj.id
            }, this.myCallbackObjectDevs)
        },
        myCallbackObjectDevs (error, response) {
            if(error)
                window.myAlert(response.message)
            else {
                this.digressionsLoaded = true;
                this.objectDevs = response.object_digressions
                if(this.object.object_type=='object')
                    this.getObjectUsers(this.object)
                else if(this.object.object_type=='zone')
                    this.getZoneDevs(this.object)
            }
        },
        getZoneDevs() {
            window.myAjax('GET', '/api/object-digressions', {
                object_id: this.object.id
            }, this.myCallbackZoneDevs);
        },
        myCallbackZoneDevs (error, response) {
            if(error)
                window.myAlert(response.message)
            else {
                this.digressionsLoaded = true;
                this.zoneDevs = response.object_digressions
                this.getZoneUsers(this.object)
            }
        },

        getObjectUsers(obj) {
            window.myAjax('GET', '/api/object-users', {
                object_id: obj.id
            }, this.myCallbackObjectUsers)
        },
        myCallbackObjectUsers (error, response) {
            if (error) {
                window.myAlert(response.message);
            } else {
                this.usersLoaded = true;
                this.objectUsers = response.users;
                this.initObjectUsersCamsDevs();
            }
        },
        getZoneUsers(obj) {
            window.myAjax('GET', '/api/object-users', {
                object_id: obj.id
            }, this.myCallbackZoneUsers)
        },
        myCallbackZoneUsers (error, response) {
            if(error)
                window.myAlert(response.message)
            else {
                this.usersLoaded = true;
                this.zoneUsers = response.users
                this.getObjectUsers(this.getParentObject(this.object))
            }
        },
        myCallbackDevToObjs (error, response) {
            window.myAlert(response.message)
            if(!error) {
//              } else {
                if(response.relation) {
                    window.myDevsToObjects.push(response.relation)
                    this.devsToObjects = window.myDevsToObjects
                }
//                  this.getDevsToObjects()
            }
        },

        thereIs(dev) {
            for(let i=0; i<this.devsToObjects.length; i++) {
                if(this.devsToObjects[i].object_id==this.object.id
                    && this.devsToObjects[i].digression_id==dev.id) {
//                      dev.prior = this.devsToObjects[i].priority_id
                    return this.devsToObjects[i]
                }
            }
            return false
        },
        thereIsInParent(dev) {
            for(let i=0; i<this.devsToObjects.length; i++) {
                if(this.devsToObjects[i].object_id==this.object.parent_id
                    && this.devsToObjects[i].digression_id==dev.id) {
//                      dev.prior = this.devsToObjects[i].priority_id
                    return this.devsToObjects[i]
                }
            }
            return false
        },

        priorName(prior_id) {
            for(let i=0; i<this.priors.length; i++)
                if(this.priors[i].id==prior_id)
                    return this.priors[i].name
            return 'неопределенный приоритет'
        },

        updateCams(updatedCams) {
            this.cams = updatedCams;
            // let updatedCams = [...this.cams];
            // const index = updatedCams.findIndex(({ id }) => id === data.camId);
            // if (index > -1) {
            //     updatedCams[index].status = data.checked ? 'Оффлайн' : 'Онлайн';
            //     this.cams = updatedCams;
            // }
        },
        saveCam(cam) {
            let url = '/api/camera',
                method = 'PUT';
            if(cam.cloud_record)
                cam.cloud_record = 1
            else
                cam.cloud_record = 0
            window.myAjax(method, url, cam, this.saveCamHandler)
        },
        saveCamHandler(error, response) {
            if(error) {
                window.myAlert(response.message)
            }
        },

        camToggle(cam) {
            if(cam.on) {
                cam.coordX = null
                cam.coordY = null
                cam.rotate = null
            }
            cam.on = !cam.on
        },
        fio(user) {
            let res = ''
            if(user.profile) {
                res = user.profile.last_name + ' ' + user.profile.first_name
                if(user.profile.middle_name)
                    res += ' ' + user.profile.middle_name
            }
            return res
        },
        

        getAllCams() {
            let data = {},
                url = '/api/client-cameras',
                method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetAllCams)
        },
        myCallbackGetAllCams (error, response) {
            if(!error)
                window.myCams = response.client_cameras
        },

        actionChange() {
            this.showDigressionsModal = true;
            this.selecting = false;
            this.action_id = '0';
        },
        iconHandler() {
            this.selecting = !this.selecting;
            this.selectedObjects = [];
        },
        selectObject(cameraId, checked) {
            if (checked) {
                this.selectedObjects.push(cameraId);
            } else {
                this.selectedObjects.splice(this.selectedObjects.indexOf(cameraId), 1);
            }
        },
        closeDigressionsModal() {
            this.showDigressionsModal = false;
            this.selecting = false;
            this.selectedObjects = [];
            this.modalSelectedDigressions = [];
        },
        updateSelected(data) {
            if (this.modalLoading) {
                return;
            }
            this.modalSelectedDigressions = data;
        },
        saveDigressionsModal() {
            if (this.modalLoading) {
                return;
            }
            if (!this.modalSelectedDigressions.length) {
                return;
            }
            this.modalLoading = true;
            this.modalObjectsToSave = [...this.selectedObjects];
            this.saveNextObjectDigressions();
        },
        saveNextObjectDigressions() {
            if (this.modalObjectsToSave.length) {
                const currentObjectId = this.modalObjectsToSave.shift();
                const digressions = [];
                this.modalSelectedDigressions.forEach( digression => {
                    digressions.push({
                        digression_id: digression,
                        priority_id: 1
                    })
                })
                window.myAjax('GET', '/api/object-digressions', {
                    object_id: currentObjectId
                }, (error, response) => {
                    if (!error) {
                        const oldDigressions = response.object_digressions || [];
                        oldDigressions.forEach( oldDigression => {
                            if (digressions.findIndex( newdig => newdig.id == oldDigression.digression_id) < 0) {
                                digressions.push({
                                    digression_id: oldDigression.digression_id,
                                    priority_id: oldDigression.priority_id
                                })
                            }
                        })
                        const data = {
                            object_id: currentObjectId,
                            digressions: JSON.stringify(digressions)
                        }
                        window.myAjax('POST', '/api/object-digressions', data, this.saveNextObjectDigressions);
                    }
                })
            } else {
                this.modalLoading = false;
                this.closeDigressionsModal();
                window.myAlert('Изменения сохранены.');
            }
        }
    }
}
