import FilesDropArea from './../../files/droparea';
import ObjectList from './../objectList';

export default {
    name: 'PricesBannerCard',
    props: {
        banner: {
            type: Object,
            default: null
        }
    },
    components: {
        FilesDropArea,
        ObjectList
    },
    data: () => ({
        selected: {
            title: '',
            logo_link: ''
        },
        loading: false,
        bannersTitles: [
            { value: 'Супермаркет' },
            { value: 'Гипермаркет' },
            { value: 'У дома' },
        ]
    }),
    mounted() {
        if (this.banner) {
            this.selected = Object.assign({}, this.banner);
        }
    },
    destroyed() {
        // todo
    },
    watch: {
        banner: {
            deep: true,
            handler(newValue) {
                if (this.banner) {
                    this.selected = Object.assign({}, newValue);
                }
            }
        }
    },
    methods: {
        openObjectHandler(data) {
            this.$emit('openObject', data);
        },
        showConfirm() {
            if (this.loading) {
                return;
            }
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить конкурента?', this.remove)
        },
        remove() {
            this.loading = true;
            const url = '/price-tag/competitor';
            window.myAjax('DELETE', url, {
                competitor_id: this.selected.id
            }, this.removeHandler);
        },
        removeHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        },
        save() {
            if (!this.isValid() || this.loading) {
                return;
            }
            this.loading = true;
            window.myAjax(this.selected.id ? 'PUT' : 'POST', '/price-tag/competitor', Object.assign({}, this.selected, {
                competitor_id: this.selected.id
            }), this.saveBannerHandler);
        },
        isValid() {
            if (
                (this.selected.title || '').length < 1
                //  &&
                // (this.selected.format || '').length < 1
            ) {
                return false;
            }
            return true;
        },
        saveBannerHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        },
        selectIcon() {
            this.$refs['banner_icon'].click();
        },
        createIcon() {
            this.loading = true;
            let file = window.$('input[type=file]:first')[0].files[0];
            const self = this;
            if (file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    window.myAjax('POST', '/price-tag/competitor/icon', {
                        image: e.target.result
                    }, function(error, response) {
                        self.loading = false;
                        if (error) {
                            window.myAlert((response || {}).message || 'Ошибка при загрузке иконки. Попробуйте позже.');
                        } else {
                            self.selected.logo_link = response;
                        }
                    });
                };
                reader.readAsDataURL(file);
            }
        }
    }
}
