import CheckboxNew from './../../../base/checkboxNew';
import Checkbox2 from './../../../base/checkbox2';

export default {
    name: 'CameraList',
    components: {
        CheckboxNew,
        Checkbox2
    },
    props:{
        options: {
            type: Array,
            default: []
        },
        selecting: {
            type: Boolean,
            default: false
        },
        selectingCameras: {
            type: Array,
            default: []
        },
        activeObject: {
            type: Object,
            default: null
        },
        search_str: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        loading: false,
        cameras: [],
        filteredCameras: [],

        runningSmarts: [],
        runningArchives: []
        // todo
    }),
    computed: {
        // todo
    },
    watch: {
        options: {
            handler() {
                this.cameras = this.options;
                this.filterCameras();
            }
        },
        activeObject: {
            handler() {
                this.filterCameras();
            }
        },
        search_str: {
            handler() {
                this.filterCameras();
            }
        }
    },
    mounted() {
        this.cameras = this.options;
        this.filterCameras();
        this.getRunning();
    },
    methods: {
        getRunning() {
            window.myAjax('GET', '/smart_archive/running', {}, (error, response) => {
                if (!error) {
                    this.runningSmarts = [];
                    for (const taskId in response) {
                        this.runningSmarts.push(response[taskId].camera_id);
                    }
                }
            })
            window.myAjax('GET', '/archive/running', {}, (error, response) => {
                if (!error) {
                    this.runningArchives = [];
                    for (const taskId in response) {
                        this.runningArchives.push(response[taskId].camera_id);
                    }
                }
            })
        },
        filterCameras() {
            this.filteredCameras = [];
            if ((this.activeObject ||  {}).object_type !== 'node') {
                this.cameras.forEach(camera => {
                    if (
                        (camera.object_id == null && this.activeObject.object_type == 'object') ||
                        camera.object_id == this.activeObject.id ||
                        (camera.object_id == this.activeObject.parent_id && (camera.zone_id == null || camera.zone_id == this.activeObject.id))
                    ) {
                        this.filteredCameras.push(camera);
                    }
                });
            } else {
                this.filteredCameras = [...this.cameras];
            }
            if ((this.search_str || '').length > 0) {
                this.filteredCameras = this.filteredCameras.filter(camera => {
                    return camera.name.toLowerCase().includes(this.search_str.toLowerCase());
                });
            }
        },
        getFilteredCameras() {
            return this.filteredCameras.map(camera => camera.id);
        },
        selectCamera(cameraId, checked) {
            let updatedCameras = [...this.selectingCameras];
            if (checked) {
                updatedCameras.push(cameraId);
            } else {
                updatedCameras.splice(updatedCameras.indexOf(cameraId), 1);
            }
            this.$emit('updateSelected', updatedCameras);
        },
        openCamera(camera) {
            if (this.selecting) {
                return;
            }
            this.$emit('open', camera);
        },
        getZoneName(objectId, zoneId) {
            if (!objectId) {
                return '';
            } 
            const object = window.myObjects.find( object => object.id === objectId );
            const zone = window.myObjects.find( object => object.id === zoneId );
            return object.name + (zone ? `, ${zone.name}` : '');
        },
    }
}
