export default {
    name: 'PricesCategoryCard',
    props: {
        category: {
            type: Object,
            default: null
        },
        parent: {
            type: Object,
            default: null
        }
    },
    components: {
        // todo
    },
    data: () => ({
        selected: {
            title: '',
            customer_info: '',
            format: '',
            logo_link: ''
        },
        loading: false,
        categoryParent: null
    }),
    watch: {
        category: {
            deep: true,
            handler(newValue) {
                if (this.category) {
                    this.selected = Object.assign({}, newValue);
                }
            }
        },
        parent: {
            deep: true,
            handler(newValue) {
                this.categoryParent = newValue;
            }
        }
    },
    mounted() {
        if (this.category) {
            this.selected = Object.assign({}, this.category);
        }
        this.categoryParent = this.parent;
    },
    destroyed() {
        // todo
    },
    methods: {
        openObjectHandler(data) {
            this.$emit('openObject', data);
        },
        showConfirm() {
            if (this.loading) {
                return;
            }
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить категорию (включая все подкатегории и товары)?', this.remove)
        },
        remove() {
            this.loading = true;
            window.myAjax('DELETE', '/price-tag/category', {
                category_id: this.selected.id
            }, this.removeHandler);
        },
        removeHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        },
        save() {
            if (!this.isValid() || this.loading) {
                return;
            }
            this.loading = true;
            if (!this.selected.id && this.categoryParent) {
                this.selected.parent_id = this.categoryParent.id;
            }
            window.myAjax(this.selected.id ? 'PUT' : 'POST', '/price-tag/category', Object.assign({}, this.selected, {
                category_id: this.selected.id
            }), this.saveCategoryHandler);
        },
        isValid() {
            if (
                (this.selected.title || '').length < 1
            ) {
                return false;
            }
            return true;
        },
        saveCategoryHandler(error) {
            this.loading = false;
            if (!error) {
                this.$emit('saved')
            }
        },
        addCategory() {
            this.$emit('addCategory');
        },
        addProduct() {
            this.$emit('addProduct');
        },
    }
}
