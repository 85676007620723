import BaseCheckbox from './../../base/checkbox2';

export default {
    name: 'FeaturesSettings',
    components: {
        BaseCheckbox
        // todo
    },
    props: {
        // todo
    },
    data: () => ({
        saving: false,
        loading: true,

        clients: [],
        features: [],

        names: {
            'journal': 'Журнал отклонений',
            'objects': 'Работа с объектами',
            'roles': 'Работа с ролями',
            'digressions': 'Отклонения',
            'cameras': 'Работа с камерам',
            'logs': 'Отчёты об активности пользователей',
            'monitoring': 'Хранилище медиа данных', // ?
            'contacts': 'Работа с контактами',
            'analytic': 'Аналитика и отчёты',
            'users settings': 'Доступ к пользовательским настройкам',
            'permissions': 'Настройка прав пользователей',
            'users': 'Работа с пользователям',
            
            /* выключенны у всех */
            // 'clients': 'Доступ к клиентам',

            /* выключенные по умолчанию */
            'checklists': 'Работа с чек-листами',
            'price tag': 'Работа с ценниками',

            'archive': 'Работа с архивом',
            'smart archive': 'Работа со смарт-архивом',
            
            'ml': 'Доступ к нейромодулям',
            
            'sensors': 'Работа со датчиками',
        },

        selected: null,
        clientFeatures: [],
        enabled: []
    }),
    watch: {
        //todo
    },
    mounted() {
        this.saveClientFeature = this.saveClientFeature.bind(this);
        this.loading = true;
        window.myAjax('GET', '/api/clients', {}, (error, response) => {
            if (!error) {
                this.clients = response.clients;
                this.selected = this.clients[0].id;
                this.getFeatures();
                this.getClientFeatures();
            }
        });
    },
    methods: {
        getFeatures() {
            this.loading = true;
            window.myAjax('GET', '/api/features', {}, this.getFeaturesCallback);
        },
        getFeaturesCallback(error, response) {
            this.loading = false;
            if (!error) {
                this.features = (response || []).sort( (a, b) => a.name > b.name ? 1 : -1).filter(a => this.names[a.name]);
                // console.log('response', response);
            }
        },
        getClientFeatures() {
            this.loading = true;
            this.enabled = [];
            window.myAjax('GET', '/api/client_features', {
                client_id: this.selected
            }, this.getClientFeaturesCallback);
        },
        getClientFeaturesCallback(error, response) {
            this.loading = false;
            if (!error) {
                this.clientFeatures = response;
                this.enabled = [];
                (this.clientFeatures || []).forEach(feature => {
                    if (feature.enabled) {
                        this.enabled.push(feature.feature_name);
                    }
                });
            }
        },
        saveClientFeature(feature) {
            if (this.saving || this.loading) {
                return;
            }
            const index = this.enabled.findIndex( name => name === feature.name );
            if (index >= 0) {
                this.enabled.splice(index, 1);
            } else {
                this.enabled.push(feature.name);
            }
            // const newValue = !value;
            // if (newValue) {
            // } else {
            //     const index = this.enabled.findIndex(feature.name);
            //     if (index) {
            //         this.enabled.splice(index, 1);
            //     }
            // }
            // feature.enabled = !feature.enabled;
            this.saving = true;
            window.myAjax('POST', '/api/client_features/toggle', {
                client_id: this.selected,
                feature_name: feature.name,
                enable: index < 0
            }, (error) => {
                this.saving = false;
                if (error) {
                    window.myAlert('Ошибка при изменении.');
                    if (index >= 0) {
                        this.enabled.splice(index, 1);
                    } else {
                        this.enabled.push(feature.name);
                    }
                }
            });
        },
    }
}
