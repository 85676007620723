<style scoped>
    .task_bg1 {
        background: #F2C94C;
    }
    .task_bg2 {
        background: #F2994A;
    }
    .task_bg3 {
        background: #1861C4;
    }
    .task_list {
        background: url('../../assets/img/bg_day_tasks.png');
        background-size: 100%;
        background-repeat: repeat-y;
        background-color: #fff;
    }
    .task {
        width:50%;
        height: 48px;
        color: #fff;
        white-space: nowrap;
        overflow-x: hidden;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 15px;
        cursor: pointer;
        transition: filter .25s ease-out;
    }
    .task:hover {
        filter: drop-shadow(2px 4px 6px #888);
    }
    .left_corners_task {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .right_corners_task {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    @media (max-width: 1024px) {
        .dsv-calendar__tasks-container {
            overflow: auto;
        }
        .dsv-calendar__tasks {
            min-width: 700px;
        }
    }
</style>

<template>
    <div class="dsv-calendar__tasks-container">
        <div class="dsv-calendar__tasks mx-lg-6 mb-8">
            <div class="row mx-0 px-0 my_bold">
                <div v-for="(i) in 12"
                    v-bind:key="i"
                    class="col-1 text-center">{{(i*2-1)}}:00</div>
            </div>
            <div class="py-3 task_list">
                <div v-for="(task, i) in tasks"
                    v-bind:key="i"
                    :class="['pt-3 px-4 task', i%3==0 ? 'task_bg1': i%3==1 ? 'task_bg2' : 'task_bg3', task.corners.left ? 'left_corners_task' : '', task.corners.right ? 'right_corners_task' : '']"
                    :style="task.leftMarginAndWidth"
                    @click="taskCard(task.task)"
                >{{task.fio}}\{{task.object_name}}<spsn v-if="task.zone_name">\{{task.zone_name}}</spsn></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Day',
        props: ['tasks'],
    data: () => ({
    }),
    mounted() {
    },
    methods: {
        working() {
//            window.myAlert('работаем...')
        },
        taskCard(task) {
            this.$emit('taskCard', task)
        }
    }
  }
</script>
