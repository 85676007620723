import ControlPanel from './../../control/panel';
import SensorGraph from './../graph';
import SensorList from './../list';

export default {
    name: 'SensorPage',
    components: {
        ControlPanel,
        SensorGraph,
        SensorList,
    },
    props: {
        activeObject: {
            type: Object,
            default: {}
        }
    },
    data: () => ({
        layout: 3,
        loading: false,
        data: [],
        activeSensor: null,
    }),
    watch: {
        activeObject: {
            handler() {
                this.activeSensor = null;
            }
        },
        activeSensor: {
            handler() {
                // console.log('this.activeSensor', this.activeSensor)
                if (this.activeSensor) {
                    // todo
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        layoutHandler(data) {
            this.layout = data;
        },
        sensorHandler(data) {
            this.activeSensor = data;
        },
        cameraHandler(data) {
            this.$emit('cameraSelected', data);
            // console.log(`go to camera ${data}`);
        },
        selectTemp(camera_id) {
            this.$refs['SensorList'].selectTemp(camera_id);
        }
    }
}
