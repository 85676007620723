import { CRON_DAYS } from './../../../neural/utils'; 
import ModalNew from './../../../base/modalNew';
import CheckboxNew from './../../../base/checkboxNew';
import CheckboxList from './../../../base/checkboxList';

export default {
    name: 'CamerasMonitoringSchedule',
    components: {
        ModalNew,
        CheckboxNew,
        CheckboxList,
    },
    props: {
        activeObject: {
            type: Object,
            default: null
        },
        clientId: {
            type: Number,
            default: null
        },
    },
    data: () => ({
        showModal: false,
        task: {},
        loading: false,
        saving: false,
        runningTasks: [],

        objects: [],
        filteredObjects: [],
        selectedObjects: [],
        showedObjects: [],

        objectFilter: '',

        cameras: [],
        filteredCameras: [],
        selectedCameras: [],

        selected: {
            renewal: false,
            startTime: null,
            endTime: null,
            step: null,
            startDate: null,
            endDate: null,
        },

        users: [],

        selectedUserIds: [],
        selectedUser: {},
        userShow: false,

        digressions: [],

        selectedDigressionUnavailableIds: [],
        selectedDigressionUnavailable: {},
        digressionUnavailableShow: false,

        digressionUnavailableCount: 2,

        selectedDigressionPortIds: [],
        selectedDigressionPort: {},
        digressionPortShow: false,

        selectedDigressionResolutionIds: [],
        selectedDigressionResolution: {},
        digressionResolutionShow: false,

        days: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false
        },
        times: [
            { value: '00:00', caption: '0:00' },
            { value: '01:00', caption: '1:00' },
            { value: '02:00', caption: '2:00' },
            { value: '03:00', caption: '3:00' },
            { value: '04:00', caption: '4:00' },
            { value: '05:00', caption: '5:00' },
            { value: '06:00', caption: '6:00' },
            { value: '07:00', caption: '7:00' },
            { value: '08:00', caption: '8:00' },
            { value: '09:00', caption: '9:00' },
            { value: '10:00' },
            { value: '11:00' },
            { value: '12:00' },
            { value: '13:00' },
            { value: '14:00' },
            { value: '15:00' },
            { value: '16:00' },
            { value: '17:00' },
            { value: '18:00' },
            { value: '19:00' },
            { value: '20:00' },
            { value: '21:00' },
            { value: '22:00' },
            { value: '23:00' },
            { value: '24:00' },
        ],
        steps: [
            { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
            { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
            { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
            { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
            { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
            { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
            { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
        ],
    }),
    watch: {
        objectFilter() {
            this.objectFilterChanged();
        }
        // todo
    },
    mounted() {
        this.getTasks();
        this.objects = window.myObjects.filter(object => object.object_type=='object')
            .sort( (a, b) => a.name > b.name ? 1 : -1);
        this.objects.forEach( object => {
            object.cameras = [];
            window.myCams.forEach(camera => {
                if (camera.object_id === object.id) {
                    object.cameras.push(camera);
                }
            });
        })

        this.selected.step = this.steps[0];
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
        this.selected.startDate = now.toISOString().slice(0,10);
        now.setMonth(now.getMonth() + 1);
        this.selected.endDate = now.toISOString().slice(0,10);
        this.selected.startTime = '09:00';
        this.selected.endTime = '18:00';

        this.filteredObjects = this.objects;
        this.cameras = window.myCams;
        window.myAjax('GET', '/api/digressions', {}, (error, response) => {
            if (!error) {
                this.digressions = (response.digressions || []).filter( digression => (!!digression.priority_id || !digression.parent_id) );
                this.digressions.sort((a, b) => a.name.localeCompare(b.name));
            }
        });
        window.myAjax('GET', '/api/users', {}, (error, response) => {
            if (!error) {
                this.users = (response.users || []);
                this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);
            }
        });
    },
    methods: {
        userHandler(data) {
            this.selectedUserIds = data.length ? [data[data.length - 1]] : [];
            if (this.selectedUserIds.length) {
                this.selectedUser = this.users.find(user => user.id === this.selectedUserIds[0]) || {};
            } else {
                this.selectedUser = {};
            }
            this.userShow = false;
        },
        resetUser() {
            this.selectedUserIds = [];
            this.selectedUser = {};
            this.userShow = true;
        },
        digressionUnavailableHandler(data) {
            this.selectedDigressionUnavailableIds = data.length ? [data[data.length - 1]] : [];
            if (this.selectedDigressionUnavailableIds.length) {
                this.selectedDigressionUnavailable = this.digressions.find(digression => digression.id === this.selectedDigressionUnavailableIds[0]) || {};
            } else {
                this.selectedDigressionUnavailable = {};
            }
            this.digressionUnavailableShow = false;
        },
        resetDigressionUnavailable() {
            this.selectedDigressionUnavailableIds = [];
            this.selectedDigressionUnavailable = {};
            this.digressionUnavailableShow = true;
        },
        digressionPortHandler(data) {
            this.selectedDigressionPortIds = data.length ? [data[data.length - 1]] : [];
            if (this.selectedDigressionPortIds.length) {
                this.selectedDigressionPort = this.digressions.find(digression => digression.id === this.selectedDigressionPortIds[0]) || {};
            } else {
                this.selectedDigressionPort = {};
            }
            this.digressionPortShow = false;
        },
        resetDigressionPort() {
            this.selectedDigressionPortIds = [];
            this.selectedDigressionPort = {};
            this.digressionPortShow = true;
        },
        digressionResolutionHandler(data) {
            this.selectedDigressionResolutionIds = data.length ? [data[data.length - 1]] : [];
            if (this.selectedDigressionResolutionIds.length) {
                this.selectedDigressionResolution = this.digressions.find(digression => digression.id === this.selectedDigressionResolutionIds[0]) || {};
            } else {
                this.selectedDigressionResolution = {};
            }
            this.digressionResolutionShow = false;
        },
        resetDigressionResolution() {
            this.selectedDigressionResolutionIds = [];
            this.selectedDigressionResolution = {};
            this.digressionResolutionShow = true;
        },
        objectFilterChanged() {
            this.filteredObjects = this.objects.filter(object => object.name.toLowerCase().indexOf(this.objectFilter.toLowerCase()) !== -1);
        },
        showedObjectHandler(object) {
            const index = this.showedObjects.indexOf(object.id);
            if (index > -1) {
                this.showedObjects.splice(index, 1);
            } else {
                this.showedObjects.push(object.id);
            }
        },
        resetSelected() {
            this.selectedCameras = [];
            this.selectedObjects = [];
        },
        objectHandler(objectId, checked) {
            if (checked) {
                this.selectedObjects.push(objectId);
            } else {
                this.selectedObjects.splice(this.selectedObjects.indexOf(objectId), 1);
                const object = this.objects.find(object => object.id === objectId);
                if (object) {
                    object.cameras.forEach(camera => {
                        this.cameraRemove(camera.id);
                    })
                }
                const index = this.showedObjects.indexOf(object.id);
                if (index > -1) {
                    this.showedObjects.splice(index, 1);
                }
            }
        },
        cameraHandler(cameraId, checked) {
            if (checked) {
                this.selectedCameras.push(cameraId);
            } else {
                this.selectedCameras.splice(this.selectedCameras.indexOf(cameraId), 1);
            }
        },
        cameraAdd(cameraId) {
            const index = this.selectedCameras.indexOf(cameraId);
            if (index === -1) {
                this.selectedCameras.push(cameraId);
            }
        },
        cameraRemove(cameraId) {
            const index = this.selectedCameras.indexOf(cameraId);
            if (index > -1) {
                this.selectedCameras.splice(index, 1);
            }
        },
        openNewTask() {
            this.showModal = true;
            this.task = {

            };
        },
        openTask(task) {
            this.showModal = true;
            this.task = Object.assign({}, task);
        },
        getTasks() {
            this.loading = true;
            window.myAjax('GET', '/monitoring/tasks', {
                task_types: JSON.stringify(['camera_connection'])
            }, (error, response) => {
                this.loading = false;
                if (!error) {
                    this.runningTasks = response || [];
                }
            });
        },

        createTask() {
            const data = {
                start: `${this.selected.startDate} ${this.selected.startTime}`,
                stop: `${this.selected.endDate} ${this.selected.endTime}`,
                schedule: this.getSchedule(),
                auto_renewal: this.selected.renewal,

                object_id: '',
                camera_ids: '',
                zone_id: null,

                responsible_id: (this.selectedUser || {}).id,
                unavailable_digression_id: (this.selectedDigressionUnavailable || {}).id,
                count_unavailable: Number(this.digressionUnavailableCount),
                port_unavailable_digression_id: (this.selectedDigressionPort || {}).id,
                resolution_digression_id: (this.selectedDigressionResolution || {}).id
            }
            console.log(data);
            console.log(this.selectedObjects, this.selectedCameras);
            this.saving = true;
            this.saveNextTask(data);
        },

        saveNextTask(data) {
            if (this.selectedObjects.length) {
                const nextObjectId = this.selectedObjects.pop();
                const camerasIds = [];
                const object = this.objects.find(object => object.id === nextObjectId);
                object.cameras.forEach(camera => {
                    if (this.selectedCameras.indexOf(camera.id) > -1) {
                        camerasIds.push(camera.id);
                    }
                });
                let objectData = Object.assign({}, data, {
                    object_id: nextObjectId,
                });
                if (camerasIds.length) {
                    objectData = Object.assign({}, data, {
                        camera_ids: JSON.stringify(camerasIds),
                    });
                }
                window.myAjax('POST', '/monitoring/tasks/camera/create_schedule', objectData, (error) => {
                    if (!error) {
                        this.saveNextTask(data);
                    } else {
                        window.myAlert('Произошла ошибка. Обратитесь к администратору.');
                        this.saving = false;
                    }
                });
            } else {
                this.showModal = false;
                this.saving = false;
            }
        },


        deleteTask(task) {
            console.log(task);
        }, 

        getSchedule() {
            const step = this.selected.step;
            let result = '';
                    //минуты
            if (step.minutes) {
                result += '*' + step.value + ' ';
            } else {
                result += '0 ';
            }
            //часы
            const hours = [];
            const startTime = parseInt(this.selected.startTime.slice(0, 2));
            const startEnd = parseInt(this.selected.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd + 1; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(this.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.join(',');
            } else {
                result += '* ';
            }
            return result;
        },

        setEndDate(event) {
            const value = event.target.value;
            if (value === this.selected.startDate) {
                const date = new Date(value);
                const key = Object.keys(CRON_DAYS).find(day =>
                    CRON_DAYS[day] === date.getDay()
                );
                this.resetDays();
                this.setDay(key);
            }
        },
        resetDays() {
            this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = false;
            this.days['sat'] = this.days['sun'] = false;
        },
        setDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = true;
                this.days['sat'] = this.days['sun'] = false;
            } else if (dayKey === 'all') {
                for (const [key] of Object.entries(this.days)) {
                    this.days[key] = true;
                }
            } else {
                this.days[dayKey] = !this.days[dayKey];
            }
        },
        userFio(user_profile) {
            let res
            if(!user_profile)
                res = 'Профиль пользователя не создан'
            else if(user_profile.middle_name)
                res = user_profile.last_name + ' ' + user_profile.first_name + ' ' + user_profile.middle_name
            else
                res = user_profile.last_name + ' ' + user_profile.first_name
            return res
        },

    }
}
