import Archive from '../../../components/cams/Archive';
import NeuralSetting from '../../../components/neural/setting';
import NeuralList from '../../../components/neural/list';
import { fullScreenStatus, exitFullscreen } from '../../../plugins/fullScreenUtilities';
import ChecklistCard from '../../../components/checklist/card';
import SmartArchive from '../../../components/smartArchive/player';
import { message } from '../../../components/Utils/modal';
import LocationMap from '../../../components/locationMap/locationMap';
import PreviewList from './../../cameras/previewList';

export default {
    name: 'Cams',
    components: {
        LocationMap,
        Archive,
        ChecklistCard,
        NeuralSetting,
        NeuralList,
        SmartArchive,
        PreviewList,
    },
    props: ['startArchiveDev', 'showMap', 'colonnada', 'archive', 'smartAchive', 'show_left_menu', 'checklistMode', 'selectedObj', 'isSmart', 'isSubPreviewmode'],
    data: () => ({
        controls: {
            scale: 1,
            x: 0,
            y: 0,
            transform: ''
        },
        ptzIndex: null,
        ptzPositions: {},
        showZoomControls: false,
        showMainCam: false,
        mousePressed: false,
        showMainCamLoading: true,
        isFullscreenMainCam: false,
        streamContainerHeight: 0,
        object: null,
        objectCams: [],
        cams: [],
        templates: [],
        templateCam: null,
        theTemplate: null,
        showTemplates: false,
        theCam: null,
        ts_date1: null,
        ts_date2: null,
        videoSpeed: 1,
        archives: [],
        currentArchive: null,
        currentArchive_i: 0,
        timerId: null,
        showMenuBtn: false,
        isCameraSelected: false,
        isMobile: false,
        positions: [],
        selectedPosition: {id: 1, name: 'позиция'},
        showPositions: false,
        positionsLoading: false,
        showNeural: false,
        showNeuralList: false,
        isQueue: false,
        neuralType: null,
        user_cameras: [],

        camsLoading: false,
        rawCams: [],
        sortedCams: [],
        smartRecords: {},
        smart_images: {},
        usualRecords: {},
        sensorRecords: {},
        loadingCamsCount: 0,
        canShowMap: true,
        stopAllPreviewOnStreamStartSettings: null,
        stopAllPreviewOnStreamStart: true,
        archiveEnabled: false,
        smartArchiveEnabled: false,
    }),
    created() {
        // window.addEventListener('beforeunload', () => {
        //     this.closeComponent()
        // });
    },
    mounted() {
        this.archiveEnabled = (window.features.find( feature => feature.feature_name === 'archive') || {}).enabled;
        this.smartArchiveEnabled = (window.features.find( feature => feature.feature_name === 'smart archive') || {}).enabled;
        window.addEventListener('beforeunload', this.closeTabHandler);
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        //window.$('.scrolling_content').css('max-height', window.innerHeight - 60)
        this.cams = window.myCams
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        window.resizeScrollingContent();
        // window.$('.scrolling_content').css('max-height', window.innerHeight - 60);
        this.getUserCams()
        const self = this;
        document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement) {
                self.isFullscreenMainCam = false;
            }
        });
        if (this.selectedObj) {
            window.$('div[node_id='+this.selectedObj.id+']:first').addClass('opened');
            window.$('div[node_id='+this.selectedObj.id+']:first>p:first').addClass('active')
            this.showObjectCams(this.selectedObj);
            if(this.selectedObj.parent_id) {
                this.openTreeObjects(this.getParentObject(this.selectedObj))
            }
        }
        this.getCams();
        this.getSmartRecords();
        this.getUsualRecords();
        this.getSensorRecords();
        setTimeout(() => {
            this.getSettings();
        }, 2000)
    },
    beforeDestroy() {
        window.clearTimeout(this.timerId);
        if (this.activeCam) {
            let url = '/stream/stop';
            window.myAjax('POST', url, {
                camera_id: this.activeCam.id
            }, function() { /** todo */ });
        }
        if (window.hls) {
            if (window.hls.stopLoad) {
                window.hls.stopLoad();
                window.hls.destroy();
            }
        }
        const camerasId = [];
        for (let i = 0; i < this.objectCams.length; i++) {
            if (this.objectCams[i].id) {
                camerasId.push(this.objectCams[i].id);
            }
        }
        window.myAjax('POST', '/preview/stop_selected', {
            cameras_id: JSON.stringify(camerasId)
        }, () => { 
            // todo
        })
    },
    watch: {
        isSmart(newValue) {
            if (this.timerId) {
                window.clearTimeout(this.timerId)
            }
            if (newValue) {
                if (this.activeCam) {
                    let url = '/stream/stop';
                    window.myAjax('POST', url, {
                        camera_id: this.activeCam.id
                    }, function() { /** todo */ });
                }
                if (window.hls) {
                    if (window.hls.stopLoad) {
                        window.hls.stopLoad();
                    }
                    if (window.hls.destroy) {
                        window.hls.destroy();
                    }
                }
                this.stopAllPreview();
            } else {
                this.startAllPreview();
            }
            this.showObjectCams(this.selectedObj || this.object, true);
        },
        showZoomControls(newValue) {
            let elementId = 'hls-video-container';
            if (this.isSmart) {
                elementId = 'smartAchive-id';
            }
            if (newValue) {
                document.addEventListener('keydown', this.keysHandler);
                document.getElementById(elementId).addEventListener('mousedown', this.videoContainerMouseDownHandler);
                document.getElementById(elementId).addEventListener('mouseup', this.videoContainerMouseUpHandler);
                document.getElementById(elementId).addEventListener('mouseout', this.videoContainerMouseUpHandler);
                document.getElementById(elementId).addEventListener('mousemove', this.videoContainerMouseMoveHandler);
                document.getElementById(elementId).addEventListener('wheel', this.scaleScroll);
            } else {
                document.removeEventListener('keydown', this.keysHandler);
                document.getElementById(elementId).removeEventListener('mousedown', this.videoContainerMouseDownHandler);
                document.getElementById(elementId).removeEventListener('mouseup', this.videoContainerMouseUpHandler);
                document.getElementById(elementId).removeEventListener('mouseout', this.videoContainerMouseUpHandler);
                document.getElementById(elementId).removeEventListener('mousemove', this.videoContainerMouseMoveHandler);
                document.getElementById(elementId).removeEventListener('wheel', this.scaleScroll);
            }
        },
        // selectedObj() {
        //     // this.sortCams();
        // },
        archive(newVal) {
            if (newVal) {
                let act_cam = this.activeCam
                // убираем видео элемент с прежней архивной камеры
                // window.$('#' + act_cam.id).html('');
                // this.startVideo(act_cam)
                this.activeCam = act_cam; // Возвращаем активную камеру для того, чтобы открылся архив
                this.isSelected = true;
                if (window.hls) {
                    if (window.hls.stopLoad) {
                        window.hls.stopLoad();
                        window.hls.destroy();
                    }
                }
                if (window.hlsTimeout) {
                    clearTimeout(window.hlsTimeout);
                    window.hlsTimeout = 0;
                }
                this.stopAllPreview();
                if (this.activeCam) {
                    let url = '/stream/stop';
                    window.myAjax('POST', url, {
                        camera_id: this.activeCam.id
                    }, function() { /** todo */ });
                }
            } else {
                if (this.activeCam) {
                    // window.$('#' + this.activeCam.id).html('');
                    this.startVideo(this.activeCam)
                } else {
                    this.startPreview();
                }
                if (this.timerId) {
                    window.clearTimeout(this.timerId)
                }
                // this.timerId = window.setTimeout(this.updateImage, 1000);
            }
        },
        objectCams: {
            handler(newVal, oldVal) {
                if (!this.isSmart) {
                    for (let i = 0; i < oldVal.length; i++) {
                        this.stopPreview(oldVal[i])
                    }
                    window.setTimeout(() => {
                        for (let i = 0; i < newVal.length; i++) {
                            this.startPreview(newVal[i])
                        }
                    }, 1000);
                }
            },
        },
    },
    computed: {
        activeCam: {
            get() {
                return this.$store.getters.ACTIVE_CAM;
            },
            set(val) {
                this.$store.dispatch('WRITE_ACTIVE_CAM', val);
            }
        }
    },
    methods: {
        getSensorRecords() {
            if ((window.direct_permissions || []).findIndex( (permission) => permission.name === 'get sensors') === -1) {
                return;
            }
            window.myAjax('GET', `/sensor/unimon/device/sensors`, {}, (error, response) => {
                if (!error) {
                    const result = {};
                    (response || []).forEach( sensor => {
                        if (sensor.camera_id) {
                            result[sensor.camera_id] = true;
                        }
                    })
                    this.sensorRecords = Object.assign({}, result);
                }
            });
        },
        getSettings() {
            const user = this.getUserById( window.getCookie('userId') );
            if ((user.profile || {}).client_id) {
                window.myAjax('GET', '/api/settings', {
                    client_id: user.profile.client_id
                }, this.getSettingsCallback)
            }
        },
        getSettingsCallback(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                this.stopAllPreviewOnStreamStartSettings = (response || []).find( (res) => res.name === "stop_all_preview_on_stream_start") || {};
                this.stopAllPreviewOnStreamStart = this.stopAllPreviewOnStreamStartSettings.value === 'false' ? false : true;
            }
        },
        getUserById(id) {
            for (let i = 0; i < window.myUsers.length; i++) {
                if (window.myUsers[i].id == id) {
                    return window.myUsers[i];
                }
            }
            return null;
        },
        openTreeObjects(obj) {
            window.$('div[node_id='+obj.id+']:first').addClass('opened')
            if(obj.parent_id)
            this.openTreeObjects(this.getParentObject(obj))
        },
        getParentObject(obj) {
            for(let i=0; i<window.myObjects.length; i++)
            if(window.myObjects[i].id==obj.parent_id)
                return window.myObjects[i]
            return null
        },
        getPositions(data) {
            this.positionsLoading = true;
            this.positions = [];
            window.myAjax('GET', '/ptz/positions', {
                camera_id: data.id
            }, this.getPositionsCallback)
        },
        getPositionsCallback(error, response) {
            this.positionsLoading = false;
            if (error) {
                // todo
            } else {
                this.positions = response.constructor.name === 'Array' ? response : [];
            }
        },
        sendPositions(data) {
            window.myAjax('POST', '/ptz/positions', {
                camera_id: data.camera_id,
                position_id: data.position_id
            }, this.sendPositionsCallback)
        },
        sendPositionsCallback(error, response) {
            if (error) {
                message(response.message)
            }
        },
        close(value) {
            this.showNeural = false;
            if (value) {
                this.showNeuralList = true;
                return;
            }
            this.showNeuralList = false;
            window.$("#modalNeural").modal('hide');
            if (this.activeCam) {
                this.stopPreview(this.activeCam);
            }
        },
        showNeuralListF() {
            this.showNeural = false;
            this.showNeuralList = true;
            window.$("#modalNeural").modal('show');
            this.startPreview(this.activeCam);
            // if (this.activeCam) {
            //     this.showNeural = false;
            //     this.showNeuralList = true;
            //     this.noActiveCam
            //     window.$("#modalNeural").modal('show');
            //     this.startPreview(this.activeCam);
            // } else {
            //     message('Выберите активную камеру');
            // }
        },
        createNeural(value) {
            this.neuralType = value;
            this.isQueue = (value === 'queue_detection');
            this.showNeural = true;
            this.showNeuralList = false;
        },
        selectPosition(position) {
            if (!this.activeCam.ptz_enabled) {
                return;
            }
            this.selectedPosition = position;
            this.sendPositions({
                position_id: this.selectedPosition.id,
                camera_id: this.activeCam.id
            });
            this.changeShowPositions(false);
        },
        changeShowPositions(value) {
            this.showPositions = typeof value === 'boolean' ? value : !this.showPositions;
        },
        cancelCreateSnap() {
            this.fullscreenMainCam();
        },
        videoContainerMouseDownHandler(event) {
            event.preventDefault();
            event.stopPropagation();
            this.mousePressed = true;
        },
        videoContainerMouseUpHandler(event) {
            event.preventDefault();
            event.stopPropagation();
            this.mousePressed = false;
        },
        videoContainerMouseMoveHandler(event) {
            if (!this.mousePressed) {
                return;
            }
            const x = event.movementX;
            const y = event.movementY;
            this.moveMouse(x, y);
        },
        keysHandler(event) {
            event.preventDefault();
            event.stopPropagation();
            switch (event.keyCode) {
                case 37:
                    this.move('left');
                    break;
                case 38:
                    this.move('up');
                    break;
                case 39:
                    this.move('right');
                    break;
                case 40:
                    this.move('down');
                    break;
            }
        },
        scale(type) {
            if (type === 'in' && this.controls.scale < 4) {
                this.controls.scale += 0.5;
            }
            if (type === 'out' && this.controls.scale > 1) {
                this.controls.scale -= 0.5;
            }
            this.checkBorders();
        },
        scaleScroll(event) {
            event.preventDefault();
            event.stopPropagation();
            this.controls.scale += event.deltaY * -0.001;
            if (this.controls.scale > 4) {
                this.controls.scale = 4;
            }
            if (this.controls.scale < 1) {
                this.controls.scale = 1;
            }
            this.checkBorders();
        },
        moveMouse(x, y) {
            const scale = this.controls.scale;
            this.controls.x += x / scale;
            this.controls.y += y / scale;
            this.checkBorders();
        },
        move(type) {
            if (type === 'left') {
                this.controls.x += 10;
            } else if (type === 'right') {
                this.controls.x -= 10;
            } else if (type === 'up') {
                this.controls.y += 10;
            } else if (type === 'down') {
                this.controls.y -= 10;
            }
            this.checkBorders();
        },
        resetControls() {
            this.showZoomControls = false;
            this.controls.scale = 1;
            this.controls.y = 0;
            this.controls.x = 0;
            this.controls.transform = '';
        },
        checkBorders() {
            const scale = this.controls.scale;
            let elementId = 'hls-video-container';
            if (this.isSmart) {
                elementId = 'smartAchive-id';
            }
            const width = document.getElementById(elementId).clientWidth;
            const height = document.getElementById(elementId).clientHeight;
            const maxMoveX = (width - width / scale) / 2;
            const maxMoveNegativeX = -maxMoveX;
            const maxMoveY = (height - height / scale) / 2;
            const maxMoveNegativeY = -maxMoveY;
            if (this.controls.x > 0) {
                if (this.controls.x > maxMoveX) {
                    this.controls.x = maxMoveX;
                }
            } else {
                if (this.controls.x < maxMoveNegativeX) {
                    this.controls.x = maxMoveNegativeX;
                }
            }
            if (this.controls.y > 0) {
                if (this.controls.y > maxMoveY) {
                    this.controls.y = maxMoveY;
                }
            } else {
                if (this.controls.y < maxMoveNegativeY) {
                    this.controls.y = maxMoveNegativeY;
                }
            }
            this.controls.transform = 'scale(' + this.controls.scale + ') translateX(' + this.controls.x + 'px) translateY(' + this.controls.y + 'px)!important';
        },
        closeTabHandler() {
            window.clearTimeout(this.timerId);
            if (this.activeCam) {
                let url = '/stream/stop';
                window.myAjax('POST', url, {
                    camera_id: this.activeCam.id
                }, function() { /** todo */ });
            }
            if (window.hls) {
                if (window.hls.stopLoad) {
                    window.hls.stopLoad();
                    window.hls.destroy();
                }
            }
            const camerasId = [];
            for (let i = 0; i < this.objectCams.length; i++) {
                if (this.objectCams[i].id) {
                    camerasId.push(this.objectCams[i].id);
                }
            }
            window.myAjax('POST', '/preview/stop_selected', {
                cameras_id: JSON.stringify(camerasId)
            }, () => { 
                // todo
            })
        },
        closeComponent() {
            window.clearTimeout(this.timerId);
            this.stopAllPreview();
            if (this.activeCam) {
                let url = '/stream/stop';
                window.myAjax('POST', url, {
                    camera_id: this.activeCam.id
                }, function() { /** todo */ });
                this.activeCam = null;
                this.isCameraSelected = false;
            }
            this.showMainCam = false;
            this.showMainCamLoading = true;
            if (window.hls) {
                if (window.hls.stopLoad) {
                    window.hls.stopLoad();
                    window.hls.destroy();
                }
            }
            // this.closeMainCam();
        },
        closeFullscreen() {
            if (fullScreenStatus()) {
                exitFullscreen();
                window.$(".video-container>video").removeClass('fullscreen_video').addClass('framed_video')
            }
        },
        getUserCams() {
            window.myAjax('GET', '/api/user-camera-relations', {
                user_id:  window.getCookie('userId')
            }, this.getUserCamsHandler)
        },
        getUserCamsHandler(error, response) {
            if (error) {
                message(response.message)
            } else {
                this.user_cameras = response.user_cameras;
                window.user_cameras = response.user_cameras;
                this.ajaxInitCams();
            }
        },
        ajaxInitCams() {
            let url = '/api/client-cameras',
                method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartC)
        },
        myCallbackGetStartC(error, response) {
            if (error) {
                message(response.message)
            } else {
                window.myCams = [];
                const cams = this.getUniqueCams(response.client_cameras);
                cams.forEach( cam => {
                    if ( this.user_cameras.find( userCam => userCam.camera_id === cam.id ) ) {
                        window.myCams.push(cam);
                    }
                })
                this.cams = window.myCams
            }
        },
        getUniqueCams(cams) {
            let result = [];
            for (let cam of cams) {
                let find = false;
                for (let i = 0; i < result.length; i++) {
                    if (result[i].id === cam.id) {
                        find = true
                        break
                    }
                }
                if (!find) {
                    result.push(cam);
                }
            }
            return result;
        },
        getParentMap() {
            for (let i = 0; i < window.myObjects.length; i++)
                if (window.myObjects[i].id == this.object.parent_id)
                    return window.myObjects[i].map_path
            return null
        },
        showObjectCams(obj, fromToggler) {
            console.log('showObjectCams', obj);
            if (!obj) {
                return;
            }
            this.object = obj
            this.objectCams = []
            switch (obj.object_type) {
                case 'object':
                    for (let i = 0; i < this.cams.length; i++)
                        if (this.cams[i].object_id == obj.id && this.cams[i].status === 'Онлайн') {
                            let objCam = Object.assign({}, this.cams[i])
                            objCam.imgSrc = '/preview/img?camera_id=' + this.cams[i].id;
                            objCam.newImage = new Image()
                            objCam.imageId = 'myImg' + this.cams[i].id
                            objCam.attempts = 0
                            this.objectCams.push(objCam)
                        }
                    break
                case 'zone':
                    for (let i = 0; i < this.cams.length; i++)
                        if (this.cams[i].zone_id == obj.id && this.cams[i].status === 'Онлайн') {
                            let objCam = Object.assign({}, this.cams[i])
                            objCam.imgSrc = '/preview/img?camera_id=' + this.cams[i].id;
                            objCam.newImage = new Image()
                            objCam.imageId = 'myImg' + this.cams[i].id
                            objCam.attempts = 0
                            this.objectCams.push(objCam)
                        }
                    this.object.map_path = this.getParentMap()
                    break
                case 'node':
                    break;
                default: // показ камер выбранного шаблона
                    for (let i = 0; i < this.cams.length; i++) {
                        if (this.cams[i].status === 'Онлайн')
                        {
                            this.templateCam = this.cams[i]
                            if (this.templateHasCam(obj)) {
                                let objCam = Object.assign({}, this.cams[i])
                                objCam.imgSrc = '/preview/img?camera_id=' + this.cams[i].id;
                                objCam.newImage = new Image()
                                objCam.imageId = 'myImg' + this.cams[i].id
                                objCam.attempts = 0
                                this.objectCams.push(objCam)
                            }
                        }
                    }
                    this.object.map_path = null
                    break
            }
            // если нужно, отключяем поток камеры рядом с картой объекта
            if (this.activeCam) {
                if (fromToggler) {
                    if (this.isSmart) {
                        this.startSmartVideo(this.activeCam);
                        // this.closeMainCam();
                    } else {
                        this.startVideo(this.activeCam);
                    }
                    // window.setTimeout(() => {
                    // if (this.objectCams.findIndex( cam => cam.id === this.activeCam.id) > -1) {
                    //     this.selectingStream(this.activeCam);
                    // }
                    // }, 10)
                } else {
                    this.closeMainCam();
                    for (let i = 0; i < this.objectCams.length; i++) {
                        if ((this.activeCam || {}.id) == this.objectCams[i].id) {
                            if (this.showMainCam && this.showMainCamLoading) {
                                document.getElementById('hls-img-' + this.objectCams[i].id).src = this.objectCams[i].imgSrc;
                            }
                            break
                        }
                    }
                }
            }
            
            this.getPtz();
            // start cams prewiew
            if (this.objectCams.length) {
                if (this.timerId) {
                    window.clearTimeout(this.timerId);
                }
                if (this.isSmart) {
                    this.timerId = window.setTimeout(this.updateSmartImage, 100);
                } else {
                    // this.timerId = window.setTimeout(this.updateImage, 100);
                }
            }
            // if (this.activeCam) {
                
            // }
            this.sortCams();
        },
        getArchiveLinks() {
            this.$refs['ArchiveRef'].getAllArchivesLinks(this.activeCam)
        },
        selectTemp() {
            const activeId = this.activeCam.id;
            if (this.activeCam) {
                this.closeMainCam();
            }
            this.$emit('tempSelected', activeId);
        },        
        startCam(newCam) {
            let cam = null;
            for (let i = 0; i < this.objectCams.length; i++) {
                const objectCam = this.objectCams[i];
                if (newCam.id === objectCam.id) {
                    cam = objectCam;
                }
            }
            this.selectingStream(cam);
        },
        selectingStream(cam) {
            if (this.activeCam) {
                if (this.activeCam.id === cam.id) {
                    return;
                }
            }
            if (this.isSmart) {
                console.log('smartRecords', this.smartRecords[cam.id]);
                if (this.smartRecords[cam.id]) {
                    this.startSmartVideo(cam);
                }
                // this.$emit('videoSmart', true);
            } else {
                if (this.isCameraSelected) {
                    if (cam.id !== this.activeCam.id) {
                        this.startVideo(cam)
                    }
                } else {
                    this.positionsLoading = false;
                    this.positions = [];
                    this.startVideo(cam)
                    if (!cam.ptz_enabled) {
                        return;
                    }
                    this.getPositions({
                        id: cam.id
                    });
                }
            }
        },
        startSmartVideo(cam) {
            if (this.activeCam) {
                this.stopSmartVideo();
            }
            if (this.timerId) {
                window.clearTimeout(this.timerId);
            }
            this.activeCam = Object.assign({}, cam);
            this.$emit('setActiveCam', cam);
        },
        stopSmartVideo() {
            this.activeCam = null;
            this.isCameraSelected = false;
            if (this.objectCams.length) {
                if (this.timerId) {
                    window.clearTimeout(this.timerId);
                }
                if (this.isSmart) {
                    this.timerId = window.setTimeout(this.updateSmartImage, 100);
                }
            }
            this.closeFullscreen();
        },
        startVideo(cam) {
            if (this.activeCam) {
                this.closeMainCam();
            }
            this.resetControls();
            this.activeCam = cam;

            if (this.timerId) {
                window.clearTimeout(this.timerId)
            }
            this.timerId = window.setTimeout(this.updateMainPreview, 1000);
            this.objectCams.forEach(cam => {
                if (cam.id !== this.activeCam.id) {
                    this.stopPreview(cam)
                }
            });

            this.$emit('setActiveCam', cam)
            if (this.isMobile) {
                return;
            }
            if (this.archive) {
                if (this.activeCam != null && this.activeCam.id == cam.id) {
                    this.fullScreenArchive()
                } else {
                    this.activeCam = cam
                    this.$emit('setActiveCam', cam)
                    // начинаем анимацию подгрузки архивов
                    window.$("#waitModal").modal('show')
                    // закончим анимацию при подгрузке первого кадра из загруженных архивов,
                    // либо при отсутствии архивных записей
                    // getAllArchivesLinks в Archive
                    window.setTimeout(this.getArchiveLinks, 300)
                }
            } else {
                if (window.hls) {
                    if (window.hls.stopLoad) {
                        window.hls.stopLoad();
                        window.hls.destroy();
                    }
                }
                if (window.hlsTimeout) {
                    clearTimeout(window.hlsTimeout);
                    window.hlsTimeout = 0;
                }
                this.showMainCam = true;
                this.showMainCamLoading = true;
                window.myAjax('POST', '/stream/start', {
                    camera_id: cam.id
                }, this.getU3MLinkCallback);
            }
        },
        updateMainPreview() {
            if (!this.showMainCamLoading) {
                return;
            }
            if (document.getElementById('hls-img-myImg' + this.activeCam.id)) {
                document.getElementById('hls-img-myImg' + this.activeCam.id).src = this.activeCam.imgSrc + '&time=' + new Date();
            }
            this.timerId = window.setTimeout(this.updateMainPreview, 1000);
        },
        exitFullscreenMainCam() {
            this.isFullscreenMainCam = false;
            const self = this;
            document.exitFullscreen()
                .then(() => {
                    self.checkBorders(); })
                .catch((err) => console.error(err))
        },
        fullscreenMainCam() {
            this.isFullscreenMainCam = true;
            var elem = this.isSmart ?
                document.getElementById('video-smart') : 
                document.getElementById('hls-video-container');
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        },
        cancelSelectingRecord() {
            this.$refs['ArchiveRef'].cancelSelectingRecord();
        },
        getSmartStatus() {
            return this.$refs['ArchiveRef'].getSmartStatus();
        },
        getAllSmartRecordsLength() {
            return this.$refs['ArchiveRef'].getAllSmartRecordsLength();
        },
        getU3MLinkCallback(error, response) {
            const _self = this;
            if (!error) {
                if (response.indexOf('https') !== 0) {
                    _self.showMainCamLoading = false;
                    _self.closeMainCam();
                }
                var video = document.getElementById('hls-video');
                var videoSrc = decodeURIComponent(response);
                if (window.Hls.isSupported()) {
                    window.hls = new window.Hls({
                        backBufferLength: 60,
                        maxMaxBufferLength: 60,
                        xhrSetup: xhr => {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + window.getCookie('userToken'))
                        }
                    });
                    window.hls.loadSource(videoSrc);
                    window.hls.attachMedia(video);
                    window.hls.on(window.Hls.Events.MANIFEST_PARSED, function () {
                        if (_self.stopAllPreviewOnStreamStart) {
                            if (_self.showNeural) {
                                for (let i = 0; i < _self.objectCams.length; i++) {
                                    if (_self.objectCams[i].id === _self.activeCam.id) {
                                        continue;
                                    }
                                    window.myAjax('POST', '/preview/stop', {
                                        camera_id: _self.objectCams[i].id
                                    }, _self.myCallbackPreview)
                                }
                            } else {
                                console.log(_self.activeCam.id);
                                _self.stopAllPreview(_self.activeCam);
                            }
                        }
                        _self.showMainCamLoading = false;
                        if (video) {
                            video.play();
                            video.controls = true;
                            video.disablePictureInPicture = false;
                        }
                    });
                    window.hls.on(window.Hls.Events.ERROR, function (event, data) {
                        if (data.fatal) {
                        switch (data.type) {
                            case window.Hls.ErrorTypes.NETWORK_ERROR:
                                window.hlsTimeout = setTimeout(function() {
                                    window.hls.loadSource(videoSrc);
                                    window.hls.startLoad();
                                }, 1000)
                                break;
                        }
                        }
                    });
                    // document.title = url;
                } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = videoSrc;
                    video.addEventListener('loadedmetadata', function () {
                        video.play();
                    });
                }
                // this.$nextTick(() => {
                //     window.playM3u8('https://m1.dsvgo.ru/hls/' + cam.id + '.m3u8');
                //     var video = document.getElementById('hls-video');
                //     if (video) {
                //         video.play();
                //     }
                // });
            } else {
                /** 
                this.showMainCamLoading = false;
                this.closeMainCam();
                */
            }
        },
        sendLog() {
            let url = '/api/log-camera-watching',
                method = 'POST',
                data = {
                    camera_id: this.activeCam.id
                }
            window.myAjax(method, url, data, this.myCallbackEmpty)
        },
        myCallbackEmpty(error, response) {
            if (error) {
                message(response.message)
            }
        },
        closeSmartCam() {
            if (this.activeCam) {
                this.showMainCam = false;
                this.stopSmartVideo();
            }
        },
        closeMainCam() {
            if (this.isSmart) {
                return this.closeSmartCam();
            }
            this.resetControls();
            if (this.activeCam) {
                let url = '/stream/stop';
                window.myAjax('POST', url, {
                    camera_id: this.activeCam.id
                }, function() { /** todo */ });
                if (this.stopAllPreviewOnStreamStart) {
                    this.startPreview();
                    if (this.timerId) {
                        window.clearTimeout(this.timerId);
                    }
                    // this.timerId = window.setTimeout(this.updateImage, 1000);
                }
                this.activeCam = null;
                this.isCameraSelected = false;
            }
            this.showMainCam = false;
            this.showMainCamLoading = true;
            if (window.hls) {
                if (window.hls.stopLoad) {
                    window.hls.stopLoad();
                    window.hls.destroy();
                }
            }
        },
        // showBorderActiveCam() {
        //     let el = window.$('#' + this.activeCam.id + ' > video')
        //     if (el.length == 0)
        //         window.setTimeout(this.showBorderActiveCam, 300)
        //     else {
        //         el.addClass('active_cam');
        //         // el.css('height', el.parent().parent().height())
        //     }
        // },
        // ----------------------------------
        fullScreenArchive() {
            let active_cam = window.$('video:first')
            active_cam.css('height', 'auto')
            this.$emit('fullScreenArchive')
        },
        getObjectCam(camId) {
            if (camId !== null && this.objectCams.length !== 0) {
                let camIdx = this.objectCams.findIndex(el => el.id === camId);
                if (camIdx !== -1) {
                    return this.objectCams[camIdx];
                }
            }
        },
        stopVideo() {
            this.resetControls();
            if (this.activeCam) {
                this.activeCam = null
                this.isCameraSelected = false;
            }
            // stop real stream
            window.leaveRoom()
        },
        /*
                    alertVideo() {
                        this.activeCam = null
                        this.$emit('setActiveCam', false)
                        this.stopVideo()
                        message('Режим видео доступен только для Онлайн камер')
                    },
        */
        startPreview(cam) {
            if (cam) {
                window.myAjax('POST', '/preview/start', {
                    camera_id: cam.id
                }, this.myCallbackPreview)
            } else {
                for (let i = 0; i < this.objectCams.length; i++) {
                    window.myAjax('POST', '/preview/start', {
                        camera_id: this.objectCams[i].id
                    }, this.myCallbackPreview)
                }
            }
        },
        stopPreview(cam) {
            if (cam) {
                window.myAjax('POST', '/preview/stop', {
                    camera_id: cam.id
                }, this.myCallbackPreview)
            } else {
                for (let i = 0; i < this.objectCams.length; i++) {
                    window.myAjax('POST', '/preview/stop', {
                        camera_id:  this.objectCams[i].id
                    }, this.myCallbackPreview)
                }
            }
        },
        startAllPreview() {
            this.startPreview();
        },
        stopAllPreview() {
            this.stopPreview();
        },
        myCallbackPreview(error, response) {
            if (error) {
                message(response.message)
            }
        },
        updateSmartImage() {
            if (!this.activeCam) {
                for (let i = 0; i < this.objectCams.length; i++) {
                    if (this.objectCams[i].status === 'Онлайн') {
                        if (this.objectCams[i].newImage.complete) {
                            if (this.objectCams[i].posId) {
                                this.updateSmartForCam(this.objectCams[i].id, this.objectCams[i].posId);
                            } else {
                                if(this.objectCams[i].newImage.src ) {
                                    if (document.getElementById(this.objectCams[i].imageId)) {
                                        document.getElementById(this.objectCams[i].imageId).src = this.objectCams[i].newImage.src;
                                    } else {
                                        return;
                                    }
                                }
                                let src = `/smart_archive/snapshot?camera_id=${this.objectCams[i].id}`;
                                
                                fetch(src, {
                                    headers: {
                                        'Authorization': 'Bearer ' + window.getCookie('userToken')
                                    }
                                }).then(res => {
                                    if (res.ok) {
                                        return res.blob();
                                    }
                                }).then(blob => {
                                    if (blob) {
                                        document.getElementById(this.objectCams[i].imageId).src = URL.createObjectURL(blob);
                                    }
                                });
                            }
                        }
                    }
                }
            }
            this.timerId = window.setTimeout(this.updateSmartImage, 60000);
        },
        updateImage() {
            if (!this.activeCam || !this.stopAllPreviewOnStreamStart || this.showMainCamLoading) {
                for (let i = 0; i < this.objectCams.length; i++) {
                    if (this.objectCams[i].status === 'Онлайн') {
                        if (this.objectCams[i].newImage.complete) {
                            if(this.objectCams[i].newImage.src) {
                                if (document.getElementById(this.objectCams[i].imageId)) {
                                    document.getElementById(this.objectCams[i].imageId).src = this.objectCams[i].newImage.src;
                                } else {
                                    return;
                                }
                            }
                            this.objectCams[i].newImage = new Image();
                            this.objectCams[i].newImage.src = this.objectCams[i].imgSrc + "&time=" + new Date();
                            this.objectCams[i].newImage.onerror = () => {
                                this.objectCams[i].attempts += 1
                                // if (this.objectCams[i].attempts === 1) {
                                this.objectCams[i].newImage.src = require('../../../assets/img/preview_not_load.png')
                                // }
                            }
                        }
                    }
                }
            }
            if (this.showMainCam && this.showMainCamLoading) {
                for (let i = 0; i < this.objectCams.length; i++) {
                    const cam = this.objectCams[i];
                    if (cam.id === this.activeCam.id) {
                        this.activeCam = cam;
                        document.getElementById('hls-img-myImg' + cam.id).src = cam.imgSrc + '&time=' + new Date();
                    }
                }
            }
            if (this.timerId) {
                window.clearTimeout(this.timerId);
            }
            // this.timerId = window.setTimeout(this.updateImage, 1000);
        },
        restartPreview() {
            this.startPreview();
            if (this.timerId) {
                window.clearTimeout(this.timerId);
            }
            // this.timerId = window.setTimeout(this.updateImage, 1000);
        },
        controlArchiveVideo(archive) {
            let video = window.$('#' + this.activeCam.id + '>video:first')
            if (video.length) {
                if (archive.onStart) {
                    video[0].pause()
                    this.currentArchive_i = 0
                    this.uploadArchiveVideo(this.archives[0])
//                      video[0].currentTime = 0
                    archive.onStart = false
                    archive.pause = false
                    return
                }
                this.videoSpeed = archive.videoSpeed
                video[0].playbackRate = this.videoSpeed
                if (archive.pause)
                    video[0].pause()
                else
                    video[0].play()
            } /* else {
                archive.pause = true
                message('Выберите камеру и период архивного видео')
            } */
        },
        cancelCreateArchiveDev() {
            this.$emit('cancelCreateArchiveDev', true)
        },
        createArchiveDev() {
            this.$emit('createArchiveDev', true)
        },
        uploadArchiveVideo() {
            window.clearTimeout(this.timerId)
        },
        hideArchiveAnimation() {
            window.$("#waitModal").modal('hide')
            this.$refs['ArchiveRef'].setArchivePause(false);
        },
        uploadNextArchiveVideo() {
            if (++this.currentArchive_i < this.archives.length) {
                this.uploadArchiveVideo(this.archives[this.currentArchive_i])
            }
        },
        timeupdateArchiveVideo() {
            let video = window.$('#' + this.activeCam.id + '>video:first')[0]
            if (video.currentTime + this.archives[this.currentArchive_i].timestart >= this.ts_date2) {
                video.pause()
                this.$refs['ArchiveRef'].setArchivePause(true)
            } else
                this.$refs['ArchiveRef'].setTimeLine(this.currentArchive_i, video.currentTime)
            //console.log(video.currentTime)
        },
        setArchivesAndDates(obj) {
            this.currentArchive_i = 0
            this.archives = obj.archives
            this.ts_date1 = obj.date1
            this.ts_date2 = obj.date2
            this.videoSpeed = obj.videoSpeed
        },
        setArchiveToTimeLineClick(timeLineTimeStamp) {
            let video = window.$('#' + this.activeCam.id + '>video:first')
            if (video.length) {
                for (let i = 0; i < this.archives.length; i++) {
                    if (this.archives[i].timeend > timeLineTimeStamp) {
                        this.currentArchive_i = i
                        let shift = timeLineTimeStamp - this.archives[i].timestart
                        this.uploadArchiveVideo(this.archives[this.currentArchive_i], shift)
                        break
                    }
                }
            } else {
                message('Выберите камеру и период архивного видео')
            }
        },
        toggleArchiveVideo(flag) {
            if (this.activeCam) {
                this.$emit('changeArchiveStatus', flag)
            }
        },
        transferSnapsUp() {
            this.$refs['ArchiveRef'].transferSnaps()
        },
        transferSnaps(snapshotsObj) {
            this.$emit('transferSnaps', snapshotsObj)
        },
        cancelTransferSnaps() {
            this.$emit('cancelTransferSnaps')
        },
        camSubscribes(cam) {
            let res = ''
            if (cam.flag_company_name)
                res += window.getCookie('company_name') + ' / '
            if (cam.flag_object_name)
                res += this.getObjectName(cam.object_id) + ' / '
            if (cam.flag_cam_name)
                res += cam.name + ' / '
            if (cam.flag_user_id)
                res += window.getCookie('userFio') + ' / '
            if (cam.flag_comment && cam.comment)
                res += cam.comment + ' / '
            if (cam.posId)
                res += cam.posName + ' / '
            if (res.length)
                res = res.substring(0, res.length - 3)
            return res
        },
        showCamTemplates(cam) {
            this.templateCam = cam
            this.showTemplates = true
            this.templates = window.myTemplates
            window.setTimeout(this.moveTemplates, 100)
        },
        hideTemplates() {
            this.showTemplates = false
        },
        moveTemplates() {
            window.$('#template_list').css({
                'top': window.$('#' + this.templateCam.id).offset().top - 5 + 'px',
                'left': window.$('#' + this.templateCam.id).offset().left + window.$('#' + this.templateCam.id).width() - window.$('#template_list').width() - 55 + 'px',
                'opacity': 1
            })
        },
        getObjectName(obj_id) {
            let obj_name = ''
            for (let i = 0; i < window.myObjects.length; i++)
                if (window.myObjects[i].id == obj_id) {
                    obj_name = window.myObjects[i].name
                    break
                }
            return obj_name
        },
        working() {
//                message('работаем')
        },
        templateHasCam(template) {
            for (let i = 0; i < template.cameras.length; i++) {
                if (this.templateCam && template.cameras[i]) {
                    if (template.cameras[i].id == this.templateCam.id) {
                        return true
                    }
                }
            }
            return false
        },
        deleteCamFromTemplate(template, camera) {
            let url = '/api/detach-user-template',
                method = 'POST';
            this.templateCam = camera;
            this.theTemplate = template
            window.myAjax(method, url, {
                template_id: template.id,
                camera_id: this.templateCam.id
            }, this.myCallbackDeleteCamFromTemplate)
        },
        myCallbackDeleteCamFromTemplate(error, response) {
            if (error)
                message(response.message)
            else
                this.delCamFromTemplate()
        },
        delCamFromTemplate() {
            for (let i = 0; i < this.theTemplate.cameras.length; i++) {
                if (this.theTemplate.cameras[i]) {
                    if (this.theTemplate.cameras[i].id == this.templateCam.id) {
                        this.theTemplate.cameras.splice(i, 1)
                        break
                    }
                }
            }
        },
        camToTemplate(template, camera) {
            let url = '/api/attach-user-template',
                method = 'POST';
            this.templateCam = camera;
            this.theTemplate = template
            window.myAjax(method, url, {
                template_id: template.id,
                camera_id: this.templateCam.id
            }, this.myCallbackCamToTemplate)
        },
        myCallbackCamToTemplate(error, response) {
            if (error)
                message(response.message)
            else {
                this.theTemplate.cameras.push(this.templateCam)
            }
        },
        deleteSnapshot(i) {
            this.$refs['ArchiveRef'].deleteSnapshot(i)
        },
        createSnap() {
            this.exitFullscreenMainCam();
            this.$emit('createSnap', {});
        },
        createDev(archive_flag) {
            this.$emit('createDev', archive_flag);
        },
        createFrontSnapshot() {
            if(this.activeCam == null)
                return false
            let video = window.$('#hls-video')[0] || window.$('video:first')[0] || window.$('#' + this.activeCam.id + '>video:first')[0];
            if (!video) {
                video = window.$('.hls-video-loader-img')[0];
            }
            //var video = window.$('#60>video:first')[0]
            var canvas = document.createElement('canvas');
            canvas.width = (video.videoWidth || video.width) / this.controls.scale;
            canvas.height = (video.videoHeight || video.height) / this.controls.scale;
            var ctx = canvas.getContext('2d');
            //draw image to canvas. scale to target dimensions
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            //convert to desired file format
            var dataURI = canvas.toDataURL('image/jpeg'); // can also use 'image/png'
            // place it directly into an img element by just setting it to the src:
//                myImage.src = dataURI;
            let snapshotSelected = {
                img_id: null,
                img_src: dataURI,
                video_src: null,
                name: null,
                type_id: 1
            };
            this.$emit('showFrontSnapshot', snapshotSelected)
        },
        getCams() {
            this.camsLoading = true;
            window.myAjax('GET', '/api/client-cameras', {}, this.getCamsCallback);
        },
        getCamsCallback(error, response) {
            this.camsLoading = false;
            if (!error) {
                this.rawCams = response.client_cameras;
            }
        },
        sortCams() {
            this.sortedCams = [];
            for (let cam of this.rawCams) {
                if (this.objectCams.findIndex(c => c.id === cam.id ) >= 0) {
                    this.sortedCams.push(cam);
                }
            }
        },
        getUsualRecords() {
            if (!this.archiveEnabled) {
                return;
            }
            window.myAjax('GET', '/archive/records_availability', {}, (error, response) => {
                if (!error) {
                    const result = {};
                    (response || []).forEach( camId => {
                        result[camId] = true;
                    })
                    this.usualRecords = Object.assign({}, this.usualRecords, result);
                }
            });
        },
        getCurrentRecordURI() {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            var base_image = document.getElementById('smartAchive-id');
            canvas.width = base_image.width;
            canvas.height = base_image.height;
            ctx.drawImage(base_image, 0, 0, canvas.width, canvas.height);
            return canvas.toDataURL('image/png');
        },
        getSmartRecords() {
            if (!this.smartArchiveEnabled) {
                return;
            }
            window.myAjax('GET', '/smart_archive/records_availability', {}, (error, response) => {
                if (!error) {
                    const result = {};
                    (response || []).forEach( camId => {
                        result[camId] = true;
                    })
                    this.smartRecords = Object.assign({}, this.smartRecords, result);
                }
            });
        },
        getPtz() {
            this.ptzIndex = null;
            this.ptzPositions = {};
            this.smart_images = {};
            this.lastDayIndex = null;
            if (this.isSmart) {
                (this.objectCams || []).forEach( (cam, index, object) => {
                    if (cam.ptz_enabled) {
                        window.myAjax('GET', '/ptz/positions', {
                            camera_id: cam.id
                        }, (error, response) => {
                            if (!error) {
                                this.ptzPositions[cam.id] = (response.constructor || {}).name === 'Array' ? response : [];
                                var start = new Date();
                                start.setDate(start.getDate() - 1);
                                start.setUTCHours(0,0,0,0);
                                var end = new Date();
                                end.setUTCHours(23,59,59,999);
                                const data = Object.assign({
                                    camera_id: cam.id,
                                    start_time_in_sec: Math.floor(start.valueOf() / 1000),
                                    end_time_in_sec: Math.floor(end.valueOf() / 1000)
                                })
                                window.myAjax('GET', '/smart_archive/records', data, (sa_error, sa_response) => {
                                    if (!sa_error) {
                                        const days = (Object.keys(sa_response) || []).sort();
                                        if (days.length <= 0) {
                                            return;
                                        }
                                        const lastDayIndex = days[days.length - 1];
                                        this.lastDayIndex = lastDayIndex;
                                        for (let posId in sa_response[lastDayIndex]) {
                                            this.smart_images[cam.id] = sa_response[lastDayIndex];
                                            if (posId) {
                                                const finded = Object.assign({}, cam);
                                                const position = this.ptzPositions[cam.id].find( pos => pos.id == posId);
                                                finded.imageId = 'myImg' + cam.id + '-' + posId;
                                                finded.posName = position.name;
                                                finded.posId = posId;
                                                this.objectCams.push(finded);
                                                setTimeout(() => {
                                                    this.updateSmartForCam(cam.id, posId);
                                                }, 100)
                                            }
                                        }
                                    }
                                    object.splice(index, 1);
                                    if (this.timerId) {
                                        window.clearTimeout(this.timerId);
                                    }
                                    if (this.isSmart) {
                                        this.timerId = window.setTimeout(this.updateSmartImage, 100);
                                    }
                                });
                            }                     
                        })
                    }
                })
            }
        },
        havePermission(type) {
            return (window.direct_permissions || []).findIndex( (permission) => permission.name === type) > -1;
        },
        updateSmartForCam(camId, posId) {
            if (this.smart_images[camId]) {
                const timestamps = this.smart_images[camId];
                if (timestamps[posId]) {
                    let time_in_sec = timestamps[posId][timestamps[posId].length - 1];
                    // time_in_sec = time_in_sec.split('_')[0];
                    const src = `/smart_archive/snapshot?camera_id=${camId}&time_in_sec=${time_in_sec}&date=${this.lastDayIndex}`;
                    fetch(src, {
                        headers: {
                            'Authorization': 'Bearer ' + window.getCookie('userToken')
                        }
                    }).then(res => res.blob())
                        .then(blob => {
                            document.getElementById('myImg' + camId + '-' + posId).src = URL.createObjectURL(blob);
                        });
                }
            }
            
        },
    },
}
