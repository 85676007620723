import { render, staticRenderFns } from "./barcodesMonitoring.vue?vue&type=template&id=00f1d473&"
import script from "./_barcodes/monitoring.js?vue&type=script&lang=js&"
export * from "./_barcodes/monitoring.js?vue&type=script&lang=js&"
import style0 from "./_barcodes/monitoring.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports