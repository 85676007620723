import FilesDropArea from './../../files/droparea';
import { setChecklistLog } from './../../checklist/Utils/setChecklistLog';
import { create } from './../../Utils/guid';
import Comments from './../../digression/threads';
import rolesUtils from './../../roles/utils';

import { getDigressionTypeWatchers } from './../../adminSettings/watchers';
import {
    getDigressionWatchers,
    // addDigressionWatchers,
    // removeDigressionWatchers
} from './../../watchers/utils';


export default {
    name: 'DevCard',
    props: ['selectedObj', 'selectedDev', 'activeCam', 'snapshots', 'status', 'activeCard'],
    components: {
        FilesDropArea,
        Comments
    },
    data: () => ({
        showDops: false,
        chat: false,
        show_for_creator: true,
        show_for_responsible: false,
        dev: {
            id: null,
            name: null,
            time_start: null,
            time_start_str: null,
            time_finish: '25.06.2020 / 18:32',
            prior: 'Высокий',
            comment: null,
            snapshots: [],
            files: [],
            responsible: null,
            priorColor: 'selectRed',
            check_list_log_id: null,

            users: [],

            status_id: 1,
            priority_id: 1,
            object_id: null,
            zone_id: null,
            digression_id: null,
            responsible_id: null,
            camera_id: null,
            detect_date: null,
            detect_date_str: null,
            detect_date2: null,
            fix_before_date: null
        },
        time_shift: 86400000,
        watchers: [],
        admins: [],
        saved: false,
        supervisers: [],
        workers: [],
        newDev: true,
        snapshotSelected: {
            name: null,
            img_src: null,
            type_id: null,
            video_src: null
        },
        priors: [
            {
                id: 1,
                colorClass: 'selectRed',
                name: 'Высокий'
            },
            {
                id: 2,
                colorClass: 'selectYellow',
                name: 'Средний'
            },
            {
                id: 3,
                colorClass: 'selectGreen',
                name: 'Низкий'
            },
            {
                id: 4,
                colorClass: 'selectWhite',
                name: 'Чек-Лист'
            },
        ],
        showWorkers: true,
        showWatchers: true,
        showAdmins: false,
        showSupers: false,
        filesLength: 0,
        filesCount: 0,
        digressionWatchers: {},
        watchers1: [],
        watchers2: [],
        showWatchers2: false,
        isAdmin: false,
        detectDate: null,
        multiDigressions: true,
    }),
    mounted() {
        if (window.fromArchive && window.archiveDate) {
            this.detectDate = window.archiveDate;
        }
        if (window.checklistCardPoint) {
            this.multiDigressions = false;
        }
        this.isAdmin = rolesUtils.isRoleContained(['Администратор', 'Суперадминистратор']);
        document.addEventListener('dragenter', function(event) {
            const items = event.dataTransfer.items;
            if (items.length === 1) {
                // const file = event.dataTransfer.items[0];
            } else if (items.length > 1) {
                // todo добавление нескольких 
            }
        });
        // const inputElement = document.getElementById("inputFiles");
        // inputElement.addEventListener("change", this.handleFiles, false);
        this.myCallbackSaveObject = this.myCallbackSaveObject.bind(this);
        // this.handleFiles = this.handleFiles.bind(this);
        let now = new Date()
        this.dev.time_start = this.dateStr(now.getTime())
//        let newSnap = Object.assign({}, this.snapshot)
  //      newSnap.name = 'Скриншот от ' + this.dev.time_start
        this.dev.snapshots = this.snapshots
        /*
        this.dev.snapshots = []
        this.dev.videos = []
        this.dev.files = []
        for(let i=0; i<this.snapshots.length; i++) {
            switch(this.snapshots[i].type_id) {
                case 1:
                    this.dev.snapshots.push(this.snapshots[i])
                    break
                case 2:
                    this.dev.videos.push(this.snapshots[i])
                    break
                case 3:
                    this.dev.files.push(this.snapshots[i])
                    break
            }
        }
//        this.dev.snapshots = this.snapshots
//        this.dev.snapshots.push(newSnap)
        */
            this.time_shift = 1000*(this.selectedDev.time_days * 86400 +
                this.selectedDev.time_hours * 3600 +
                this.selectedDev.time_minutes * 60)
//        console.log(this.selectedDev)
            //      console.log(this.time_shift)

            this.dev.time_finish = this.dateStr(now.getTime()+this.time_shift)
            this.dev.detect_date = parseInt(now.getTime()/1000)
            this.dev.detect_date2 = parseInt(now.getTime()/1000)
            this.dev.fix_before_date = parseInt(now.getTime()/1000 + this.time_shift/1000)

        if (this.activeCam) {
            this.dev.camera_id = this.activeCam.id;
        }
        this.dev.object_id = this.selectedObj.id;
        this.dev.digression_id = this.selectedDev.id;
        this.dev.priority_id = this.selectedDev.priority_id;
        let pr = this.getPrior(this.selectedDev.priority_id);
        this.dev.prior = pr.name;
        this.dev.priorColor = pr.color;

        this.setWorkers();
        // this.setHistory();
        // window.setTimeout(this.getWatchers, 300);
    },
    methods: {
        setWorkers() {
            this.workers = [];
            if (this.dev.object_id && this.dev.digression_id) {
                window.myAjax('GET', '/api/digression-executors?object_id=' + this.dev.object_id + '&digression_id=' + this.dev.digression_id, {}, this.getWorkersCallback);
            }
        },
        getWorkersCallback(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                this.workers = [];
                this.setWatchers();
                const userId = window.getCookie("userId");
                for (let i=0; i < response.length; i++) {
                    const user = this.getUser(response[i]);
                    if (userId != user.id) {
                        this.workers.push(user)
                    }
                }
                if ((this.workers || []).length === 1 && !this.dev.id) {
                    this.setResponsible(this.workers[0]);
                }
            }
        },
        filesChangedHandler(data) {
            const self = this;
            
            this.filesLength = data.length;
            this.filesCount = 0;
            for (let i = 0; i < data.length; i++) {
                let url = '/preview/snapshot',
                    method = 'POST';
                window.myAjax(method, url, {image: data[i].result}, function(error, response) {
                    if (!error) {
                        self.sendImageCallback(response, data[i].file)
                    }
                });
            }
        },
        sendImageCallback(response, file) {
            this.filesCount++;
            if (this.filesCount >= this.filesLength) {
                this.filesLength = 0;
                this.filesCount = 0;
            }
            const _file = {
                type_id: 1,
                img_id: response.id,
                img_src: response.link,
                name: file.name || 'Изображение'
            } 
            this.addFile(_file);

        },
        filesDeletedHandler() {
            // todo
        },
        addFile(file) {
            this.dev.snapshots.push(file);
        },
        getPrior(priority_id) {
            let res = {
                name: 'Неопределен',
                color: ''
            }
            for(let i=0; i<this.priors.length; i++) {
                if(this.priors[i].id==priority_id) {
                    res.name = this.priors[i].name
                    res.color = this.priors[i].colorClass
                    break
                }
            }
            return res
        },
        working() {
//            window.myAlert('работаем...')
        },
        dateStr(ts) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(ts),

                options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
//              weekday: 'long',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric'//,
                    //second: 'numeric'
                },
                date_str = date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            return date_str;
        },
        deleteSnap(snapshot) {
            for(let i = 0; i < this.dev.snapshots.length; i++) {
                if (snapshot.img_id == this.dev.snapshots[i].img_id) {
                    this.dev.snapshots.splice(i, 1);
                }
            }
            window.myAjax('DELETE', '/preview/snapshot', {
                image_id: snapshot.img_id
            }, function() { /* ... */ })
        },
        deleteDev() {
            let url = '/api/digression-record',
                method = 'DELETE';
            window.myAjax(method, url, { id: this.dev.id }, this.myCallbackDeleteDev)
        },
        myCallbackDeleteDev (error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                window.myAlert(response.message)
                window.afterDelete = true;
                this.$emit('devDeleted')
            }
        },
        setStatus(status_id) {
            let url = '/api/digression-record-status',
                method = 'PUT';
            const obj = {};
            obj.status_id = status_id;
            this.dev = Object.assign({}, this.dev, obj);
            window.myAjax(method, url, {
                status_id: this.dev.status_id,
                id: this.dev.id,
            }, this.myCallbackSetStatus)
        },
        myCallbackSetStatus (error, response) {
            if(error) {
                window.myAlert(response.message)
            }
        },
        saveDev2(addNew, fromSave) {
            if (this.saved) {
                return;
            }
            if (this.dev.responsible_id) {
                const data = Object.assign({}, this.dev);
                let url = '/api/digression-record';
                let method = 'POST';
                if (this.dev.id) {
                    url = '/api/digression-record';
                    method = 'PUT';
                }
                data['watchers'] = [];
                for (let key in this.digressionWatchers) {
                    if (this.digressionWatchers[key]) {
                        data['watchers'].push(parseInt(key));
                    }
                }
                let detect_date;
                if (this.detectDate) {
                    detect_date = this.detectDate;
                } else {
                    const d = new Date();
                    let diff = d.getTimezoneOffset() / -60;
                    detect_date = new Date(data.detect_date2 * 1000);
                    detect_date.setHours(detect_date.getHours() - diff);
                }
                // window.detect_date = null;
                let detect_dateStr = detect_date.getFullYear() + '-' + ('0' + (detect_date.getMonth() + 1)).slice(-2) + '-' + ('0' + detect_date.getDate()).slice(-2) + ' '
                                    + detect_date.toLocaleString('ru', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric'
                                    });
                let fix_before_date = new Date(data.fix_before_date * 1000);
                let fix_before_dateStr = fix_before_date.getFullYear() + '-' + ('0' + (fix_before_date.getMonth() + 1)).slice(-2) + '-' + ('0' + fix_before_date.getDate()).slice(-2) + ' '
                                        + fix_before_date.toLocaleString('ru', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric'
                                        });
                data.detect_date = detect_dateStr;
                data.fix_before_date = fix_before_dateStr;
                // if (window.checklistsMode && window.checklistCardPoint) {
                //     this.digression.check_list_log_id = window.checklistLogId;
                // }
                if (!data.snapshots[0]) {
                    delete data['snapshots'];
                }
                if (data.camera_id === null || data.camera_id === undefined) {
                    delete data['camera_id'];
                }
                if (!data.id) {
                    delete data['id'];
                }
                if (!data.zone_id) {
                    delete data['zone_id'];
                }
                if (data.users) {
                    delete data['users'];
                }
                if (!data.name) {
                    delete data['name'];
                }
                delete data['responsible'];
                delete data['created_by'];
                // data.created_by = this.dev.created_by;
                if (data.watchers.length) {
                    data.watchers = JSON.stringify(data.watchers);
                }
                const snapshots = [];
                (data.snapshots || []).forEach( snap => {
                    snapshots.push({
                        name: snap.name,
                        img_id: snap.img_id,
                        img_src: snap.img_src,
                        video_src: snap.video_src,
                        type_id: snap.type_id,
                    })
                })
                if (snapshots.length) {
                    data.snapshots = JSON.stringify(snapshots);
                }
                this.saved = true;
                window.myAjax(method, url, data, (error, response) => {
                    this.saved = false;
                    if(error) {
                        window.myAlert(response.message || 'Ошибка сохранения. Попробуйте позже.')
                    } else {
                        if (window.checklistCardPoint) {
                            const params = {
                                primary_id: window.checklistCardPoint.id,
                                status: 'has_digressions',
                                session_id: window.sessionId || create(),
                                object_id: this.selectedObj.id,
                                digressions: [response.digressionsList.id]
                            };
                            setChecklistLog(params, function() {
                                setTimeout( () => {
                                    if (window.checklistId) {
                                        const params = { 
                                            id: window.checklistId,
                                            log: true,
                                            next: true
                                        };
                                        const updateEvent = new CustomEvent('update', { detail: params });
                                        document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                                    }
                                }, 100)
                                this.$emit('devCreatedTwo', { digression: response.digressionsList, addNew: addNew, fromSave: fromSave })
                            }.bind(this));
                        } else {
                            this.$emit('devCreatedTwo', { digression: response.digressionsList, addNew: addNew, fromSave: fromSave })
                        }
                    }
                })
            } else {
                window.myAlert('Не назначен ответственный исполнитель')
            }
        },

        
        getData() {
            return this.dev;
        },

        saveDev() {
            if (this.saved) {
                return;
            }
            if (this.activeCard) {
                this.saveDev2(false, true);
                return;
            }
            if (this.dev.responsible_id) {
                const data = Object.assign({}, this.dev);
                let url = '/api/create-digression-record';
                let method = 'POST';
                if (this.dev.id) {
                    url = '/api/update-digression-record';
                }
                data['watchers'] = [];
                for (let key in this.digressionWatchers) {
                    if (this.digressionWatchers[key]) {
                        data['watchers'].push(parseInt(key));
                    }
                }
                let detect_date;
                if (this.detectDate) {
                    detect_date = this.detectDate;
                } else {
                    const d = new Date();
                    let diff = d.getTimezoneOffset() / -60;
                    detect_date = new Date(data.detect_date2 * 1000);
                    detect_date.setHours(detect_date.getHours() - diff);
                }
                // window.detect_date = null;
                let detect_dateStr = detect_date.getFullYear() + '-' + ('0' + (detect_date.getMonth() + 1)).slice(-2) + '-' + ('0' + detect_date.getDate()).slice(-2) + ' '
                                    + detect_date.toLocaleString('ru', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric'
                                    });
                let fix_before_date = new Date(data.fix_before_date * 1000);
                let fix_before_dateStr = fix_before_date.getFullYear() + '-' + ('0' + (fix_before_date.getMonth() + 1)).slice(-2) + '-' + ('0' + fix_before_date.getDate()).slice(-2) + ' '
                                        + fix_before_date.toLocaleString('ru', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric'
                                        });
                data.detect_date = detect_dateStr;
                data.fix_before_date = fix_before_dateStr;
                // if (window.checklistsMode && window.checklistCardPoint) {
                //     this.digression.check_list_log_id = window.checklistLogId;
                // }
                if (!data.snapshots[0]) {
                    delete data['snapshots'];
                }
                if (data.camera_id === null || data.camera_id === undefined) {
                    delete data['camera_id'];
                }
                if (!data.id) {
                    delete data['id'];
                }
                if (!data.zone_id) {
                    delete data['zone_id'];
                }
                if (data.users) {
                    delete data['users'];
                }
                if (!data.name) {
                    delete data['name'];
                }
                delete data['responsible'];
                delete data['created_by'];
                // data.created_by = this.dev.created_by;
                if (data.watchers.length) {
                    data.watchers = JSON.stringify(data.watchers);
                }
                const snapshots = [];
                (data.snapshots || []).forEach( snap => {
                    snapshots.push({
                        name: snap.name,
                        img_id: snap.img_id,
                        img_src: snap.img_src,
                        video_src: snap.video_src,
                        type_id: snap.type_id,
                    })
                })
                if (snapshots.length) {
                    data.snapshots = JSON.stringify(snapshots);
                }
                this.saved = true;
                window.myAjax(method, url, data, this.myCallbackSaveObject)
            } else {
                window.myAlert('Не назначен ответственный исполнитель')
            }
        },
        myCallbackSaveObject (error, response) {
            this.saved = false;
            if(error) {
                window.myAlert(response.message || 'Ошибка сохранения. Попробуйте позже.')
            } else {
                if (window.checklistCardPoint) {
                    const params = {
                        primary_id: window.checklistCardPoint.id,
                        status: 'has_digressions',
                        session_id: window.sessionId || create(),
                        object_id: this.selectedObj.id,
                        digressions: [response.digressionsList.id]
                    };
                    setChecklistLog(params, function() {
                        window.myAlert(response.message)
                        this.dev.id = response.digressionsList.id
                        this.dev.name = response.digressionsList.name
                        this.dev.room_id = parseInt(response.digressionsList.room_id)
                        this.$emit('devCreated')
                    }.bind(this));
                } else {
                    window.myAlert(response.message)
                    this.dev.id = response.digressionsList.id
                    this.dev.room_id = parseInt(response.digressionsList.room_id)
                    this.$emit('devCreated')
                }
            }
        },
        setWatchers() {
            window.myAjax('GET', '/api/contacts?page=0&limit=1000', {}, this.setWatchersHandler);
        },
        setWatchersHandler(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                this.watchers = response.contacts;
                if (this.newDev) {
                    this.getDigressionTypeWatchers();
                } else {
                    this.getDigressionWatchers();
                }
            }
        },
        getDigressionWatchers() {
            this.digressionWatchers = {};
            getDigressionWatchers({id: this.dev.id}, this.getDigressionWatchersCallback)
        },
        getDigressionWatchersCallback(data) {
            this.watchers1 = [];
            this.watchers2 = this.watchers;
            console.log(this.watchers, this.watchers2);
            (data || []).forEach(watcher => {
                console.log(watcher.contact_id)
                const findIndex = this.watchers.findIndex( w => w.id == watcher.contact_id);
                if (findIndex >= 0) {
                    this.digressionWatchers[watcher.contact_id] = true;
                    this.watchers1.push( this.watchers[findIndex] );
                    const index = this.watchers2.map(e => e.id).indexOf(watcher.contact_id);
                    this.watchers2.splice(index, 1);
                }
            });

        },
        getDigressionTypeWatchers() {
            this.digressionWatchers = {};
            
            getDigressionTypeWatchers({
                digression_id: this.selectedDev.id,
                object_id: this.selectedObj.id
            }, this.getDigressionTypeWatchersCallback)
        },
        getDigressionTypeWatchersCallback(data) {
            this.digressionWatchers = {};
            const obj = {};
            this.watchers1 = [];
            this.watchers2 = this.watchers;
            (data || []).forEach(watcher => {
                const findIndex = this.watchers.findIndex( w => w.id == watcher.contact_id);
                if (findIndex >= 0) {
                    obj[watcher.contact_id] = true;
                    this.watchers1.push( this.watchers[findIndex] );
                    const index = this.watchers2.map(e => e.id).indexOf(watcher.contact_id);
                    this.watchers2.splice(index, 1);
                }
            });
            this.digressionWatchers = Object.assign({}, this.digressionWatchers, obj);
        },
        toggleDigressionWatcher(watcher) {
            const obj = {};
            obj[watcher.id] = !this.digressionWatchers[watcher.id];
            this.digressionWatchers = Object.assign({}, this.digressionWatchers, obj);
            // const params = {
            //     digression_list_id: this.dev.id,
            //     contact_id: watcher.id
            // };
            // if (this.digressionWatchers[watcher.id]) {
            //     addDigressionWatchers(params, this.addDigressionWatcherHandler)
            // } else {
            //     removeDigressionWatchers(params, this.removeDigressionWatcherHandler)
            // }
        },
        addDigressionWatcherHandler(data) {
            console.log(data);
        },
        removeDigressionWatcherHandler(data) {
            console.log(data);
        },
        setUsers() {
            this.dev.users = []
            for(let i=0; i<this.watchers.length; i++)
                if(this.watchers[i].relation_flag)
                    this.addUser(this.watchers[i])
        },
        isInWatchers(user) {
            if (user.id === this.dev.responsible_id) {
                return true;
            }
            return false;
        },
        addUser(user) {
            if(user.base_role=='Исполнитель') {
                if(this.thereIsWorker())
                    window.myAlert('Исполнитель может быть только один')
                else {
                    this.dev.users.push(user)
                    this.dev.responsible_id = user.id
                }
            } else
                this.dev.users.push(user)
        },
        setResponsible(user) {
            this.dev.responsible_id = user.id;
        },
        thereIsWorker() {
            let res = false
            for(let i=0; i<this.dev.users.length; i++) {
                if(this.dev.users[i].base_role=='Исполнитель') {
                    res = true
                    break
                }
            }
            return res
        },
        deleteUser(user) {
            for(let i=0; i<this.dev.users.length; i++) {
                if(this.dev.users[i].id==user.id) {
                    if(user.base_role=='Исполнитель')
                        this.dev.responsible_id = null
                    this.dev.users.splice(i, 1)
                    break
                }
            }
        },
        fullDevCard(dev) {
            this.dev = {};
            this.newDev = false;
            this.dev.time_start = dev.created_at;
            this.dev.detect_date = dev.detect_date;
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            let start_dateTime = new Date(this.dev.time_start);
            let detect_dateTime = new Date(this.dev.detect_date);
            start_dateTime.setHours(start_dateTime.getHours() + diff);
            detect_dateTime.setHours(detect_dateTime.getHours() + diff);
            this.dev.detect_date_str = detect_dateTime.getFullYear() + '-' +
                ('0' + (detect_dateTime.getMonth() + 1)).slice(-2) + '-' +
                ('0' + detect_dateTime.getDate()).slice(-2) + ' ' +
                detect_dateTime.toLocaleString('ru', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });
            this.dev.time_start_str = start_dateTime.getFullYear() + '-' +
                ('0' + (start_dateTime.getMonth() + 1)).slice(-2) + '-' +
                ('0' + start_dateTime.getDate()).slice(-2) + ' ' +
                start_dateTime.toLocaleString('ru', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });
            this.dev.time_finish = dev.fix_before_date
            this.dev.room_id = dev.room_id
            this.dev.comment = dev.comment
            this.dev.id = dev.id
            this.dev.name = dev.name
            this.dev.status_id = dev.status_id
            this.dev.camera_id = dev.camera_id
            this.dev.detect_date2 = parseInt((new Date(dev.detect_date)).getTime()/1000)
            this.dev.fix_before_date = parseInt((new Date(dev.fix_before_date)).getTime()/1000)
            this.dev.responsible = dev.responsible
            this.dev.responsible_id = dev.responsible_id;
            if (!this.dev.responsible) {
                this.dev.responsible = this.getUser(dev.responsible_id);
            }
            this.dev.created_by = this.getUser(dev.created_by);
            this.show_for_creator = dev.created_by == window.getCookie('userId');
            this.show_for_responsible = dev.responsible_id == window.getCookie('userId');
            this.dev.snapshots = dev.files
            this.setWatchers();
            this.setWorkers();
            // this.setHistory();
        },
        getUser(id) {
            for (let i = 0; i < window.myUsers.length; i++){
                if (id == window.myUsers[i].id) {
                    return window.myUsers[i];
                }
            }
            return null;
        },
        showScreenshot(snapshot) {
            this.snapshotSelected = Object.assign({}, snapshot);
            window.$("#modalViewScreenShotdev").modal('show')
        },
        closeScreenShotModal() {
            window.$("#modalViewScreenShotdev").modal('hide')
        },

        showChat() {
            if(this.dev.id)
                this.$store.dispatch('OPEN_CHAT_BY_ID', {openChat: true, showChatButton: false, openDialog: true, id: this.dev.room_id})
            else
                window.myAlert('Сначала сохраните отклонение')
        },

        getDevStatus(status_id) {
            let res = 'неопределен'
            for(let i=0; i<window.myDevStatuses.length; i++) {
                if(window.myDevStatuses[i].id==status_id) {
                    res = window.myDevStatuses[i].name
                    break
                }
            }
            return res
        },

        getDevStatusColor(status_id) {
            let res = ''
            switch(status_id) {
                case 1:
                    res = 'selectBlue'
                    break
                case 2:
                    res = 'selectRed'
                    break
                case 3:
                    res = 'selectYellow'
                    break
                case 4:
                    res = 'selectOrange'
                    break
                case 5:
                    res = 'selectGreen'
                    break
            }
            return res
        },

        cancelDev() {
            this.$emit('cancelDev')
        }

    }
}