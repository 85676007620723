import Vue from 'vue';

const TOKEN_AGE = 300000;

export default {
    name: 'Login',
    data: () => ({
        login: '',
        pw: '',
        newPw: '',
        newPwConfirm: '',
        errorLogin: '',
        errorNewPw: '',
        errorEmail: '',
        resetPwToken: null,
        isLogin: true
    }),
    created() {
        Vue.i18n.set(this.$store.state.curLanguage.short);
    },
    mounted() {
        const dsvLocale = window.getCookie('dsvLocale');
        const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
        
        Vue.i18n.set(this.$store.state.curLanguage.short);
        Vue.i18n.set(short);
        this.$store.commit('setLanguage', short);
        const now = new Date();
        const currentTimeZoneOffsetInHours = now.getTimezoneOffset() / -60;
        if (currentTimeZoneOffsetInHours) {
            window.setCookieTime(
                'timezone',
                this.getTimeZoneOffsetString(currentTimeZoneOffsetInHours)
            )
        }
        if(window.location.pathname=='/password_reset') {
            let params = window.location.search.split("&")
            this.resetPwToken = params[0].substring(7)
            this.login = params[1].substring(6).replace('%40', '@')
        }
    },
      methods: {
        getTimeZoneOffsetString(offsetValue) {
            const offset = offsetValue.toString();
            const timeZoneOffsetString = offsetValue < 0 ?
                (offset.length === 2 ? ('-0' + Math.abs(offsetValue)) : offset) :
                ( '+' + (offset.length === 1 ? ('0' + offset) : offset));
            return 'UTC' + timeZoneOffsetString + ':00'
        },
        resetPassword() {
            if(this.newPw.length==0) {
                this.errorNewPw = 'Не указан пароль'
            } else if(this.newPw!=this.newPwConfirm) {
                this.errorNewPw = 'Пароли не совпадают'
            } else {
                this.errorNewPw = ''
                let data = {
                        email: this.login,
                        password: this.newPw,
                        token: this.resetPwToken
                    },
                    //TODO
                    url = '/api/password/reset',
                    method = 'POST';
                window.myAjax(method, url, data, this.resetPWresponse)
            }
        },
        back() {
            this.resetPwToken = false;
            this.isLogin = true;
        },
        toReset() {
            this.isLogin = false;
        },
        resetPWresponse(error, response) {
            window.myAlert(response.message)
            if(error) {
                console.log('Ошибка! Код: ' + response.code +'Описание: ' + response.message)
            } else {
                this.errorLogin = ''
                window.userBaseRole = this.getUserBaseRole(((response.user || {}).roles || []))
                window.setCookieTime('userToken', response.user.token, 1)
                if(response.user.profile==undefined) {
                    window.dolgAndFio = window.userBaseRole
                    window.setCookieTime('userId', '', 1)
                    window.setCookieTime('userFio', '', 1)
                    window.setCookieTime('userBaseRole', window.userBaseRole, 1)
                }
                else {
                    window.dolgAndFio = response.user.profile.last_name + ' ' + response.user.profile.first_name
                    window.setCookieTime('userId', response.user.profile.user_id, 1)
                    window.setCookieTime('userFio', window.dolgAndFio, 1)
                    if (response.profile.client) {
                        window.setCookieTime('company_name', response.profile.client.name, 1)
                    }
                    window.setCookieTime('userBaseRole', window.userBaseRole, 1)
                }
                window.location.href = "/";
            }
        },
        getUserBaseRole (roles) {
            let baseRole = 'Исполнитель'
            for(let i=0; i<roles.length; i++) {
                if(roles[i].parent_id==null && roles[i].name=='super-admin') {
                    baseRole = 'Суперадминистратор'
                    break
                } else if(roles[i].parent_id==null && roles[i].name=='Администратор') {
                    baseRole = 'Администратор'
                    break
                } else if(roles[i].parent_id==null && roles[i].name=='Руководитель супервайзеров') {
                    baseRole = 'Руководитель супервайзеров'
                } else if(roles[i].parent_id==null && roles[i].name=='Супервайзер') {
                    baseRole = 'Супервайзер'
                }
            }
            return baseRole
        },
        routeMy() {
            this.getPasswordLink()
        },
        getPasswordLink() {
            if(window.isEmail(this.login)) {
                this.errorEmail = ''
                let data = {
                        email: this.login
                    },
                    //TODO
                    url = '/api/password/email',
                    method = 'POST';
                window.myAjax(method, url, data, this.myCallbackForgetPw)
            } else {
                this.errorEmail = 'Некорректный E-mail'
            }
        },
        login2() {
            if(this.validate()) {
                window.myAjax('POST', '/api/login', {
                    email: this.login,
                    password: this.pw
                }, this.myCallback1);
            }
        },
        myCallback1(error, response) {
            if(error) {
                if(response==undefined)
                    this.errorLogin = error
                else
                    this.errorLogin = response.message
            } else {
                this.errorLogin = ''
                window.userBaseRole = this.getUserBaseRole(response.user_roles);
                window.setCookie('userToken', response.token, 1);
                window.setCookie('refreshToken', response.refresh_token);
                window.direct_permissions = response.direct_permissions;
                window.userRoles = response.user_roles;
                if(response.profile==undefined) {
                    window.dolgAndFio = window.userBaseRole
                    window.setCookieTime('userId', '', 1)
                    window.setCookieTime('userFio', '', 1)
                    window.setCookieTime('userBaseRole', window.userBaseRole, 1)
                } else {
                    window.dolgAndFio = response.profile.last_name + ' ' + response.profile.first_name
                    window.setCookieTime('userId', response.profile.user_id, 1)
                    window.setCookieTime('userFio', window.dolgAndFio, 1)
                    if (response.profile.client) {
                        window.setCookieTime('company_name', response.profile.client.name, 1)
                    }
                    window.setCookieTime('userBaseRole', window.userBaseRole, 1)
                }
                this.nextRefreshToken();
                if (window.userBaseRole != 'Суперадминистратор') {
                    window.myAjax('GET', '/api/users', {}, (uerror, uresponse) => {
                        if (!uerror) {
                            const user = (uresponse.users || []).find(u => u.id == (response.profile || {}).user_id);
                            if (user) {
                                window.permissions = user.permissions || [];
                            }
                            if ((user.profile || {}).client_id) {
                                window.myAjax('GET', '/api/client_features', {
                                    client_id: user.profile.client_id
                                }, (cferror, cfresponse) => {
                                    if (!cferror) {
                                        window.features = cfresponse;
                                        this.Auth();
                                    }
                                });
                            }
                        }
                    });
                } else {
                    this.Auth();
                }
            }
        },
        nextRefreshToken() {
            setTimeout(() => {
                window.myAjax('POST', '/api/refresh', {
                    refresh_token: window.getCookie('refreshToken')
                }, (error, response) => {
                    if (!error && response) {
                        window.direct_permissions = response.direct_permissions;
                        window.setCookie('userToken', response.token);
                        window.setCookie('refreshToken', response.refresh_token);
                        this.nextRefreshToken();
                    }
                } )
            }, TOKEN_AGE)
        },
        myCallbackForgetPw (error, response) {
            if (error) {
                this.errorEmail = response.message
            } else {
                window.myAlert(response.message)
            }
        },
        validate() {
            let res = true;
            if(this.login.length) {
                if(window.isEmail(this.login)) {
                    if(this.pw.length) {
                        this.errorLogin = ''
                    } else {
                        res = false
                        this.errorLogin = 'Не указан пароль'
                    }
                } else {
                    res = false
                    this.errorLogin = 'Некорректный E-mail (логин)'
                }
            } else {
                res = false
                this.errorLogin = 'Не указан логин'
            }
            return res
        },

        Auth() {
            this.$emit('loginUser', window.userBaseRole=='Суперадминистратор' ? true: false)
            window.setTimeout(window.loginUser, 200)
            window.lastDevs = null;
            window.lastFilter = null;
        },
    }
}