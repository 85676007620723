var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.list.type === 'subgroup')?_c('input',{staticClass:"dsv-checklistTree__item-checkbox",attrs:{"type":"checkbox","id":'dsv-checklist-tree-checkbox-' + _vm.list.id,"value":"no"}}):_vm._e(),_c('label',{staticClass:"dsv-checklistTree__item-label",attrs:{"for":'dsv-checklist-tree-checkbox-' + _vm.list.id}},[_c('div',{class:[
                'dsv-checklistTree__item',
                _vm.adminMode ? 'dsv-checklistTree__item_admin' : 'dsv-checklistTree__item_viewed'
            ],attrs:{"id":_vm.list.id}},[_c('div',{class:[
                    'dsv-checklistTree__item-caption',
                    _vm.list.type !== 'point' ? 'dsv-checklistTree__item-caption_group' : '',
                    _vm.list.status ? 'dsv-checklistTree__item-caption_' + _vm.list.status : ''
                ],on:{"click":function($event){return _vm.clickHandler(_vm.list)}}},[_c('div',{staticClass:"dsv-checklistTree__item-caption-text"},[_vm._v(_vm._s(_vm.list.caption))]),(_vm.list.nodeg)?_c('div',{staticClass:"dsv-checklistTree__item-caption_nodeg",attrs:{"title":"К данному пункту чек-листа не привязаны отклонения. Обратитесь к вашему Администратору платформы."}},[_vm._v(" (нет отклонений) ")]):_vm._e()])])]),_c('div',{class:[ 'dsv-checklistTree__item-children',
        _vm.list.type === 'root' ? 'dsv-checklistTree__item-root-children' : '' ]},_vm._l((_vm.children),function(child){return _c('RootTemplate',{key:child.id,attrs:{"adminMode":_vm.adminMode,"list":child},on:{"click":_vm.clickHandler}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }