export function fullScreenStatus () {
    if (document.fullscreenElement ||
        document.mozFullscreenElement ||
        document.webkitFullscreenElement) {
        return true
    } else {
        return false
    }
}

export function requestFullscreen (element) {
    var ret = true;
    if (element.requestFullscreen) {
        element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
    } else {
        console.log('Fullscreen API is not supported.')
        ret = false;
    }
    return ret;
}

export function exitFullscreen () {
    if (document.exitFullscreen) {
        document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
    } else {
        console.log('Fullscreen API is not supported.')
    }
}