export default {
    name: 'PricesBannerTree',
    props: {
        // todo
    },
    components: {
        // todo
    },
    data: () => ({
        banners: [],
        loading: false,
        active: null
    }),
    mounted() {
        this.getBanners();
    },
    destroyed() {
        // todo
    },
    watch: {
        // todo
    },
    methods: {
        getBanners() {
            this.loading = true;
            window.myAjax('GET', '/price-tag/competitors', {}, this.getBannersHandler);
        },
        getBannersHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.banners = response;
            }
        },
        setActiveBanner(data) {
            this.getBanners();
            this.active = null;
            if ((data || {}).banner) {
                this.active = Object.assign({}, data.banner);
            }
        },
        selectHandler(banner) {
            this.active = Object.assign({}, banner);
            this.$emit('selected', {banner: banner});
        }
    }
}
