import LayoutTumbler from './../layoutTumbler';

export default {
    name: 'ControlPanel',
    components: {
        LayoutTumbler
    },
    props: {
        showMods: { type: Boolean, default: true },

        showSmart: { type: Boolean, default: true },
        showArchive: { type: Boolean, default: true },

        showLayout: { type: Boolean, default: true },
        showMap: { type: Boolean, default: true },

        showNeurals: { type: Boolean, default: true },
        showCreateDigression: { type: Boolean, default: true },

        extendedLayout: { type: Boolean, default: false },
        layout: { type: Number, default: 3 }
    },
    data: () => ({
        
        // todo
    }),
    watch: {
        // todo
    },
    mounted() {
        // todo
    },
    methods: {
        layoutHandler(data) {
            this.$emit('changed:layout', data);
        }
    }
}
