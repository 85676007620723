export default {
    name: 'ModalNew',
    props: {
        width: {
            type: Number,
            default: 1100
        },
        headerCaption: {
            type: String,
            default: null
        }
    },
    data: () => ({
        showUp: false,
        showDown: true
    }),
    mounted() {
        this.updateButtons();
        window.resize_ob = new ResizeObserver(() => {
            this.updateButtons();
        });
        window.resize_ob.observe(this.$el);
    },
    beforeDestroy() {
        window.resize_ob.unobserve(this.$el);
    },
    methods: {
        scroll(type) {
            this.$el.scrollTop = type === 'up' ? 0 : this.$el.scrollHeight;
        },
        updateButtons() {
            const scrollTop = this.$el.scrollTop || 0;
            const modalHeight = this.$el.scrollHeight || 10;
            const clientHeight = document.body.clientHeight || 1000;
            this.showUp = scrollTop > 20;
            this.showDown = clientHeight + scrollTop + 20 < modalHeight;
        }
    }
}
