export default {
    name: 'TabsGroup',
    components: {
        // todo
    },
    props: ['items', 'active'],
    data: () => ({
        // todo
    }),
    computed: {
        // todo
    },
    watch: {
        // todo
    },
    mounted() {
        // todo
    },
    methods: {
        // todo
    }
}
