// filter = function(obj) {
//     return Object.keys(obj).filter(function(id) {
//         return obj[id] === true
//     }).reduce( function(res, key) {
//         return res[key] = obj[key], res
//     }, {});
// }

export function filter(obj, predicate) {
    return Object.keys(obj).filter(function(key) {
        return predicate(obj[key]);
    }).reduce( function(res, key) {
        return res[key] = obj[key], res;
    }, {});
}
