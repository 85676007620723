/**
 * Удаление узла и вложенных чек-листов.
 * @param {deleteParams} params Параметры.
 * @param {function} callback Функция обратного вызова при успешном вызове. 
 */
export function getDigressions(params, callback) {
    const url = '/api/digressions';
    const method = 'GET';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert(response.message);
        } else {
            callback(response);
        }
    });
}

function startBuildTree(nodes, parent_id) {
    let oneLevelNodes = []
    for(let i=0; i<nodes.length; i++) {
      if(nodes[i].parent_id==parent_id ||
              (parent_id==0 && nodes[i].parent_id==null)) {
        let nextLevelNode = Object.assign({}, nodes[i])
        nextLevelNode.childrenNodes = startBuildTree(nodes, nextLevelNode.id)
        if(nextLevelNode.childrenNodes.length)
          nextLevelNode.hasChildren = 'hasChildren'
        else
          nextLevelNode.hasChildren = ''
        oneLevelNodes.push(nextLevelNode)
      }
    }
    return oneLevelNodes
}

export function getDigressionsTree(callback) {
    const abc = startBuildTree(window.myDevs, 0);
    callback(abc);
    // getDigressions(params, function(response) {
    //     callback(response);
    // })
}
