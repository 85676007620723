
// import BaseHelperList from './../../base/helperList';
// import ModalNew from './../../base/modalNew';
import CheckboxNew from './../../base/checkboxNew';

export default {
    name: 'EmailTemplatesSettings',
    components: {
        // BaseHelperList,
        // ModalNew,
        CheckboxNew,
    },
    props: {
        // todo
    },
    data: () => ({
        contactsLoading: false,
        emailsLoading: false,
        templatesLoading: false,
        emailTemplates: [],
        contacts: [],
        filteredContacts: [],
        emails: {},

        showedTemplates: [],

        created: false,
        edited: null,

        title: '',
        description: '',
        selectedEmails: [],

        searchContacts: '',
        selectedAllEmails: false,
    }),
    watch: {
        // todo
    },
    mounted() {
        this.getContacts();
    },
    methods: {
        getContacts() {
            this.contactsLoading = true;
            window.myAjax('GET', '/api/contacts?page=0&limit=1000', {}, (error, response) => {
                if (!error) {
                    this.contactsLoading = false;
                    this.contacts = (response.contacts || []);
                    this.contacts.forEach( contact => {
                        this.emails[contact.id] = [];
                    });
                    this.filteredContacts = this.contacts;
                    this.getEmails();
                }
            });
        },
        getEmails() {
            this.emailsLoading = true;
            window.myAjax('GET', '/api/emails', {}, (error, response) => {
                if (!error) {
                    this.emailsLoading = false;
                    const emails = (response.emails || []).filter( email => email.deleted_at === null);
                    // this.mapEmails = emails.filter( email => (email || {}).id > 0 );
                    emails.forEach( email => {
                        if (this.emails[email.contact_id]) {
                            this.emails[email.contact_id].push(email);
                        }
                    })
                    this.getTemplates();
                }
            });
        },
        getTemplates() {
            this.templatesLoading = true;
            window.myAjax('GET', '/api/email-templates', {}, (error, response) => {
                if (!error) {
                    this.templatesLoading = false;
                    this.emailTemplates = response;
                }
            });
        },
        saveTemplate() {
            const data = {
                template_id: this.edited,
                title: this.title,
                description: this.description,
                email_ids: JSON.stringify(this.selectedEmails || []),
            };
            window.myAjax(this.edited ? 'PUT' : 'POST', '/api/email-template', data, (error) => {
                if (error) {
                    window.myAlert('Ошибка при сохранении шаблона');
                } else {
                    this.cancelEdited();
                    this.getTemplates();
                }
            });            
        },
        checkEmail(emailId, checked) {
            if (checked) {
                this.selectedEmails.push(emailId);
            } else {
                this.selectedEmails.splice(this.selectedEmails.indexOf(emailId), 1);
            }
        },
        createTemplate() {
            this.created = true;
            this.edited = null;
        },
        editTemplate(templateId) {
            this.created = false;
            this.edited = templateId;
            const template = (this.emailTemplates || []).find(t => t.id === templateId);
            if (!template) {
                return;
            }
            this.title = template.title;
            this.description = template.description;
            this.selectedEmails = template.email_ids || [];
        },
        cancelCreated() {
            this.cancelEdited();
        },
        cancelEdited() {
            this.created = false;
            this.edited = null;
            this.title = '';
            this.description = '';
            this.selectedEmails = [];
            this.searchContacts = '';
            this.selectedAllEmails = false;
        },
        deleteTemplate(templateId) {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить список?', () => {
                window.myAjax('DELETE', '/api/email-template', {
                    template_id: templateId
                }, (error) => {
                    if (error) {
                        window.myAlert('Ошибка при удалении шаблона');
                    } else {
                        this.cancelEdited();
                        this.getTemplates();
                    }
                }); 
            })
        },
        toggleShowed(emailId) {
            const checked = this.showedTemplates.includes(emailId);
            if (checked) {
                this.showedTemplates.splice(this.showedTemplates.indexOf(emailId), 1);
            } else {
                this.showedTemplates.push(emailId);
            }
        },

        selectedAllEmailsHandler(checked) {
            if (checked) {
                this.selectedEmails = [];
                this.contacts.forEach( contact => {
                    this.emails[contact.id].forEach( email => {
                        this.selectedEmails.push(email.id);
                    })
                });
                this.selectedAllEmails = true;
            } else {
                this.selectedEmails = [];
                this.selectedAllEmails = false;
            }

        },

        searchContactsHandler() {
            if (this.searchContacts.length > 0) {
                this.filteredContacts = this.contacts.filter( contact => {
                    return contact.contact_fio.toLowerCase().indexOf(this.searchContacts.toLowerCase()) !== -1
                });
            } else {
                this.filteredContacts = this.contacts;
            }
        },
    }
}
