import Graph from './../graph';
import CheckboxList from './../../base/checkboxList';
import ModalNew from './../../base/modalNew';
import DigressionsSchedule from './../digressionsSchedule';

export default {
    name: 'DigressionsReports',
    components: {
        Graph,
        CheckboxList,
        ModalNew,
        DigressionsSchedule
    },
    data: () => ({
        isLoading: false,

        start_date: null,
        end_date: null,
        digressions: [],
        digressionsGroups: [],
        objects: [],

        selectedDigressions: [],
        selectedObjects: [],

        report: {
            total: 0,
            viewed: 0,
            edited: 0,
            v_percent: 0,
            e_percent: 0
        },

        digressionsList: [],
        totalCount: 0,

        itemsByTimes: {},
        itemsByDates: {},

        modalSettings: null,
        modalSchedule: null,
        groupByFormat: false
    }),
    mounted() {
        this.excelEnabled = (window.direct_permissions || []).findIndex( (permission) => permission.name === 'download analytic report') >= 0;
        window.myAjax('GET', '/api/digressions', {}, (error, response) => {
            if (!error) {
                this.digressions = (response.digressions || []).filter( digression => (!!digression.priority_id || !digression.parent_id) );
                this.digressionsGroups = (response.digressions || []).filter( digression => !digression.priority_id );

                this.digressions.sort((a, b) => a.name.localeCompare(b.name))
                this.digressionsGroups.sort((a, b) => a.name.localeCompare(b.name))
            }
        });
        window.myAjax('GET', '/api/objects', {}, (error, response) => {
            if (!error) {
                this.objects = (response.objects || []).filter( object => object.object_type === 'object' );
            }
        });
        this.resetFilter();
        this.getReports();
    },
    methods: {
        getExcel() {
            const data = {
                start_date: this.start_date ? new Date(this.start_date).getTime() / 1000 : null,
                end_date: this.end_date ? ((new Date(this.end_date).getTime() / 1000) + 86400) : null
            }
            if (data.start_date && data.end_date > data.end_timestamp) {
                window.myAlert('Дата ОТ больше даты ДО');
                return;
            }
            data.objects = this.selectedObjects.length > 0 ? JSON.stringify(this.selectedObjects) : null;
            data.digressions = this.selectedDigressions.length > 0 ? JSON.stringify(this.selectedDigressions) : null;
            if (this.groupByFormat) {
                data.group_by_format = true;
            }

            window.myAjax('POST', '/analytic/report/excel', data, (error, response) => {
                if (error) {
                    window.myAlert("Не удалось получить данные. Пожалуйста, попробуйте еще раз.");
                } else {
                    const url = response.excel_url;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'report_' + this.formatDate(this.start_date) + "_to_" + this.formatDate(this.end_date) + '.xlsx';
                    document.body.appendChild(a);
                    a.click();
                }
            })
        },
        formatDate(date) {
            let localDate = new Date(Date.parse(date.toString()));
            let dd = localDate.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = localDate.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = localDate.getFullYear() % 100;
            if (yy < 10) yy = '0' + yy;
            return dd + '.' + mm + '.' + yy;
        },
        resetFilter() {
            this.end_date = new Date();
            this.start_date = new Date();
            this.start_date.setMonth(this.start_date.getMonth() - 1);
            this.start_date = this.start_date.toISOString().substring(0,10);
            this.end_date = this.end_date.toISOString().substring(0,10);
            this.selectedObjects = [];
            this.selectedDigressions = [];
        },
        applyFilter() {
            this.getReports();
        },
        digressionCheckHandler(data) {
            this.selectedDigressions = data;
        },
        objectsCheckHandler(data) {
            this.selectedObjects = data;
        },
        getReports() {
            this.isLoading = true;
            const data = {
                start_date: this.start_date ? new Date(this.start_date).getTime() / 1000 : null,
                end_date: this.end_date ? ((new Date(this.end_date).getTime() / 1000) + 86400) : null
            }
            if (data.start_date && data.end_date > data.end_timestamp) {
                window.myAlert('Дата ОТ больше даты ДО');
                return;
            }
            data.objects = this.selectedObjects.length > 0 ? JSON.stringify(this.selectedObjects) : null;
            data.digressions = this.selectedDigressions.length > 0 ? JSON.stringify(this.selectedDigressions) : null;
            window.myAjax('GET', '/analytic/report_data', data, (error, response) => {
                this.isLoading = false;
                if (!error) {
                    this.digressionsList = response.percentage_list;
                    this.totalCount = response.total_digression_count;
                    this.itemsByDates = response.digressions_counts;
                    this.itemsByTimes = response.hours_average;
                }
            })
        },
        getReportsCallback(error, response) {
            this.isLoading = false;
            if(!error) {
                this.report = response;
                if (!Number.isInteger(this.report.viewed)) {
                    this.report.viewed = 0;
                }
                if (!Number.isInteger(this.report.edited)) {
                    this.report.edited = 0;
                }
                this.report.v_percent = this.report.total > 0 ? 
                    parseFloat((100 * this.report.viewed / this.report.total).toFixed(2)) : 0;
                
                this.report.e_percent = this.report.total > 0 ? 
                    parseFloat((100 * this.report.edited / this.report.total).toFixed(2)) : 0;
            }
        },
        togglerHandler(value) {
            this.groupByFormat = typeof value === 'boolean' ? value : !this.groupByFormat;
        }
    }
}