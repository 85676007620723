import TabsGroup from './../../base/tabsGroup';

import BannerList from './../bannerList';
import ObjectList from './../objectList';
import CategoryList from './../categoryList';
import BrandList from './../brandList';

// Справочники
import FormatList from './../formatList';
import UnitList from './../unitList';

// Карточки
import BannerCard from './../bannerCard';
import ObjectCard from './../objectCard';
import CategoryCard from './../categoryCard';
import ProductCard from './../productCard';
import ExcelButtons from './../excelButtons';

export default {
    name: 'PricesContent',
    props: ['selectedObj'],
    components: {
        TabsGroup,
        BannerList,
        BannerCard,
        ObjectCard,
        CategoryList,
        CategoryCard,
        ProductCard,
        ObjectList,
        FormatList,
        UnitList,
        ExcelButtons,
        BrandList,
    },
    data: () => ({
        lastTab: null,
        tab: 'banners', // 'banners' || 'objects' || 'categories',
        tabs: [
            {id: 'banners', caption: 'Конкуренты'},
            {id: 'objects', caption: 'Объекты мониторинга'},
            {id: 'categories', caption: 'Категории и товары'},
            {id: 'catalogs', caption: 'Справочники'},
            {id: 'brands', caption: 'Бренды'},
        ],

        // lists
        banners: [],
        objects: [],

        parent: null,

        // selected:
        banner: null,
        object: null,
        category: null,
        product: null,

        obj: null,

        productSearch: '',
        showProductSearchResults: false,
        productSearchResults: [],
        productSearchResultsLoading: false,
        
    }),
    mounted() {
        this.$emit('changeMenu', { type: 'banners' });
        // todo
        
    },
    destroyed() {
        this.$emit('changeMenu');
        // todo
    },
    methods: {
        selectedObjHandler(data) {
            this.$emit('selectedObjChanged', data);
        },
        setActiveBanner(data) {
            this.$emit('setActiveBanner', data);
        },
        selectBanner(data) {
            this.openBannerHandler(data);
        },
        setActiveCategory(data) {
            this.$emit('setActiveCategory', data);
        },
        selectCategory(data) {
            this.openCategoryHandler(data);
        },
        getBanners() {
            this.banners = [];
            window.myAjax('GET', '/price-tag/competitors', {}, this.getBannersHandler);
        },
        getBannersHandler(error, response) {
            if (!error) {
                this.banners = response;
            }
        },
        excelPostedHandler() {
            this.$emit('setActiveCategory');
        },
        tabClickHandler(tab) {
            this.tab = tab;
            // const type = this.tab === 'banners' ? 'objects' : 'categories';
            if (this.tab === 'banners') {
                this.category = null;
                this.product = null;
                this.object = null;
                this.$emit('changeMenu', { type: 'banners' });
            } else if (this.tab === 'objects') {
                this.banner = null;
                this.category = null;
                this.product = null;
                this.$emit('changeMenu');
            } else if (this.tab === 'categories') {
                this.banner = null;
                this.object = null;
                this.$emit('changeMenu', { type: 'categories' });
            } else if (this.tab === 'catalogs') {
                this.banner = null;
                this.object = null;
                this.category = null;
                this.product = null;
                this.$emit('changeMenu');
            }
        },
        showObjectCard(obj) {
            this.obj = Object.assign({}, obj);
            window.$('.dsv-nav__tree-object>p').removeClass('active');
            window.$('div[node_id='+obj.id+']:first>p:first').addClass('active');
            if (window.$('div[node_id='+obj.id+']:first').hasClass('opened')) {
                window.$('div[node_id='+obj.id+']:first').removeClass('opened');
            } else {
                window.$('div[node_id='+obj.id+']:first').addClass('opened');
            }
        },
        back() {
            if (this.object) {
                this.tab = this.lastTab;
                this.object = null;
            }
            if (this.banner) {
                this.tab = this.lastTab;
                this.banner = null;
                this.$emit('setActiveBanner');
                this.getBanners();
            }
            if (this.product) {
                this.category = this.parent;
                if (this.category) {
                    this.$emit('setActiveCategory', { category: this.category });
                } else {
                    this.$emit('setActiveCategory');
                }
                this.product = null;
            } else if (this.category) {
                this.$emit('setActiveCategory');
                this.tab = this.lastTab;
                this.category = null;
                this.product = null;
            }
        },
        openBannerHandler(data) {
            if (this.tab) {
                this.lastTab = this.tab;
                this.tab = null;
            }
            this.banner = Object.assign({}, data.banner);
            this.object = null;
        },
        openObjectHandler(data) {
            if (this.tab) {
                this.lastTab = this.tab;
                this.tab = null;
            }
            this.object = Object.assign({}, data.object);
        },
        openCategoryHandler(data) {
            if (this.tab) {
                this.lastTab = this.tab;
                this.tab = null;
            }
            if (data.category.children) {
                this.parent = this.category;
                this.category = Object.assign({}, data.category);
            } else {
                this.parent = this.category || { id: data.category.category_id };
                this.category = null;
                this.product = Object.assign({}, data.category);
            }
        },
        addProductHandler() {
            this.parent = this.category;
            this.category = null;
            this.product = {};
        },
        addCategoryHandler() {
            if (this.tab) {
                this.lastTab = this.tab;
                this.tab = null;
            }
            this.parent = this.category;
            this.category = {
                title: ''
            };
            this.product = null;
        },

        productSearchHandler() {
            if (this.productSearchResultsLoading) {
                return;
            }
            if (this.productSearch.length > 3) {
                // this.getProductSearch();
                this.productSearchResultsLoading = true;
                this.showProductSearchResults = true;
                window.myAjax('GET', '/price-tag/products', {
                    page: 0,
                    limit: 0,
                    search_title: this.productSearch
                }, (error, response) => {
                    if (!error) {
                        this.productSearchResults = response || [];
                        this.productSearchResultsLoading = false;
                    }
                });
            } else {
                this.showProductSearchResults = false;
                this.productSearchResultsLoading = false;
                return;
            }   
        },
        productResultHandler(product) {
            console.log('productResultHandler', product)
            this.lastTab = this.tab;
            this.tab = null;
            this.parent = { id: product.category_id };
            // this.$emit('setActiveCategory', { category: this.category });
            this.product = Object.assign({}, product);            
            this.showProductSearchResults = false;
            this.productSearchResultsLoading = false;
        },
    }
}
