<script src="./_root.js"></script>

<template>
    <div>
        <div v-for="option in options" :key="option.id">
            <template v-if="option.type === 'group' && !option.hide">
                <div class="dsv-checkboxList__group-header">
                    <CheckboxNew v-if="!singleOption" class="dsv-checkboxList__group-header-checkbox"
                        :checked="valueGroups.includes(option.id)"
                        :interim="interimGroups.includes(option.id)"
                        :label="(option.name || option.title)"
                        @click="toggleHidden(option.id)"
                        @update:checked="groupHandler(option.id, $event)"/>
                    <div v-else class="dsv-checkboxList__group-header-checkbox">{{ (option.name || option.title) }}</div>
                    <div class="dsv-checkboxList__group-header-hidder" @click="toggleHidden(option.id)">
                        {{ hiddenGroups.includes(option.id) ? 'показать' : 'скрыть' }}
                    </div>
                </div>
                <div class="dsv-checkboxList__group-items" v-if="!hiddenGroups.includes(option.id)">
                    <CheckboxListGroup 
                        :value="value"
                        :options="option.children"
                        :singleOption="singleOption"

                        :valueGroups="valueGroups"
                        :interimGroups="interimGroups"

                        :hiddenGroups="hiddenGroups"

                        @optionHandler="optionHandler"
                        @groupHandler="groupHandler"
                        @toggleHidden="toggleHidden"/>
                </div>
            </template>
            <CheckboxNew v-if="option.type !== 'group'"
                :checked="value.includes(option.id)"
                @update:checked="optionHandler(option.id, $event)"
                :fieldId="option.id"
                :label="option.name">
        </div>
    </div>
</template>

<script>
    import CheckboxNew from './../checkboxNew';

    export default {
        name: 'CheckboxListGroup',
        components: {
            CheckboxNew
        },
        data: () => ({
            //
        }),
        props: ['options', 'value', 'hiddenGroups', 'valueGroups', 'interimGroups', 'singleOption'],
        mounted() {
            // todo
        },
        methods: {
            optionHandler(optionId, value) {
                this.$emit('optionHandler', optionId, value);
                // console.log(optionId, value);
            },
            groupHandler(groupId, value) {
                this.$emit('groupHandler', groupId, value);
                // console.log(groupId, value);
            },
            toggleHidden(groupId) {
                this.$emit('toggleHidden', groupId);
                // console.log(groupId);
            }
        }
    }

</script>