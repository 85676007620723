import Checkbox from './../../../base/checkbox';

export default {
    name: 'ObjectsCamsList',
    components: {
        Checkbox
    },
    props: ['cams', 'objects','map'],
    data: () => ({
        // todo
    }),
    mounted() {
        // todo
    },
    methods: {
        toggleCamOnMap(camId) {
            this.$emit('update:map', camId);
        },
        check(camId, checked) {
            let updatedCams = [...this.cams];
            const index = updatedCams.findIndex(({ id }) => id === camId);
            if (index > -1) {
                updatedCams[index].status = checked ? 'Онлайн' : 'Оффлайн';
            }
            this.$emit('update:cams', updatedCams);
        },
        getZoneName(zoneId) {
            const zone = window.myObjects.find( object => object.id === zoneId );
            return zone ? `${zone.name}` : '-';
        }
    }
}
