<style scoped>
    .devCard {
        background: #FFFFFF;
        box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }
    .day {
        background-color: #fff;
        padding: 7px;
        border-radius: 7px;
    }
    .calendar_small {
        min-width: 400px;
    }
    input, select {
        border: 1px solid #81adac;
        border-radius: 5px;
    }
    select {
        min-width: 170px;
        filter: grayscale(1);
    }
    select:hover {
        filter: none;
    }
    .template_select {
        border: none;
    }
    .delete_contact_mail2 {
        background: url(../../assets/img/add.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px;
        height: 32px;
        transition: all .5s ease-out;
        cursor: pointer;

        -moz-transform: rotate(-45deg); /* Для Firefox */
        -ms-transform: rotate(-45deg); /* Для IE */
        -webkit-transform: rotate(-45deg); /* Для Safari, Chrome, iOS */
        -o-transform: rotate(-45deg); /* Для Opera */
        transform: rotate(-45deg);

    }
    .delete_contact_mail2:hover {
        -moz-transform: rotate(45deg); /* Для Firefox */
        -ms-transform: rotate(45deg); /* Для IE */
        -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
        -o-transform: rotate(45deg); /* Для Opera */
        transform: rotate(45deg);
    }
    .comment {
        width: 100%;
        height: 165px;
    }
</style>

<template>
    <div class="devCard mx-6 mb-8 px-6 py-5">
        <div class="row mx-0">
            <div class="col-6 headline mb-6">{{ $t('Расписание работы') }}</div>
            <div class="col-6 text-right mb-6">
                <select :disabled="task.id>0" class="template_select day" v-model="task.calendar_template_id" @change="filterCalendar">
                    <option value=0>{{ $t('Выбрать шаблон') }}</option>
                    <option v-for="template in calendar_templates"
                            v-bind:key="template.id"
                            :value="template.id"
                    >{{template.name}}</option>
                </select>

            </div>

            <CalendarSmall ref="CalendarSmallRef"
                   :dateP="date"
                   :error_dates="error.dates"
                   :dates="dates"
                   :task_start_date="task.start_datetime"
                   :task_end_date="task.end_datetime"
                   :calendar_template_id="task.calendar_template_id"
            />

            <div class="col my_bold row">
                <div class="col-lg-4">
                    <div class="mb-3">{{ $t('Время работы') }} *</div>
                    <div class="mb-3 row">
                        <div class="col-6 col-lg-12">
                            {{ $t('От') }}
                            <input :disabled="task.id>0" class="mx-2 pl-3 py-1" type="time" v-model="time1">
                        </div>
                        <div class="col-6 col-lg-12">
                            {{ $t('До') }}
                            <input :disabled="task.id>0" class="mx-2 pl-3 py-1" type="time" v-model="time2">
                        </div>
                    </div>
                    <div class="errors">{{ error.time }}</div>
                </div>
                <div class="col-lg-4">
                    <div class="mb-3">{{ $t('Объект') }} *</div>
                    <div class="mb-3">
                        <select :disabled="task.id>0" class="day" v-model="task.object_id" @change="filterZones">
                            <option value=0>{{ $t('Выберите') }}</option>
                            <option v-for="item in objects"
                                    v-bind:key="item.id"
                                    :value="item.id"
                            >{{item.name}}</option>
                        </select>
                    </div>
                    <div class="errors">{{ error.object }}</div>
                </div>
                
                <div class="col-lg-4">
                    <div class="mb-3">{{ $t('Зона') }}</div>
                    <div class="mb-3">
                        <select :disabled="task.id>0" class="day" v-model="task.zone_id">
                            <option value=0>{{ $t('Выберите') }}</option>
                            <option v-for="item in zones"
                                    v-bind:key="item.id"
                                    :value="item.id"
                            >{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div v-if="adminBaseRole" class="col-lg-12">
                    <div class="my_label">{{ $t('Ваш Комментарий') }}</div>
                    <textarea class="comment mt-2 px-2 border mb-4" v-model="task.creator_comment"></textarea>

                    <div class="my_label">{{ $t('Комментарий сотрудника') }}</div>
                    <textarea disabled class="comment mt-2 px-2 border mb-4" v-model="task.responsible_comment"></textarea>

                    <div class="mb-3">{{ $t('Сотрудник') }} *</div>
                    <div class="mb-5">
                        <select v-if="task.id==0" class="day mr-5 mb-4" v-model="selected_role_id" @change="filterUsers">
                            <option value=0 selected>{{ $t('Все роли') }}</option>
                            <option v-for="role in base_roles"
                                    v-bind:key="role.id"
                                    :value="role.id"
                            >{{role.name}}</option>
                        </select>
                        <select :disabled="task.id>0" class="day" v-model="task.user_id" @change="addUser">
                            <option value=0>{{ $t('Выберите сотрудника') }} *</option>
                            <option v-for="item in users"
                                    v-bind:key="item.id"
                                    :value="item.id"
                            >{{fio(item)}}</option>
                        </select>
                    </div>
                    <div class="errors">{{ error.user }}</div>
                    <div v-if="task.id==0">
                        <div v-for="user in selected_users"
                             v-bind:key="user.id"
                             class="row mx-0"
                        >
                            <div class="col px-0">{{fio(user)}}</div>
                            <div :title="$t('исключить пользователя')" class="col-1 delete_contact_mail2" @click="deleteUser(user)"></div>
                        </div>
                    </div>
                </div>
                <div v-else-if="rucsupBaseRole" class="col-lg-12">
                    <div class="my_label">{{ $t('Ваш Комментарий') }}</div>
                    <textarea class="comment mt-2 px-2 border mb-4" v-model="task.creator_comment"></textarea>

                    <div class="my_label">{{ $t('Комментарий сотрудника') }}</div>
                    <textarea disabled class="comment mt-2 px-2 border mb-4" v-model="task.responsible_comment"></textarea>

                    <div class="mb-3">{{ $t('Сотрудник') }} *</div>
                    <div class="mb-5">
                        <select v-if="task.id==0" class="day mr-5 mb-4" v-model="selected_group_id" @change="filterUsers2">
                            <option value=0 selected>{{ $t('Все группы') }}</option>
                            <option v-for="role in groups"
                                    v-bind:key="role.id"
                                    :value="role.id"
                            >{{role.name}}</option>
                        </select>
                        <select :disabled="task.id>0" class="day" v-model="task.user_id" @change="addUser">
                            <option value=0>{{ $t('Выберите супервайзера') }} *</option>
                            <option v-for="item in users"
                                    v-bind:key="item.id"
                                    :value="item.id"
                            >{{fio(item)}}</option>
                        </select>
                    </div>
                    <div class="errors">{{ error.user }}</div>
                    <div v-if="task.id==0">
                        <div v-for="user in selected_users"
                             v-bind:key="user.id"
                             class="row mx-0"
                        >
                            <div class="col px-0">{{fio(user)}}</div>
                            <div :title="$t('исключить пользователя')" class="col-1 delete_contact_mail2" @click="deleteUser(user)"></div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-lg-12">
                    <div class="my_label">{{ $t('Ваш Комментарий') }}</div>
                    <textarea class="comment mt-2 px-2 border mb-4" v-model="task.responsible_comment"></textarea>
                </div>
            </div>
        </div>

        <hr class="mt-5"/>
        <div class="row mx-0 pb-4">
            <div class="col-12 px-9 row mx-0 justify-content-end">
                <div class="my_btn btn_delete mb-3" @click="cancelTask">{{ $t('Отмена') }}</div>
                <div class="my_btn btn_green ml-8 mb-3" :title="$t('сохранить изменения')" @click="saveTask">{{ $t('Сохранить') }}</div>
            </div>
        </div>


    </div>
</template>

<script>
    import CalendarSmall from '../../components/calendar/CalendarSmall';

    export default {
        name: 'Task',
        components: {
            CalendarSmall
        },
        props: ['task', 'date', 'adminBaseRole', 'rucsupBaseRole'],
    data: () => ({
        selected_role_id: 0,
        base_roles: [],
        selected_group_id: 0,
        groups: [],
        calendar_templates: [],
        users: [],
        selected_users: [],
        objects: [],
        zones: [],
        time1: '09:00',
        time2: '18:00',
        dates: {
            start: null,
            end: null
        },
        error: {
            object: '',
            time: '',
            user: '',
            dates: ''
        }
    }),
    mounted() {
        this.calendar_templates = window.myCalendarTemplates
        this.selected_users = []
        if(this.adminBaseRole) {
            this.base_roles = []
            for(let i=0; i<window.myRoles.length; i++)
                if(window.myRoles[i].parent_id==null)
                    this.base_roles.push(window.myRoles[i])
            this.filterUsers()
        } else if(this.rucsupBaseRole) {
            this.groups = window.myGroups
            this.filterUsers2()
        } else {
            this.task.user_id = window.getCookie('userId')
            this.selected_users.push({
                id: this.task.user_id
            })
        }
        this.objects = []
        for(let i=0; i<window.myObjects.length; i++)
            if(window.myObjects[i].object_type=='object')
                this.objects.push(window.myObjects[i])
        if(this.task.id) {
            this.selected_users.push({
                id: this.task.user_id
            })
            this.time1 = this.timeStr(new Date(this.task.start_datetime))
            this.time2 = this.timeStr(new Date(this.task.end_datetime))
            if(this.task.zone_id) {
                let zone = this.task.zone_id
                this.filterZones()
                this.task.zone_id = zone
            }
            if(this.task.calendar_template_id==null)
                this.task.calendar_template_id = 0
        }
    },
    methods: {
        timeStr(date) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let options = {
                hour: 'numeric',
                minute: 'numeric'//,
            }
            return date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00;
        },

        filterUsers2() {
            this.users = [{
                id: window.getCookie('userId'),
                fio: window.getCookie('userFio')
            }]
            if(this.selected_group_id>0) {
                let group = this.getSelectedGroup()
                if(group)
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id)
                                this.users.push(window.myUsers[i])
            } else {
                for (let k = 0; k < window.myGroups.length; k++) {
                    let group = window.myGroups[k]
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id && this.userNotInUsers(window.myUsers[i]))
                                this.users.push(window.myUsers[i])
                }
            }
        },
        getSelectedGroup() {
            for (let k = 0; k < window.myGroups.length; k++)
                if(window.myGroups[k].id==this.selected_group_id)
                    return window.myGroups[k]
            return null
        },
        userNotInUsers(user) {
            for (let k = 0; k < this.users.length; k++)
                if(this.users[k].id==user.id)
                    return false
            return true
        },

        fio(user) {
            if(user.profile) {
                let res = user.profile.last_name + ' ' + user.profile.first_name
                if(user.profile.middle_name)
                    res += ' ' + user.profile.middle_name
                return res
            } else
                return user.fio
        },
        working() {
//            window.myAlert('работаем...')
        },

        filterCalendar() {
            window.setTimeout(this.$refs['CalendarSmallRef'].setRangeDays, 100)
        },
        addUser() {
            this.error.user = ''
            if(this.theUserNotSelected())
                for(let i=0; i<this.users.length; i++)
                    if((this.users[i].profile!=undefined || this.users[i].fio!=undefined) && this.task.user_id==this.users[i].id)
                        this.selected_users.push(this.users[i])
        },
        theUserNotSelected() {
            for(let i=0; i<this.selected_users.length; i++)
                if(this.task.user_id==this.selected_users[i].id)
                    return false
            return true
        },
        deleteUser(user) {
            for(let i=0; i<this.selected_users.length; i++)
                if(user.id==this.selected_users[i].id) {
                    this.selected_users.splice(i, 1)
                    break
                }
        },
        filterZones() {
            this.error.object = ''
            this.zones = []
            for(let i=0; i<window.myObjects.length; i++)
                if(window.myObjects[i].object_type=='zone' && window.myObjects[i].parent_id==this.task.object_id)
                    this.zones.push(window.myObjects[i])
        },
        filterUsers() {
            this.users = []
            for(let i=0; i<window.myUsers.length; i++) {
                if(window.myUsers[i].profile!=undefined && (this.selected_role_id==0 || this.userHasBaseRole(window.myUsers[i])))
                    this.users.push(window.myUsers[i])
            }
        },
        userHasBaseRole(user) {
            for(let i=0; i<user.roles.length; i++)
                if(this.selected_role_id==this.getBaseRoleId(user.roles[i]))
                    return true
            return false
        },
        getBaseRoleId(role) {
            if(role.parent_id==null)
                return role.id
            for(let i=0; i<window.myRoles.length; i++)
                if(window.myRoles[i].id==role.parent_id)
                    return this.getBaseRoleId(window.myRoles[i])
            return 0
        },

        valideTask() {
            let res = true
            if(this.time1.length && this.time2.length) {
                this.error.time = ''
            } else {
                res = false
                this.error.time = 'Укажите время'
            }
            if(this.task.object_id) {
                this.error.object = ''
            } else {
                res = false
                this.error.object = 'Не выбран объект'
            }
            if(this.selected_users.length) {
                this.error.user = ''
            } else {
                res = false
                this.error.user = 'Не выбран пользователь'
            }
            if(this.dates.start && this.dates.end) {
                this.error.dates = ''
                this.task.start_datetime = parseInt(new Date(this.dates.start.getFullYear()+'-'+
                    (this.dates.start.getMonth()+1) + '-' + this.dates.start.getDate() +
                    ' ' + this.time1).getTime()/1000)
                this.task.end_datetime = parseInt(new Date(this.dates.end.getFullYear()+'-'+
                    (this.dates.end.getMonth()+1) + '-' + this.dates.end.getDate() +
                    ' ' + this.time2).getTime()/1000)
            } else {
                res = false
                this.error.dates = 'Не выбраны даты'
            }

            return res
        },
        saveTask() {
            if (this.task.id)
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения графика работы?', this.saveTask2)
            else
                this.saveTask2()
        },
        saveTask2() {
            if(this.valideTask()) {
                let url = '/api/create-calendar-event',
                    method = 'POST';
                if (this.task.id)
                    url = '/api/update-calendar-event'
                else
                    for(let i=0; i<this.selected_users.length; i++)
                        this.task.user_ids.push(this.selected_users[i].id)

                window.myAjax(method, url, this.task, this.myCallbackSaveTask)
            }
        },
        myCallbackSaveTask (error, response) {
            window.myAlert(response.message)
            if(!error) {
                this.$emit('getCalendarTasks')
            }
        },
        cancelTask() {
            this.$emit('cancelTask')
        }
    }
  }
</script>
