export default {
    'Активировать камеру': 'Activate Camera',
    'Действия': 'Actions',
    'Добавить камеру на карту': 'Add Camera on Map',
    'добавить узел-потомок': 'add child node',
    'добавить объект-потомок': 'add child unit',
    'Добавить камеру': 'Add new Camera',
    'добавить камеру к шаблону': 'Add new Camera to Display Template',
    'добавить новое отклонение': 'add new deviation',
    'добавить новую группу отклонений': 'add new deviation group',
    'Добавить группу': 'Add new Group',
    'Добавить пункт': 'Add new Item',
    'добавить новую роль': 'add new role',
    'добавить супервайзеров': 'Add new Supervisors',
    'добавить зону внутри объекта': 'add new zone inside unit',
    'Добавить второй канал': 'Add Second Stream',
    'добавить объект': 'add units',
    'Адрес': 'Address',
    'Администратор': 'Administrator',
    'Администраторы': 'Administrators',
    'Все группы': 'All Groups',
    'Все роли': 'All Roles',
    'Все супервайзеры': 'All Supervisors',
    'Все объекты': 'All Units',
    'Все пользователи': 'All Users',
    'Аналитика': 'Analitics',
    'Применить': 'Apply',
    'Прикрепить файл': 'Attach Files',
    'Прикрепленные файлы': 'Attached Files',
    'Назад': 'Back',
    'Назад к авторизации': 'Back to Login',
    'перемотка к началу воспроизведения': 'back to start',
    'Базовая роль пользователя': 'Base role',
    'привяжите контакт к объекту': 'bind contact to an unit',
    'привязать контакт к объекту': 'bind contact to an unit',
    'Краткое пояснение': 'Brief Explanation',
    'По пользователям': 'By users',
    'Камера': 'Camera',
    'адрес камеры': 'Camera Address',
    'адрес второго канала камеры': 'Camera Address (second stream)',
    'Подписи к камере': 'Camera Captions',
    'Камера не выбрана': 'Camera is not selected',
    'Название камеры': 'Camera Name',
    'Камеры': 'Cameras',
    'список камер': 'Cameras List',
    'Отмена': 'Cancel',
    'Отменить': 'Cancel',
    'Категории': 'Categories',
    'заменить карту': 'Change Map',
    'чат': 'Chat',
    'Доступные для редактирования чек-листы': 'Check lists available for editing',
    'Чек-лист': 'Checklist',
    'Чек-листы': 'Checklists',
    'Город': 'City',
    'Очистить': 'Clear',
    'вход в ЛК клиента': 'client login',
    'список клиентов': 'Clients List',
    'Закрыть': 'Close',
    'Закрыть карточку камеры и вернуться к списку': 'Close the window and return to the list',
    'колонка': 'column',
    'колонок': 'column',
    'колонки': 'columns',
    'Ваш Комментарий': 'Comment',
    'Комментарий': 'Comment',
    'Компания': 'Company',
    'Завершить': 'Complete',
    'Выполнено': 'Completed',
    'Контактные': 'Contact ',
    'Контактное лицо': 'Contact Person',
    'Контактные телефоны': 'Contact Phone Numbers',
    'Контакты': 'Contacts',
    'Продолжить': 'Continue',
    'Создать отклонение': 'Creare Deviation',
    'Создать новый чек-лист': 'Create New Checklist',
    'Создать новый корневой узел чек-листа': 'Create New Checklist Root Item',
    'Создать новый шаблон': 'Create new Display Template',
    'Создать новую группу супервайзеров': 'Create new Group of Supervisors',
    'Создать нового пользователя': 'Create New User',
    'Создано': 'Created at',
    'Клиенты': 'Customers',
    'Дата': 'Date',
    'Дата / время': 'Date / Time',
    'День, Дни': 'Day, Days',
    'дней': 'days',
    'Деактивировать камеру': 'Deactivate Camera',
    'уменьшить скорость воспроизведения': 'decrease playback speed',
    'по умолчанию': 'default',
    'телефон по умолчанию': 'Default Phone Number',
    'шаблон по умолчанию': 'default template',
    'Делегированные права': 'Delegated Permissions',
    'Удалить': 'Delete',
    'удалить камеру из шаблона': 'Delete Camera from Display Template',
    'Удалить чек-лист': 'delete Checklist',
    'удалить телефон по умолчанию': 'delete default phone',
    'удалить шаблон': 'Delete Display Template',
    'удалить группу': 'Delete Group',
    'удалить карту': 'Delete Map',
    'удалить телефон': 'delete phone',
    'Удалить второй канал': 'Delete Second Stream',
    'Отвязать камеру от объекта/зоны': 'Delink Camera from Unit/Zone',
    'Описание': 'Description',
    'Подробное описание для отображения в окне “Справка”': 'Detailed Descritpion',
    'Отклонение обнаружено': 'Detected at',
    'Отклонение': 'Deviation',
    'Карточка отклонения (типа/группы)': 'Deviation Card (Deviation Type Card/Deviation Group Card)',
    'Группа отклонений': 'Deviation Group',
    'Название отклонения и его положение в иерархии': 'Deviation Name and its Position in the List',
    'Отклонение №': 'Deviation №',
    'Тип': 'Deviation Type',
    'Тип отклонений': 'Deviation Type',
    'Отклонения': 'Deviations',
    'Название шаблона': 'Display Template Name',
    'Скачать': 'Download',
    'скачать отчёт в Excel': 'Download Report in Excel',
    'скачать журнал в Excel': 'Download to Excel',
    'Перетащите в эту область файлы для прикрепления': 'Drag files to this area to attach',
    'редактировать шаблон': 'Edit Display Template',
    'Название элемента': 'Element Name',
    'Почта': 'Email',
    'Сначала привяжите контакт к объекту': 'First, link the Contact to the Unit',
    'Устранить до': 'Fix till',
    'за': 'fo',
    'На доработку': 'For Revision',
    'Забыли пароль?': 'Forgot the Password?',
    'Пт': 'Fr',
    'Пятница': 'Friday',
    'От': 'From',
    'от, до': 'from, to',
    'ФИО': 'Full Name',
    'Группа': 'Group',
    'Группы': 'Groups',
    'Скрыть камеры': 'Hide Cameras',
    'Час, Часы': 'Hour, Hours',
    'ИЖК': 'ICL',
    'Иконка': 'Icon',
    'Исполнение': 'Implement',
    'Исполнители': 'Implementer',
    'включены': 'included',
    'увеличить скорость воспроизведения': 'increase playback speed',
    'IP адрес камеры': 'IP address of Camera',
    'IP адрес (второго канала) камеры': 'IP address of Camera (second stream)',
    'Должность': 'Job Title',
    'Журнал': 'Journal',
    'Последняя активность': 'Last Activity',
    'В прошлый раз заполнение чек-листа не было завершено. Продолжить заполнение начатого?': 'Last time, filling out the checklist was not completed. Continue filling in what you started?',
    'Привязать камеру к объекту/зоне': 'Link Camera to Unit/Zone',
    'Привязка чек-листов': 'Linking checklists',
    'Привязать отклонение': 'Linking deviations',
    'Привязка отклонений к пункту чек-листа': 'Linking deviations to a checklist item',
    'список типов отклонений, доступных на этом объекте': 'List of Deviation Types available at this Unit',
    'Загрузка': 'Loading',
    'Войти': 'Log in',
    'Логин': 'Login',
    'Логин (второго канала)': 'Login (second stream)',
    'Главное контактное лицо': 'Main Contact',
    'сделать скриншот': 'make screenshot',
    'Производитель': 'Manufacturer',
    'Максимальное разрешение': 'Max Resolution',
    'Отчество': 'Middle Name',
    'Минута, Минуты': 'Minute, Minutes',
    'минут': 'minutes',
    'Пн': 'Mo',
    'Пн, Вт, Ср, Чт, Пт, Сб, Вс': 'Mo, Tu, We, Th, Fr, Sa, Su',
    'Модель': 'Model',
    'Понедельник': 'Monday',
    'Имя': 'Name',
    'Название': 'Name',
    'Наименование': 'Name',
    'Новое отклонение': 'New Deviation',
    'У вас есть новые сообщения': 'New Messages',
    'Нет доступных отклонений': 'No Deviations Available',
    'Нет сигнала': 'No Signal',
    'Примечание': 'Note',
    'Уведомления': 'Notifications',
    'Оффлайн': 'Offline ',
    'Ок': 'Ok',
    'Онлайн': 'Online',
    'открыть интерфейс привязки отклонений': 'Open the Deviations Linking Interface',
    'Пароль': 'Password',
    'Пароль (второго канала)': 'Password (second stream)',
    'Права': 'Permissions',
    'Телефон': 'Phone Number',
    'Телефоны': 'Phone Numbers',
    'Укажите': 'Please, specify the ',
    'Укажите email, который вы использовали при регистрации, мы вышлем вам новый пароль': 'Please, specify the email you used when registering, we ill send you a new password',
    'Порт': 'Port',
    'Порт (второго канала)': 'Port (second stream)',
    'Предварительный просмотр': 'Preview',
    'Приоритет': 'Priority',
    'Кол': 'Quantity',
    'Кол.': 'Quantity',
    'исключить пользователя': 'Remove User',
    'Отчёты': 'Reports',
    'Сбросить': 'Clear',
    'сбросить скорость': 'reset playback speed',
    'Ответственный': 'Responsible',
    'Роль': 'Role',
    'Роли': 'Roles',
    'Сб': 'Sa',
    'Суббота': 'Saturday',
    'Сохранить': 'Save',
    'Сохранить камеру': 'Save Camera',
    'сохранить изменения': 'save changes',
    'сохранить шаблон': 'Save Display Template',
    'Поиск': 'Search',
    'второго канала камеры': 'second camera stream',
    'Выберите': 'Select',
    'Выберите базовую роль': 'Select Base Role',
    'Выбрать шаблон': 'Select Display Template',
    'Выберите сотрудника': 'Select Staff Member',
    'Выберите супервайзера': 'Select Supervisor',
    'Выберите объект/зону или шаблон из списков слева': 'Select Unit / Zone or Display Template from the Left List',
    'Выберите объект из списка слева, с которым будете работать по чек-листу': 'Select Unit from the Left List',
    'Для просмотра списка камер, выберите объект или зону в левой панели навигации по объектам': 'Select Unit/Zone from the Left List to See it\'s Cameras List',
    'Отправить': 'Send',
    'Задать': 'Set',
    'Установить пароль': 'Set Password',
    'Доля': 'Share',
    'Список объектов': 'Units List',
    'Показ локального архива': 'Showing a Remote Archive',
    'Сотрудник': 'Staff Member',
    'Комментарий сотрудника': 'Staff Member\'s Comment',
    'Начать новый': 'Start New List',
    'Взять в работу': 'Start Processing',
    'Статус': 'Status',
    'Качество потока': 'Stream Quality',
    'Вс': 'Su',
    'Воскресенье': 'Sunday',
    'Супервайзер': 'Supervisor',
    'Супервайзеры': 'Supervisors',
    'Фамилия': 'Surname',
    'Шаблоны': 'Templates',
    'Чт': 'Th',
    'По выбранному фильтру отсутствуют отклонения.': 'There are no Deviations for this filter',
    'По выбранному фильтру отсутствуют чек-листы.': 'There are no Checklists for this filter',
    'Четверг': 'Thursday',
    'время': 'time',
    'Время на устранение отклонения': 'Time left to fix deviation',
    'Таймер': 'Timer',
    'График': 'Timetable',
    'До': 'to',
    'Вт': 'Tu',
    'Вторник': 'Tuesday',
    'Объект': 'Unit',
    'Адрес объекта': 'Unit Address',
    'Карта объекта': 'Unit Card',
    'Название объекта': 'Unit Name',
    'Объекты': 'Units',
    'Объекты / Камеры': 'Units / Cameras',
    'Объекты / Зоны': 'Units / Zones',
    'Объекты / Зоны / Камеры': 'Units / Zones / Cameras',
    'URL камеры': 'URL of Camera',
    'URL (второго канала) камеры': 'URL of Camera (second stream)',
    'Пользователь': 'User',
    'Учетная запись': 'User Account',
    'Пользователи': 'Users',
    'Список всех пользователей': 'Users List',
    'список пользователей': 'Users List',
    'архив видео': 'video archive',
    'запись видео (старт/стоп)': 'video recording (start/stop)',
    'Ср': 'We',
    'Среда': 'Wednesday',
    'Расписание работы': 'Work Schedule',
    'Время работы': 'Work Time',
    'Зона': 'Zone',
    'Название зоны объекта': 'Zone Name',
    'Зоны': 'Zones',
    'Выход': 'Logout',
    'Сначала новые': 'By date (newest)',
    'Сначала старые': 'By date (oldest)',

    'Январь': 'January',
    'Февраль': 'February',
    'Март': 'March',
    'Апрель': 'April',
    'Май': 'May',
    'Июнь': 'June',
    'Июль': 'July',
    'Август': 'August',
    'Сентябрь': 'September',
    'Октябрь': 'October',
    'Ноябрь': 'November',
    'Декабрь': 'December',

    'Января': 'January',
    'Февраля': 'February',
    'Марта': 'March',
    'Апреля': 'April',
    'Мая': 'May',
    'Июня': 'June',
    'Июля': 'July',
    'Августа': 'August',
    'Сентября': 'September',
    'Октября': 'October',
    'Ноября': 'November',
    'Декабря': 'December',

    'Оформление отклонения': 'Creating Deviation',
    'Количество отклонений по дате за период:': 'Number of Deviations by date for the period:',
    'Усредненное кол-во отклонений по часам за период:': 'Average number of Deviations by hours for the period:',
}
