import PathList from './../pathList';
import CardList from './../cardList';

export default {
    name: 'SettingsPage',
    components: {
        CardList,
        PathList,
        //todo
    },
    props: {
        clientId: {
            type: Number,
            default: null
        }
        //todo
    },
    data: () => ({
        loading: false,
        cards: [],
    }),
    watch: {
        //todo
    },
    mounted() {
        this.getCards();
    },
    methods: {
        getCards() {
            this.loading = true;
            window.myAjax('GET', '/ml/cards', {}, this.getCardsHandler);
        },
        getCardsHandler(error, response) {
            this.loading = false;
            if (!error) {
                this.cards = [];
                (response || []).forEach( card => {
                    this.cards.push(card);
                });
            }
        },
        updateCardsHandler() {
            this.getCards();
        }
    }
}
