/**
 * @name FilesList
 * @description
 * todo
 */
export default {
    name: 'FilesList',
    props: {
        files: {
            type: Array,
            default: []
        },
        canDeleted: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        // todo
    }),
    mounted() {
        // todo
    },
    methods: {
        fileSelectedHandler(file) {
            this.$emit('fileSelected', file);
        },
        fileDeletedHandler(file) {
            this.$emit('fileDeleted', file);
        }
    }
}
