/**
 * @name FilesButton
 * @description
 * Компонент кнопка для прикрепления файлов.
 */
export default {
    name: 'FilesButton',
    props: {
        /** Можно ли прикреплять несколько файлов. */
        multiple: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        button: null
    }),
    mounted() {
        const self = this;
        this.button = document.getElementById("dsv-files-input");
        this.button.addEventListener("change", function() {
            self.sendData(this.files);
        }, false);
        // this.handleFiles = this.handleFiles.bind(this);
    },
    beforeDestroy() {
        const self = this;
        this.button.removeEventListener("change", function() {
            self.sendData(this.files);
        });
    },
    methods: {
        sendData(files) {
            this.$emit('changed', files);
        }
    }
}
