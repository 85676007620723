export default {
    name: 'FormatList',
    components: {
        //todo
    },
    props: ['selectedObj'],
    data: () => ({
        loading: false,
        formats: [],

        created: null,
        selectedId: null,
    }),
    watch: {
        //todo
    },
    mounted() {
        this.getFormats();
    },
    methods: {
        getFormats() {
            this.loading = true;
            window.myAjax('GET', '/api/formats', {}, (error, response) => {
                this.loading = false;
                if (!error) {
                    this.formats = response.formats;
                }
            })
        },
        create() {
            this.created = {
                name: ''
            }
        },
        remove(formatId) {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить формат?', () => {
                this.loading = true;
                window.myAjax('DELETE', '/api/format', {
                    id: formatId
                }, (error) => {
                    if (!error) {
                        this.getFormats();
                    }
                })
            })
        },
        save(formatId) {
            this.loading = true;
            let finded;
            if (formatId) {
                finded = this.formats.find( format => format.id === formatId);
                if (!finded) {
                    return;
                }
            }
            window.myAjax(formatId ? 'PUT' : 'POST', '/api/format', {
                id: formatId,
                name: formatId ? finded.name : this.created.name
            }, (error) => {
                if (!error) {
                    this.created = null;
                    this.getFormats();
                }
            })
        }
    }
}
