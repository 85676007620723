import Graph from './../../reports/graph';

export default {
    name: 'SensorGraph',
    components: {
        Graph
    },
    props: {
        activeObject: {
            type: Object,
            default: {}
        },
        activeSensor: {
            type: Object
        }
    },
    data: () => ({
        loading: false,
        startDate: null,
        endDate: null,
        mode: 'graph', // graph || table
        data: [],
        rows: [],
        items: [],
    }),
    watch: {
        activeSensor: {
            handler() {
                this.getStates();
            }
        }
    },
    created() {
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);
        this.startDate = this.startDate.toISOString().slice(0,10);
        this.endDate = new Date().toISOString().slice(0, 10);
    },
    mounted() {
        this.getStates();
        this.applyWatcher();
    },
    methods: {
        applyWatcher() {
            this.$watch('startDate', () => {
                this.getStates();
            });
            this.$watch('endDate', () => {
                this.getStates();
            });
        },
        getStates() {
            if (!(this.activeSensor || {}).device_id) {
                return;
            }
            this.loading = true;
            const startTimeStamp = new Date(this.startDate);
            startTimeStamp.setMinutes(startTimeStamp.getMinutes() + startTimeStamp.getTimezoneOffset());
            const endTimeStamp = new Date(this.endDate);
            endTimeStamp.setHours(23, 59, 59);
            endTimeStamp.setMinutes(endTimeStamp.getMinutes() + endTimeStamp.getTimezoneOffset());
            if (this.endDate - this.startDate <= 0) {
                window.myAlert('Конечная дата должна быть начальный даты.')
                return;
            }
            window.myAjax('GET', `/sensor/unimon/device_sensor_states`, {
                device_id: this.activeSensor.device_id,
                sensor_code: this.activeSensor.code,
                start_timestamp: parseInt(Math.floor(startTimeStamp.valueOf() / 1000)),
                end_timestamp: parseInt(Math.floor(endTimeStamp.valueOf() / 1000)),
            }, (error, response) => {
                    if (!error) {
                        this.loading = false;
                        this.data = (response || []);
                        const _items = [];
                        this.items = [];
                        this.data.forEach(element => {
                            _items.push(Object.assign({}, element));
                        });
                        this.items = _items.reverse();
                    }
                }
            );
        },
        toggleMode() {
            this.mode = (this.mode === 'table' ? 'graph' : 'table');
        }
    }
}
