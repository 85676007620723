export default {
    inserted(el, binding) {
        if (binding.modifiers.time) {
            el.oninput = function (e) {
                if (!e.isTrusted) {
                    return;
                }
                const target = e.target;

                if (
                    e.inputType !== 'deleteContentBackward' &&
                    e.inputType !== 'deleteContentForward'
                ) {
                    const x = target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
                    target.value = x !== null ?
                        (x[1] <= 23 && x[1] >= 0 ? x[1] + ':' : '')
                        +
                        (x[2] <= 59 && x[2] >= 0 ? x[2] : '')
                    : '';
                    target.dispatchEvent(new Event('input'));
                }
            };
        }
    },
};