// import rolesUtils from './../roles/utils';
import usersService from './../users/utils';
  
export default {
    name: 'MenuNav',
    props: ['settingsOn', 'show_left_menu', 'showSettings', 'mode',
        'pushOn',
        'pushSupport',
        'monitoringTotal',
        'selectedLanguage',
        'languages',
        'userFio'
    ],
    data: () => ({
        menuPunkt: 'MainCams',

        isMobile: false,
        show_sub_menu: false,

        isExecutor: false,
        showNeural: false,
        showMods: false,

        // journalEnabled: false
        // contactsEnabled: false,
        // analyticEnabled: false,
        sensorsEnabled: false,
        neuralsEnabled: false,
        logsEnabled: false,

        // objectsEnabled: false
        // camerasEnabled: false,
        // digressionsEnabled: false,
        // usersEnabled: false,
        checklistsEnabled: false,
        priceTagsEnabled: false,

    }),
    watch: {
        showSettings: {
            handler(value) {
                console.log('value', value)
            }
        }
    },
    mounted() {
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        if (window.getCookie('userBaseRole') === 'Исполнитель') {
            this.isExecutor = true;
            this.toggleMenu('IZK');
        }

        this.sensorsEnabled = (window.features.find( feature => feature.feature_name === 'sensors') || {}).enabled;
        this.neuralsEnabled = (window.features.find( feature => feature.feature_name === 'ml') || {}).enabled;
        this.logsEnabled = (window.features.find( feature => feature.feature_name === 'logs') || {}).enabled;
        this.checklistsEnabled = (window.features.find( feature => feature.feature_name === 'checklists') || {}).enabled;
        this.priceTagsEnabled = (window.features.find( feature => feature.feature_name === 'price tag') || {}).enabled;

        this.showNeural = usersService.hasPermission('run ml tasks') || window.userBaseRole === 'Администратор';
    },
    methods: {
        toggleShowMods() {
            this.showMods = !this.showMods;
        },
        toggleMenu(menuPunkt) {
            this.menuPunkt = menuPunkt;
            if (menuPunkt === 'IZK') {
                window.izkSelected = true;
            } else {
                window.izkSelected = false;
            }
            window.localStorage.setItem('page', menuPunkt)
            window.$('.dsv-nav__item').removeClass('active')
            window.$('.dsv-nav__item[name='+menuPunkt+']').addClass('active')
            if (menuPunkt === 'Checklists') {
                window.checklistsMode = true;
                this.$emit('chapter', 'MainCams', {checklists: true});
                window.$("#objects-list-title").show();
                window.$("#template_list_tmp").hide();
                // setTimeout(function() { window.$("#objects-list-title").hide(); }, 5000);
                // if (window.$('#dsv-app').hasClass('dsv-mobile')) {
                //     window.toggleMenu();
                // }
            } else if (menuPunkt === 'MainCams') {
                this.$emit('chapter', menuPunkt);
                window.$("#objects-list-title").hide();
                window.$("#template_list_tmp").show();
            } else {
                this.$emit('chapter', menuPunkt);
            }
            return;
        },
        toggleLeftMenu() {
            this.$emit('toggleLeftMenu')
            window.toggleMenu();
        },

        toggleMenu2() {
            this.$emit('toggleMenu');
        },
        pushClick() {
            this.$emit('pushClick');
        },
        goToJournalHandler() {
            this.$emit('goToJournalHandler');
        },
        Logout() {
            this.$emit('Logout');
        },
    }
}
