import Pagination from './../../Pagination';

export default {
    name: 'LogsList',
    components: {
        Pagination,
    },
    data: () => ({
        logs: [],
        selected_role_id: 0,
        base_roles: [],
        users: [],
        total: null,
        pages: [],
        all_pages: null,
        request: {
            page: 0,
            limit: 25,
            user_id: 0,
            start_date: null,
            end_date: null,
            order_by: 'created_at', //(‘user_id’ | ‘created_at’),
            order: 'desc' // (‘asc’ | ‘desc’)
        }
    }),
    mounted() {
        // window.$('.scrolling_content').css('max-height', window.innerHeight-60)
        window.resizeScrollingContent();
        this.base_roles = []
        for(let i=0; i<window.myRoles.length; i++) {
            if(window.myRoles[i].parent_id==null)
                this.base_roles.push(window.myRoles[i])
        }
        this.filterUsers()
        this.getLogs(0)
    },
    methods: {
        filterUsers() {
            this.users = [];
            const unsortedusers = [];
            for(let i=0; i<window.myUsers.length; i++) {
                if(window.myUsers[i].profile!=undefined && (this.selected_role_id==0 || this.userHasBaseRole(window.myUsers[i])))
                unsortedusers.push(window.myUsers[i])
            }
            this.users = unsortedusers.sort( (a,b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1)
        },
        userFio(user_profile) {
            let res
            if(!user_profile)
                res = 'Профиль пользователя не создан'
            else if(user_profile.middle_name)
                res = user_profile.last_name + ' ' + user_profile.first_name + ' ' + user_profile.middle_name
            else
                res = user_profile.last_name + ' ' + user_profile.first_name
            return res
        },
        userHasBaseRole(user) {
            for(let i=0; i<user.roles.length; i++)
                if(this.selected_role_id==this.getBaseRoleId(user.roles[i]))
                    return true
            return false
        },
        getBaseRoleId(roleId) {
            const role = window.myRoles.find(r => r.id === roleId);
            if(role.parent_id==null)
                return role.id
            for(let i=0; i<window.myRoles.length; i++)
                if(window.myRoles[i].id == role.parent_id)
                    return this.getBaseRoleId(window.myRoles[i].id)
            return 0
        },

        dateStr(ts) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(ts),

                options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric'
                },
                date_str = date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            return date_str;
        },
        getLogs(page) {
            if(this.request.end_date && this.request.start_date>this.request.end_date) {
                window.myAlert('Дата ОТ больше даты ДО')
                return
            }
            this.request.page = page;
            window.myAjax('GET', '/api/logs', this.request, this.myCallbackGetLogs)
        },
        myCallbackGetLogs (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.logs = [];
                const now = new Date();
                const diff = now.getTimezoneOffset() / -60;
                response.logs.forEach(log => {
                    const times = log.record_datetime.split('.');
                    const day = times[0];
                    const month = times[1];
                    const other = times[2];
                    const date = new Date(`${month}-${day}-${other}`);
                    date.setHours(date.getHours() + diff);
                    this.logs.push(Object.assign(log, {
                        record_datetime: date.toLocaleString()
                    }));
                });
                let all_pages = 2 + parseInt(response.total / this.request.limit)
                if(response.total % this.request.limit==0)
                    all_pages--
                this.pages = []
                for(let i=1; i<all_pages; i++)
                    this.pages.push(i)
                window.$('.scrolling_content').animate({
                    scrollTop: 0
                }, 1000);
            }
        },
    }
}