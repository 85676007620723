<style lang="scss" src="./sass/app.scss"></style>
<style>
    :-webkit-full-screen {
        align-items: center;
        display: flex;
    }
    :-webkit-full-screen .hls-video-controls_smart {
        right: 20px;
    }
    :-webkit-full-screen .hls-temp-wrapper {
        display: none;
    }
    :-moz-full-screen {
        align-items: center;
        display: flex;
    }
    a:active, a:focus {
        outline: none;
    }
    input, textarea {
        outline:none;
    }
    input:active, textarea:active {
        outline:none;
    }
    :focus {
        outline:none;
    }
</style>

<template>
    <v-app class="dsv-app__wrap">
        <v-main>
            <div v-if="loading" class="dsv-loader__container" style="width: 100%; height: 100%">
                <div class="dsv-loader"></div>
            </div>
            <div id="dsv-app" :class="[isMobile ? 'dsv-mobile' : '']" v-else-if="auth">
                <!-- <chat-room v-if="!superAdmin" /> -->
                <LkMainSuper v-if="superAdmin"
                    @logoutUser="auth = false"
                    @enterToClient="superAdmin = false"/>
                <LkMain v-else ref="lk-main" @logoutUser="checkSuperadmin"/>
            </div>
            <div v-else>
                <Login @loginUser="loginUser($event)"/>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import Login from './components/Login';
import LkMain from './components/LkMain';
import LkMainSuper from './components/superadminka/LkMainSuper';

const CACHE_MAX_AGE = 28800;
const TOKEN_AGE = 300000;

export default {
    name: 'myApp',

    components: {
        Login,
        LkMain,
        LkMainSuper
    },

    data: () => ({
        auth: false,
        isMobile: false,
        superAdmin: false,
        loading: false
    }),
    created() {
        window.addEventListener("resize", this.resizeHandler);
    },
    mounted() {
        window.features = [];
        this.resizeHandler();
        let superAdminToken = window.getCookie('superAdminToken');
        let userToken = window.getCookie('userToken');
        let refreshToken = window.getCookie('refreshToken');
        if (
            (userToken || []).length > 0 &&
            (refreshToken || []).length > 0 &&
            !superAdminToken
        ) {
            this.loading = true;
            window.$.ajax({
                type: 'POST',
                url: '/api/refresh',
                headers: {
                    "Authorization": "Bearer " + userToken,
                    "Cache-Control": "max-age=" + CACHE_MAX_AGE 
                },
                data: {
                    refresh_token: refreshToken
                },
                success: (response) => {
                    window.setCookieTime('userToken', response.token, 1);
                    window.setCookie('refreshToken', response.refresh_token);
                    window.userBaseRole = window.getCookie('userBaseRole');

                    window.direct_permissions = response.direct_permissions;

                    window.userRoles = response.user_roles;
                    if(response.profile==undefined) {
                        window.dolgAndFio = window.userBaseRole
                        window.setCookieTime('userId', '', 1)
                        window.setCookieTime('userFio', '', 1)
                        window.setCookieTime('userBaseRole', window.userBaseRole, 1)
                    } else {
                        window.dolgAndFio = response.profile.last_name + ' ' + response.profile.first_name
                        window.setCookieTime('userId', response.profile.user_id, 1)
                        window.setCookieTime('userFio', window.dolgAndFio, 1)
                        if (response.profile.client) {
                            window.setCookieTime('company_name', response.profile.client.name, 1)
                        }
                        window.setCookieTime('userBaseRole', window.userBaseRole, 1)
                    }

                    setTimeout( () => {
                        const savedMain = window.localStorage.getItem('page');
                        if (savedMain) {
                            if (
                                savedMain === 'MainCams' ||
                                savedMain === 'IZK' ||
                                savedMain === 'Reports' ||
                                savedMain === 'AnalyticReports' ||
                                savedMain === 'Neural' ||
                                savedMain === 'Contacts' ||
                                savedMain === 'Calendar'
                            ) {
                                if (this.$refs['lk-main']) {
                                    this.$refs['lk-main'].settingsPunkt(savedMain);
                                }
                                window.$('.dsv-nav__item').removeClass('active');
                                window.$('.dsv-nav__item[name='+savedMain+']').addClass('active');
                            }
                        }
                    }, 150);
                    this.nextRefreshToken();
                    if (window.userBaseRole != 'Суперадминистратор') {
                        window.myAjax('GET', '/api/users', {}, (uerror, uresponse) => {
                            if (!uerror) {
                                const user = (uresponse.users || []).find(u => u.id == (response.profile || {}).user_id);
                                if (user) {
                                    window.permissions = user.permissions || [];
                                }
                                if ((user.profile || {}).client_id) {
                                    window.myAjax('GET', '/api/client_features', {
                                        client_id: user.profile.client_id
                                    }, (cferror, cfresponse) => {
                                        this.loading = false;
                                        if (!cferror) {
                                            window.features = cfresponse;
                                            this.auth = true;
                                        }
                                    });
                                }
                            }
                        });
                    } else {
                        this.auth = true;
                    }
                },
                error: () => {
                    this.loading = false;
                    window.localStorage.removeItem("page");
                }
            })
        }
    },
      methods: {
        nextRefreshToken() {
            setTimeout(() => {
                window.myAjax('POST', '/api/refresh', {
                    refresh_token: window.getCookie('refreshToken')
                }, (error, response) => {
                    if (!error && response) {
                        window.setCookie('userToken', response.token);
                        window.setCookie('refreshToken', response.refresh_token);
                        this.nextRefreshToken();
                    }
                } )
            }, TOKEN_AGE)
        },
        /**
        * Обработчик измения размера экрана.
        */
        resizeHandler() {
            if (window.innerWidth <= 1024) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        loginUser(superAdmin) {
            if(superAdmin) {
                this.superAdmin = true
                window.setCookieTime('superAdminToken', window.getCookie('userToken'), 1)
            } else {
                this.superAdmin = false
                window.deleteCookie('superAdminToken');
            }
            this.auth = true;
        },
        checkSuperadmin() {
            let superAdminToken = window.getCookie('superAdminToken')
            if (superAdminToken.length) {
                window.setCookieTime('userToken', superAdminToken, 1)
                window.userBaseRole = 'Суперадминистратор'
                // window.deleteCookie('superAdminToken')
                this.superAdmin = true
            } else {
                window.deleteCookie('refreshToken');
                window.deleteCookie('superAdminToken');
                window.localStorage.removeItem("page");
                this.auth = false
            }
        }
      }
};
</script>
