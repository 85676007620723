import BaseButton from './../../base/button';

export default {
    name: 'ChecklistPanelCard',
    components: {
        BaseButton
    },
    props: {
        // todo
    },
    data: () => ({
        loading: null,
        checklists: [],
        filteredChecklists: [],
        filter: '',
    }),
    watch: {
        filter: {
            handler() {
                this.filterChecklists();
            }
        }
    },
    mounted() {
        this.getChecklists();
    },
    methods: {
        getChecklists() {
            window.myAjax('GET', '/api/checklists', {}, (error, response) => {
                if (!error) {
                    this.checklists = response.check_lists || [];
                    this.filterChecklists();
                }
            });
        },
        filterChecklists() {
            const searchString = this.filter.trim();
            this.filteredChecklists = [];
            if (searchString.length) {
                this.checklists.forEach(checklist => {
                    if (checklist.caption.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                        this.filteredChecklists.push(checklist);
                    }
                });
            } else {
                this.filteredChecklists = this.checklists;
            }
        }
    }
}
