/**
 * @param {*} id - Id отклонения.
 */
 export function getDigressionWatchers(params, callback) {
    const url = '/api/digression-record-watchers';
    const method = 'GET';
    window.myAjax(method, url, {
        digression_record_id: params.id
    }, function(error, response) {
        if (error) {
            window.myAlert(response.message || 'Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_list_id - Id отклонения.
 * @param {*} contact_id - Id контакта.
 */
 export function addDigressionWatchers(params, callback) {
    const url = '/api/digression-record-watchers';
    const method = 'POST';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert('Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}

/**
 * @param {*} digression_list_id - Id отклонения.
 * @param {*} contact_id - Id контакта.
 */
 export function removeDigressionWatchers(params, callback) {
    const url = '/api/digression-record-watchers';
    const method = 'DELETE';
    window.myAjax(method, url, params, function(error, response) {
        if (error) {
            window.myAlert('Ошибка в запросе.');
        } else {
            callback(response);
        }
    });
}
