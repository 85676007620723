export default {
    name: 'EditChecklist',
    components: {
        // ...
    },
    data: () => ({
        caption: null
    }),
    mounted() {
        // todo
    },
    methods: {
        /** Обработчик клика по кнопке Отменить. */
        cancelHandler() {
            this.$emit('cancel');
        },

        /** Обработчик клика по кнопке Сохранить. */
        saveHandler() {
            this.$emit('save', {
                caption: this.caption
            });
        }
    }
}
